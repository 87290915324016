import React, { useContext } from 'react'
import { Heading } from 'grommet'
import { FormContext } from '.'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import Text from '../../../../components/simple/text/Text'

export const StepHeader = () => {
  const { activeIndex, activeStep, id, steps } = useContext(FormContext)
  return (
    <Box id={id} gap='xsmall' flex={false}>
      {/* <Text>
        Step {activeStep} of {steps.length}
      </Text> */}
      <Heading margin='none' size='small'>
        {steps[activeIndex].title || `Step ${activeStep} Title`}
      </Heading>
      <Divider color='primary' margin={{ vertical: 'small' }} />
    </Box>
  )
};
