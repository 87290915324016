// Useful Object functions

// Get Key by Value
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

// Get an Object from an array by field and value
export const getObjectByValue = (arr, field, value) => {
  const result = arr.filter(function (o) {
    return o[field] === value
  })

  return result ? result[0] : null
}

export default { getKeyByValue, getObjectByValue }
