import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import moment from 'moment'

// Error handler
import useErrorHandler from '../utils/useErrorHandler'
import FlashMessage from '../utils/FlashMessage'
import { capitalize } from '../utils/text'

// Services
import FormService from '../services/FormService'
import LocalStorageService from '../services/LocalStorageService'
import NotificationMessagesService from '../services/NotificationMessagesService'
import OrganisationService from '../services/OrganisationService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'
import List from '../components/simple/list/List'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'
import H3 from '../components/simple/heading/H3'

// Appt Widgets
import CounterBox from '../components/compound/counterBox/CounterBox'
import ListBox from '../components/compound/listBox/ListBox'

import { hasAnyPermissionIn, isCurrentOrgAdmin } from '../utils/permissions'
import { WifiNone } from 'grommet-icons'


function Dashboard (props) {
  const history = useHistory()
  const { error, showError } = useErrorHandler(null)

  // const [apiToken, setApiToken, deleteApiToken] = useStore()
  // const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  // const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  // const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const activeUser = LocalStorageService.get('userDetails')

  const apiToken = LocalStorageService.get('apiToken')

  const [dashboardState, setDashboardState] = useState({
    users: 0,
    students: 0
  })

  // Store the notifications
  const [notifications, setNotifications] = useState([])

  // Mark notification as read
  const notificationRead = async (id) => {
    (async () => {
      await NotificationMessagesService.read(id)
      await getNotifications()
    })()
  }


  const openForm = (type, formId, action = 'view') => {
    history.push(`/form/${type}/${formId}/${action}`)
  }

  const [formsRequiringFeedback, setFormsRequiringFeedback] = useState([])
  const getFormsRequiringFeedback = async () => {
    const params = {
      fields: 'id,user,subject,type,status,createdAt,feedbackRequired,feedbackRequiredBy,revisionRequired',
      limit: 1000,
      sort: 'createdAt DESC',
      orgId: activeOrganisation.id,
      siteId: activeSite.id
    }
    const where = {
      organisationId: activeOrganisation.id,
      feedbackRequired: true,
      revisionRequired: false,
      feedbackRequiredBy: activeUser.id,
      status: 'Draft'
    }
    const submissions = await FormService.getSubmissions(apiToken, params, where)
    if (submissions?.data?.length) {
      const mappedForms = submissions.data.map((form) => {
        return {
          forms: (
            <Box direction='column'>
              <Box direction='row' margin={{ left: 'none' }}>
                <Text>{form.type ? `${form.type} ` : ''}</Text>
                <Button
                  margin={{ left: 'small', top: '-2px' }}
                  onClick={() => { openForm(form.type, form.id) }}
                  plain
                >
                  <FontAwesome color='brand' icon={['fal', 'pencil']} size='sm'/>
                  <Text weight={400} size='small' color='brand' >View</Text>
                </Button>
              </Box>
              <Box direction='row' margin={{ bottom: 'medium', left: 'none' }}>
                <Text size='xsmall'>{moment(form.createdAt).fromNow()}</Text>
              </Box>
            </Box>)
        }
      })
      setFormsRequiringFeedback(mappedForms)
    }
  }
  useEffect(() => {
    getFormsRequiringFeedback()
  }, [])


  const [formsRequiringRevision, setFormsRequiringRevision] = useState([])
  const getFormsRequiringRevision = async () => {
    const params = {
      fields: 'id,user,subject,type,status,createdAt,feedbackRequired,feedbackRequiredBy,revisionRequired',
      limit: 1000,
      sort: 'createdAt DESC',
      orgId: activeOrganisation.id,
      siteId: activeSite.id,
      where: {
        orgId: activeOrganisation.id,
        user: activeUser.id,
        revisionRequired: true,
        status: 'Draft'
      }
    }
    const submissions = await FormService.getSubmissions(apiToken, params)
    if (submissions?.data?.length) {
      const mappedForms = submissions.data.map((form) => {
        // if (form.revisionRequired) {
          return {
            forms: (
              <Box direction='column'>
                <Box direction='row' margin={{ left: 'none' }}>
                  <Text>{form.type ? `${form.type} ` : ''}</Text>
                  <Button
                    margin={{ left: 'small', top: '-2px' }}
                    onClick={() => { openForm(form.type, form.id, 'feedback') }}
                    plain
                  >
                    <FontAwesome color='brand' icon={['fal', 'pencil']} size='sm' />
                    <Text weight={400} size='small' color='brand' >View</Text>
                  </Button>
                </Box>
                <Box direction='row' margin={{ bottom: 'medium', left: 'none' }}>
                  <Text size='xsmall'>{moment(form.createdAt).fromNow()}</Text>
                </Box>
              </Box>)
          // }
        }
      })
      setFormsRequiringRevision(mappedForms)
    }
  }
  useEffect(() => {
    getFormsRequiringRevision()
  }, [])


  // Get the notifications
  const getNotifications = async () => {
    const params = {
      // fields: 'createdAt,id,message',
      limit: 1000,
      orgId: activeOrganisation.id,
      where: {
        read: false
      }
    }

    const where = {
    }
    // const where = {
    //   organisationId: activeOrganisation.id
    // }

    const notifications = await NotificationMessagesService.get(params, where)
    if (notifications?.data) {

      const filteredNotifications = notifications.data.filter((item) => !item.read)

      const mappedNotifications = filteredNotifications.map((item) => {
        return {
          notification: (
            <Box direction='column'>
              <Box direction='row' margin={{ left: 'none' }}>
                <Text>{item.type ? `${capitalize(item.type)}: ` : ''}{item.message}</Text>
                <Button
                  margin={{ left: 'small', top: '-2px' }}
                  onClick={() => { notificationRead(item.id) }}
                  plain
                >
                  <FontAwesome color='brand' icon={['fal', 'minus-circle']} size='sm'/>
                </Button>
              </Box>
              <Box direction='row' margin={{ bottom: 'medium', left: 'none' }}>
                <Text size='xsmall'>{moment(item.createdAt).fromNow()}</Text>
              </Box>
            </Box>)
        }
      })

      setNotifications(mappedNotifications)
    }
  }

  // Get number of Users for this Organisation
  const getDashboardCounts = async () => {
    const apiToken = LocalStorageService.get('apiToken')

    var userCount = {
      users: 0,
      students: 0
    }
    userCount.users = await OrganisationService.getUserCount(apiToken, 'staff')
    userCount.students = await OrganisationService.getUserCount(apiToken, 'client')
    getNotifications()

    setDashboardState(userCount)
  }

  useEffect(() => {
    getDashboardCounts()
  }, [props.activeOrg])

  return (
    <>
      <Box elevation='small' flex='grow' background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Dashboard </H1>
          {error &&
            <FlashMessage message={error} />}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box direction='row-responsive' flex='grow' gap='small' margin={{ bottom: 'medium' }}>
        <Anchor href='/clients' width='100%'>
          <CounterBox counter={dashboardState.students || 0} iconColor='#52bc94' icon='user'>Clients</CounterBox>
        </Anchor>
        {(hasAnyPermissionIn(['staffViewMy', 'staffViewAll']) || isCurrentOrgAdmin()) &&
          <Anchor width='100%' href={(hasAnyPermissionIn(['staffEditMy', 'staffEditAll']) || isCurrentOrgAdmin()) ? '/staff' : '/dashboard' }>
            <CounterBox counter={dashboardState.users || 0} iconColor='#52bc94' icon='user-friends'>Staff</CounterBox>
          </Anchor>}
      </Box>

      <Box direction='row-responsive' flex='grow' gap='medium'>
        <Box background='white' elevation='small' direction='column' gap='medium' pad='medium' round='small' width='large'>
          <H3 margin={{ vertical: 'none' }} weight='bold'>Notifications</H3>
          {!notifications.length && <Text size='small'>There are no notifications to display.</Text>}

          <List border={false} data={notifications} pad={{ left: 'none' }} primaryKey='notification' />
        </Box>
      </Box>

      <Box direction='row-responsive' flex='grow' gap='medium' margin={{ top: 'medium' }}>
        <Box background='white' elevation='small' direction='column' gap='medium' pad='medium' round='small' width='large'>
          <H3 margin={{ vertical: 'none' }} weight='bold'>Forms Requiring Approval</H3>
          {!formsRequiringFeedback.length && <Text size='small'>There are no forms to display.</Text>}
          <List border={false} data={formsRequiringFeedback} pad={{ left: 'none' }} primaryKey='forms' />
        </Box>
      </Box>

      <Box direction='row-responsive' flex='grow' gap='medium' margin={{ top: 'medium' }}>
        <Box background='white' elevation='small' direction='column' gap='medium' pad='medium' round='small' width='large'>
          <H3 margin={{ vertical: 'none' }} weight='bold'>Forms Requiring Revision</H3>
          {!formsRequiringRevision.length && <Text size='small'>There are no forms to display.</Text>}
          <List border={false} data={formsRequiringRevision} pad={{ left: 'none' }} primaryKey='forms' />
        </Box>
      </Box>

    </>
  )
}

export default Dashboard
