import React, { useContext, useEffect, useRef, useState } from "react";

import { ThemeContext } from "styled-components";

import { useHistory, useParams } from "react-router-dom";

import { ResponsiveContext } from "grommet";

import {
  CancellationLayer,
  Error,
  StepFooter,
  StepContent,
  FormContext,
  FormHeader,
} from "./shared";

import { getWidth } from "./utils/utils";

import moment from "moment";

import { getObjectByValue } from "../../../utils/objects";

// Services
import FormService from "../../../services/FormService";
import LocalStorageService from "../../../services/LocalStorageService";
import NotificationService from "../../../services/NotificationService";
import UserTagService from "../../../services/UserTagService";
import UserService from "../../../services/UserService";

// Appt
import Anchor from "../../../components/simple/anchor/Anchor";
import Box from "../../../components/simple/box/Box";
import Text from "../../../components/simple/text/Text";

// Shared Components
import BreadcrumbBar from "../../shared/BreadcrumbBar";

// Steps
import StepOneReferrerDetails from "./steps/stepOne-ReferrerDetails";
import StepTwoClientDetails from "./steps/stepTwo-ClientDetails";
import StepThreeClientDetails from "./steps/stepThree-ClientDetails";
import StepFourClientDetails from "./steps/stepFour-ClientDetails";
import StepFiveClientDetails from "./steps/stepFive-ClientDetails";

export const defaultFormValues = {
  // step One
  feelingNervous: "",
  controlWorrying: "",
  worrying: "",
  notRelaxing: "",
  restless: "",
  irritable: "",
  feelingAfraid: "",
  doWork: "",
};

const questions = [
  // step One
  {
    questionText: "Name of Referrer",
    key: "referrerName",
  },
  {
    questionText: "Relationship to Client",
    key: "clientRelationship",
  },
  {
    questionText: "Telephone Number",
    key: "referrerPhone",
  },
  {
    questionText: "Email Address",
    key: "referrerEmail",
  },
  {
    questionText: "Referrer Organisation",
    key: "referrerOrganisation",
  },
  {
    questionText: "Referrer Organisation Other",
    key: "referrerOrganisationOther",
  },
  {
    questionText: "Other Agency - Social Worker",
    key: "otherAgencySocialWorker",
  },
  {
    questionText: "Other Agency - Probation Officer",
    key: "otherAgencyProbationOfficer",
  },
  {
    questionText: "Other Agency - CPN/Crisis team",
    key: "otherAgencyCPNCrisis",
  },
  {
    questionText: "Other Agency - Housing Support",
    key: "otherAgencyHousingSupport",
  },
  {
    questionText: "Other Agency - School/College",
    key: "otherAgencySchoolCollege",
  },
  {
    questionText: "Other Agency - Other(s)",
    key: "otherAgencyOthers",
  },
  // step Two
  {
    questionText: "Client Last Name",
    key: "clientLastName",
  },
  {
    questionText: "Client First Name",
    key: "clientFirstName",
  },
  {
    questionText: "Known As",
    key: "clientKnownAs",
  },
  {
    questionText: "Date of Birth",
    key: "clientDOB",
  },
  {
    questionText: "Marital Status",
    key: "clientMaritalStatus",
  },
  {
    questionText: "Faith/Religion",
    key: "clientFaith",
  },
  {
    questionText: "Childcare Required",
    key: "clientChildcareRequired",
  },
  {
    questionText: "Nationality",
    key: "clientNationality",
  },
  {
    questionText: "Primary Language",
    key: "clientPrimaryLanguage",
  },
  {
    questionText: "Gender",
    key: "clientGender",
  },
  {
    questionText: "Address Line One",
    key: "clientAddressLineOne",
  },
  {
    questionText: "Address Line One",
    key: "clientAddressLineTwo",
  },
  {
    questionText: "City",
    key: "clientCity",
  },
  {
    questionText: "Postcode",
    key: "clientPostcode",
  },
  {
    questionText: "Housing Situation",
    key: "clientHousingSituation",
  },
  {
    questionText: "Housing Situation Other",
    key: "clientHousingSituationOther",
  },
  {
    questionText: "Phone Number",
    key: "clientPhone",
  },
  {
    questionText: "Email Address",
    key: "clientEmail",
  },
  {
    questionText: "Preferred Contact Method",
    key: "clientPreferredContactMethod",
  },
  {
    questionText: "Safeguarding",
    key: "clientSafeguarding",
  },
  {
    questionText: "Type of exploitation",
    key: "clientExploitationType",
  },
  {
    questionText: "Children",
    key: "children",
  },
  // Hidden fields
  {
    questionText: "Client Name",
    key: "clientName",
  },
  {
    questionText: "Child first name",
    key: "firstNameChild",
  },
  {
    questionText: "Child last name",
    key: "lastNameChild",
  },
  {
    questionText: "Child dob",
    key: "dobChild",
  },

  // Step Three
  {
    questionText: "Trafficking Status",
    key: "traffickingStatus",
  },
  {
    questionText: "UK Status",
    key: "ukStatus",
  },
  {
    questionText: "Income",
    key: "income",
  },
  {
    questionText: "English",
    key: "english",
  },
  {
    questionText: "Literacy 1st Language",
    key: "literacyFirstLanguage",
  },
  {
    questionText: "Literacy English",
    key: "literacyEnglish",
  },
  {
    questionText: "Interpreter",
    key: "interpreter",
  },
  {
    questionText: "Solicitor Name and Organisation",
    key: "solicitorName",
  },
  {
    questionText: "Solicitor Contact Details",
    key: "solicitorContactDetails",
  },
  {
    questionText: "Complaint against Traffickers",
    key: "traffickerComplaint",
  },
  {
    questionText: "Police Investigation",
    key: "policeInvestigation",
  },
  {
    questionText: "Investigation Information",
    key: "policeInvestigationDetails",
  },
  // Step Four
  {
    questionText:
      "Does the client have a disability that we need to consider when we offer counselling?",
    key: "disability",
  },
  {
    questionText: "Disability Details",
    key: "disabilityDetails",
  },
  {
    questionText: "GP",
    key: "gp",
  },
  {
    questionText: "Psychiatrist",
    key: "psychiatrist",
  },
  {
    questionText: "Other Health Professional",
    key: "otherHealthProfessional",
  },
  {
    questionText: "Current Medication",
    key: "currentMedication",
  },
  {
    questionText: "Physical Diagnoses",
    key: "physicalDiagnoses",
  },
  {
    questionText: "Substance Abuse",
    key: "substanceAbuse",
  },
  {
    questionText: "Mental Health Diagnoses",
    key: "mentalHealthDiagnoses",
  },
  {
    questionText: "Has the client received counselling before",
    key: "receivedCounsellingBefore",
  },
  {
    questionText: "Previous Counsellor",
    key: "counsellingDetails",
  },
  // Step Five
  {
    questionText:
      "Please provide brief details regarding the client’s exploitation/trafficking",
    key: "traffickingDetails",
  },
  {
    questionText:
      "Length of time the client has been out of exploitation/trafficking",
    key: "traffickingLength",
  },
  {
    questionText: "Presenting Issues",
    key: "presentingIssues",
  },
];

export const steps = [
  {
    description: <></>,
    inputs: <StepOneReferrerDetails />,
    title: "Referrer Details",
    // header: true // identifies a large circle in the header
  },
  {
    description: <></>,
    inputs: <StepTwoClientDetails />,
    title: "Client Details",
    // header: true // identifies a large circle in the header
  },
  {
    description: <></>,
    inputs: <StepThreeClientDetails />,
    title: "Client Details",
    // header: true // identifies a large circle in the header
  },
  {
    description: <></>,
    inputs: <StepFourClientDetails />,
    title: "Client Details",
    // header: true // identifies a large circle in the header
  },
  {
    description: <></>,
    inputs: <StepFiveClientDetails />,
    title: "Client Details",
    // header: true // identifies a large circle in the header
  },
];

function CounsellingReferral(props) {
  const history = useHistory();

  const params = useParams();
  const userId = params.userid;

  const apiToken = LocalStorageService.get("apiToken");
  const activeOrganisation = LocalStorageService.get("activeOrg");
  const activeUser = LocalStorageService.get("userDetails");
  const activeSite = LocalStorageService.get("activeSite");

  const size = useContext(ResponsiveContext);
  const theme = useContext(ThemeContext);
  const [activeIndex, setActiveIndex] = useState(0);
  // for readability, this is used to display numeric value of step on screen,
  // such as step 1 of 3. it will always be one more than the active array index
  const [activeStep, setActiveStep] = useState(activeIndex + 1);

  // store form values in state so they persist
  // when user goes back a step
  const [formValues, setFormValues] = useState(defaultFormValues);

  // controls state of cancel layer
  const [open, setOpen] = useState(false);

  // tracks validation results for the current step
  const [valid, setValid] = useState(true);

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false;

    switch (typeof input) {
      case "string":
        inputHasContent = input.length > 0;
        break;
      case "number":
        inputHasContent = input > 0;
        break;
      case "boolean":
        break;
      case "object":
        inputHasContent = true;
        // inputHasContent = input.length > 0
        break;
    }

    return inputHasContent;
  };

  const createClientTags = async (formDetailsToSave, userId) => {
    const userTags = [];
    let item = {};

    // Loop through all the questions
    // To build an array for User Tag
    // saving from those marked as tag
    for (var key of questions) {
      if (checkInputHasContent(formDetailsToSave[key.key])) {
        item = {
          user: userId[0],
          organisation: activeOrganisation.id,
          key: key.key,
          value: formDetailsToSave[key.key],
          type: "general",
        };
        userTags.push(item);
      }
    }

    userTags.push({
      user: userId[0],
      organisation: activeOrganisation.id,
      key: 'referredDateFirst',
      value: new Date(),
      type: 'general',
      sensitivity: 'personal'
    })

    userTags.push({
      user: userId[0],
      organisation: activeOrganisation.id,
      key: 'referredDateCounselling',
      value: new Date(),
      type: 'general',
      sensitivity: 'personal'
    })

    const response = await UserTagService.set(apiToken, userTags);
    if (response?.error) {
      NotificationService.error(response.error);
    }
  };

  const createClient = async (formDetailsToSave) => {
    var data = {
      // reference: formDetailsToSave?.reference,
      firstName: formDetailsToSave?.clientFirstName,
      lastName: formDetailsToSave?.clientLastName,
      // email: formDetailsToSave?.email,
      gender: formDetailsToSave?.clientGender,
      // ethnicity: formDetailsToSave?.ethnicity,
      type: "client",
    };

    if (formDetailsToSave.clientDOB) {
      data.dob = moment(formDetailsToSave.clientDOB).format("YYYY-MM-DD");
    }

    const dataToSubmit = {
      data: data,
      organisation: activeOrganisation.id,
    };

    const params = {
      orgId: activeOrganisation.id,
    };

    var userAdded = await UserService.createUser(
      apiToken,
      dataToSubmit,
      params
    );

    if (userAdded.error) {
      NotificationService.error(userAdded.error);
    } else {
      createClientTags(formDetailsToSave, userAdded.data.id);
    }

    return userAdded.data.id[0]
  };

  const submitForm = async () => {
    console.log(formValues);

    const formValuesToSubmit = formValues;

    // Clear some fields if they've been entered
    // but then decision has changed
    if (formValues.referrerOrganisation !== "other") {
      formValuesToSubmit.referrerOrganisationOther = "";
    }

    if (formValues.policeInvestigation !== "Yes") {
      formValuesToSubmit.policeInvestigationDetails = "";
    }

    if (formValues.disability !== "Yes") {
      formValuesToSubmit.disabilityDetails = "";
    }

    if (formValues.receivedCounsellingBefore !== "Yes") {
      formValuesToSubmit.counsellingDetails = "";
    }

    var submitArray = [];
    var item = {
      key: "",
      questionText: "",
      answer: "",
    };

    // Loop through each form value
    let question = "";

    for (var key in formValuesToSubmit) {
      // console.log(key)

      question = getObjectByValue(questions, "key", key);

      // Validate value entered here ?

      // question will be undefined until we have entered all questions
      if (question !== undefined && formValuesToSubmit[key] !== "") {
        item = {
          questionKey: key,
          questionText: question.questionText,
          response: formValuesToSubmit[key],
        };
        submitArray.push(item);
      }
    }

    console.log(submitArray);

    const clientId = await createClient(formValues);

    // Build output data
    const data = {
      user: activeUser.id,
      subject: clientId,
      organisation: activeOrganisation.id,
      // status: draft ? 'Draft' : 'Published',
      status: "Published",
      type: "counsellingreferral",
      reference: "",
      site: activeSite.id,
      // notify: JSON.stringify(notifyArray),
      notify: JSON.stringify(formValuesToSubmit.notifyStaff || []),
      responses: submitArray,
    };

    var submissionAdded = await FormService.makeSubmissions(apiToken, data);
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error);
    } else {
      NotificationService.info("Counselling Referral Form added");
      history.push('/clients')
    }
  };

  useEffect(() => {
    const getResponses = async () => {
      const thisParams = {
        fields: 'id,questionKey,questionText,response',
        limit: 2000
      }
      const where = {
        submission: params.formId
      }

      const responses = await FormService.getResponses(apiToken, thisParams, where)

      const savedResponses = {}

      responses.data.forEach(element => {
        let thisValue = element.response

        if (element.questionKey === 'clientName') {
          thisValue = parseInt(element.response)
        }
        savedResponses[element.questionKey] = thisValue


      })

      console.log("setFormDetails", savedResponses)

      setFormValues(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (params.formId !== 'new') {
      getResponses()
    }

  }, [])

  // ref allows us to access the wizard container and ensure scroll position
  // is at the top as user advances between steps. useEffect is triggered
  // when the active step changes.
  const formRef = useRef();

  useEffect(() => {
    setActiveStep(activeIndex + 1);
  }, [activeIndex]);

  const id = "simple-wizard";
  // scroll to top of step when step changes
  useEffect(() => {
    const container = formRef.current;
    const header = document.querySelector(`#${id}`);
    container.scrollTop = -header.getBoundingClientRect().bottom;
  }, [activeIndex, open]);

  const numberColumns = 2;
  const width = getWidth(numberColumns, theme, size);
  return (
    <FormContext.Provider
      value={{
        activeIndex,
        id,
        defaultFormValues,
        setActiveIndex,
        activeStep,
        setActiveStep,
        valid,
        ref: formRef,
        setValid,
        steps,
        formValues,
        setFormValues,
        formTitle: "Counselling Referral Form",
        width,
      }}
    >
      <Box fill>
        {/* Breadcrumb section */}
        <BreadcrumbBar
          path={
            <>
              <Anchor href="/">Snowdrop CRM</Anchor>
              <Text color="brand" size="xsmall">
                {history.location.pathname}
              </Text>
            </>
          }
        >
          FQ Forms:
          {/* FQ Forms: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'} */}
        </BreadcrumbBar>

        {/* <FormHeader setOpen={setOpen} /> */}

        <StepContent
          // onSubmit={({ value }) => console.log('onSubmit:', value)}
          onSubmit={({ value }) => submitForm()}
        />
        <StepFooter />
      </Box>
      {open && <CancellationLayer onSetOpen={setOpen} />}
    </FormContext.Provider>
  );
}

export default CounsellingReferral;
