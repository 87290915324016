export const content = {
  reports: {
    title: 'Reports',
    subtitle: 'Available Reports',
    location_main_path: 'Snowdrop',
    type_label: 'Export Data',
    filter: {
      title: 'Filters',
      fields_labels: {
        field_label: 'Field',
        query_label: 'Query',
        value_label: 'Value'
      },
      add_criteria_button_label: 'Add Criteria',
      back_button_label: '< Back',
      export_button_label: 'Export Results',
      get_button_label: 'Get Results'
    }
  }
}
