import React, { useContext, useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { ResponsiveContext } from 'grommet'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserGroupService from '../../services/UserGroupService'
import UserGroupMemberService from '../../services/UserGroupMemberService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'
import TextArea from '../../components/simple/input/TextArea'

// import { Error } from './Error'

function PartnerEdit (props) {
  const history = useHistory()

  const size = useContext(ResponsiveContext)

  const params = useParams()

  const partnerId = params.partnerid

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [userValues, setUserValues] = useState({
    assignedTo: parseInt(props.location.partnerorgid),
    firstName: '',
    lastName: '',
    address: '',
    postcode: '',
    city: '',
    mobile: '',
    email: '',
    job: '',
    team: '',
    notes: ''
  })

  const [partnerOrgs, setPartnerOrgs] = useState([])

  const [valid, setValid] = useState(true)

  // partnerOrgId set from previous screen
  // This will always be set to the previously
  // saved UserGroupMember usergroup value
  const [partnerOrgId, setPartnerOrgId] = useState(parseInt(props.location.partnerorgid))

  const onValidate = (validationResults) => {
    const names = [
      ...Object.keys(validationResults.errors),
      ...Object.keys(validationResults.infos)
    ]
    if (names.length > 0) {
      const selector = names.map((name) => `[name=${name}]`).join(',')
      const firstInvalid = document.querySelectorAll(selector)[0]
      if (firstInvalid) {
        setTimeout(() => firstInvalid.focus(), 0)
      }
    }
    setTimeout(() => setValid(names.length === 0), 0)
  }

  // Assign Partner to a PartnerOrg
  const assignPartner = async (usergroupId, partnerId) => {
    // First delete the old UserGroupMember record if one existed
    const memberDeleted = await UserGroupMemberService.findAndDeleteMember(apiToken, partnerOrgId, partnerId)

    const dataToSubmit = {
      data: {
        usergroup: usergroupId,
        user: parseInt(partnerId),
        organisation: activeOrg.id
      }
    }

    const memberCreated = await UserGroupMemberService.createMember(apiToken, dataToSubmit)
    if (memberCreated.error) {
      NotificationService.error(memberCreated.error)
    }
  }

  // Submit form values
  const submitForm = async (values) => {
    const data = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      addressLineOne: values?.addressLineOne,
      postcode: values?.postcode,
      city: values?.city,
      mobile: values?.mobile,
      email: values?.email,
      job: values?.job,
      team: values?.team,
      notes: values?.notes,
      type: 'partnerContact'
    }

    if (partnerId === 'new') {
      const dataToSubmit = {
        data: data,
        organisation: activeOrg.id
      }

      const params = {
        orgId: activeOrg.id
      }

      const userAdded = await UserService.createUser(apiToken, dataToSubmit, params)
      if (userAdded.error) {
        NotificationService.error(userAdded.error)
      } else {
        const newPartnerId = userAdded.data.id[0]
        await assignPartner(values.assignedTo, newPartnerId)
        NotificationService.info('Partner added')

        history.goBack()
      }
    } else {
      const userData = {
        data: data,
        organisation: activeOrg.id
      }
      var userUpdated = await UserService.updateUser(apiToken, partnerId, userData, {
        type: 'partnerContact'
      })
      if (userUpdated.error) {
        NotificationService.error(userUpdated.error)
      } else {
        if (values.assignedTo) {
          await assignPartner(values.assignedTo, partnerId)
        }
        NotificationService.info('Partner updated')
      }
    }

    setPartnerOrgId(values.assignedTo)
  }

  const goBack = () => {
    history.goBack()
  }

  const getPartnerOrgs = async () => {
    const params = {
      fields: 'id,name,deleted'
    }

    const where = {
      deleted: false,
      type: 'partnerOrg'
    }

    const organisations = await UserGroupService.getGroups(apiToken, params, where)
    if (organisations?.error) {
      NotificationService.error(organisations.error)
    } else {
      if (!organisations || !organisations.data) {
        return
      }

      // const newOrgValues = organisations.filter((item) => item.deleted !== false)

      const mappedOrganisations = organisations.data.map((data, index) => ({
        id: data.id,
        name: data.name
      }))

      setPartnerOrgs(mappedOrganisations)
    }
  }

  useEffect(() => {
    let unmounted = false

    const getUserDetails = async () => {
      const userDetails = await UserService.getUser(apiToken, partnerId, {
        type: 'partnerContact',
        fields: 'id,firstName,lastName,email,addressLineOne,postcode,city,mobile,job,team,notes'
      })
      if (!unmounted) {
        setUserValues(prevState => ({
          ...prevState,
          id: userDetails?.data?.[0]?.id,
          firstName: userDetails?.data?.[0]?.firstName,
          lastName: userDetails?.data?.[0]?.lastName,
          email: userDetails?.data?.[0]?.email,
          addressLineOne: userDetails?.data?.[0]?.addressLineOne,
          postcode: userDetails?.data?.[0]?.postcode,
          city: userDetails?.data?.[0]?.city,
          mobile: userDetails?.data?.[0]?.mobile,
          job: userDetails?.data?.[0]?.job,
          team: userDetails?.data?.[0]?.team,
          notes: userDetails?.data?.[0]?.notes,
          name: userDetails?.data?.[0]?.firstName + ' ' + userDetails?.data?.[0]?.lastName
        }))
      }
    }

    (async () => {
      await getPartnerOrgs()

      if (partnerId !== 'new') {
        await getUserDetails()
      }

      return () => { unmounted = true }
    })()
  }, [])

  useEffect(() => {
    console.log(partnerOrgs)
  }, [partnerOrgs])

  return (
    <Box width='xlarge'>
      <Box background='background' elevation='small' direction='column' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Partner: {userValues.id ? userValues.name : 'New Partner'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor color='secondary' href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='background' elevatino='small' direction='column' flex='grow' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Form
            //   validate='blur'
            onChange={nextValue => {
              // console.log(nextValue)
              setUserValues(nextValue)
            }}
            onSubmit={({ value: nextValue }) => {
              // console.log(nextValue)
              submitForm(nextValue)
            }}
            onValidate={onValidate}
            value={userValues}
          >
            <>
              <Box
                direction={size !== 'small' ? 'row' : 'column'}
                gap={size !== 'small' ? 'small' : undefined}
              >
                {/* First Name */}
                <FormField
                  label='First Name *'
                  htmlFor='firstName'
                  name='firstName'
                  required
                >
                  <TextInput
                    id='firstName'
                    name='firstName'
                  />
                </FormField>

                {/* Last Name */}
                <FormField
                  label='Last Name *'
                  htmlFor='lastName'
                  name='lastName'
                  required
                >
                  <TextInput
                    id='lastName'
                    name='lastName'
                  />
                </FormField>
              </Box>

              <Box
                direction={size !== 'small' ? 'row' : 'column'}
                gap={size !== 'small' ? 'small' : undefined}
              >
                {/* Address */}
                <FormField
                  label='Address'
                  htmlFor='addressLineOne'
                  name='addressLineOne'
                >
                  <TextInput
                    id='addressLineOne'
                    name='addressLineOne'
                  />
                </FormField>

                {/* Postcode */}
                <FormField
                  label='Postcode'
                  htmlFor='postcode'
                  name='postcode'
                >
                  <TextInput
                    id='postcode'
                    name='postcode'
                  />
                </FormField>

                {/* City */}
                <FormField
                  label='City'
                  htmlFor='city'
                  name='city'
                >
                  <TextInput
                    id='city'
                    name='city'
                  />
                </FormField>
              </Box>

              <Box
                direction={size !== 'small' ? 'row' : 'column'}
                gap={size !== 'small' ? 'small' : undefined}
              >
                {/* Mobile */}
                <FormField
                  label='Mobile'
                  htmlFor='mobile'
                  name='mobile'
                >
                  <TextInput
                    id='mobile'
                    name='mobile'
                  />
                </FormField>

                {/* Email */}
                <FormField
                  label='Email *'
                  htmlFor='email'
                  name='email'
                  required
                  validate={{
                    regexp: new RegExp(
                      '(^$)|([^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+)'
                    ),
                    message: 'Invalid email address',
                    status: 'error'
                  }}
                >
                  <TextInput
                    id='email'
                    name='email'
                    // onChange={() => setValid(true)}
                  />
                </FormField>
              </Box>
              <Box
                direction={size !== 'small' ? 'row' : 'column'}
                gap={size !== 'small' ? 'small' : undefined}
              >
                {/* Job title */}
                <FormField
                  label='Job title'
                  htmlFor='job'
                  name='job'
                >
                  <TextInput
                    id='job'
                    name='job'
                  />
                </FormField>

                {/* team */}
                <FormField
                  label='Team/Area'
                  htmlFor='team'
                  name='team'
                >
                  <TextInput
                    id='team'
                    name='team'
                  />
                </FormField>
              </Box>

              {/* Assign to Partner Org */}
              <FormField
                label='Partner Org'
                name='assignedTo'
              >
                <Select
                  name='assignedTo'
                  emptySearchMessage='No partner orgs found'
                  labelKey='name'
                  options={partnerOrgs}
                  valueKey={{ key: 'id', reduce: true }}
                />
              </FormField>

              <Box>
                {/* Notes */}
                <FormField
                  label='Notes'
                  name='notes'
                >
                  <TextArea
                    name='notes'
                  />
                </FormField>
              </Box>

              {/* {!valid && <Error>There is an error with one or more inputs.</Error>} */}
            </>

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Button label='< Back' onClick={() => goBack()} secondary />
              <Button type='submit' label='Save' primary />
            </Box>
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

export default PartnerEdit
