// React
import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

// Appt
import Anchor from '../../simple/anchor/Anchor.js'
import Box from '../../simple/box/Box.js'
import Button from '../../simple/button/Button.js'
import Form from '../../simple/form/Form.js'
import FormField from '../../simple/formField/FormField.js'
import Image from '../../simple/image/Image.js'
import Text from '../../simple/text/Text.js'
import TextInput from '../../simple/input/TextInput.js'
import NotificationService from '../../../services/NotificationService.js'

/**
 * `UserVerification` renders a UserVerification screen
 */
function UserVerification (props) {
  const history = useHistory()

  const [code, setCode] = useState({
    verificationCode: ''
  })

  const handleBackClick = () => history.push('/login')

  const handleCodeChange = (code) => {
    setCode(code)
  }

  const onSubmit = () => {
    // if (code.verificationCode.length !== 5) {
    //   NotificationService.error('Code must be five characters')
    // } else {
    props.handleVerificationCode(code.verificationCode)
    //   console.log(code)
    // }
  }

  return (
    <>
      <Box align='center' animation='fadeIn' pad='large' alignSelf='center' width='medium'>
        <Box width='small' height="small">
          <Image fit='contain' src={props.image} />
        </Box>

        <Box width='medium'>
          <Form
            onChange={nextValue => {
              handleCodeChange(nextValue)
            }}
            onSubmit={() => onSubmit()}
            value={code}
          >
            <Text color='primary' size='small'>Your email address needs to be verified. Please check your email and enter a code below.</Text>

            <FormField margin={{ vertical: 'small' }} name='verificationCode' required label={<Text size='medium' >Verification Code</Text>}>
              <TextInput name='verificationCode' placeholder='00000' />
            </FormField>

            <Button fill='horizontal' label='Submit Verification' primary type='submit' />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Anchor color='white' onClick={() => handleBackClick()} size='xsmall' label='< Back to login' />
            </Box>
          </Form>
        </Box>
      </Box>
    </>)
}

export default UserVerification
