import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import moment from 'moment'

import Responsive from '../../utils/Responsive'

import LocalStorageService from '../../services/LocalStorageService'
import MediaService from '../../services/MediaService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserGroupService from '../../services/UserGroupService'
import UserGroupMemberService from '../../services/UserGroupMemberService'
import UserTagService from '../../services/UserTagService'

import { hasPermission } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form'
import Text from '../../components/simple/text/Text'
import H2 from '../../components/simple/heading/H2'

import TextInput from '../../components/simple/input/TextInput'
import Select from '../../components/simple/input/Select'
import { WindowsLegacy } from 'grommet-icons'

function Contacts (props) {
  const history = useHistory()
  const urlParams = useParams()

  const [loading, setLoading] = useState(false)
  const [hasAllPermissions, setHasAllPermissions] = useState(false)
  const [missingPermissions, setMissingPermissions] = useState([])

  const [localUserDetails, setLocalUserDetails] = useState(props.userDetails)

  const [allPartnerOrgs, setAllPartnerOrgs] = useState([])
  const [allPartnerContacts, setAllPartnerContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [linkedContacts, setLinkedContacts] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')


  // Get organisations
  const getPartnerOrgs = async () => {
    const params = {
      fields: 'id,name,createdAt',
      limit: 1000
    }

    const where = {
      deleted: false,
      type: 'partnerOrg'
    }
    const organisations = await UserGroupService.getGroups(apiToken, params, where)

    if (organisations?.data) {
      setAllPartnerOrgs(organisations.data)
    }

    if (organisations?.error) {
      console.error(organisations.error)
      setLoading(false)
    }
  }

  // Get partners
  const getPartnerContacts = async () => {
    // First get the UserGroupMember's the Usergroup has
    const params = {
      fields: 'id,user,usergroup',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null
    }

    const members = await UserGroupMemberService.getMembers(apiToken, params)

    if (members?.data?.length > 0) {
      const membersIds = members?.data.map(a => a.user)
      const params = {
        fields: 'id,firstName,lastName,email,mobile,createdAt',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'partnerContact',
        where: {
          deleted: false
        }
      }

      params.where = {
        deleted: false,
        id: membersIds
      }
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        NotificationService.error(users.error)
        setLoading(false)
      } else if (users?.data) {
        var mappedPartnerOrgs = {}
        // try to map the partner orgs
        for (const user of users.data) {
          const matchingMemberRecored = members?.data.find(i => parseInt(i.user) === parseInt(user.id))
          if (matchingMemberRecored) {
            const matchingPartnerOrg = allPartnerOrgs.find(i => i.id === matchingMemberRecored.usergroup)
            if (matchingPartnerOrg) {
              mappedPartnerOrgs[user.id] = matchingPartnerOrg
            }
          }
        }
        const mappedUsers = users.data.map((data, index) => ({
          createdAt: moment(data.createdAt).fromNow(),
          id: data.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          label: `${data.firstName || ''} ${data.lastName || ''} (${mappedPartnerOrgs?.[data.id]?.name || ''})`,
          partnerOrgId: mappedPartnerOrgs?.[data.id]?.id,
          partnerOrgName: mappedPartnerOrgs?.[data.id]?.name
        }))
        setAllPartnerContacts(mappedUsers)
      }
    }
  }

  const getLinkedContacts = async () => {
    const where = {
      user: urlParams.userid,
      type: 'general',
      organisation: activeOrg.id,
      site: activeSite.id,
      key: ['linkedPartnerContacts'],
      sensitivity: ['basic']
    }

    const params = {
      fields: 'id,key,value',
      type: 'client',
      limit: 1000
    }

    const matchingTags = await UserTagService.get(apiToken, params, where)

    if (matchingTags?.[0]?.value) {
      const linkedIds = matchingTags[0].value.split(',')
      const mappedLinkedContacts = allPartnerContacts.filter(c => linkedIds.includes(String(c.id)) )
      setLinkedContacts(mappedLinkedContacts)
    }
  }

  const addPartnerContact = async (values) => {
    if (!values.partnerContact) {
      NotificationService.warning('No Partner Contact selected')
      return
    }
    setLoading(true)

    // create a new csv string
    const mappedContacts = linkedContacts.map(i => i.id)
    const stringToSave = [values.partnerContact].concat(mappedContacts).join(',')

    // save that as a user tag
    const tags = [{
      user: urlParams.userid,
      organisation: activeOrg.id,
      key: 'linkedPartnerContacts',
      value: stringToSave || '',
      type: 'general',
      sensitivity: 'basic'
    }]
    const response = await UserTagService.set(apiToken, tags, 'client')

    if (response.error) {
      NotificationService.error('There was an error assigning this contact')
    } else {
      NotificationService.success('Partner contact successfully linked')
    }

    await getLinkedContacts()

    setLoading(false)
    // get the user tag
  }

  const unlinkContact = async (idToRemove) => {
    if (window.confirm('Are you sure you want to unlink this contact?')) {
      setLoading(true)

      // create a new csv string
      const mappedContacts = linkedContacts.map(i => i.id)
      const stringToSave = mappedContacts.filter(id => id !== idToRemove).join(',')

      // save that as a user tag
      const tags = [{
        user: urlParams.userid,
        organisation: activeOrg.id,
        key: 'linkedPartnerContacts',
        value: stringToSave || '',
        type: 'general',
        sensitivity: 'basic'
      }]
      const response = await UserTagService.set(apiToken, tags, 'client')

      if (response.error) {
        NotificationService.error('There was an error assigning this contact')
      } else {
        NotificationService.success('Partner contact successfully linked')
      }

      await getLinkedContacts()

      setLoading(false)
    }
  }

  // make sure the user has all the permissions required to view the page
  useEffect(() => {
    let hasAllPermissions = true
    const requiredPermissions = ['partnerOrgViewAll', 'partnerContactViewAll']
    for (const thisPermissions of requiredPermissions) {
      if (!hasPermission(thisPermissions)) {
        hasAllPermissions = false
        setMissingPermissions([thisPermissions].concat([missingPermissions]))
      }
    }
    setHasAllPermissions(hasAllPermissions)
  }, [])

  // get the partner orgs and contacts
  useEffect(() => {
    if (hasAllPermissions) {
      setLoading(true)
      getPartnerOrgs()
    }
  }, [hasAllPermissions])

  // get the contacts when the orgs list has changed
  useEffect(() => {
    if (hasAllPermissions) {
      getPartnerContacts()
    }
  }, [allPartnerOrgs])

  // get the linked contacts when the contacts list has changed
  useEffect(() => {
    (async () => {
      if (hasAllPermissions) {
        await getLinkedContacts()
        setLoading(false)
      }
    })()
  }, [allPartnerContacts])

  // update the filtered contacted when the main list is updated
  useEffect(() => {
    setFilteredContacts(allPartnerContacts)
  }, [allPartnerContacts])

  // update local user details if the props change
  useEffect(() => {
    setLocalUserDetails(props.userDetails)
  }, [props.userDetails])

  return (
    <Box gridArea='main' direction='column' gap='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <H2 margin={{ vertical: 'none' }}>Linked Contacts</H2>
        <PulseLoader color='orange' loading={loading} />

        {!loading && !hasAllPermissions && <Text><Text weight='bold'>Access Denied:</Text> The following permissions are required to access this tab: {missingPermissions.join(',')}</Text>}


        {!loading && hasAllPermissions && <Responsive
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['1/2', '1/2'],
            large: ['1/2', '1/2'],
            xlarge: ['1/2', '1/2']
          }}
          gap='small'
        >
          <Box>

            {linkedContacts?.length > 0
              ? linkedContacts?.map((item) => (
                <Box direction='column' key={item.id} margin={{ top: '20px' }}>
                  <Text size='large' weight='bold'>{item.firstName} {item.lastName} ({item.partnerOrgName})</Text>
                  {item.mobile && <Text>Mobile: {item.mobile}</Text>}
                  {item.email && <Text>Email: <Anchor href={`mailto:${item.email}`}>{item.email}</Anchor></Text>}
                  {hasPermission('clientContactEdit') && <Text size='small'><Anchor onClick={() => unlinkContact(item.id)}>Unlink Contact</Anchor></Text>}
                </Box>)
              )
              : <Text>No Partner Contacts available</Text>}
          </Box>
          {hasPermission('clientContactEdit') && <Box>
            <Box>
              <Text weight='bold'>Assign a contact to this client</Text>

              <Form
                onSubmit={({ value: nextValue }) => {
                  addPartnerContact(nextValue)
                }}
              >
                <Box>
                  <Select
                    name='partnerContact'
                    emptySearchMessage='No values found'
                    labelKey='label'
                    options={filteredContacts}
                    valueKey={{ key: 'id', reduce: true }}
                    onSearch={text => {
                      // The line below escapes regular expression special characters:
                      const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
                      const exp = new RegExp(escapedText, 'i')
                      setFilteredContacts(allPartnerContacts.filter(o => exp.test(o.label)))
                    }}
                  />
                </Box>

                <Button type='submit' label='Add' />
              </Form>

            </Box>

          </Box>}
        </Responsive>}

        <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

        <Box direction='row' justify='between' margin={{ top: 'medium' }}>
          <Button label='< Back' onClick={() => props.previousPage()} secondary />
        </Box>
      </Box>
    </Box>
  )
}

export default Contacts
