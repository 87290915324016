import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Grid from '../components/simple/grid/Grid'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'
import H1 from '../components/simple/heading/H1'
import Text from '../components/simple/text/Text'
import { isGlobalAdmin, hasPermission, hasAnyPermissionIn, isCurrentOrgAdmin, currentOrgHasSitesEnabled } from '../utils/permissions'

function SystemManagement (props) {
  const history = useHistory()

  useEffect(() => {

  }, [])

  return (
    <>
      <Box width='xlarge'>
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='column' pad={{ horizontal: 'medium' }}>
            <H1 margin={{ top: 'small', bottom: 'none' }}>System Management</H1>
            <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
          </Box>
        </Box>

        <Box direction='column' background='white' round='small' pad='medium'>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='small' weight='bold'>Available System Management Modules:</Text>

          <Box direction='row-responsive' fill gap='small' pad={{ horizontal: 'none', bottom: 'small' }}>
            <Grid
              fill
              rows='xxsmall'
              columns='20%'
              gap='small'
            >
              {isGlobalAdmin() && <Button href='/globaladmins' label={<><Text pad={{ horizontal: 'medium' }}><FontAwesome color='background' icon={['fal', 'cog']} /> Global Admins </Text><Text size='small'><FontAwesome color='background' icon={['fal', 'angle-right']} /></Text></>} />}
              {isGlobalAdmin() && <Button href='/services' label={<><Text><FontAwesome color='background' icon={['fal', 'cog']} /> System Organisations </Text><Text size='small'><FontAwesome color='background' icon={['fal', 'angle-right']} /></Text></>} />}
              {currentOrgHasSitesEnabled() && isCurrentOrgAdmin() && <Button href='/sites' label={<><Text><FontAwesome color='background' icon={['fal', 'cog']} /> Manage Sites </Text><Text size='small'><FontAwesome color='background' icon={['fal', 'angle-right']} /></Text></>} />}
              {(hasPermission('roleAccessModule') || isCurrentOrgAdmin()) && <Button href='/roles' label={<><Text><FontAwesome color='background' icon={['fal', 'cog']} /> Manage Roles </Text><Text size='small'><FontAwesome color='background' icon={['fal', 'angle-right']} /></Text></>} /> }
              {(hasAnyPermissionIn(['gdprGetAll', 'gdprDeleteAll']) || isCurrentOrgAdmin()) && <Button href='/gdpr' label={<><Text><FontAwesome color='background' icon={['fal', 'cog']} /> GDPR </Text><Text size='small'><FontAwesome color='background' icon={['fal', 'angle-right']} /></Text></>} />}
              {(hasPermission('reportAccessModule') || isCurrentOrgAdmin()) && <Button href='/reports' label={<><Text><FontAwesome color='background' icon={['fal', 'cog']} /> Reports </Text><Text size='small'><FontAwesome color='background' icon={['fal', 'angle-right']} /></Text></>} />}

            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SystemManagement
