import queryString from 'query-string'

/**
 * `queryStringHandler` parses searchValue
 *
 *  Usage example:
 *
 *  To parse a url query string eg. ?apiToken=1234&user=4
 *      const location = useLocation()
 *      const queryStringVal = queryStringHandler(location.search)
 *  returns
 *      {apiToken: 1234, user: 4}
 */

const queryStringHandler = (searchValue) => {
  return queryString.parse(searchValue)
}

export default queryStringHandler
