// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { TextArea as GTextArea } from 'grommet'

/**
 * `TextArea` renders a Grommet TextArea box
 */
class TextArea extends React.Component {
  render () {
    return (
      <GTextArea
        {...this.props}
        // onChange={(input) => onChange(input)}
      />)
  }
}

TextArea.propTypes = {
  /**
   * Custom title to be used by screen readers.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * Whether the width and height should fill the container.  
   *  
   * true
   * **false**
   */
  fill: PropTypes.bool,
  /**
   * Whether the plain text input should receive a focus outline.  
   */
  focusIndicator: PropTypes.bool,
  /**
   * The id attribute of the input.
   */
  id: PropTypes.string,
  /**
   * The name of the attribute when in a Form or FormField.
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user types in the input.
   */
  onChange: PropTypes.func,
  /**
   * Placeholder to use when no value is provided.  
   *   
   * "string"
   */
  placeholder: PropTypes.string,
  /**
   * Whether this is a plain input with no border or padding.  
   * Only use this when the containing context provides sufficient affordance  
   *   
   * true  
   * false
   */
  plain: PropTypes.bool,
  /**
   * Whether user is allowed to resize the textarea.  
   *   
   * vertical  
   * horizontal  
   * **true**  
   * false
   */
  resize: PropTypes.bool,
  /**
   * The size of the TextArea.  
   *   
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  size: PropTypes.string,
  /**
   * What text to put in the input.
   */
  value: PropTypes.string
}

export default TextArea
