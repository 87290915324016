import React from 'react'

// Grommet
import { ResponsiveContext } from 'grommet'

import Grid from '../../simple/grid/Grid.js'

const ResponsiveGrid = ({
  children,
  columns,
  rows,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {size => {
      // columns, rows and areas are for Grid with a known number of contents / boxes.

      // If the size is small, we only see 1 column
      // If the size is either medium, large or xlarge, we see 4 columns
      // const columns = {
      //   small: ['auto'],
      //   medium: ['auto', 'auto', 'auto', 'auto'],
      //   large: ['auto', 'auto', 'auto', 'auto'],
      //   xlarge: ['auto', 'auto', 'auto', 'auto']
      // }

      // const rows = {
      //   small: 'xsmall',
      //   medium: 'xsmall',
      //   large: 'xsmall',
      //   xlarge: 'xsmall'
      // }

      // Default the rows to xsmall if no props
      if (!rows) {
        rows = 'xsmall'
      }

      // Take into consideration if not array is sent but a simple string
      let columnsVal = columns
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size]
        }
      }

      let rowsVal = rows
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size]
        }
      }

      // Also if areas is a simple array not an object of arrays for
      // different sizes
      let areasVal = areas
      if (areas && !Array.isArray(areas)) areasVal = areas[size]

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
        >
          {children}
        </Grid>
      )
    }}
  </ResponsiveContext.Consumer>
)

export default ResponsiveGrid
