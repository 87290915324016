// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { DataTable as GDataTable } from 'grommet'

/**
 * `DataTable` renders a Grommet DataTable component
 */
class DataTable extends React.Component {
  render () {
    return (
      <GDataTable
        {...this.props}
      />)
  }
}

DataTable.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Cell background.  
   * You can set the background per context by passing an object with keys for 'heading', 'body', and/or 'footer'.  
   * If you pass an array, rows will cycle between the array values.  
   *   
   * "light-2"  
   * ["white", "light-2"]  
   * {  
   * &nbsp;"header": "dark-2",  
   * &nbsp;"body": ["white", "light-2"],  
   * &nbsp;"footer": "dark-3"  
   * }
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  /**
   * Cell border.  
   * You can set the border per context by passing an object with keys for 'heading', 'body', and/or 'footer'.  
   *   
   * true  
   * "top"  
   * "left"  
   * "bottom"  
   * "right"  
   * "horizontal"  
   * "vertical"  
   * {  
   * &nbsp;"color": "border",  
   * &nbsp;"size": "small",  
   * &nbsp;"side": "horizontal"  
   * }  
   * {
   * &nbsp;"header": "bottom",
   * &nbsp;"body": {
   * &nbsp;&nbsp;"color": "light-2",
   * &nbsp;&nbsp;"side": "bottom"
   * &nbsp;},
   * &nbsp;"footer": "top"
   * }  
   *   
   * where size could be:  
   * &nbsp;"xsmall"  
   * &nbsp;"small"  
   * &nbsp;"medium"  
   * &nbsp;"large"  
   * &nbsp;"xlarge"  
   * &nbsp;"any CSS size"  
   *  
   * where style could be:  
   * &nbsp;"solid"  
   * &nbsp;"dashed"  
   * &nbsp;"dotted"  
   * &nbsp;"double"  
   * &nbsp;"groove"  
   * &nbsp;"ridge"  
   * &nbsp;"inset"  
   * &nbsp;"outset"  
   * &nbsp;"hidden"  
   *  
   * where side could be:  
   * &nbsp;"top"  
   * &nbsp;"left"  
   * &nbsp;"bottom"  
   * &nbsp;"right"  
   * &nbsp;"horizontal"  
   * &nbsp;"vertical"  
   * &nbsp;"all"
   */
  border: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * A description of the data.  
   * The order controls the column order.  
   * &nbsp;'property' indicates which property in the data objects to associate the column with.  
   * &nbsp;'header' indicates what to display in the column header.  
   * &nbsp;'render' allows for custom rendering of body cells.  
   * &nbsp;&nbsp;Use 'render' for custom formatting for things like currency and date or to display rich content like Meters.  
   * &nbsp;'align' indicates how the cells in the column are aligned.  
   * &nbsp;'aggregate' indicates how the data in the column should be aggregated.  
   * &nbsp;&nbsp;This only applies to a footer or groupBy context.  
   * &nbsp;'footer' indicates what should be shown in the footer for the column.  
   * &nbsp;'search' indicates whether a search filter should be made available for the column.  
   * &nbsp;'primary' indicates that this property should be used as the unique identifier, which gives the cell 'row' scope for accessibility.  
   * &nbsp;&nbsp;If 'primary' is not used for any column, and 'primaryKey' isn't specified either, then the first column will be used.  
   *   
   * [  
   * &nbsp;{  
   * &nbsp;&nbsp;"align": "center",  
   * &nbsp;&nbsp;"aggregate": "avg",  
   * &nbsp;&nbsp;"footer": {"aggregate": true},  
   * &nbsp;&nbsp;"header": "Name",  
   * &nbsp;&nbsp;"primary": true,  
   * &nbsp;&nbsp;"property": "name",  
   * &nbsp;&nbsp;"render": "(datum) => <Content />",  
   * &nbsp;&nbsp;"size": "small",  
   * &nbsp;&nbsp;"search": true,  
   * &nbsp;&nbsp;"sortable": true  
   * &nbsp;}  
   * ]  
   *   
   * where align could be:  
   * &nbsp;"start"  
   * &nbsp;"center"  
   * &nbsp;"end"  
   *  
   * where aggregate could be:  
   * &nbsp;"avg"  
   * &nbsp;"max"  
   * &nbsp;"min"  
   * &nbsp;"sum"
   */
  columns: PropTypes.array,
  /**
   * Array of data objects.  
   *   
   * {  
   * &nbsp;""
   * }
   */
  data: PropTypes.array,

  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * Property to group data by.  
   * If object is specified 'property' is used to group data by, 'expand' accepts array of group keys that sets expanded groups and 'onExpand' is a function that will be called after expand button is clicked with an array of keys of expanded groups.    
   *   
   * "location"  
   * {  
   * &nbsp;"property": "location",  
   * &nbsp;"expand": ["Paris", "Los Angeles"],  
   * &nbsp;"onExpand": "(key) => {...}"  
   * }
  */
  groupBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * When supplied, this function will be called with an event object that include a 'datum' property containing the data value associated with the clicked row.  
   * You should not include interactive elements, like Anchor or Button inside table cells as that can cause confusion with overlapping interactive elements.  
   *   
   * ({ datum }) => {...}
   */
  onClickRow: PropTypes.func,
  /**
   * Use this to indicate that 'data' doesn't contain all that it could.  
   * It will be called when all of the data rows have been rendered.  
   * This might be used when the total number of items that could be retrieved is more than you'd want to load into the browser.  
   * 'onMore' allows you to lazily fetch more from the server only when needed.  
   * This cannot be combined with properties that expect all data to be present in the browser, such as columns.search, sortable, groupBy, or columns.aggregate.  
   *   
   * () => {...}
   */
  onMore: PropTypes.func,
  /**
   * When supplied, and when at least one column has 'search' enabled, this function will be called with an object with keys for property names and values which are the search text strings.  
   * This is typically employed so a back-end can be used to search through the data.  
   *   
   * ({ key: "search text", ... }) => {...}
   */
  onSearch: PropTypes.func,
  /**
   * When supplied, this function will be called with an object with a 'property' property that indicates which property is being sorted on and a 'direction' property that will either be 'asc' or 'desc'.  
   * onSort={({ property, direction }) => {}}  
   *   
   * function
   */
  onSort: PropTypes.func,
  /**
   * Cell padding.  
   * You can set the padding per context by passing an object with keys for 'heading', 'body', and/or 'footer'.  
   *   
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...", - ... is one of "xxsmall", "xsmall" ... "string"
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   * &nbsp;{  
   * &nbsp;&nbsp;"header": "custom",  
   * &nbsp;&nbsp;"body": "custom",  
   * &nbsp;&nbsp;"footer": "custom",  
   * &nbsp;}
   */
  pad: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * When supplied, indicates the property for a data object to use to get a unique identifier.  
   * See also the 'columns.primary' description.  
   * Use this property when the columns approach will not work for your data set.  
   * Setting primaryKey to false indicates there should be no unique identifier, avoid this as it's less accessible.  
   *   
   * "string"  
   * true
   * false
   */
  primaryKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * Whether to replace previously rendered items with a generic spacing element when they have scrolled out of view.  
   * This is more performant but means that in-page searching will not find elements that have been replaced.  
   *   
   * true  
   * false
   */
  replace: PropTypes.bool,
  /**
   * Whether to allow the user to resize column widths.  
   *   
   * true  
   * false  
   */
  resizeable: PropTypes.bool,
  /**
   * Row specific background, border, and pad, keyed by primary key value.  
   * For example: { "primary-key-value": { background: ..., border: ..., pad: ... }}, where the background, border, and pad accept the same values as the same named properties on DataTable.  
   *   
   * {  
   * nbsp;""  
   * }
   */
  rowProps: PropTypes.object,
  /**
   * The height of the table body.  
   * If set, the table body will have a fixed height and the rows will be scrollable within it.  
   * In order to preserve header and footer cell alignment, all cells will have the same width.  
   * This cannot be used in combination with 'resizeable'.  
   *   
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  size: PropTypes.string,
  /**
   * Which property to sort on and which direction to sort.  
   *   
   * {  
   * &nbsp;direction:  
   * &nbsp;&nbsp;"asc"  
   * &nbsp;&nbsp;"desc,"  
   * &nbsp;property: string  
   * }
   */
  sort: PropTypes.object,
  /**
   * Whether to allow the user to sort columns.  
   *   
   * true  
   * false
   */
  sortable: PropTypes.bool,
  /**
   * How many items to render at a time.  
   *   
   * 50
   */
  step: PropTypes.number

}

export default DataTable
