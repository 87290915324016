import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

function Logout (props) {
  // NOTE - wrap function in useEffect otherwise we get error
  // Cannot update a component (`App`) while rendering a different component
  useEffect(() => {
    props.handleLogout()
  }, [])

  return (
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )
}

export default Logout
