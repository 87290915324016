// React
import React from 'react'

// Grommet
import { Paragraph as GParagraph } from 'grommet'

/**
 * `Paragraph` renders a Paragraph label as a Grommet Paragraph component
 */
class Paragraph extends React.Component {
  render() {
    return (
      <GParagraph
        {...this.props}
        className={this.props.className}
      >
        {this.props.children}
      </GParagraph>)
  }
}
export default Paragraph
