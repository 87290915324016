import React, { useEffect, useState } from 'react'


// Services
import LocalStorageService from '../../../services/LocalStorageService'
import UserService from '../../../services/UserService'

// Appt Components
import FormField from '../../../components/simple/formField/FormField.js'
import Select from '../../../components/simple/input/Select'

function Notify (props) {
  const [loading, setLoading] = useState(false)

  const [staffValues, setStaffValues] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  useEffect(() => {
    let unmounted = false

    // Get Staff to notify
    const getStaff = async () => {
      const params = {
        fields: 'id,firstName,lastName',
        limit: 1000,
        type: 'staff',
        orgId: activeOrg.id,
        where: {
          deleted: false
        }
      }

      setLoading(true)
      const staff = await UserService.getUsers(apiToken, params)
      if (staff?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (staff?.error) {
            setStaffValues(null)
          } else if (staff?.data) {
            const mappedStaff = staff.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName
            }))

            setStaffValues(mappedStaff)
          }
        }
      }
    }

    getStaff()

    setLoading(false)

    return () => { unmounted = true }
  }, [])

  return (
    <>
      {/* Staff to notify */}
      <FormField
        label='Staff to notify'
        name='notifyStaff'
      >
        <Select
          name='notifyStaff'
          labelKey='name'
          multiple
          options={staffValues}
          valueKey={{ key: 'id', reduce: true }}
        />
      </FormField>
    </>
  )
}
export default Notify
