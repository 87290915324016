import React, { useEffect, useState } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Grommet
import { ResponsiveContext } from 'grommet'

import { constants } from '../../../utils/constants'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import UserService from '../../../services/UserService'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import Divider from '../../../components/simple/divider/Divider'
import H2 from '../../../components/simple/heading/H2.js'
import Select from '../../../components/simple/input/Select'
import TextInput from '../../../components/simple/input/TextInput'
import TextArea from '../../../components/simple/input/TextArea'

function ClientFeedback (props) {
  const [loading, setLoading] = useState(false)

  const [userValues, setUserValues] = useState([])
  const [staffValues, setStaffValues] = useState([])

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)
  useEffect(() => { setLocalFormDetails(props.formDetails) }, [props.formDetails])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(localFormDetails)
  }

  // Get Users for this organisation
  const getStaff = async () => {
    const params = {
      fields: 'id,firstName,lastName,reference',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'staff',
      where: {
        deleted: false
      }
    }

    const users = await UserService.getUsers(apiToken, params)
    if (users?.error) {
      // NotificationService.error(users.error)
    } else {
      if (users?.error) {
        setStaffValues([])
      } else if (users?.data) {
        const mappedUsers = users.data.map((data, index) => ({
          id: data.id,
          name: data.firstName + ' ' + data.lastName
        }))

        setStaffValues(mappedUsers)
      }
    }
  }

  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'client',
        where: {
          deleted: false
        }
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    getUsers()
    getStaff()

    setLocalFormDetails(previousValues => ({
      ...previousValues,
      clientName: parseInt(props.clientId)
    }))
    setLoading(false)

    return () => { unmounted = true }
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Client Feedback and Consent</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >

              {/* What do you feel like you have achieved during your time at Snowdrop? */}
              <FormField
                label={questions[0].questionText}
                name={questions[0].key}
                required
              >
                <TextArea
                  name={questions[0].key}
                  type='text'
                />
              </FormField>


              {/* Is there anything Snowdrop could have supported you better with? */}
              <FormField
                label={questions[1].questionText}
                name={questions[1].key}
                required
              >
                <TextArea
                  name={questions[1].key}
                  type='text'
                />
              </FormField>

              {/* Is there anything that your caseworker did well? */}
              <FormField
                label={questions[2].questionText}
                name={questions[2].key}
                required
              >
                <TextArea
                  name={questions[2].key}
                  type='text'
                />
              </FormField>

              {/*Is there anything that your caseworker could have done better? */}
              <FormField
                label={questions[3].questionText}
                name={questions[3].key}
                required
              >
                <TextArea
                  name={questions[3].key}
                  type='text'
                />
              </FormField>

              {/* Did you get support from a befriender? Do you have any feedback about this? */}
              <FormField
                label={questions[4].questionText}
                name={questions[4].key}
                required
              >
                <TextArea
                  name={questions[4].key}
                  type='text'
                />
              </FormField>

              {/* Did you attend any community activities? If so, which ones and do you have any feedback about them? */}
              <FormField
                label={questions[5].questionText}
                name={questions[5].key}
                required
              >
                <TextArea
                  name={questions[5].key}
                  type='text'
                />
              </FormField>

              {/* Did you have a house renovation? If so please comment on how this went. */}
              <FormField
                label={questions[6].questionText}
                name={questions[6].key}
                required
              >
                <TextArea
                  name={questions[6].key}
                  type='text'
                />
              </FormField>

              {/*Do you have any other feedback about your time with Snowdrop support? */}
              <FormField
                label={questions[7].questionText}
                name={questions[7].key}
                required
              >
                <TextArea
                  name={questions[7].key}
                  type='text'
                />
              </FormField>

              {/* What are your plans for the future? */}
              <FormField
                label={questions[8].questionText}
                name={questions[8].key}
                required
              >
                <TextArea
                  name={questions[8].key}
                  type='text'
                />
              </FormField>

              {/* Are you happy for the feedback to be (anonymously) used by Snowdrop to publicise our work in print or on the website or on social media? */}
              <FormField
                label={questions[9].questionText}
                name={questions[9].key}
                required
              >
                <TextArea
                  name={questions[9].key}
                  type='text'
                />
              </FormField>

              {/*Would you be happy for us to use your story as an anonymous case study on our website or in publicity materials?  (We would make sure you could not be identified) */}
              <FormField
                label={questions[10].questionText}
                name={questions[10].key}
                required
              >
                <TextArea
                  name={questions[10].key}
                  type='text'
                />
              </FormField>

              {/* Would you be happy to be contacted in future if we have any opportunities for you to speak to the Media about your story? */}
              <FormField
                label={questions[11].questionText}
                name={questions[11].key}
                required
              >
                <TextArea
                  name={questions[11].key}
                  type='text'
                />
              </FormField>

              {/* "Would you be happy for us to use your story as an anonymous case study on our website or in publicity materials?" -  saying 'If yes, would you like to choose a pseudonym for yourself ?' */}
              <FormField
                label={questions[12].questionText}
                name={questions[12].key}
                required
              >
                <TextArea
                  name={questions[12].key}
                  type='text'
                />
              </FormField>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default ClientFeedback
