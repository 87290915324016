// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Form as GForm } from 'grommet'

/**
 * `Form` renders a Grommet Form
 */
class Form extends React.Component {
  render () {
    return (
      <GForm
        {...this.props}
      />)
  }
}

Form.propTypes = {
  /**
   * An object representing any errors in the data.  
   * Their keys should match the keys in the value object. 
   *    
   * {"name": ["message", "<Box>...</Box>"]}
   */
  errors: PropTypes.object,
  /**
   * An object representing any information details in the data. Their keys should match the keys in the value object.  
   *   
   * {"name": ["message", "<Box>...</Box>"]}
   */
  infos: PropTypes.object,
  /**
   * Custom validation messages.  
   *   
   * {  
   * &nbsp;invalid: "string,"  
   * &nbsp;required: string  
   * }
   */
  messages: PropTypes.object,
  /**
   * Function that will be called when any fields are updated.  
   * The fields must have a non-null name property assigned.  
   *   
   * (value) => {}
   */
  onChange: PropTypes.func,
  /**
   * Function that will be called when the form is reset.  
   * The single argument is the event provided by react.  
   *   
   * () => {}
   */
  onReset: PropTypes.func,
  /**
   * Function that will be called when the form is submitted.  
   * The single argument is an event containing the latest value object via event.value and an object indicating which fields were touched via event.touched.  
   *   
   * ({ value, touched }) => {}
   */
  OnSubmit: PropTypes.func,
  /**
   * When to perform validation.  
   *   
   * "blur"  
   * **"submit"
   */  
  validate: PropTypes.string,
  /**
   * An object representing all of the data in the form.  
   *   
   * {"name": "value"}
   */
  value: PropTypes.object
}

export default Form
