// This is used to determine if a user is authenticated by checking if an apiToken exists
// and if they are allowed to visit the page they navigated to.

// If they are: then proceed to the page
// If not: they are redirected to the login page.
import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import moment from 'moment'

// Appt Components
import Box from './components/simple/box/Box'

// Services
import LocalStorageService from './services/LocalStorageService'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const apiToken = LocalStorageService.get('apiToken')

  const [logout, setLogout] = useState(false)

  useEffect(() => {
    // If no activity for greater than 4 hours
    // clear local storage and logout
    const lastActionTime = LocalStorageService.get('lastActionTime')

    const timeNow = moment()
    const inactivity = moment.duration(timeNow.diff(moment(lastActionTime)))

    if (inactivity.as('hours') > 4) {
      // remove all localstorage
      LocalStorageService.clear()

      setLogout(true)
    } else {
      LocalStorageService.set('lastActionTime', Date.now())
    }
  }, [Component])

  return (
    <Route
      {...rest}
      render={props =>
        apiToken ? (
          <Box width='xlarge' alignSelf='start'>
            <Component {...rest} />
          </Box>
        ) : logout ? (
          <Redirect to={{ pathname: '/logout', state: { from: props.location } }} />
        )
          : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )}
    />
  )
}

export default PrivateRoute
