import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Meter from '../../../components/simple/meter/Meter'
import Tabs from '../../../components/simple/tabs/Tabs'
import Text from '../../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

// Child Forms
import CaseDetails from '../shared/CaseDetails'
import Conclusion from './Conclusion'
import FutureHopes from './FutureHopes'
import HousingStatus from './HousingStatus'
import IndependentLiving from './IndependentLiving'
import LegalStatus from './LegalStatus'
import Overview from './Overview'
import Relationship from './Relationship'
import Safety from './Safety'
import Wellbeing from './Wellbeing'
import SubmitPage from './SubmitPage'


function ClientCarePlan (props) {
  const history = useHistory()

  const [meterValue, setMeterValue] = useState()

  const [formDetails, setFormDetails] = useState({
    clientName: '',
    caseworker: '',
    supervisor: '',
    carePlanPoint: '',
    dateCompleted: '',
    threeGoals: '',
    goalRecommendations: '',
    goalActions: '',
    goalBarriers: '',
    housingStatus: '',
    housingRisk: '',
    housingRiskReason: '',
    housingChanges: '',
    housingImportantEvent: '',
    housingImportantEventDate: '',
    housingImportantEvents: '',
    legalStatus: '',
    traffickingDecision: '',
    discretionaryLeave: '',
    asylumClaim: '',
    riskStatus: '',
    riskReason: '',
    legalChanges: '',
    legalImportantEvent: '',
    legalImportantEventDate: '',
    legalImportantEvents: '',
    independentLiving: '',
    independentLivingRisk: '',
    independentLivingRiskReason: '',
    independentLivingGoal: '',
    independentLivingGoalAction: '',
    independentLivingGoalActionee: '',
    independentLivingGoalDate: '',
    independentLivingGoals: '',
    relationship: '',
    relationshipRisk: '',
    relationshipRiskReason: '',
    relationshipGoal: '',
    relationshipGoalAction: '',
    relationshipGoalActionee: '',
    relationshipGoalDate: '',
    relationshipGoals: '',
    wellbeing: '',
    wellbeingRisk: '',
    wellbeingRiskReason: '',
    wellbeingGoal: '',
    wellbeingGoalAction: '',
    wellbeingGoalActionee: '',
    wellbeingGoalDate: '',
    wellbeingGoals: '',
    safety: '',
    safetyRisk: '',
    safetyRiskReason: '',
    safetyGoal: '',
    safetyGoalAction: '',
    safetyGoalActionee: '',
    safetyGoalDate: '',
    safetyGoals: '',
    hopes: '',
    hopesRisk: '',
    hopesRiskReason: '',
    hopesGoal: '',
    hopesGoalAction: '',
    hopesGoalActionee: '',
    hopesGoalDate: '',
    hopesGoals: '',
    conclusionSummary: '',
    conclusionPriorities: ''
  })

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const activeUser = LocalStorageService.get('userDetails')

  const urlParams = useParams()

  // This doesnt work not sure how to fix it though
  let clientId = props.location?.clientid || null
  const viewtype = urlParams.viewtype

  const submitForm = async (formDetailsToSave, status, feedbackRequired = false, feedbackRequiredBy, changesRequested, feedback, formId) => {
    setFormDetails(formDetailsToSave)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    for (var key in formDetailsToSave) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(question => question.key === key)

        if (found && checkInputHasContent(formDetailsToSave[key])) {
          let response = null

          response = formDetailsToSave[key]

          item = {
            questionKey: key,
            questionText: found.questionText,
            response: response,
            responseType: found?.responseType || 'string'
          }
          submitArray.push(item)
        }
      })
    }

    // Build output data
    var data = {
      user: activeUser.id,
      organisation: activeOrganisation.id,
      status: status,
      type: 'clientcareplanform',
      reference: '',
      responses: submitArray
    }

    if (clientId) {
      data.subject = clientId
    } else {
      data.subject = formDetailsToSave.clientName
    }

    data.feedbackRequired = feedbackRequired
    data.feedbackRequiredBy = feedbackRequiredBy
    data.revisionRequired = changesRequested
    data.feedbackNotes = feedback

    // Only submit if there is data
    var submissionResponse
    if (urlParams.formId !== 'new') {
      delete data.user
      // delete data.subject
      delete data.organisation
      submissionResponse = await FormService.updateSubmission(apiToken, urlParams.formId, data)
    } else {
      submissionResponse = await FormService.makeSubmissions(apiToken, data)
    }
    if (submissionResponse.error) {
      NotificationService.error(submissionResponse.error)
    } else {
      if (status === 'Published' || feedbackRequired) {
        NotificationService.success('Form Submitted')
        if (clientId) {
          history.push(`/client/${clientId}/edit`)
        } else {
          history.push('/dashboard')
        }
      } else {
        nextPage()
      }
    }
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = true
        // inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / tabContent.length)
    setTabIndex(nextIndex)
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex)
    }
  }

  const nextPage = (formDetailsToSave) => {
    if (formDetailsToSave) { setFormDetails(formDetailsToSave) }
    if (tabIndex === tabContent.length - 1) {
      submitForm()
    } else {
      const nextPageIndex = tabIndex + 1
      onActiveTab(nextPageIndex)
    }
  }

  const [questions, setQuestions] = useState([
    // Personal Details
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Name of Client',
          key: 'clientName'
        },
        {
          questionText: 'Snowdrop Caseworker',
          key: 'caseworker'
        },
        {
          questionText: 'Snowdrop Supervisor',
          key: 'supervisor'
        },
        {
          questionText: 'Point of Care Plan',
          key: 'carePlanPoint'
        },
        {
          questionText: 'Date completed',
          key: 'dateCompleted'
        }
      ]
    },
    // Overview
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Currently what are the client’s 3 most important goals?',
          key: 'threeGoals'
        },
        {
          questionText: 'What is currently happening to help the client achieve them?',
          key: 'goalRecommendations'
        },
        {
          questionText: 'What action is needed to achieve these?',
          key: 'goalActions'
        },
        {
          questionText: 'Are there any barriers (internal or external) to achieving these?',
          key: 'goalBarriers'
        }
      ]
    },
    // Housing Status
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Housing Status',
          key: 'housingStatus'
        },
        {
          questionText: 'Does their housing currently pose a risk?',
          key: 'housingRisk'
        },
        {
          questionText: 'If yes, specify how',
          key: 'housingRiskReason'
        },
        {
          questionText: 'Upcoming changes or challenges',
          key: 'housingChanges'
        },
        {
          questionText: 'Event',
          key: 'housingImportantEvent'
        },
        {
          questionText: 'Date',
          key: 'housingImportantEventDate'
        },
        // Hidden question to store the array of housing important events
        {
          questionText: 'Housing Important Events',
          key: 'housingImportantEvents'
        }
      ]
    },
    // Legal Status
    {
      tabId: 3,
      questions: [
        {
          questionText: 'Legal Status',
          key: 'legalStatus'
        },
        {
          questionText: 'Trafficking Decision',
          key: 'traffickingDecision'
        },
        {
          questionText: 'Discretionary Leave',
          key: 'discretionaryLeave'
        },
        {
          questionText: 'If Asylum Seeker please indicate',
          key: 'asylumClaim'
        },
        {
          questionText: 'Does their status currently pose a risk?',
          key: 'riskStatus'
        },
        {
          questionText: 'If yes, specify how',
          key: 'riskReason'
        },
        {
          questionText: 'Upcoming changes or challenges',
          key: 'legalChanges'
        },
        {
          questionText: 'Event',
          key: 'legalImportantEvent'
        },
        {
          questionText: 'Date',
          key: 'legalImportantEventDate'
        },
        // Hidden question to store the array of legal important events
        {
          questionText: 'Legal Important Events',
          key: 'legalImportantEvents'
        }
      ]
    },
    // Independent Living
    {
      tabId: 4,
      questions: [
        {
          questionText: 'Independent Living',
          key: 'independentLiving'
        },
        {
          questionText: 'Does their current level of independent living pose any risks to the client?',
          key: 'independentLivingRisk'
        },
        {
          questionText: 'Specify the risks',
          key: 'independentLivingRiskReason'
        },
        {
          questionText: 'Goal',
          key: 'independentLivingGoal'
        },
        {
          questionText: 'What action is needed?',
          key: 'independentLivingGoalAction'
        },
        {
          questionText: 'Who is going to do this?',
          key: 'independentLivingGoalActionee'
        },
        {
          questionText: 'By when?',
          key: 'independentLivingGoalDate'
        },
        // Hidden question to store the array of independent living goals
        {
          questionText: 'Independent Living Goals',
          key: 'independentLivingGoals'
        }
      ]
    },
    // Relationships and Community
    {
      tabId: 5,
      questions: [
        {
          questionText: 'Relationships and Community',
          key: 'relationship'
        },
        {
          questionText: 'Does their current level of social support pose any risks to the client?',
          key: 'relationshipRisk'
        },
        {
          questionText: 'Specify the risks',
          key: 'relationshipRiskReason'
        },
        {
          questionText: 'Goal',
          key: 'relationshipGoal'
        },
        {
          questionText: 'What action is needed?',
          key: 'relationshipGoalAction'
        },
        {
          questionText: 'Who is going to do this?',
          key: 'relationshipGoalActionee'
        },
        {
          questionText: 'By when?',
          key: 'relationshipGoalDate'
        },
        // Hidden question to store the array of relationship goals
        {
          questionText: 'Relationship Goals',
          key: 'relationshipGoals'
        }
      ]
    },
    // Mental/Physical Health and Wellbeing
    {
      tabId: 6,
      questions: [
        {
          questionText: 'Mental/Physical Health and Wellbeing',
          key: 'wellbeing'
        },
        {
          questionText: 'Does their current level of mental/physical health pose any risks to the client?',
          key: 'wellbeingRisk'
        },
        {
          questionText: 'Specify the risks',
          key: 'wellbeingRiskReason'
        },
        {
          questionText: 'Goal',
          key: 'wellbeingGoal'
        },
        {
          questionText: 'What action is needed?',
          key: 'wellbeingGoalAction'
        },
        {
          questionText: 'Who is going to do this?',
          key: 'wellbeingGoalActionee'
        },
        {
          questionText: 'By when?',
          key: 'wellbeingGoalDate'
        },
        // Hidden question to store the array of wellbeing goals
        {
          questionText: 'Wellbeing Goals',
          key: 'wellbeingGoals'
        }
      ]
    },
    // Safety and Security
    {
      tabId: 7,
      questions: [
        {
          questionText: 'Safety and Security',
          key: 'safety'
        },
        {
          questionText: 'Does their current level of safety and security pose any risks to the client?',
          key: 'safetyRisk'
        },
        {
          questionText: 'Specify the risks',
          key: 'safetyRiskReason'
        },
        {
          questionText: 'Goal',
          key: 'safetyGoal'
        },
        {
          questionText: 'What action is needed?',
          key: 'safetyGoalAction'
        },
        {
          questionText: 'Who is going to do this?',
          key: 'safetyGoalActionee'
        },
        {
          questionText: 'By when?',
          key: 'safetyGoalDate'
        },
        // Hidden question to store the array of safety goals
        {
          questionText: 'Safety Goals',
          key: 'safetyGoals'
        }
      ]
    },
    // Future Hopes
    {
      tabId: 8,
      questions: [
        {
          questionText: 'Future Hopes and Self-Confidence',
          key: 'hopes'
        },
        {
          questionText: 'Does their current level of future hope and self-confidence pose any risks to the client?',
          key: 'hopesRisk'
        },
        {
          questionText: 'Specify the risks',
          key: 'hopesRiskReason'
        },
        {
          questionText: 'Goal',
          key: 'hopesGoal'
        },
        {
          questionText: 'What action is needed?',
          key: 'hopesGoalAction'
        },
        {
          questionText: 'Who is going to do this?',
          key: 'hopesGoalActionee'
        },
        {
          questionText: 'By when?',
          key: 'hopesGoalDate'
        },
        // Hidden question to store the array of hopes goals
        {
          questionText: 'Hopes Goals',
          key: 'hopesGoals'
        }
      ]
    },
    // Conclusion
    {
      tabId: 9,
      questions: [
        {
          questionText: 'Overall summary of how client is currently doing',
          key: 'conclusionSummary'
        },
        {
          questionText: 'What are the main priorities for the client for the next 3 months?',
          key: 'conclusionPriorities'
        }
      ]
    },
    {
      tabId: 10,
      questions: [
        {
          questionText: 'If you would like to notify an additional staff member, please select them below.',
          key: 'additionalNotification'
        },
        {
          questionText: 'I confirm that I have the consent of the client to submit this application',
          key: 'clientConsent'
        },
        // Hidden field to hold the automatic notifications
        {
          questionText: 'Staff Notifications',
          key: 'staffNotifications'
        },
        {
          questionText: 'Who needs to approve this form',
          key: 'feedbackRequiredBy'
        }
      ]
    }
  ])

  const tabContent = [
    {
      // name: 'Case Details',
      name: '1',
      hideTitle: true,
      content: <CaseDetails clientId={clientId} previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[0].questions} />
    },
    {
      // name: 'Overview',
      name: '2',
      hideTitle: true,
      content: <Overview previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[1].questions} />
    },
    {
      // name: 'Housing Status',
      name: '3',
      hideTitle: true,
      content: <HousingStatus previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[2].questions} />
    },
    {
      // name: 'Legal Status',
      name: '4',
      hideTitle: true,
      content: <LegalStatus previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[3].questions} />
    },
    {
      // name: 'Independent Living',
      name: '5',
      hideTitle: true,
      content: <IndependentLiving previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[4].questions} />
    },
    {
      // name: 'Relationships and Community',
      name: '6',
      hideTitle: true,
      content: <Relationship previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[5].questions} />
    },
    {
      // name: 'Mental/Physical Health and Wellbeing',
      name: '7',
      hideTitle: true,
      content: <Wellbeing previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[6].questions} />
    },
    {
      // name: 'Safety and Security',
      name: '8',
      hideTitle: true,
      content: <Safety previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[7].questions} />
    },
    {
      // name: 'Future Hopes and Self-Confidence',
      name: '9',
      hideTitle: true,
      content: <FutureHopes previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[8].questions} />
    },
    {
      // name: 'Conclusion',
      name: '10',
      hideTitle: true,
      content: <Conclusion previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[9].questions} />
    },
    {
      name: '11',
      hideTitle: true,
      content: <SubmitPage previousPage={previousPage} nextPage={nextPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[10].questions} />
    }
  ]

  const getResponses = async (formId) => {
    const params = {
      fields: 'id,questionKey,questionText,response',
      limit: 2000
    }
    const where = {
      submission: formId
    }

    const responses = await FormService.getResponses(apiToken, params, where)

    const savedResponses = {}

    responses.data.forEach(element => {
      let thisValue = element.response

      if (element.questionKey === 'clientName') {
        thisValue = parseInt(element.response)
      }
      savedResponses[element.questionKey] = thisValue


    })
    setFormDetails(previousValues => ({
      ...previousValues,
      ...savedResponses
    }))
  }

  const getLastFormOfThisType = async () => {

    if (!clientId) {
      return
    }

    const params = {
      fields: 'id,user,organisation,reference,type,status,createdAt',
      limit: 1,
      sort: 'createdAt DESC',
      orgId: activeOrganisation.id,
      siteId: activeSite.id
    }
    const where = {
      organisationId: activeOrganisation.id,
      subject: clientId,
      user: activeUser.id,
      type: 'clientcareplanform',
      status: 'Published'
    }
    const submissions = await FormService.getSubmissions(apiToken, params, where)
    if (submissions?.data?.length) {
      return submissions.data[0].id
    }
  }

  const [feedbackNotes, setFeedbackNotes] = useState()
  const getNotesFromThisForm = async () => {
    const params = {
      fields: 'id,user,organisation,reference,type,status,createdAt,feedbackNotes,revisionRequired',
      limit: 1,
      sort: 'createdAt DESC',
      orgId: activeOrganisation.id,
      siteId: activeSite.id
    }
    const where = {
      organisationId: activeOrganisation.id,
      // subject: clientId,
      // user: activeUser.id,
      type: 'clientcareplanform',
      id: urlParams.formId
    }
    console.log("my search", params, where)
    const submissions = await FormService.getSubmissions(apiToken, params, where)
    if (submissions?.data?.length && submissions.data[0]?.feedbackNotes) {
      setFeedbackNotes(submissions.data[0]?.feedbackNotes)
    }
  }


  useEffect(() => {
    (async () => {
      if (['view', 'feedback'].includes(urlParams.viewtype)) {
        getResponses(urlParams.formId)
        getNotesFromThisForm()
      } else if (urlParams.formId === 'new') {
        // get the last response for this user
        const lastFormId = await getLastFormOfThisType()
        if (lastFormId) {
          getResponses(lastFormId)
        }
      }
    })()
  }, [])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Client Care Plan Form
      </BreadcrumbBar>

      {feedbackNotes && <Box background='stickyNote' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' elevation='small'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Box>
            <Text weight='bold'>Feedback</Text>
            <Text>{feedbackNotes}</Text>
          </Box>
        </Box>
      </Box>}

      <Box flex='grow' gridArea='main' background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >

          <Meter background='light-2' size='full' thickness='xsmall' type='bar' values={[{ color: 'brand', value: meterValue }]} />

          <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />
        </Box>
      </Box>
    </Box>
  )
}

export default ClientCarePlan
