// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Grid as GGrid } from 'grommet'

/**
 * `Grid` renders a Grommet Grid component
 */
class Grid extends React.Component {
  render () {
    return (
      <GGrid
        {...this.props}
      />)
  }
}

Grid.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align the contents along the cross axis.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * **"stretch"**
   */
  align: PropTypes.string,
  /**
   * How to align the contents when there is extra space in the cross axis  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "between"  
   * "around"  
   * "stretch"
   */
  alignContent: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Grid areas. Either area names and column,row coordinates.  
   * Or, an array of string arrays that specify named grid areas.  
   *   
   * {  
   * nbsp;"name": "header",  
   * nbsp;"start": [0, 0],  
   * nbsp;"end": [0, 2]
   * }
   */
  areas: PropTypes.array,
  /**
   * The DOM tag or react component to use for the element.  
   *   
   * "a parent grid area name"  
   * "string"  
   * function
   */
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * Column sizes.  
   * If an array value is an array, the inner array indicates the minimum and maximum sizes for the column.  
   * Specifying a single string will repeat multiple columns of that size, as long as there is room for more.  
   * Specifying an object allows indicating how the columns stretch to fit the available space.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "any CSS size"  
   * ["small", "..."]  
   * [["small", "medium"], "..."]  
   * {"count": "fit", "size": "..."}  
   *   
   * where count could be:  
   * &nbsp;"fit"  
   * &nbsp;"fill"  
   *   
   * where array values could be:  
   * &nbsp;"xsmall"  
   * &nbsp;"small"  
   * &nbsp;"medium"  
   * &nbsp;"large"  
   * &nbsp;"xlarge"  
   * &nbsp;"full"  
   * &nbsp;"flex"  
   * &nbsp;"auto"  
   * &nbsp;"1/2"  
   * &nbsp;"1/3"  
   * &nbsp;"2/3"  
   * &nbsp;"1/4"  
   * &nbsp;"2/4"  
   * &nbsp;"3/4"
   */
  columns: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Whether the width and/or height should fill the container.  
   *   
   * "horizontal"  
   * "vertical"  
   * true  
   * false
   */
  fill: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * Whether flex-grow and/or flex-shrink is true and at a desired factor.  
   *   
   * "grow"  
   * "shrink"  
   * true  
   * false  
   * {  
   * &nbsp;&nbsp;grow: number,  
   * &nbsp;&nbsp;shrink: number  
   * }
   */
  flex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  /**
   * Gap sizes between rows and/or columns.  
   *   
   * "small"  
   * "medium"  
   * "large"  
   * "none"  
   * "any CSS size"  
   * {"row": "...", "column": "..."}
   */
  gap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * How to align the individual items inside the grid when there is extra space in the row axis.  
   *   
   * "center"  
   * "end"  
   * "start"  
   * "stretch"
   */
  justify: PropTypes.string,
  /**
   * How to align the contents along the row axis.  
   *   
   * "center"  
   * "end"  
   * "start"  
   * "stretch"  
   * "between"  
   * "around"
   */
  justifyContent: PropTypes.string,
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Spacing around the outer edge of the drawing coordinate area for the graphic elements to overflow into.  
   *   
   * **"none"**  
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "any CSS size"  
   * {  
   * nbsp;"vertical": "...", where ... is any of the above  
   * nbsp;"horizontal": "...",  
   * nbsp;"top": "...",  
   * nbsp;"bottom": "...",  
   * nbsp;"start": "...",  
   * nbsp;"end": "...",  
   * nbsp;"left": "...",  
   * nbsp;"right": "..."  
   * }
   */
  pad: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Whether margin, pad, and border sizes should be scaled for mobile environments.  
   *   
   * **true**  
   * false
   */
  responsive: PropTypes.bool,
  /**
   * Row sizes.  
   * If an array value is an array, the inner array indicates the minimum and maximum sizes for the row.  
   * Specifying a single string will cause automatically added rows to be the specified size.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "any CSS size"  
   * ["small", "..."]  
   * [["small", "medium"], "..."]  
   *  
   * where array values could be:  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "full"  
   * "flex"  
   * "auto"  
   * "1/2"  
   * "1/3"  
   * "2/3"  
   * "1/4"  
   * "2/4"  
   * "3/4"
   */
  rows: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  /**
   * The DOM tag to use for the element. NOTE: This is deprecated in favor of indicating the DOM tag via the 'as' property.  
   *   
   * "string"  
   * "function"
   */
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
}

export default Grid
