export const fileTypes = {
  client: {
    id: 'client',
    name: 'Client',
    description: 'A CSV of client users to be imported',
    type: 'csv',
    requiredFields: [
      'firstName'
      // 'lastName'
    ],
    optionalFields: [
      'lastName',
      'address',
      'assessmentCompletedDate',
      'assessmentOutcome',
      'assignedAssessor',
      'attendanceFormCompletedDate', 'carePlanLastCompleted',
      'carePlanNextDue',
      'counsellorAssigned',
      'caseworkerAssigned',
      'CGDate',
      'childcareNeeded',
      'children',
      'city',
      'clientDob',
      'counsellingCompletedDate',
      'counsellingReferralDate',
      'counsellingReportsCompleted',
      'counsellingStartedDate',
      'countryOfOrigin',
      'dobChild1',
      'dobChild2',
      'dobChild3',
      'education1',
      'email',
      'emergencyContactName',
      'emergencyContactPhone',
      'employment1',
      'employment2',
      'firstNameChild1',
      'firstNameChild2',
      'firstNameChild3',
      'gender',
      'healthDiagnosis',
      'hoReferenceNumber',
      'housingType1',
      'housingType2',
      'immigrationStatus',
      'income',
      'income2',
      'interpreterNeeded',
      'knownAs',
      'lastNameChild1',
      'lastNameChild2',
      'lastNameChild3',
      'lastUpdated',
      'legalInterventions',
      'literacyLevel',
      'livingWithClientChild1',
      'livingWithClientChild2',
      'livingWithClientChild3',
      'meetingFrequency',
      'mobile',
      'monitoringFormLastCompleted',
      'monitoringFormNextDue',
      'nino',
      'NRMExitDate',
      'nrmStatus', 'otherChildcareNeeded',
      'otherInterpreterNeeded',
      'otherLanguage',
      'partnerDob',
      'partnerLivingWithClient',
      'partnerName',
      'personalNotes',
      'postcode',
      'preferredContactMethod',
      'preferredPronoun',
      'previousPathways',
      'primaryLanguage',
      'referredDate',
      'referringAgency',
      'renovationDate1',
      'renovationDate2',
      'renovationDate3',
      'renovationDescription1',
      'renovationDescription2',
      'renovationDescription3',
      'sensitiveNotes',
      'startedSupportDate',
      'stickyNote',
      'suitableForPlacement',
      'supportEndedDate',
      'supportOrganisation',
      'traffickingType',
      'volunteersAssigned',
      'weeklyActivitiesRegistered'
    ]
  },
  user: {
    id: 'user',
    name: 'User',
    description: 'A CSV of standard users to be imported',
    type: 'csv',
    requiredFields: [
      'username',
      'firstName',
      'lastName',
      'email',
      'school',
      'role'
    ],
    optionalFields: [
      'jobTitle'
    ]
  }
}
