import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

import { constants } from '../../../../utils/constants'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import RadioButtonGroup from '../../../../components/simple/input/RadioButtonGroup'
import Select from '../../../../components/simple/input/Select'
import Text from '../../../../components/simple/text/Text'
import TextArea from '../../../../components/simple/input/TextArea'
import TextInput from '../../../../components/simple/input/TextInput'

function StepOneReferrerDetails () {
  const { formValues, valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const stepOneInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <Text weight='bold'>Referrer Details</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* referrerName */}
                  <FormField
                    label='Name of Referrer'
                    name='referrerName'
                    // required
                  >
                    <TextInput
                      name='referrerName'
                      // onChange={() => setValid(true)}
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientRelationship */}
                  <FormField
                    label='Relationship to Client'
                    name='clientRelationship'
                  >
                    <TextInput
                      name='clientRelationship'
                      type='text'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* referrerPhone */}
                  <FormField
                    label='Telephone Number'
                    name='referrerPhone'
                  >
                    <TextInput
                      name='referrerPhone'
                      type='tel'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* referrerEmail */}
                  <FormField
                    label='Email Address'
                    name='referrerEmail'
                  >
                    <TextInput
                      name='referrerEmail'
                      type='email'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Referrer Organisation</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* referrerOrganisation */}
                  <FormField
                    label='Referrer Organisation'
                    name='referrerOrganisation'
                  >
                    <RadioButtonGroup
                      direction='row-responsive'
                      labelKey='label'
                      name='referrerOrganisation'
                      options={constants.referrerOrganisation}
                      valueKey='value'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          {formValues.referrerOrganisation === 'other' &&
            <ResponsiveContext.Consumer>
              {responsive => (
                <Box direction='row-responsive' gap='small'>
                  <Box width={responsive === 'small' ? '100%' : '100%'}>
                    {/* referrerOrganisationOther */}
                    <FormField
                      label='Referrer Organisation Other'
                      name='referrerOrganisationOther'
                    >
                      <TextInput
                        name='referrerOrganisationOther'
                        type='text'
                      />
                    </FormField>
                  </Box>
                </Box>)}
            </ResponsiveContext.Consumer>}

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Other Agencies Involved in Client Care</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherAgencySocialWorker */}
                  <FormField
                    label='Social Worker'
                    name='otherAgencySocialWorker'
                  >
                    <TextArea
                      name='otherAgencySocialWorker'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherAgencyProbationOfficer */}
                  <FormField
                    label='Probation Officer'
                    name='otherAgencyProbationOfficer'
                  >
                    <TextArea
                      name='otherAgencyProbationOfficer'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherAgencyCPNCrisis */}
                  <FormField
                    label='CPN/Crisis Team'
                    name='otherAgencyCPNCrisis'
                  >
                    <TextArea
                      name='otherAgencyCPNCrisis'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherAgencyHousingSupport */}
                  <FormField
                    label='Housing Support'
                    name='otherAgencyHousingSupport'
                  >
                    <TextArea
                      name='otherAgencyHousingSupport'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherAgencySchoolCollege */}
                  <FormField
                    label='School/College'
                    name='otherAgencySchoolCollege'
                  >
                    <TextArea
                      name='otherAgencySchoolCollege'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherAgencyOthers */}
                  <FormField
                    label='Other(s)'
                    name='otherAgencyOthers'
                  >
                    <TextArea
                      name='otherAgencyOthers'
                    />
                  </FormField>
                </Box>
              </>)}
          </ResponsiveContext.Consumer>
        </Box>
      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepOneInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepOneReferrerDetails
