import { grommet } from 'grommet'
import { deepMerge } from 'grommet/utils'

export const theme = deepMerge(grommet, {
  global: {
    font: {
      size: '18px',
      height: '20px'
    },
    colors: {
      brand: '#d95d89',
      'brand-contrast': '#54ab88',
      active: '#eecc33',
      text: '#000'
    }
  },
  button: {
    border: {
      radius: '4px'
    },
    default: {
      color: 'text',
      padding: {
        horizontal: '12px',
        vertical: '8px'
      }
    },
    primary: {
      background: { color: 'brand' },
      color: 'light-1',
      font: { weight: 'bold' },
      padding: {
        horizontal: '12px',
        vertical: '8px'
      }
    },
    secondary: {
      border: { color: 'brand', width: '4px' },
      color: 'text',
      padding: {
        horizontal: '8px',
        vertical: '4px'
      }
    },
    active: {
      background: { color: 'brand-contrast' },
      color: 'text',
      secondary: {
        background: 'none',
        border: {
          color: 'brand-contrast'
        }
      }
    },
    disabled: {
      background: { color: '#fff' },
      color: 'text-weak',
      opacity: 0.3,
      secondary: {
        border: { color: 'text-weak' }
      }
    },
    hover: {
      background: { color: 'active' },
      secondary: {
        border: { color: 'active' }
      }
    }
  },
  formField: {
    border: {
      color: 'none'
    },
    help: {
      color: 'light-6'
    },
    label: {
      weight: 700,
      // extend: () => `
      //   flex: 1;`
    }
  },
  // textInput: {
  //   extend: () => `
  //     border-radius: 12px;
  //     box-shadow: 3px 3px #eee;`
  // }
})
