import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Text from '../../components/simple/text/Text'

import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'

import { Footer, ResponsiveContext } from 'grommet'
import { FormNextLink } from 'grommet-icons'
import { RiskContext } from './RiskContext'

import styled from 'styled-components'

const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`

export const StepFooter = () => {
  const size = useContext(ResponsiveContext)
  const { activeIndex, activeStep, id, steps, width } = useContext(RiskContext)
  const params = useParams()

  return (
    <Box
      margin={size !== 'small' ? { horizontal: 'medium' } : undefined}
      flex={false}
    >
      <Footer
        pad={
          size !== 'small'
            ? { vertical: 'medium' }
            : { vertical: 'small', horizontal: 'medium' }
        }
        width={width}
      >
        <Box direction='column'>
          {steps.length - 1 !== activeIndex &&
            <>
              <UpperCaseText size='xsmall'>Next - {steps[activeIndex + 1].section}</UpperCaseText>
            </>}
        </Box>

        {(params.formId === 'new' || activeIndex !== steps.length - 1) &&
          <Button
            fill={size === 'small' ? 'horizontal' : undefined}
            primary
            label={activeIndex === steps.length - 1 ? 'Finish Wizard' : <Text>Next <FontAwesome color='background' icon={['fal', 'angle-right']} /></Text>}
            form={`${id}-form`}
            type='submit'
          />}
      </Footer>
    </Box>
  )
}
