import React, { useState, useEffect } from 'react'

import { constants } from '../../../utils/constants'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import RadioButtonGroup from '../../../components/simple/input/RadioButtonGroup'
import Text from '../../../components/simple/text/Text'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function Family (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)
  useEffect(() => { setLocalFormDetails(props.formDetails) }, [props.formDetails])

  // Determine if legal importantEvent Add button is enabled or not
  const [legalImportantEventAddButton, setLegalImportantEventAddButton] = useState(true)
  // Array of legal important events
  const [legalImportantEvents, setLegalImportantEvents] = useState([])
  // Array of legal important events mapped for DataTable
  const [mappedLegalImportantEvents, setMappedLegalImportantEvents] = useState([])
  // DataTable columns for legal important events
  const [legalImportantEventsColumns, setLegalImportantEventsDataColumns] = useState()

  useEffect(() => {
    try {
      setLegalImportantEvents(JSON.parse(props.formDetails.legalImportantEvents))
    } catch (err) {
      console.error(err)
    }
  }, [props.formDetails.legalImportantEvents])

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(localFormDetails)
  }

  // Legal Important Event management functions
  const addLegalImportantEvent = () => {
    // Don't save if all fields empty
    if (localFormDetails.legalImportantEvent !== '' || localFormDetails.legalImportantEventDate !== '') {
      const event = {
        legalImportantEvent: localFormDetails.legalImportantEvent,
        legalImportantEventDate: localFormDetails.legalImportantEventDate
      }

      setLegalImportantEvents(legalImportantEvents => [...legalImportantEvents, event])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        legalImportantEvent: '',
        legalImportantEventDate: ''
      }))

      setLegalImportantEventAddButton(true)
    }
  }

  const removeLegalImportantEvent = (data) => {
    const newEvent = legalImportantEvents.filter((item) => item.legalImportantEvent !== data.legalImportantEvent)

    setLegalImportantEvents(newEvent)
  }

  const editLegalImportantEvent = (data) => {
    setLocalFormDetails(formDetails => ({
      ...formDetails,
      legalImportantEvent: data.legalImportantEvent,
      legalImportantEventDate: data.legalImportantEventDate
    }))

    removeLegalImportantEvent(data)
    setLegalImportantEventAddButton(false)
  }

  // Legal Important events have been altered
  // so rebuild the display list
  useEffect(() => {
    setLegalImportantEventsDataColumns([
      {
        property: 'legalImportantEvent',
        header: <Text pad='none' margin='none'>Event</Text>
      },
      {
        property: 'legalImportantEventDate',
        header: <Text>Date</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeLegalImportantEvent(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editLegalImportantEvent(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedLegalImportantEvents = legalImportantEvents.map((event, index) => ({
      legalImportantEvent: event.legalImportantEvent,
      legalImportantEventDate: moment(event.legalImportantEventDate).format('YYYY-MM-DD')
    }))

    setMappedLegalImportantEvents(mappedLegalImportantEvents)

    // Stringify legal important events and store them
    const legalImportantEventsToSave = JSON.stringify(legalImportantEvents)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      legalImportantEvents: legalImportantEventsToSave
    }))
  }, [legalImportantEvents])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Legal Status</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setLegalImportantEventAddButton(nextValue?.legalImportantEvent === '' || nextValue?.legalImportantEventDate === '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* Legal Status */}
                <FormField
                  // label='Are there any Urgent Needs?'
                  name={questions[0].key}
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name={questions[0].key}
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.legalStatus}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* traffickingDecision */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name={questions[1].key}
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.traffickingStatus}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* discretionaryLeave */}
                <FormField
                  label={questions[2].questionText}
                  name={questions[2].key}
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name={questions[2].key}
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.discretionaryLeave}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              {localFormDetails.legalStatus === 'asylumSeeker' &&
                <Box direction='row-responsive' gap='small'>
                  {/* asylumClaim */}
                  <FormField
                    label={questions[3].questionText}
                    name={questions[3].key}
                  >
                    <RadioButtonGroup
                      direction='row-responsive'
                      // labelKey='display'
                      name={questions[3].key}
                      // onChange={event => {
                      //   //   setToSend(event.value)
                      //   props.setNotifications(event.value)
                      //   console.log('Group1: ', event.value)
                      // }}
                      options={['Initial claim', 'Appeal 1st Tier', 'Appeal Upper Tier', 'Fresh Claim', 'Other']}
                      // valueKey='email'
                    />
                  </FormField>
                </Box>}

              {/* riskStatus */}
              <FormField
                label={questions[4].questionText}
                name={questions[4].key}
              >
                <OptionButtons
                  id={questions[4].key}
                  name={questions[4].key}
                />
              </FormField>

              {localFormDetails.riskStatus === 'Yes' &&
                // riskReason
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                >
                  <TextArea
                    name={questions[5].key}
                  />
                </FormField>}

              {/* legalChanges */}
              <FormField
                label={questions[6].questionText}
                name={questions[6].key}
              >
                <TextArea
                  name={questions[6].key}
                />
              </FormField>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Important Events</Text>

              {mappedLegalImportantEvents.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={legalImportantEventsColumns}
                    data={mappedLegalImportantEvents}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}

              <Box direction='row-responsive' gap='medium'>
                {/* legalImportantEvent */}
                <FormField
                  label={questions[7].questionText}
                  name={questions[7].key}
                >
                  <TextInput
                    name={questions[7].key}
                    type='text'
                  />
                </FormField>

                {/* legalImportantDate */}
                <FormField
                  label={questions[8].questionText}
                  name={questions[8].key}
                >
                  <DateInput
                    name={questions[8].key}
                  />
                </FormField>

                <Button disabled={legalImportantEventAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addLegalImportantEvent()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Family
