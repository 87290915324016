
import ApiService from './ApiService'

// Utils
import { constants } from '../utils/constants'
import moment from 'moment'

class ReportService {

  userReports = async (apiToken, startDate, endDate, params = {}) => {
    var url = '/api/user/reports'
    params.startDate = moment(startDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD')
    params.endDate = moment(endDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')

    try {
      var result = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }

    return result
  }

  get = async (apiToken, params) => {
    var url = '/api/search/objects'

    try {
      var result = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ReportService:get ', result)
    return result
  }

  getFilters = async (apiToken, params) => {
    var url = '/api/search/filters'

    try {
      var result = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ReportService:get ', result)
    return result
  }

  getReports = async (apiToken, params, where, getCount) => {
    var url = '/api/search'

    if (where) {
      params.where = where
    }

    if (getCount) {
      params.getCount = true
    }

    try {
      var result = await ApiService.get(apiToken, url, params, constants.responseType.blobText)
    } catch (err) {
      console.log(err)
    }
    console.log('FormService:getReports ', result)
    return result
  }

  exportReports = async (apiToken, params, where, getCount) => {
    var url = '/api/search'

    if (where) {
      params.where = where
    }

    if (getCount) {
      params.getCount = true
    }

    try {
      var result = await ApiService.openDocument(apiToken, url, params, constants.responseType.blobText)
    } catch (err) {
      console.log(err)
    }
    console.log('FormService:getReports ', result)
    return result
  }

  // getReferrals = async (apiToken, params, where, getCount) => {
  //   var url = '/api/report'
  //   if (where) {
  //     params.where = where
  //   }

  //   if (getCount) {
  //     params.getCount = true
  //   }

  //   try {
  //     var result = await ApiService.get(apiToken, url, params, constants.responseType.blobText)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   console.log('FormService:getReferrals ', result)
  //   return result
  // }

  // getCharts= async (apiToken, params, where, getCount) => {
  //   var url = '/api/report'
  //   if (where) {
  //     params.where = where
  //   }

  //   if (getCount) {
  //     params.getCount = true
  //   }

  //   try {
  //     var result = await ApiService.get(apiToken, url, params, constants.responseType.blobText)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   console.log('FormService:getCharts ', result)
  //   return result
  // }
}
export default new ReportService()
