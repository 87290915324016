
import ApiService from './ApiService'

class SiteService {
  create = async (apiToken, data) => {
    var url = '/api/site'

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('SiteService:create ', result)
    return result
  }

  getSites = async (apiToken, params) => {
    var url = '/api/site'

    try {
      var sites = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('SiteService:getSites ', sites)
    return sites
  }

  getSite = async (apiToken, siteId, params) => {
    var url = `/api/site/${siteId}`

    try {
      var site = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('SiteService:getSite ', site)
    return site
  }

  update = async (apiToken, siteId, data) => {
    var url = `/api/site/${siteId}`

    // We don't want the id in the query
    delete data.id

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('SiteService:update ', result)
    return result
  }

//   getUserCount = async (apiToken, organisationId) => {
//     var url = `/api/organisation/${organisationId}/user`

//     var params = {
//       fields: 'id,email,firstName,lastName,type'
//     }

//     try {
//       var users = await ApiService.get(apiToken, url, params)
//     } catch (err) {
//       console.log(err)
//     }
//     console.log('OrganisationService:getUserCount ', users)

//     var userCounts = {}

//     if (users?.page?.total) {
//       userCounts.users = users.page.total

//       var students = users.data.filter(user => {
//         return user.type === 'student'
//       })

//       userCounts.students = students.length
//     }
//     return userCounts
//   }

//   getRolesForOrg = async (apiToken, organisationId) => {
//     var url = `/api/organisation/${organisationId}/roles`

//     var params = {
//       fields: 'id,title,permissions'
//     }

//     try {
//       var roles = await ApiService.get(apiToken, url, params)
//     } catch (err) {
//       console.log(err)
//     }
//     console.log('OrganisationService:getRolesForOrg ', roles)
//     return roles
//   }

  delete = async (apiToken, data) => {
    const siteId = data.id
    var url = `/api/site/${siteId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('SiteService:delete ', result)
    return result
  }
}
export default new SiteService()
