// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { CheckBox as GCheckBox } from 'grommet'

/**
 * `CheckBox` renders a Grommet CheckBox control
 */
class CheckBox extends React.Component {
  render () {
    // const onChange = (input) => this.props.onChange(input)

    return (
      <GCheckBox
        {...this.props}
        // onChange={(input) => onChange(input)}
      />)
  }
}

CheckBox.propTypes = {
  /**
   * Same as React <input checked={} />  
   *   
   * true   
   * **false**
   */
  checked: PropTypes.bool,
  /**
   * Same as React <input disabled={} />.  
   * Also adds a hidden input element with the same name so form submissions work.
   *   
   * true   
   * **false**
   */
  disabled: PropTypes.bool,
  /**
   * The DOM id attribute value to use for the underlying <input/> element.  
   *   
   * "a-dom-id"  
   */
  id: PropTypes.string,
  /**
   * Whether state is indeterminate. NOTE: This can only be used with non-toggle components  
   *   
   * true   
   * **false**
   */
  indeterminate: PropTypes.bool,
  /**
   * Label text to place next to the control.  
   *   
   * "enabled"  
   * <Box>...</Box>
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  /**
   * The DOM name attribute value to use for the underlying <input/> element.  
   *   
   * "a-dom-name"
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user clicks the check box.  
   * It will be passed a React event object.  
   * The current state can be accessed via event.target.checked.  
   * Same as React <input onChange={} />.  
   *   
   * () => {}
   */
  onChange: PropTypes.func,
  /**
   * Whether to show the label in front of the checkbox.  
   *   
   * true  
   * **false**
   */
  reverse: PropTypes.bool,
  /**
   * Whether to visualize it as a toggle switch.  
   *   
   * true  
   * **false**
   */
  toggle: PropTypes.bool
}

export default CheckBox
