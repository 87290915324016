import React, { useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { Footer, ResponsiveContext } from 'grommet'
import { FormContext } from '.'

// Appt
import Box from '../../../../components/simple/box/Box'
import Button from '../../../../components/simple/button/Button'

export const StepFooter = () => {
  const size = useContext(ResponsiveContext)
  const { activeIndex, id, setActiveIndex, steps, width } = useContext(FormContext)

  const history = useHistory()

  const goBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    } else {
      history.goBack()
    }
  }

  return (
    <Box
      background='background'
      elevation='small'
      // border={{ color: 'primary', size: 'small' }}
      margin={{ top: 'small' }}
      pad={size !== 'small' ? { horizontal: 'medium' } : undefined}
      round='small'
      flex={false}
    >
      <Footer
        // border={{ side: 'top', color: 'border' }}
        // justify='end'
        pad={
          size !== 'small'
            ? { vertical: 'medium', horizontal: 'medium' }
            : { vertical: 'small', horizontal: 'medium' }
        }
        // alignSelf='center'
        width={width}
      >
        <Button
          fill={size === 'small' ? 'horizontal' : undefined}
          // icon={<FormNextLink />}
          secondary
          label='< Back'
          form={`${id}-form`}
          onClick={() => goBack()}
        />

        <Button
          fill={size === 'small' ? 'horizontal' : undefined}
          // icon={<FormNextLink />}
          primary
          reverse
          label={activeIndex === steps.length - 1 ? 'Submit' : 'Next Page'}
          form={`${id}-form`}
          type='submit'
        />
      </Footer>
    </Box>
  )
}
