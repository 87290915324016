import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import RadioButtonGroup from '../../../../components/simple/input/RadioButtonGroup'
import RatingButtonGroup from '../../../../components/compound/ratingButtonGroup/RatingButtonGroup'
import ClientNames from '../../shared/ClientNames'
import Text from '../../../../components/simple/text/Text'
import TextArea from '../../../../components/simple/input/TextArea'
import OptionButtons from '../../../../components/compound/optionButtons/OptionButtons'

import NotifyStaff from '../../shared/NotifyStaff'

// Services
import UserService from '../../../../services/UserService'
import LocalStorageService from '../../../../services/LocalStorageService'

const building = [
  {
    label: 'Strongly Agreed',
    value: 'stronglyAgree'
  },
  {
    label: 'Agree',
    value: 'agree'
  },
  {
    label: 'Neither Agree or Disagree',
    value: 'neither'
  },
  {
    label: 'Disagree',
    value: 'disagree'
  },
  {
    label: 'Strongly Disagree',
    value: 'stronglyDisagree'
  }
]

const counsellor = [
  {
    label: 'Very Well',
    value: 'veryWell'
  },
  {
    label: 'Well Enought',
    value: 'well'
  },
  {
    label: 'Not Well',
    value: 'notWell'
  }
]

const counsellingTime = [
  {
    label: 'Too Long',
    value: 'tooLong'
  },
  {
    label: 'About Right',
    value: 'right'
  },
  {
    label: 'Not Long Enough',
    value: 'notLong'
  }
]

const completing = [
  {
    label: 'Easy',
    value: 'easy'
  },
  {
    label: 'Neither Easy Nor Difficult',
    value: 'neither'
  },
  {
    label: 'Difficult',
    value: 'difficult'
  }
]

const frequency = [
  {
    label: 'Too Often',
    value: 'tooOffen'
  },
  {
    label: 'About Right',
    value: 'right'
  },
  {
    label: 'Not Often Enough',
    value: 'notOften'
  }
]

const difference = [
  {
    label: 'Not Relevant',
    value: 'notRelevant'
  },
  {
    label: 'Made A Lot Worse',
    value: 'lotWorse'
  },
  {
    label: 'Made A Little Worse',
    value: 'littleWorse'
  },
  {
    label: 'Made No Difference',
    value: 'noDifference'
  },
  {
    label: 'Helped A Little',
    value: 'helpLittle'
  },
  {
    label: 'Helped A Lot',
    value: 'helpLot'
  }
]
function StepOneExit () {
  const { valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const [staff, setStaff] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const stepOneInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <ClientNames />
          <Text weight='bold'>Could you please answer the following questions about your experience of the counselling at the snowdrop Project?</Text>
          <Text weight='bold'>1. Did you find the building... (please tick one option or each statement)</Text>
          <FormField
            label='Welcoming'
            htmlFor='welcoming'
            name='welcoming'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='welcoming'
              options={building}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Easy to get to'
            htmlFor='easy'
            name='easy'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='easy'
              options={building}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='A good environment for counselling'
            htmlFor='environment'
            name='environment'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='environment'
              options={building}
              valueKey='value'
            />
          </FormField>
          <Box width='100%'>
            <FormField
              label='Comments'
              name='buildingComments'
            >
              <TextArea
                name='buildingComments'
              />
            </FormField>
          </Box>
          <Text weight='bold'>2. How well do you think your counsellor understood your issues?</Text>

          <FormField
            htmlFor='counsellor'
            name='counsellor'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='counsellor'
              options={counsellor}
              valueKey='value'
            />
          </FormField>
          <Box width='100%'>
            <FormField
              label='Comments'
              name='counsellorComments'
            >
              <TextArea
                name='counsellorComments'
              />
            </FormField>
          </Box>
          <Text weight='bold'>3. How do you feel about the length of time you received counseling?</Text>

          <FormField
            htmlFor='counsellingTime'
            name='counsellingTime'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='counsellingTime'
              options={counsellingTime}
              valueKey='value'
            />
          </FormField>
          <Box width='100%'>
            <FormField
              label='Comments'
              name='counsellingTimeComments'
            >
              <TextArea
                name='counsellingTimeComments'
              />
            </FormField>
          </Box>
          <Text weight='bold'>4. How did you find completing the forms? (PHQ9 & GAD7, assessment forms, etc)</Text>

          <FormField
            htmlFor='completing'
            name='completing'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='completing'
              options={completing}
              valueKey='value'
            />
          </FormField>
          <Box width='100%'>
            <FormField
              label='Comments'
              name='completingComments'
            >
              <TextArea
                name='completingComments'
              />
            </FormField>
          </Box>
          <Text weight='bold'>5. How did you feel about the frequency of the forms? (PHQ9 & GAD7, assessment forms, etc)</Text>

          <FormField
            htmlFor='frequency'
            name='frequency'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='frequency'
              options={frequency}
              valueKey='value'
            />
          </FormField>
          <Box width='100%'>
            <FormField
              label='Comments'
              name='frequencyComments'
            >
              <TextArea
                name='frequencyComments'
              />
            </FormField>
          </Box>
          <Text weight='bold'>6. We would like to know what difference the counselling you received at the Snowdrop Project has made to you. Could you please think about what has changed in your life as a direct or indirect result of your counselling?
          </Text>
          <Text weight='bold'>Please tick one option for each statement
          </Text>
          <FormField
            label='Any changes in medication'
            htmlFor='medication'
            name='medication'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='medication'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Helping with feelings of depression'
            htmlFor='depression'
            name='depression'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='depression'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Helping with feelings of anxiety'
            htmlFor='anxiety'
            name='anxiety'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='anxiety'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Having fewer panic attacks'
            htmlFor='panic'
            name='panic'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='panic'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Sleeping better'
            htmlFor='sleeping'
            name='sleeping'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='sleeping'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Improving the quality of my relationships with friends & others'
            htmlFor='relationships'
            name='relationships'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='relationships'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Feeling more safe and secure'
            htmlFor='safe'
            name='safe'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='safe'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Feeling less lonely'
            htmlFor='lonely'
            name='lonely'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='lonely'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Improving my confidence'
            htmlFor='confidence'
            name='confidence'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='confidence'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Coping with life on a day to day level'
            htmlFor='coping'
            name='coping'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='coping'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Feeling more in control of my life'
            htmlFor='control'
            name='control'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='control'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Feeling more positive generally'
            htmlFor='positive'
            name='positive'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='positive'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <FormField
            label='Feeling more positive about the future'
            htmlFor='positiveFuture'
            name='positiveFuture'
          >
            <RadioButtonGroup
              direction='row'
              labelKey='label'
              name='positiveFuture'
              options={difference}
              valueKey='value'
            />
          </FormField>
          <Text weight='bold'>7. Could you please tell us anything else about your experiences of coming for counselling at Snowdrop Project. All feedback is welcome, both what went well, and not so well for you</Text>
          <Box width='100%'>
            <FormField
              name='feedback'
            >
              <TextArea
                name='feedback'
              />
            </FormField>
          </Box>
          <Text weight='bold'>8. It can be useful for the Snowdrop Project to use clients comments for research, funding and publicity purposes. Would you be willing for us to use comments from this form? All comments used for whatever purposes are made anonymous.
          </Text>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='useComment'
            >
              <OptionButtons
                id='useComment'
                name='useComment'
              />
            </FormField>
          </Box>
          <Text weight='bold'>9. It can sometimes be helpful to contact former clients when our services are being reviewed so that the services remain relevant and appropriate for our clients. Would you be willing for us to use your comments in this way?
          </Text>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='contactClient'
            >
              <OptionButtons
                id='contactClient'
                name='contactClient'
              />
            </FormField>
          </Box>
          <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

          <Text weight='bold'>Over the last 2 weeks, how often have you been bothered by the following problems?</Text>
          <Text size='small'>1=Not true, 7=Completely true</Text>
          <FormField
            label='I do not feel safe anywhere anymore'
            htmlFor='feelSafe'
            name='feelSafe'
          >
            <RatingButtonGroup
              clear='n/a'
              id='feelSafe'
              name='feelSafe'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='Other people can be genuinely loving toward me'
            htmlFor='otherPeople'
            name='otherPeople'
          >
            <RatingButtonGroup
              clear='n/a'
              id='otherPeople'
              name='otherPeople'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I am a good person'
            htmlFor='goodPerson'
            name='goodPerson'
          >
            <RatingButtonGroup
              clear='n/a'
              id='goodPerson'
              name='goodPerson'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='The world is very dangerous'
            htmlFor='worldDangerous'
            name='worldDangerous'
          >
            <RatingButtonGroup
              clear='n/a'
              id='worldDangerous'
              name='worldDangerous'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I do not trust anyone anymore'
            htmlFor='trustAnyone'
            name='trustAnyone'
          >
            <RatingButtonGroup
              clear='n/a'
              id='trustAnyone'
              name='trustAnyone'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='It is possible for me to have close and loving feelings with other people'
            htmlFor='lovingFeelings'
            name='lovingFeelings'
          >
            <RatingButtonGroup
              clear='n/a'
              id='lovingFeelings'
              name='lovingFeelings'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I trust my own judgement'
            htmlFor='trustJudgment'
            name='trustJudgment'
          >
            <RatingButtonGroup
              clear='n/a'
              id='trustJudgment'
              name='trustJudgment'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I avoid other people because they might hurt me'
            htmlFor='avoidPeople'
            name='avoidPeople'
          >
            <RatingButtonGroup
              clear='n/a'
              id='avoidPeople'
              name='avoidPeople'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I have lost respect for myself'
            htmlFor='lostRespect'
            name='lostRespect'
          >
            <RatingButtonGroup
              clear='n/a'
              id='lostRespect'
              name='lostRespect'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I do not feel confident that i can make good decisions for myself'
            htmlFor='goodDecisions'
            name='goodDecisions'
          >
            <RatingButtonGroup
              clear='n/a'
              id='goodDecisions'
              name='goodDecisions'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='Some people can be trusted'
            htmlFor='peopleTrusted'
            name='peopleTrusted'
          >
            <RatingButtonGroup
              clear='n/a'
              id='peopleTrusted'
              name='peopleTrusted'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='Because I do not feel able to protect myself, I have lost my sense of freedom'
            htmlFor='lostFreedom'
            name='lostFreedom'
          >
            <RatingButtonGroup
              clear='n/a'
              id='lostFreedom'
              name='lostFreedom'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I feel as though I can depend on other people'
            htmlFor='depend'
            name='depend'
          >
            <RatingButtonGroup
              clear='n/a'
              id='depend'
              name='depend'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='Most people are basically caring'
            htmlFor='caring'
            name='caring'
          >
            <RatingButtonGroup
              clear='n/a'
              id='caring'
              name='caring'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>
          <FormField
            label='I comfort myself very well when I am upset'
            htmlFor='comfort'
            name='comfort'
          >
            <RatingButtonGroup
              clear='n/a'
              id='comfort'
              name='comfort'
              minRange={1}
              maxRange={7}
              step={1}
            />
          </FormField>

          <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

          <NotifyStaff />
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

      </>
    )
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  useEffect(() => {
    let unmounted = false

    // Get Staff for this organisation
    const getStaff = async () => {
      var params = {
        fields: 'id,firstName,lastName',
        limit: 1000,
        type: 'user',
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        where: {
          deleted: false
        }
      }

      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: (data?.firstName || '') + ' ' + (data?.lastName || '')
            }))
            setStaff(mappedUsers)
          }
        }
      }
    }

    getStaff()

    return () => { unmounted = true }
  }, [])

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepOneInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepOneExit
