import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { hasAnyPermissionIn } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

function StaffListArchive (props) {
  const [userValues, setUserValues] = useState([])
  const history = useHistory()
  const [userToDelete, setUserToDelete] = useState(null)
  const [showDeleteOptions, showDelete] = useState(false)
  const [userToRestore, setUserToRestore] = useState(null)
  const [showRestoreOptions, showRestore] = useState(false)

  const [loading, setLoading] = useState(false)

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'reference',
    direction: 'asc'
  })

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // Prompt user to delete
  const showDeleteUserOptions = (user) => {
    setUserToDelete(user)
    showDelete(true)
  }

  // Delete a User
  const deleteUser = async () => {
    // Delete from server
    const response = await UserService.deleteUser(apiToken, userToDelete, true, {
      type: 'staff'
    })
    if (response?.data?.success === 'true') {
      // And from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
      setUserValues(newUserValues)
      NotificationService.success(`${userToDelete.name} deleted`)
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Prompt user to restore service user
  const showRestoreUserOptions = (user) => {
    setUserToRestore(user)
    showRestore(true)
  }

  // Restore a User
  const restoreUser = async () => {
    showRestore(false)

    const userData = {
      data: { deleted: false }
    }

    var userUpdated = await UserService.updateUser(apiToken, userToRestore.id, userData, {
      type: 'staff'
    })
    if (userUpdated.error) {
      NotificationService.error(userUpdated.error)
    } else {
      // Delete from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToRestore.id)
      setUserValues(newUserValues)

      NotificationService.info(userToRestore.name + ' has been restored')
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'staff',
        where: {
          deleted: true
        }
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              reference: data.reference,
              name: data.firstName + ' ' + data.lastName
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    if (hasAnyPermissionIn(['staffAccessArchive'])) {
      getUsers()
      setLoading(false)
    } else {
      history.push('/dashboard')
    }

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'reference',
      header: <Text weight={600}>Reference</Text>,
      primary: true,
      search: true,
      render: datum => (
        <Text>C000{datum.id}</Text>
      )
    },
    {
      property: 'name',
      header: <Text weight={600}>Name</Text>,
      primary: true,
      search: true
    },
    // {
    //   property: 'school',
    //   header: <Text>School</Text>,
    //   render: datum => (
    //     <Text>Coming soon</Text>
    //   )
    // },
    // {
    //   property: 'email',
    //   header: <Text>Email</Text>
    // },
    // {
    //   property: 'created',
    //   header: <Text>Created</Text>
    // },
    {
      property: 'actions',
      header: <Text weight={600}>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          {hasAnyPermissionIn(['staffRestoreMy', 'staffRestoreAll']) &&
            <Button
              target='_self'
              plain
              onClick={() => showRestoreUserOptions(datum)}
              children={
                <Box pad='5px'>
                  <FontAwesome icon={['fal', 'undo']} color='#fe8300' />
                </Box>
              }
            />}

          {hasAnyPermissionIn(['staffDestroyMy', 'staffDestroyAll']) &&
            <Button
              target='_self'
              plain
              onClick={() => showDeleteUserOptions(datum)}
              children={
                <Box pad='5px'>
                  <FontAwesome icon={['fal', 'trash']} color='#fe8300' />
                </Box>
              }
            />}
        </Box>
      ),
      sortable: false
    }
  ]

  return (
    <>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Staff Archive Directory </H1>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }} status='error'>Are you sure you want to permanently delete user {userToDelete.name}? If you delete the user you will not be able to retrieve their details again!</NotificationLayer>}
          {showRestoreOptions &&
            <NotificationLayer button1Text='Yes' button1Click={restoreUser} button2Text='No' button2Click={() => { showRestore(false) }}>Are you sure you want to restore user {userToRestore.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' pad='medium' round='small'>
        <Box direction='column'>
          <Box alignSelf='end' direction='row-responsive' pad={{ horizontal: 'medium' }}>
            <Box direction='row-responsive' gap='xsmall'>
              <Button href='/staff' label={<Text><FontAwesome icon={['fal', 'user']} /> View Staff</Text>} secondary />
            </Box>
          </Box>
          <Box direction='column' gap='medium' pad={{ horizontal: 'medium' }}>
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>
            {userValues?.length
              ? <DataTable columns={dataColumns} data={userValues} onSort={setSort} sort={sort} />
              : <Text margin={{ vertical: 'small' }}>There are no records to display.</Text>}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default StaffListArchive
