// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Layer as GLayer } from 'grommet'

/**
 * `Layer` renders a Layer as a Grommet Layer component
 */
class Layer extends React.Component {
  render () {
    return (
      <GLayer
        {...this.props}
      >
        {this.props.children}
      </GLayer>)
  }
}

Layer.propTypes = {
  /**
   * Whether to animate the Layer content when it opens.  
   * This property is deprecated and will be removed in the next major version of grommet.  
   * Instead, use 'animation'.  
   *   
   * **true**  
   * false
   */
  animate: PropTypes.bool,
  /**
   * Animation transition of the Layer content when it opens and closes.  
   *   
   *  **"slide"**  
   *  "fadeIn"  
   *  "none"  
   *  true  
   *  false
   */
  animation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * Whether the width and/or height should fill the current viewport size.  
   *   
   * true  
   * **false**  
   * "horizontal"  
   * "vertical"
   */
  full: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "none"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "any css size"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Whether there should be an overlay preventing interaction underneath the layer.  
   *   
   * **true**  
   * false  
   */  
  modal: PropTypes.bool,
  /**
   * Function that will be invoked on modal layers when the user clicks outside the layer.  
   *   
   * () => {}
   */
  onClickOutside: PropTypes.func,
  /**
   * Function that will be called when the user presses the escape key inside the layer.  
   *     
   * () => {}
   */
  onEsc: PropTypes.func,
  /**
   * Whether this is a plain Layer with no background color or border.  
   *   
   * true  
   * **false**
   */
  plain: PropTypes.bool,
  /**
   * Position of the layer content.  
   *   
   * "bottom"  
   * "bottom-left"  
   * "bottom-right"  
   * **"center"**  
   * "end"  
   * "hidden"  
   * "left"  
   * "right"  
   * "start"  
   * "top"  
   * "top-left"  
   * "top-right"
   */
  position: PropTypes.string,
  /**
   * Whether the layer should take full width and height on mobile.  
   *   
   * **true**  
   * false
   */
  responsive: PropTypes.bool,
  /**
   * Target where the layer will be aligned to. This should be a React reference.  
   *   
   * object
   */
  target: PropTypes.object
}

export default Layer
