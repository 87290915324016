import React, { useEffect, useState } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Grommet
import { ResponsiveContext } from 'grommet'

import { constants } from '../../../utils/constants'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import UserService from '../../../services/UserService'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import Divider from '../../../components/simple/divider/Divider'
import H2 from '../../../components/simple/heading/H2.js'
import Select from '../../../components/simple/input/Select'
import TextInput from '../../../components/simple/input/TextInput'
import TextArea from '../../../components/simple/input/TextArea'

function SummaryReport (props) {
  const [loading, setLoading] = useState(false)

  const [userValues, setUserValues] = useState([])
  const [staffValues, setStaffValues] = useState([])

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)
  useEffect(() => { setLocalFormDetails(props.formDetails) }, [props.formDetails])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(localFormDetails)
  }

  // Get Users for this organisation
  const getStaff = async () => {
    const params = {
      fields: 'id,firstName,lastName,reference',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'staff',
      where: {
        deleted: false
      }
    }

    const users = await UserService.getUsers(apiToken, params)
    if (users?.error) {
      // NotificationService.error(users.error)
    } else {
      if (users?.error) {
        setStaffValues([])
      } else if (users?.data) {
        const mappedUsers = users.data.map((data, index) => ({
          id: data.id,
          name: data.firstName + ' ' + data.lastName
        }))

        setStaffValues(mappedUsers)
      }
    }
  }

  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'client',
        where: {
          deleted: false
        }
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    getUsers()
    getStaff()

    setLocalFormDetails(previousValues => ({
      ...previousValues,
      clientName: parseInt(props.clientId)
    }))
    setLoading(false)

    return () => { unmounted = true }
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Summary Report</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              {/* Name of Client */}
              <FormField
                label={questions[0].questionText}
                name={questions[0].key}
                required
              >
                <Select
                  name={questions[0].key}
                  labelKey='name'
                  options={userValues}
                  valueKey={{ key: 'id', reduce: true }}
                />
              </FormField>

              <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />
              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Support Start */}
                      <FormField
                        label={questions[1].questionText}
                        name={questions[1].key}
                      >
                        <DateInput
                          id={questions[1].key}
                          name={questions[1].key}
                        />
                      </FormField>
                    </Box>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Support End */}
                      <FormField
                        label={questions[2].questionText}
                        name={questions[2].key}
                        required
                      >
                        <DateInput
                          id={questions[2].key}
                          name={questions[2].key}
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '33%'}>
                      {/* Final Caseworker Name */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <Select
                          name={questions[3].key}
                          labelKey='name'
                          options={staffValues}
                          valueKey={{ key: 'id', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '33%'}>
                      {/* Supervisor Name */}
                      <FormField
                        label={questions[4].questionText}
                        name={questions[4].key}
                      >
                        <TextInput
                          name={questions[4].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                    <Box width={responsive === 'small' ? '100%' : '33%'}>
                      {/* Other CW/Vols Involved */}
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                      >
                        <TextInput
                          name={questions[5].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>


              {/* Summary of Work with Client */}
              <FormField
                label={questions[6].questionText}
                name={questions[6].key}
                required
              >
                <TextArea
                  name={questions[6].key}
                  type='text'
                />
              </FormField>

              {/* Outstanding Actions */}
              <FormField
                label={questions[7].questionText}
                name={questions[7].key}
                required
              >
                <TextArea
                  name={questions[7].key}
                  type='text'
                />
              </FormField>

              {/* Reason for Closure */}
              <FormField
                label={questions[8].questionText}
                name={questions[8].key}
                required
              >
                <TextArea
                  name={questions[8].key}
                  type='text'
                />
              </FormField>

              {/* What have you learned from this case */}
              <FormField
                label={questions[9].questionText}
                name={questions[9].key}
                required
              >
                <TextArea
                  name={questions[9].key}
                  type='text'
                />
              </FormField>

              {/* Any Signposting Details */}
              <FormField
                label={questions[10].questionText}
                name={questions[10].key}
                required
              >
                <TextArea
                  name={questions[10].key}
                  type='text'
                />
              </FormField>

              {/* Other Notes */}
              <FormField
                label={questions[11].questionText}
                name={questions[11].key}
                required
              >
                <TextArea
                  name={questions[11].key}
                  type='text'
                />
              </FormField>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default SummaryReport
