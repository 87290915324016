import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import { ResponsiveContext } from 'grommet'
import { StepHeader } from './StepHeader'
import { RiskContext } from './RiskContext'

import Box from '../../components/simple/box/Box'
import Form from '../../components/simple/form/Form'
import FormField from '../../components/simple/formField/FormField.js'
import Grid from '../../components/simple/grid/Grid.js'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'

export const StepContent = (props) => {
  const size = useContext(ResponsiveContext)
  const {
    activeIndex,
    setActiveIndex,
    formValues,
    id,
    ref,
    riskColorLookup,
    setFormValues,
    setValid,
    steps,
    width
  } = useContext(RiskContext)

  const params = useParams()
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  useEffect(() => {
    setFormValues(props.formValues)
  }, [props.formValues])

  useEffect(() => {
    const getResponses = async () => {
      const thisParams = {
        fields: 'id,questionKey,questionText,response',
        limit: 2000
      }
      const where = {
        submission: params.formId
      }
      const responses = await FormService.getResponses(apiToken, thisParams, where)

      const savedResponses = {}

      for (const element of responses.data) {
        var thisValue = element.response

        if (element.questionKey === 'clientName') {
          thisValue = parseInt(element.response)
          savedResponses[element.questionKey] = thisValue
          continue
        }

        if (element.questionKey === 'riskNotes') {
          try {
            var notes = JSON.parse(element.response)
            if (Object.keys(notes).length) {
              for (const noteKey in notes) {
                savedResponses[noteKey] = notes[noteKey]
              }
            }
          } catch (err) {
            console.error(err)
          }
          continue
        }

        savedResponses[element.questionKey] = thisValue
        continue
      }

      setFormValues(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (params.formId !== 'new') {
      getResponses()
    }

  }, [])

  const handleSubmit = (event) => {
    // TEMP
    // setValid(true)
    // if (activeIndex < 3) {
    //   console.log(formValues)

    //   setActiveIndex(activeIndex + 1)
    // } else if (onSubmit) {
    //   onSubmit(event)
    // }

    setValid(true)
    if (activeIndex < steps.length - 1) {
      console.log(formValues)
      props.onSubmit(event)
      setActiveIndex(activeIndex + 1)
    } else if (props.onSubmit) {
      props.onSubmit(event)
    }
  }

  const handleFormChange = (values) => {
    // NB. Check nextValue as the set state
    // will not have completed in time
    switch (activeIndex) {
      case 0:
        if (values.firstName !== '' && values.lastName !== '' && values.gender !== '' && values.nationality !== '' && values.age !== '') {
          steps[activeIndex].riskLevel = 'Complete'
        } else {
          steps[activeIndex].riskLevel = 'Missing Answers'
        }
        break
      case 1:
        steps[activeIndex].selected = values.homelessness
        break
    }
  }

  // On long forms, we want to focus the first of any fields that
  // return an error or info message. This removes the need for the
  // user to scroll to find which field blocked form submission.
  const onValidate = (validationResults) => {
    const names = [
      ...Object.keys(validationResults.errors),
      ...Object.keys(validationResults.infos)
    ]
    if (names.length > 0) {
      const selector = names.map((name) => `[name=${name}]`).join(',')
      const firstInvalid = document.querySelectorAll(selector)[0]
      if (firstInvalid) {
        setTimeout(() => firstInvalid.focus(), 0)
      }
    }
    setTimeout(() => setValid(names.length === 0), 0)
  }

  return (
    <Box
      align='start'
      pad={size !== 'small' ? { vertical: 'large' } : { vertical: 'medium' }}
      overflow='auto'
      ref={ref}
      flex={size === 'small' ? true : undefined}
      margin={size !== 'small' ? { horizontal: 'medium' } : undefined}
    >
      <Box
        width='100%'
        gap='medium'
        pad={size === 'small' ? { horizontal: 'medium' } : 'xxsmall'}
      >
        <StepHeader />
        <Box margin={{ top: 'small' }}>
          <Form
            // needed to associate form submit button with form
            // since submit button lives outside form tag
            id={`${id}-form`}
            value={formValues}
            onChange={(nextValue) => {
              setFormValues(nextValue)
              handleFormChange(nextValue)
            }}
            onSubmit={handleSubmit}
            onValidate={onValidate}
            method='post'
            messages={{
              required: 'This is a required field.'
            }}
          >

            {/* Don't display the Grid if first or last page */}
            {activeIndex !== 0 && activeIndex < steps.length - 1
              ? (
                <>
                  <Grid columns={{ count: 5, size: 'auto' }}>
                    <Box align='center' border justify='center' background={{ color: riskColorLookup.Safeguarding }} pad={{ vertical: 'small' }}>
                      <Text color='white' size='small' textAlign='center' weight='bold'>
                        Safeguarding
                      </Text>
                    </Box>
                    <Box align='center' border justify='center' background={{ color: riskColorLookup['High Complexity'] }} pad={{ vertical: 'small' }}>
                      <Text color='white' size='small' textAlign='center' weight='bold'>
                        High Complexity
                      </Text>
                    </Box>
                    <Box align='center' border justify='center' background={{ color: riskColorLookup['Medium Complexity'] }} pad={{ vertical: 'small' }}>
                      <Text color='white' size='small' textAlign='center' weight='bold'>
                        Medium Complexity
                      </Text>
                    </Box>
                    <Box align='center' border justify='center' background={{ color: riskColorLookup['Low Complexity'] }} pad={{ vertical: 'small' }}>
                      <Text color='white' size='small' textAlign='center' weight='bold'>
                        Low Complexity
                      </Text>
                    </Box>
                    <Box align='center' border justify='center' background={{ color: riskColorLookup.Independent }} pad={{ vertical: 'small' }}>
                      <Text color='white' size='small' textAlign='center' weight='bold'>
                        Independent
                      </Text>
                    </Box>

                    {steps[activeIndex].inputs}

                  </Grid>

                  {/* NOTE: Finances/Budgeting section so strip the slash ! */}
                  <FormField
                    label='Other Notes'
                    htmlFor={`${steps[activeIndex].section.replace('/', '').toLowerCase()}OtherNotes`}
                    name={`${steps[activeIndex].section.replace('/', '').toLowerCase()}OtherNotes`}
                  >
                    <TextArea
                      id={`${steps[activeIndex].section.replace('/', '').toLowerCase()}OtherNotes`}
                      name={`${steps[activeIndex].section.replace('/', '').toLowerCase()}OtherNotes`}
                    />
                  </FormField>
                </>
              )
              : (
                steps[activeIndex].inputs
              )}
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

StepContent.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
