import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getObjectByValue } from '../../utils/objects'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form'
import FormField from '../../components/simple/formField/FormField'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import H2 from '../../components/simple/heading/H2'

function StaffEditAccess (props) {
  // List of staff
  const [staff, setStaff] = useState([])
  // Selected staff id
  const [accessValues, setAccessValues] = useState()
  // List of staff with access
  const [grantedStaff, setGrantedStaff] = useState([])
  // Enable/disable Grant Access button
  const [grantAccessAddButton, setGrantAccessAddButton] = useState(false)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const params = useParams()

  const userId = params.staffid

  const grantAccess = async (values) => {
    const toSend = {
      data: [
        {
          userGrantedAccess: values.id
        }
      ],
      // temporary bodge
      organisation: activeOrg.id
    }

    const accessGranted = await UserService.grantDirectAccess(apiToken, toSend, userId)

    if (accessGranted?.error) {
      NotificationService.error(accessGranted.error)
      return false
    } else {
      return true
    }
  }

  // Add staff member to granted access
  const addGrantAccess = async () => {
    // accessValues.staff has the id of the staff member
    // so get the name from the staff list

    const staffObject = getObjectByValue(staff, 'id', accessValues.staff)

    if (await grantAccess(staffObject)) {
      setGrantedStaff(granted => [...granted, staffObject])

      // Remove granted staff from list
      const newStaff = staff.filter((staff) => { return staff.id !== parseInt(staffObject.id) })
      setStaff(newStaff)

      // Clear Select box
      setAccessValues(values => ({
        ...values,
        staff: ''
      }))

      setGrantAccessAddButton(false)
    }
  }

  const revokeAccess = async (revokeId) => {
    const toSend = {
      data: [
        {
          userGrantedAccess: revokeId
        }
      ],
      // temporary bodge
      organisation: activeOrg.id
    }

    const accessRevoked = await UserService.revokeDirectAccess(apiToken, toSend, userId)

    if (accessRevoked?.error) {
      NotificationService.error(accessRevoked.error)
      return false
    } else {
      return true
    }
  }

  // Remove access for staff member
  const removeGrantAccess = async (memberIndex) => {
    if (await revokeAccess(memberIndex)) {
      // Add staff member back in list
      const staffToAdd = grantedStaff.filter((item) => item.id === memberIndex)
      // staffToAdd = staffToAdd[0]

      setStaff(previousValues => [
        ...previousValues,
        staffToAdd[0]
      ])

      // And remove from granted list
      const newGrants = grantedStaff.filter((item) => item.id !== memberIndex)

      setGrantedStaff(newGrants)
    }
  }

  useEffect(() => {
    let unmounted = false

    // Get all Staff with direct access to this user
    const getDirectAccess = async () => {
      console.log(userId)
      const params = {
        fields: 'id,firstName,lastName,email',
        limit: 1000
      }

      const directAccessGrants = await UserService.getDirectAccessGrants(apiToken, userId, params)
      if (!directAccessGrants.error) {
        if (!unmounted) {
          const grantedStaff = directAccessGrants.data.map((data, index) => ({
            id: data.id,
            name: data.firstName + ' ' + data.lastName
          }))

          setGrantedStaff(grantedStaff)
        }
      }
    }

    getDirectAccess()

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    // Get all Staff for this Org
    const getStaff = async () => {
      const params = {
        fields: 'id,firstName,lastName,email',
        limit: 1000,
        type: 'staff',
        where: {
          deleted: false
        }
      }

      const staff = await UserService.getUsers(apiToken, params)
      if (!staff.error) {
        let mappedStaff = staff.data.map((data, index) => ({
          id: data.id,
          name: data.firstName + ' ' + data.lastName
        }))

        // Remove staff member being edited from the list
        mappedStaff = mappedStaff.filter((staff) => { return staff.id !== parseInt(userId) })

        // Remove granted staff from list
        grantedStaff.forEach(element => {
          mappedStaff = mappedStaff.filter((staff) => { return staff.id !== parseInt(element.id) })
        })

        setStaff(mappedStaff)
      }
    }

    getStaff()
  }, [grantedStaff])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Staff with access</H2>
          <Text size='xsmall'>The current staff members who can view this contact</Text>
        </Box>

        <>
          <Form
            onChange={nextValue => {
              console.log(nextValue)
              if (nextValue.staff) {
                setGrantAccessAddButton(true)
              }
              setAccessValues(nextValue)
            }}
            // onSubmit={({ value: nextValue }) => {
            //   submitForm(nextValue)
            // }}
            value={accessValues}
          >
            <Box direction='row-responsive' justify='around'>
              <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>

                <Box direction='row-responsive' gap='medium'>
                  <ResponsiveGrid
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto'],
                      large: ['auto', 'auto'],
                      xlarge: ['auto', 'auto']
                    }}
                    gap='small'
                  >
                    {grantedStaff.length > 0
                      ? (
                        <ResponsiveGrid
                          rows={['auto']}
                          columns={{
                            small: ['auto'],
                            medium: ['auto', 'auto'],
                            large: ['auto', 'auto'],
                            xlarge: ['auto', 'auto']
                          }}
                          gap='small'
                        >
                          {grantedStaff.map((staff, index) => {
                            return (
                              <Box direction='row' key={index} margin='xsmall'>
                                {staff.name} <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333', height: '35px' }} onClick={() => removeGrantAccess(staff.id)} target='_self' />
                              </Box>)
                          })}
                        </ResponsiveGrid>)
                      : <Text>No granted staff yet</Text>}

                  </ResponsiveGrid>
                </Box>
              </Box>

              <Box direction='column'>
                <FormField
                  label='Grant access to a staff member'
                  name='staff'
                  required
                >
                  <Select
                    id='staff'
                    name='staff'
                    labelKey='name'
                    //   onChange={({ option }) => changeOrganisation(option)}
                    options={staff}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                <Button disabled={!grantAccessAddButton} label={<Text><FontAwesome color='light-1' icon={['fal', 'plus-circle']} /> Grant Access</Text>} onClick={() => addGrantAccess()} />
              </Box>
            </Box>

            <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Button label='< Back' onClick={() => props.goBack()} secondary />
              {/* <Button type='submit' label='Save' primary /> */}
            </Box>
          </Form>
        </>
      </Box>
    </Box>
  )
}

export default StaffEditAccess
