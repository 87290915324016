// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { ThemeContext } from 'grommet'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * `FontAwesome` renders a FontAwesomeIcon component using the Grommet theme
 */
class FontAwesome extends React.Component {
  render () {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <FontAwesomeIcon
            {...this.props}
            color={theme.global.colors[this.props.color]}
          />
        )}
      </ThemeContext.Consumer>)
  }
}

FontAwesome.propTypes = {
  /**
   * Color.
   */
  color: PropTypes.string
}

export default FontAwesome
