import React, { useContext } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import Text from '../../../../components/simple/text/Text'
import TextArea from '../../../../components/simple/input/TextArea'

import NotifyStaff from '../../shared/NotifyStaff'

function StepFiveClientDetails () {
  const { valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const stepFiveInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <Text weight='bold'>Trafficking History</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* traffickingDetails */}
                  <FormField
                    label='Please provide brief details regarding the client’s exploitation/trafficking'
                    name='traffickingDetails'
                  >
                    <TextArea
                      name='traffickingDetails'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* traffickingLength */}
                  <FormField
                    label='Length of time the client has been out of exploitation/trafficking'
                    name='traffickingLength'
                  >
                    <TextArea
                      name='traffickingLength'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* presentingIssues */}
                  <FormField
                    label='Presenting Issues'
                    name='presentingIssues'
                  >
                    <TextArea
                      name='presentingIssues'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  <NotifyStaff />
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>
        </Box>
      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepFiveInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepFiveClientDetails
