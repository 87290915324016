
const hideValues = false

class LocalStorageService {
  get (key) {
    try {
      var value = localStorage.getItem(key)

      // somewhere undefined is being stored as "undefined"
      // if that happens, make it actually undefined
      if (value === 'undefined') {
        value = undefined
      }

      if (value && value !== null) {
        if (hideValues) {
          value = Buffer.from(value, 'base64').toString('utf8')
        }

        value = JSON.parse(value)
      }
      return value
    } catch (err) {
      console.error(err)
      return null
    }
  }

  set (key, value) {
    try {
      value = JSON.stringify(value)

      if (hideValues) {
        value = Buffer.from(value, 'utf8').toString('base64')
      }

      localStorage.setItem(key, value)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  clear () {
    localStorage.clear()
  }
}
export default new LocalStorageService()
