import React, { useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { hasAnyPermissionIn } from '../../utils/permissions'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'

import PartnerList from '../partners/PartnerList'
import PartnerListArchive from '../partners/PartnerListArchive'

function Contacts (props) {
  const history = useHistory()

  const [viewArchive, setViewArchive] = useState(false)

  const params = useParams()
  const partnerOrgId = params.orgid

  const addContact = () => {
    history.push({
      pathname: '/partner/new/edit',
      partnerorgid: partnerOrgId
    })
  }

  const handleViewArchive = () => {
    setViewArchive(true)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <Box fill>
            <Text size='large'>Organisation Contacts{viewArchive ? ' Archive' : null}</Text>
          </Box>
          <Box direction='row' fill gap='small' justify='end'>
            {!viewArchive &&
              <Box direction='row-responsive' gap='small'>
                {hasAnyPermissionIn(['partnerContactArchiveAll', 'partnerContactArchiveMy']) &&
                  <Button label={<Text><FontAwesome icon={['fal', 'trash']} /> View Archive</Text>} onClick={() => handleViewArchive()} />}
                {hasAnyPermissionIn(['partnerContactCreate']) &&
                  <Button label={<Text><FontAwesome icon={['fal', 'plus-circle']} /> Add Contact</Text>} onClick={() => addContact()} />}
              </Box>}
          </Box>
        </Box>

        {!viewArchive &&
          <PartnerList partnerOrgId={partnerOrgId} />}
        {viewArchive &&
          <PartnerListArchive partnerOrgId={partnerOrgId} />}

      </Box>
    </Box>
  )
}

export default Contacts
