import React, { useEffect, useState } from 'react'

// Appt Components
import Select from '../../../components/simple/input/Select'
import Text from '../../../components/simple/text/Text'

// Utils
import { constants } from '../../../utils/constants'

const OperatorBox = (props) => {
  const [operatorOptions, setOperatorOptions] = useState([])

  useEffect(() => {
    let unmounted = false

    // Set operator options based on field datatype
    const setOperator = async () => {
      switch (props.type) {
        case 'autoComplete':
          setOperatorOptions(constants.autoCompleteQueries)
          break
        case 'boolean':
          setOperatorOptions(constants.booleanQueries)
          break
        case 'date':
          setOperatorOptions(constants.dateQueries)
          break
        case 'integer':
          setOperatorOptions(constants.integerQueries)
          break
        case 'list':
          setOperatorOptions(constants.listQueries)
          break
        case 'time':
          setOperatorOptions(constants.timeQueries)
          break

        default:
          setOperatorOptions(constants.autoCompleteQueries)
      }
    }

    setOperator()

    return () => { unmounted = true }
  }, [props.type])

  return (
    <>
      <Select
        options={operatorOptions}
        {...props}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
      />
    </>
  )
}

export default OperatorBox
