import React from 'react'

// Utils
import { constants } from '../../../utils/constants'

// Appt Components
import Select from '../../../components/simple/input/Select'
import FormField from '../../../components/simple/formField/FormField.js'

const TypeBox = (props) => {
  // const [defaultValue, setDefaultValue] = useState()

  const handleFieldChanged = (value) => {
    props.setModelType(value)
  }

  return (
    <FormField
      direction='column'
      label='Type'
      name='modelType'
    >
      <Select
        {...props}
        name='modelType'
        labelKey='label'
        onChange={({ value: nextValue }) => {
          handleFieldChanged(nextValue)
        }}
        // defaultValue={defaultValue}
        options={constants.modelTypes}
        valueKey={{ key: 'value', reduce: true }}
      />
    </FormField>
  )
}

export default TypeBox
