import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

import { constants } from '../../../../utils/constants'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import OptionButtons from '../../../../components/compound/optionButtons/OptionButtons'
import Select from '../../../../components/simple/input/Select'
import Text from '../../../../components/simple/text/Text'
import TextArea from '../../../../components/simple/input/TextArea'

function StepFourClientDetails () {
  const { formValues, setFormValues, valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const stepFourInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <Text weight='bold'>Health</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* disability */}
                  <FormField
                    label='Does the client have a disability that we need to consider when we offer counselling?'
                    name='disability'
                  >
                    <OptionButtons
                      id='disability'
                      name='disability'
                      options={['Yes', 'No']}
                    />
                  </FormField>
                </Box>

                {formValues.disability === 'Yes' &&
                  <Box width={responsive === 'small' ? '100%' : '50%'}>
                    {/* ukStatus */}
                    <FormField
                      label='Disability Details'
                      name='disabilityDetails'
                    >
                      <TextArea
                        name='disabilityDetails'
                      />
                    </FormField>
                  </Box>}
              </Box>)}
          </ResponsiveContext.Consumer>

          <Text weight='bold'>Contact Details of Health Professionals</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* GP */}
                  <FormField
                    label='GP'
                    name='gp'
                  >
                    <TextArea
                      name='gp'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* psychiatrist */}
                  <FormField
                    label='Psychiatrist'
                    name='psychiatrist'
                  >
                    <TextArea
                      name='psychiatrist'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* otherHealthProfessional */}
                  <FormField
                    label='Other Health Professional'
                    name='otherHealthProfessional'
                  >
                    <TextArea
                      name='otherHealthProfessional'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Diagnoses</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* currentMedication */}
                  <FormField
                    label='Current Medication'
                    name='currentMedication'
                  >
                    <TextArea
                      name='currentMedication'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* physicalDiagnoses */}
                  <FormField
                    label='Physical Diagnoses'
                    name='physicalDiagnoses'
                  >
                    <TextArea
                      name='physicalDiagnoses'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* mentalHealthDiagnoses */}
                  <FormField
                    label='Mental Health Diagnoses'
                    name='mentalHealthDiagnoses'
                  >
                    <TextArea
                      name='mentalHealthDiagnoses'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* substanceAbuse */}
                  <FormField
                    label='Substance Abuse'
                    name='substanceAbuse'
                  >
                    <Select
                      name='substanceAbuse'
                      labelKey='label'
                      options={constants.substanceAbuse}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* receivedCounsellingBefore */}
                  <FormField
                    label='Has the client received counselling before'
                    name='receivedCounsellingBefore'
                  >
                    <OptionButtons
                      id='receivedCounsellingBefore'
                      name='receivedCounsellingBefore'
                      options={['Yes', 'No']}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          {formValues.receivedCounsellingBefore === 'Yes' &&
            <ResponsiveContext.Consumer>
              {responsive => (
                <Box direction='row-responsive' gap='small'>
                  <Box width={responsive === 'small' ? '100%' : '100%'}>
                    {/* counsellingDetails */}
                    <FormField
                      label='Counsellor Name and Organisation'
                      name='counsellingDetails'
                    >
                      <TextArea
                        name='counsellingDetails'
                      />
                    </FormField>
                  </Box>
                </Box>)}
            </ResponsiveContext.Consumer>}
        </Box>
      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepFourInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepFourClientDetails
