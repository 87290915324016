
import ApiService from './ApiService'

class MediaService {
  get = async (apiToken, params) => {
    var url = '/api/media'

    try {
      var result = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('MediaService:get ', result)
    return result
  }

  create = async (apiToken, params, data) => {
    const url = '/api/media'

    const options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('MediaService:create ', result)
    return result
  }

  update = async (apiToken, mediaId, data) => {
    const url = `/api/media/${mediaId}`

    var params = {}

    data = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    console.log('MediaService:update ', result)
    return result
  }

  delete = async (apiToken, data) => {
    const mediaId = data.id
    var url = `/api/media/${mediaId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('MediaService:delete ', result)
    return result
  }
}
export default new MediaService()
