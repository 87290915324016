// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { DateInput as GDateInput } from 'grommet'

/**
 * `DateInput` renders a Grommet DateInput box
 */
class DateInput extends React.Component {
  render () {
  // const onChange = (input) => this.props.onChange(input)

    return (
      <GDateInput
        {...this.props}
        // onChange={(input) => onChange(input)}
      />)
  }
}

DateInput.defaultProps = {
  buttonProps: { backgroundColor: 'white' },
  format: 'dd/mm/yyyy'
}

DateInput.propTypes = {
  /**
   * Any properties to pass on to the underlying DropButton when not inline and no format.  
   *   
   * {  
   * &nbsp; ""  
   * }
   */
  buttonProps: PropTypes.object,
  /**
   * Any properties to pass on to the underlying Calendar.  
   *   
   * {  
   * &nbsp; ""  
   * }
   */
  calendarProps: PropTypes.object,
  /**
   * The default date or date range value in ISO8601 format.  
   *   
   * "string"  
   * [  
   * &nbsp;"string"  
   * ]
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  /**
   * Any properties to pass on to the underlying Drop when not inline.  
   *   
   * {  
   * &nbsp; ""  
   * }
   */
  dropProps: PropTypes.object,
  /**
   * The date format to use.  
   * If not specified, the date value will not be displayed as a text string and the user will not be able to enter a date by typing.  
   * For example: 'mm/dd/yyyy', or for a range: 'mm/dd/yyyy-mm/dd/yyyy'.  
   * This property should be used when in a Form.  
   */
  format: PropTypes.string,
  /**
   * The id of the input.  
   */
  id: PropTypes.string,
  /**
   * Whether the calendar should always be shown or via a Drop when interacting with the input.
   */
  inline: PropTypes.bool,
  /**
   * Any properties to pass on to the underlying MaskedInput when there is a format.  
   *   
   * {  
   * &nbsp; ""  
   * }
   */
  inputProps: PropTypes.object,
  /**
   * The name of the attribute when in a Form or FormField.
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user types or selects a date.  
   * The updated value will be available via 'event.value'.
   */
  onChange: PropTypes.func,
  /**
   * The date or date range value(s) in ISO8601 format.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

export default DateInput
