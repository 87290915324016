import ApiService from './ApiService'

class UserService {
  inviteUser = async (apiToken, data) => {
    var url = '/api/user/invite'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      email: [data.email],
      role: data.role,
      organisation: data.organisation
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:inviteUser ', result)
    return result
  }

  createUser = async (apiToken, body, params) => {
    var url = '/api/user'

    try {
      var result = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:createUser ', result)
    return result
  }

  getUser = async (apiToken, userId, params) => {
    var url = `/api/user/${userId}`

    // var params = {
    //   fields: 'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
    //   limit: 1
    // }

    try {
      var user = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getUser ', user)
    return user
  }

  getUsers = async (apiToken, params) => {
    var url = '/api/user'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
    //   limit: 1000
    // }

    // if (where) {
    //   params.where = where
    // }

    try {
      var users = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getUsers ', users)
    return users
  }

  getGlobalAdmins = async (apiToken, params = {}) => {
    var url = '/api/user/globaladmin'
    try {
      var users = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getUsers ', users)
    return users
  }

  updateUser = async (apiToken, userId, data, params = {}) => {
    var url = `/api/user/${userId}`

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    // var options = {
    //   data: {
    //     email: data.email,
    //     firstName: data.firstName,
    //     lastName: data.lastName
    //   }
    // }

    try {
      var result = await ApiService.put(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    return result
  }

  deleteUser = async (apiToken, data, force, params = {}) => {
    const userId = data.id
    var url = `/api/user/${userId}`

    var options = {}

    if (force) {
      params.force = 1
    }

    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:deleteUser ', result)
    return result
  }
  acceptInvite = async (inviteToken, data) => {
    console.log(inviteToken, data)

    var url = '/api/accept-invite'

    const body = {
      token: inviteToken,
      user: {
        firstName: data.firstname,
        lastName: data.lastname,
        password: data.password
      },
      acceptTerms: data.tandc,
      userType: data.type
    }

    try {
      var result = await ApiService.post(null, url, {}, body)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:activateUser ', result)
    return result
  }

  getDirectAccessGrants = async (apiToken, userId, params) => {
    var url = `/api/user/${userId}/directaccess/grants`

    try {
      var response = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getDirectAccessGrants ', response)
    return response
  }

  grantDirectAccess = async (apiToken, body, userId) => {
    const params = {}
    var url = `/api/user/${userId}/directaccess`

    try {
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:granttDirectAccess ', response)
    return response
  }

  revokeDirectAccess = async (apiToken, body, userId) => {
    const params = {}
    var url = `/api/user/${userId}/directaccess`

    try {
      var response = await ApiService.sendDelete(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:granttDirectAccess ', response)
    return response
  }
}
export default new UserService()
