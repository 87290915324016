import React, { useState } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import FieldBox from '../shared/FieldBox.js'
import FormField from '../../../components/simple/formField/FormField.js'
import JoinBox from '../shared/JoinBox.js'
import OperatorBox from '../shared/OperatorBox.js'
import Text from '../../../components/simple/text/Text'
import ValueBox from '../shared/ValueBox'

function CriteriaBox (props) {
  const [type, setType] = useState()
  const [fieldType, setFieldType] = useState()
  const [list, setList] = useState()

  const handleFieldChanged = (fieldName, value) => {
    const fieldIndex = fieldName.substring(fieldName.indexOf('-') + 1)
    const newFields = props.fields

    newFields[fieldIndex] = value
    setType(newFields[fieldIndex].type)
    setFieldType(newFields[fieldIndex].value)
    setList(newFields[fieldIndex].list)
    props.setFields(newFields)
  }

  return (
    <>
      <Box direction='row-responsive' gap='medium'>
        <FormField
          direction='column'
          label='Field'
          name={`Field-${props.index}`}
        >
          <FieldBox
            handleFieldChanged={handleFieldChanged}
            name={`Field-${props.index}`}
            modelType={props.modelType}
          />
        </FormField>

        <FormField
          direction='column'
          label='Query'
          name={`Query-${props.index}`}
        >
          <OperatorBox
            index={props.index}
            name={`Query-${props.index}`}
            type={type}
          />
        </FormField>

        <FormField
          direction='column'
          label='Value'
          name={`Value-${props.index}`}
        >
          <ValueBox
            name={`Value-${props.index}`}
            type={type}
            list={list}
            fieldType={fieldType}
          />
        </FormField>

        <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall', vertical: 'medium' }} style={{ background: '#333' }} onClick={() => props.removeCriteria(props.index)} target='_self' />
      </Box>
    </>
  )
}

export default CriteriaBox
