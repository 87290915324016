
// const hideValues = false

import ApiService from './ApiService'

class ConnectionService {
  getConnection = async () => {
    var url = '/api/ping'

    var params = {}

    try {
      var connection = await ApiService.getConnection(null, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('ConnectionService:getConnection ', connection)
    return connection
  }
}
export default new ConnectionService()
