import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import moment from 'moment'

import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import UserService from '../../../services/UserService'
import UserTagService from '../../../services/UserTagService'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Meter from '../../../components/simple/meter/Meter'
import Tabs from '../../../components/simple/tabs/Tabs'
import Text from '../../../components/simple/text/Text'

// Child Forms
import Family from './Family'
import Health from './Health'
import HousingDetails from './HousingDetails'
import LegalStatus from './LegalStatus'
import Nationality from './Nationality'
import PersonalDetails from './PersonalDetails'
import Police from './Police'
import Referral from './Referral'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

function CaseworkReferralForm (props) {
  const history = useHistory()

  const [meterValue, setMeterValue] = useState()

  const [formDetails, setFormDetails] = useState({
    // Personal Details
    urgentNeeds: '',
    lastName: '',
    firstName: '',
    knownAs: '',
    dob: '',
    addressLineOne: '',
    addressLineTwo: '',
    phone: '',
    postcode: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    firstNameChild: '',
    lastNameChild: '',
    dobChild: '',
    children: '',
    maritalStatus: '',
    gender: '',
    nino: '',
    att: '',
    hoReferenceNumber: '',
    supportArea: '',
    nrmStatus: '',
    // Nationality
    nationality: '',
    primaryLanguage: '',
    englishStandard: '',
    interpreterNeeded: '',
    communicationNeeds: '',
    // Legal
    legalStatus: '',
    discretionaryLeave: '',
    asylumClaim: '',
    compensation: '',
    compensationClaim: '',
    compensationType: '',
    compensationDate: '',
    immigrationAsylumSolicitor: '',
    immigrationAsylumSolicitorAddress: '',
    immigrationAsylumSolicitorPhone: '',
    immigrationAsylumSolicitorEmail: '',
    compensationSolicitor: '',
    compensationSolicitorAddress: '',
    compensationSolicitorPhone: '',
    compensationSolicitorEmail: '',
    criminalSolicitor: '',
    criminalSolicitorAddress: '',
    criminalSolicitorPhone: '',
    criminalSolicitorEmail: '',
    // Housing
    housingStatus: '',
    keyDate: '',
    incomeDetails: '',
    previousnrmAddressLineOne: '',
    previousnrmAddressLineTwo: '',
    previousnrmPhone: '',
    previousnrmPostcode: '',
    afterCareExitDate: '',
    referrerName: '',
    referrerPhone: '',
    referrerRelationship: '',
    referrerEmail: '',
    reachInSupport: '',
    reachInStartDate: '',
    reachInEndDate: '',
    reachInProvider: '',
    reachInContact: '',
    // Police
    ongoingPoliceCase: '',
    policeContactName: '',
    policeContactPhone: '',
    policeDetails: '',
    // Health
    gpName: '',
    gpPhone: '',
    dentistName: '',
    dentistPhone: '',
    counsellorName: '',
    counsellorPhone: '',
    otherHealthProfessionalName: '',
    otherHealthProfessionalPhone: '',
    physicalDisability: '',
    healthDetails: '',
    collectMedication: '',
    // Family
    familyHere: '',
    familyAbroad: '',
    familyHaveContact: '',
    familyWantContact: '',
    familyDetails: '',
    // Initial referral
    background: '',
    personalProblems: '',
    supportNeeds: '',
    personalStatement: ''
  })

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')
  const params = useParams()
  const viewtype = params.viewtype

  const [clientId, setClientId] = useState()

  // Create User Tags for client
  // ie. data not saved in main Client form
  const createClientTags = async (formDetailsToSave, userId) => {
    const userTags = []
    let item = {}

    // Loop through all the questions
    // To build an array for User Tag
    // saving from those marked as tag
    for (var value of questions) {
      value.questions.forEach((question) => {
        if (checkInputHasContent(formDetailsToSave[question.key]) && question?.tag) {
          item = {
            user: userId[0],
            organisation: activeOrganisation.id,
            key: question.key,
            value: formDetailsToSave[question.key],
            type: 'general'
          }
          userTags.push(item)
        }
      })
    }
    userTags.push({
      user: userId[0],
      organisation: activeOrganisation.id,
      key: 'referredDateFirst',
      value: new Date(),
      type: 'general',
      sensitivity: 'personal'
    })

    userTags.push({
      user: userId[0],
      organisation: activeOrganisation.id,
      key: 'referredDate',
      value: new Date(),
      type: 'general',
      sensitivity: 'personal'
    })

    const response = await UserTagService.set(apiToken, userTags)
    if (response?.error) {
      NotificationService.error(response.error)
    }
  }

  // Create a client
  const createClient = async (formDetailsToSave) => {
    var data = {
      // reference: formDetailsToSave?.reference,
      firstName: formDetailsToSave?.firstName,
      lastName: formDetailsToSave?.lastName,
      // email: formDetailsToSave?.email,
      gender: formDetailsToSave?.gender,
      // ethnicity: formDetailsToSave?.ethnicity,
      type: 'client'
    }

    if (formDetailsToSave.dob) {
      data.dob = moment(formDetailsToSave.dob).format('YYYY-MM-DD')
    }

    const dataToSubmit = {
      data: data,
      organisation: activeOrganisation.id
    }

    const params = {
      orgId: activeOrganisation.id
    }

    var userAdded = await UserService.createUser(apiToken, dataToSubmit, params)

    if (userAdded.error) {
      NotificationService.error(userAdded.error)
    } else {
      createClientTags(formDetailsToSave, userAdded.data.id)
    }

    return userAdded.data.id[0]
  }

  // Submit Form
  const submitForm = async (formDetailsToSave, status) => {
    if (viewtype === 'edit') {
      setFormDetails(formDetailsToSave)

      var submitArray = []
      var item = {
        key: '',
        questionText: '',
        answer: ''
      }

      // Loop through each form value
      for (var key in formDetailsToSave) {
        // And questions on each page of the form
        questions.forEach(function (pageOfQuestions, index) {
          // To match them up
          var found = pageOfQuestions.questions.find(question => question.key === key)

          if (found && checkInputHasContent(formDetailsToSave[key])) {
            let response = null

            response = formDetailsToSave[key]

            item = {
              questionKey: key,
              questionText: found.questionText,
              response: response,
              responseType: found?.responseType || 'string'
            }
            submitArray.push(item)
          }
        })
      }

      var newClientId = clientId
      if (!newClientId) {
        newClientId = await createClient(formDetailsToSave)
        setClientId(newClientId)
      }

      // Build output data
      const data = {
        user: activeUser.id,
        organisation: activeOrganisation.id,
        subject: newClientId,
        status: status,
        type: 'caseworkform',
        reference: '',
        notify: JSON.stringify(formDetailsToSave.notifyStaff || []),
        responses: submitArray
      }

      // Only submit if there is data
      if (submitArray.length !== 0) {
        var submissionAdded = await FormService.makeSubmissions(apiToken, data)
        if (submissionAdded.error) {
          NotificationService.error(submissionAdded.error)
        } else {
          if (status === 'Published') {
            NotificationService.success('Form Submitted')
            history.push('/')
          } else {
            nextPage()
          }
        }
      } else {
        // If we're on the last page stay there
        if (tabIndex !== tabContent.length - 1) {
          nextPage()
        }
      }
    } else {
      // Don't save - just view the output
      nextPage()
    }
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = true
        // inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / tabContent.length)
    setTabIndex(nextIndex)
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex)
    }
  }

  const nextPage = () => {
    if (tabIndex === tabContent.length - 1) {
      submitForm()
    } else {
      const nextPageIndex = tabIndex + 1
      onActiveTab(nextPageIndex)
    }
  }

  useEffect(() => {
    const getResponses = async () => {
      const thisParams = {
        fields: 'id,questionKey,questionText,response',
        limit: 2000
      }
      const where = {
        submission: params.formId
      }

      const responses = await FormService.getResponses(apiToken, thisParams, where)

      const savedResponses = {}

      responses.data.forEach(element => {
        let thisValue = element.response

        if (element.questionKey === 'clientName') {
          thisValue = parseInt(element.response)
        }
        savedResponses[element.questionKey] = thisValue


      })

      console.log("setFormDetails", savedResponses)

      setFormDetails(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (params.formId !== 'new') {
      getResponses()
    }

  }, [])

  const [questions, setQuestions] = useState([
    // Personal Details
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Are there any Urgent Needs?',
          key: 'urgentNeeds'
        },
        {
          questionText: 'Last Name',
          key: 'lastName'
        },
        {
          questionText: 'First Name',
          key: 'firstName'
        },
        {
          questionText: 'Known as',
          key: 'knownAs',
          tag: true
        },
        {
          questionText: 'Date of birth',
          key: 'dob'
        },
        {
          questionText: 'Address 1',
          key: 'address',
          tag: true
        },
        {
          questionText: 'Address 2',
          key: 'addressLineTwo'
        },
        {
          questionText: 'Phone',
          key: 'mobile',
          tag: true
        },
        {
          questionText: 'Postcode',
          key: 'postcode',
          tag: true
        },
        {
          questionText: 'Marital Status',
          key: 'maritalStatus'
        },
        {
          questionText: 'Gender',
          key: 'gender'
        },
        {
          questionText: 'Name of emergency contact/next of kin',
          key: 'emergencyContactName',
          tag: true
        },
        {
          questionText: 'Phone/contact of emergency contact/next of kin',
          key: 'emergencyContactPhone',
          tag: true
        },
        {
          questionText: 'First Name',
          key: 'firstNameChild'
        },
        {
          questionText: 'Last Name',
          key: 'lastNameChild'
        },
        {
          questionText: 'Date of Birth',
          key: 'dobChild'
        },
        {
          questionText: 'NINO (if applicable)',
          key: 'nino',
          tag: true
        },
        {
          questionText: 'ATT Number (if applicable)',
          key: 'att'
        },
        {
          questionText: 'HO Ref No.',
          key: 'hoReferenceNumber',
          tag: true
        },
        {
          questionText: 'Where do you require support?',
          key: 'supportArea'
        },
        {
          questionText: 'Trafficking/NRM Decision',
          key: 'nrmStatus'
        },
        // Hidden question to store the array of children
        {
          questionText: 'Children',
          key: 'children',
          tag: true
        }
      ]
    },
    // Nationality & Language
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Nationality',
          key: 'countryOfOrigin'
        },
        {
          questionText: 'Primary Language',
          key: 'primaryLanguage',
          tag: true
        },
        {
          questionText: 'English',
          key: 'englishStandard'
        },
        {
          questionText: 'Interpreter',
          key: 'interpreterNeeded'
        },
        {
          questionText: 'Other communication needs',
          key: 'communicationNeeds'
        }
      ]
    },
    // Legal Status
    {
      tabId: 2,
      questions: [
        {
          questionText: 'legal Status',
          key: 'immigrationStatus'
        },
        {
          questionText: 'Discretionary Leave',
          key: 'discretionaryLeave'
        },
        {
          questionText: 'If Asylum Seeker please indicate',
          key: 'asylumClaim'
        },
        {
          questionText: 'Has the person made a claim for compensation?',
          key: 'compensationClaim'
        },
        {
          questionText: 'Type of compensation',
          key: 'compensationType'
        },
        {
          questionText: 'Date of claim',
          key: 'compensationDate'
        },
        {
          questionText: 'Immigration/Asylum',
          key: 'immigrationAsylumSolicitor'
        },
        {
          questionText: 'Immigration/Asylum Solicitor Name',
          key: 'immigrationAsylumSolicitor'
        },
        {
          questionText: 'Immigration/Asylum Address',
          key: 'immigrationAsylumSolicitorAddress'
        },
        {
          questionText: 'Immigration/Asylum',
          key: 'immigrationAsylumSolicitorPhone'
        },
        {
          questionText: 'Immigration/Asylum',
          key: 'immigrationAsylumSolicitorEmail'
        },
        {
          questionText: 'Compensation',
          key: 'compensationSolicitor'
        },
        {
          questionText: 'Compensation',
          key: 'compensationSolicitorAddress'
        },
        {
          questionText: 'Compensation',
          key: 'compensationSolicitorPhone'
        },
        {
          questionText: 'Compensation',
          key: 'compensationSolicitorEmail'
        },
        {
          questionText: 'Criminal',
          key: 'criminalSolicitor'
        },
        {
          questionText: 'Criminal',
          key: 'criminalSolicitorAddress'
        },
        {
          questionText: 'Criminal',
          key: 'criminalSolicitorPhone'
        },
        {
          questionText: 'Criminal',
          key: 'criminalSolicitorEmail'
        },
        // Hidden question to store the array of compensation
        {
          questionText: 'Compensation',
          key: 'compensation'
        }
      ]
    },
    // Housing Details
    {
      tabId: 3,
      questions: [
        {
          questionText: 'Housing Status',
          key: 'housingStatus'
        },
        {
          questionText: 'Any Key Dates (Is there an eviction date?)',
          key: 'keyDate'
        },
        {
          questionText: 'Income Details',
          key: 'incomeDetails'
        },
        {
          questionText: 'Address 1',
          key: 'previousnrmAddressLineOne'
        },
        {
          questionText: 'Address 2',
          key: 'previousnrmAddressLineTwo'
        },
        {
          questionText: 'Phone',
          key: 'previousnrmPhone'
        },
        {
          questionText: 'Postcode',
          key: 'previousnrmPostcode'
        },
        {
          questionText: 'Aftercare exit date',
          key: 'afterCareExitDate'
        },
        {
          questionText: 'Referrer name',
          key: 'referrerName'
        },
        {
          questionText: 'Contact Number',
          key: 'referrerPhone'
        },
        {
          questionText: 'Referrer Relationship',
          key: 'referrerRelationship'
        },
        {
          questionText: 'Email',
          key: 'referrerEmail'
        },
        {
          questionText: 'Has the person had support from Reach In?',
          key: 'reachInSupport'
        },
        {
          questionText: 'Date Reach In support started',
          key: 'reachInStartDate'
        },
        {
          questionText: 'Date Reach In support ended',
          key: 'reachInEndDate'
        },
        {
          questionText: 'Name of Reach In Provider',
          key: 'reachInProvider'
        },
        {
          questionText: 'Contact Name of Reach In Provider',
          key: 'reachInContact'
        }
      ]
    },
    // Police
    {
      tabId: 4,
      questions: [
        {
          questionText: 'Do they have an ongoing case with the police?',
          key: 'ongoingPoliceCase'
        },
        {
          questionText: 'Surname',
          key: 'policeContactLastName'
        },
        {
          questionText: 'First name',
          key: 'policeContactFirstName'
        },
        {
          questionText: 'Phone',
          key: 'policeContactPhone'
        },
        {
          questionText: 'Provide details of the current investigation including interview dates, any Crime Reference numbers and necessary details',
          key: 'policeDetails'
        }
      ]
    },
    // Health
    {
      tabId: 5,
      questions: [
        {
          questionText: 'GP Name',
          key: 'gpName'
        },
        {
          questionText: 'GP Phone',
          key: 'gpPhone'
        },
        {
          questionText: 'Dentist Name',
          key: 'dentistName'
        },
        {
          questionText: 'Dentist Phone',
          key: 'dentistPhone'
        },
        {
          questionText: 'Counsellor Name',
          key: 'counsellorName'
        },
        {
          questionText: 'Counsellor Phone',
          key: 'counsellorPhone'
        },
        {
          questionText: 'Other Health Professional Name',
          key: 'otherHealthProfessionalName'
        },
        {
          questionText: 'Other Health Professional Phone',
          key: 'otherHealthProfessionalPhone'
        },
        {
          questionText: 'Disability or Mental/Physical Health Diagnosis',
          key: 'physicalDisability'
        },
        {
          questionText: 'Provide details including but not limited to, their current physical and mental health; any diagnosis; important historical problems; outstanding appointments; medication; ability to self care; substance or alcohol use',
          key: 'healthDetails'
        },
        {
          questionText: 'Are they able to collect their own medication?',
          key: 'collectMedication'
        }
      ]
    },
    // Family
    {
      tabId: 6,
      questions: [
        {
          questionText: 'Do they have family in this country?',
          key: 'familyHere'
        },
        {
          questionText: 'Do they have family abroad?',
          key: 'familyAbroad'
        },
        {
          questionText: 'Do they have contact with them?',
          key: 'familyHaveContact'
        },
        {
          questionText: 'Do they want to attempt to contact ‘missing’ family connections?',
          key: 'familyWantContact'
        },
        {
          questionText: 'Provide details including but not limited to important family and friends and whether there are any safety issues around family contacts',
          key: 'familyDetails'
        }
      ]
    },
    // Referral
    {
      tabId: 7,
      questions: [
        {
          questionText: 'Are there any specific personal problems or Safety Issues?',
          key: 'personalProblems'
        },
        {
          questionText: 'To help us with our assessment, please provide details of what the person’s current support needs are?',
          key: 'supportNeeds'
        },
        {
          questionText: 'Personal statement',
          key: 'personalStatement'
        }
      ]
    }
  ])

  const tabContent = [
    {
      // name: 'Personal Details',
      name: 'Page 1',
      hideTitle: true,
      content: <PersonalDetails previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[0].questions} />
    },
    {
      // name: 'Nationality and Language Details',
      name: 'Page 2',
      hideTitle: true,
      content: <Nationality previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[1].questions} />
    },
    {
      // name: 'Legal Status and Solicitor Details',
      name: 'Page 3',
      hideTitle: true,
      content: <LegalStatus previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[2].questions} />
    },
    {
      // name: 'Housing Details',
      name: 'Page 4',
      hideTitle: true,
      content: <HousingDetails previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[3].questions} />
    },
    {
      // name: 'Police',
      name: 'Page 5',
      hideTitle: true,
      content: <Police previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[4].questions} />
    },
    {
      // name: 'Health',
      name: 'Page 6',
      hideTitle: true,
      content: <Health previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[5].questions} />
    },
    {
      // name: 'Family',
      name: 'Page 7',
      hideTitle: true,
      content: <Family previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[6].questions} />
    },
    {
      // name: 'Initial Referral Details',
      name: 'Page 8',
      hideTitle: true,
      content: <Referral previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[7].questions} />
    }
  ]

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Casework Referral Form
      </BreadcrumbBar>

      <Box flex='grow' gridArea='main' background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Meter background='light-2' size='full' thickness='xsmall' type='bar' values={[{ color: 'brand', value: meterValue }]} />

          <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} full />
        </Box>
      </Box>
    </Box>
  )
}

export default CaseworkReferralForm
