import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import RoleService from '../../services/RoleService'

import { capitalize } from '../../utils/text'
import { hasPermission } from '../../utils/permissions'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Grid from '../../components/simple/grid/Grid'
import Button from '../../components/simple/button/Button'
import CheckBoxGroup from '../../components/simple/input/CheckBoxGroup'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H4 from '../../components/simple/heading/H4'
import H5 from '../../components/simple/heading/H5'

import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

function RoleEdit () {
  const history = useHistory()

  const [roleDetails, setRoleDetails] = useState({
    title: '',
    permissions: []
  })

  const params = useParams()

  const roleId = params.roleid

  // Permissions from Flytt tranlsated into key/label pairs
  const allPermissions = {
    clientAll: {
      label: 'Client (All)',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [
          {
            key: 'clientCreate',
            label: 'Create'
          }
        ],
        moderate: [
          {
            key: 'clientViewAll',
            label: 'Access'
          },
          {
            key: 'clientViewAllBasic',
            label: 'View Basic Info'
          },
          {
            key: 'clientViewAllPersonal',
            label: 'View Personal Info'
          },
          {
            key: 'clientEditAll',
            label: 'Edit'
          },
          {
            key: 'clientArchiveAll',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'clientAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'clientRestoreAll',
            label: 'Restore'
          },
          {
            key: 'clientDestroyAll',
            label: 'Destroy'
          },
          {
            key: 'clientGrantDirectAccess',
            label: 'Assign Direct Access'
          },
          {
            key: 'clientViewAllSensitive',
            label: 'View Sensitive Info'
          },
          {
            key: 'roleManageClientPermissions',
            label: 'Manage Client Permissions'
          }
        ]
      }
    },
    clientMy: {
      label: 'Client (Assigned)',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [
          {
            key: 'clientViewMy',
            label: 'Access'
          },
          {
            key: 'clientViewMyBasic',
            label: 'View Basic Info'
          },
          {
            key: 'clientEditMy',
            label: 'Edit'
          },
          {
            key: 'clientArchiveMy',
            label: 'Archive'
          }
        ],
        moderate: [
          {
            key: 'clientViewMyPersonal',
            label: 'View Personal Info'
          }
        ],
        privileged: [
          {
            key: 'clientViewMySensitive',
            label: 'View Sensitive Info'
          }
        ]
      }
    },
    clientNote: {
      label: 'Client Notes',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [
          {
            key: 'clientNoteView',
            label: 'View'
          },
          {
            key: 'clientNoteEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'clientNoteArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'clientNoteAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'clientNoteRestore',
            label: 'Restore'
          },
          {
            key: 'clientNoteViewAllTeams',
            label: 'View Notes From All Teams'
          }
        ]
      }
    },
    clientDocs: {
      label: 'Client Documents',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [
          {
            key: 'clientDocumentView',
            label: 'View'
          },
          {
            key: 'clientDocumentEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'clientDocumentArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'clientDocumentAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'clientDocumentRestore',
            label: 'Restore'
          }
        ]
      }
    },
    clientContact: {
      label: 'Client Contacts',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [
          {
            key: 'clientContactView',
            label: 'View'
          }
        ],
        moderate: [
          {
            key: 'clientContactEdit',
            label: 'Edit'
          }
        ],
        privileged: []
      }
    },
    clientForm: {
      label: 'Client Forms',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [
          {
            key: 'clientFormView',
            label: 'View'
          },
          {
            key: 'clientFormEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'clientFormArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'clientFormAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'clientFormRestore',
            label: 'Restore'
          }
        ]
      }
    },
    clientAuditTrail: {
      label: 'Client Audit Trail',
      requiredPermission: 'roleManageClientPermissions',
      permissions: {
        basic: [],
        moderate: [],
        privileged: [
          {
            key: 'clientAuditTrail',
            label: 'Access Audit Trail'
          }
        ]
      }
    },
    form: {
      label: 'Form',
      requiredPermission: 'formManagePermissions',
      permissions: {
        basic: [
          {
            key: 'formAccessModule',
            label: 'Access Module'
          },

          {
            key: 'formAccessCaseworkReferral',
            label: 'Casework Referral'
          },
          {
            key: 'formAccessMonitoring',
            label: 'Monitoring Tool'
          },
          {
            key: 'formAccessExitCasework',
            label: 'Exit (Casework)'
          },
          {
            key: 'formAccessExitCounselling',
            label: 'Exit (Counselling)'
          },
          {
            key: 'formAccessClientCarePlan',
            label: 'Client Care Plan'
          },
          {
            key: 'formAccessCounselling',
            label: 'Counselling'
          },
          {
            key: 'formAccessGAD7',
            label: 'GAD7'
          },
          {
            key: 'formAccessPHQ9',
            label: 'PHQ9'
          },
          {
            key: 'formAccessRiskMatrix',
            label: 'Risk Matrix'
          }
        ],
        moderate: [
          {
            key: 'formAccessAll',
            label: 'Access All Forms'
          }
        ],
        privileged: [
          {
            key: 'formGrantDirectAccess',
            label: 'Assign Direct Access'
          }
        ]
      }
    },
    role: {
      label: 'Role',
      requiredPermission: 'roleManage',
      permissions: {
        basic: [
        ],
        moderate: [
          {
            key: 'roleAccessModule',
            label: 'Access Module'
          }
        ],
        privileged: [
          {
            key: 'roleManage',
            label: 'Manage'
          }
        ]
      }
    },
    staffAll: {
      label: 'Staff (All)',
      requiredPermission: 'roleManageStaffPermissions',
      permissions: {
        basic: [
          {
            key: 'staffCreate',
            label: 'Create'
          }
        ],
        moderate: [
          {
            key: 'staffViewAll',
            label: 'Access'
          },
          {
            key: 'staffEditAll',
            label: 'Edit'
          },
          {
            key: 'staffArchiveAll',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'staffAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'staffRestoreAll',
            label: 'Restore'
          },
          {
            key: 'staffDestroyAll',
            label: 'Destroy All'
          },
          {
            key: 'staffGrantDirectAccess',
            label: 'Assign Direct Access'
          }
        ]
      }
    },
    staffMy: {
      label: 'Staff (Assigned)',
      requiredPermission: 'roleManageStaffPermissions',
      permissions: {
        basic: [
          {
            key: 'staffViewMy',
            label: 'Access'
          },
          {
            key: 'staffEditMy',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'staffArchiveMy',
            label: 'Archive'
          }
        ],
        privileged: [
        ]
      }
    },
    staffNote: {
      label: 'Staff Notes',
      requiredPermission: 'roleManageStaffPermissions',
      permissions: {
        basic: [
          {
            key: 'staffNoteView',
            label: 'View'
          },
          {
            key: 'staffNoteEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'staffNoteArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'staffNoteAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'staffNoteRestore',
            label: 'Restore'
          }
        ]
      }
    },
    staffDocs: {
      label: 'Staff Documents',
      requiredPermission: 'roleManageStaffPermissions',
      permissions: {
        basic: [
          {
            key: 'staffDocumentView',
            label: 'View'
          },
          {
            key: 'staffDocumentEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'staffDocumentArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'staffDocumentAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'staffDocumentRestore',
            label: 'Restore'
          }
        ]
      }
    },
    staffAuditTrail: {
      label: 'Staff Audit Trail',
      requiredPermission: 'roleManageStaffPermissions',
      permissions: {
        basic: [],
        moderate: [],
        privileged: [
          {
            key: 'staffAuditTrail',
            label: 'Access Audit Trail'
          }
        ]
      }
    },
    partnerOrgAll: {
      label: 'Partner Org (All)',
      requiredPermission: 'roleManagePartnerOrgPermissions',
      permissions: {
        basic: [
          {
            key: 'partnerOrgViewAll',
            label: 'View'
          },
          {
            key: 'partnerOrgCreate',
            label: 'Create'
          }
        ],
        moderate: [
          {
            key: 'partnerOrgEditAll',
            label: 'Edit'
          },
          {
            key: 'partnerOrgArchiveAll',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'partnerOrgAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'partnerOrgRestoreAll',
            label: 'Restore'
          },
          {
            key: 'partnerOrgDestroyAll',
            label: 'Destroy All'
          },
          {
            key: 'partnerOrgGrantDirectAccess',
            label: 'Assign Direct Access'
          }
        ]
      }
    },
    partnerOrgMy: {
      label: 'Partner Org (Assigned)',
      requiredPermission: 'roleManagePartnerOrgPermissions',
      permissions: {
        basic: [
          {
            key: 'partnerOrgViewMy',
            label: 'Access'
          },
          {
            key: 'partnerOrgEditMy',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerOrgArchiveMy',
            label: 'Archive'
          }
        ],
        privileged: [
        ]
      }
    },
    partnerOrgNote: {
      label: 'Partner Org Notes',
      requiredPermission: 'partnerOrgNoteManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerOrgNoteView',
            label: 'View'
          },
          {
            key: 'partnerOrgNoteEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerOrgNoteArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'partnerOrgNoteAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'partnerOrgNoteRestore',
            label: 'Restore'
          }
        ]
      }
    },
    partnerOrgDocs: {
      label: 'Partner Org Documents',
      requiredPermission: 'partnerOrgDocumentManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerOrgDocumentView',
            label: 'View'
          },
          {
            key: 'partnerOrgDocumentEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerOrgDocumentArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'partnerOrgDocumentAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'partnerOrgDocumentRestore',
            label: 'Restore'
          }
        ]
      }
    },
    partnerContact: {
      label: 'Partner Org Contacts',
      requiredPermission: 'partnerContactManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerContactViewMy',
            label: 'View'
          },
          {
            key: 'partnerContactEditMy',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerContactArchiveMy',
            label: 'Archive'
          }
        ],
        privileged: [
        ]
      }
    },
    partnerContactAll: {
      label: 'Partner Contact (All)',
      requiredPermission: 'partnerContactManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerContactCreate',
            label: 'Create'
          },
          {
            key: 'partnerContactViewAll',
            label: 'View'
          }
        ],
        moderate: [
          {
            key: 'partnerContactEditAll',
            label: 'Edit'
          },
          {
            key: 'partnerContactArchiveAll',
            label: 'Archive'
          },
        ],
        privileged: [
          {
            key: 'partnerContactAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'partnerContactRestoreAll',
            label: 'Restore'
          },
          {
            key: 'partnerContactDestroyAll',
            label: 'Destroy All'
          }
        ]
      }
    },
    partnerContactMy: {
      label: 'Partner Contact (Assigned)',
      requiredPermission: 'partnerContactManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerContactViewMy',
            label: 'Access'
          },
          {
            key: 'partnerContactEditMy',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerContactArchiveMy',
            label: 'Archive'
          }
        ],
        privileged: [
        ]
      }
    },
    partnerContactNote: {
      label: 'Partner Contact Notes',
      requiredPermission: 'partnerContactManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerContactNoteView',
            label: 'View'
          },
          {
            key: 'partnerContactNoteEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerContactNoteArchive',
            label: 'Archive'
          },
        ],
        privileged: [
          {
            key: 'partnerContactNoteAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'partnerContactNoteRestore',
            label: 'Restore'
          }
        ]
      }
    },
    partnerContactDocument: {
      label: 'Partner Contact Documents',
      requiredPermission: 'partnerContactManagePermissions',
      permissions: {
        basic: [
          {
            key: 'partnerContactDocumentView',
            label: 'View'
          },
          {
            key: 'partnerContactDocumentEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'partnerContactDocumentArchive',
            label: 'Archive'
          }
        ],
        privileged: [
          {
            key: 'partnerContactDocumentAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'partnerContactDocumentRestore',
            label: 'Restore'
          }
        ]
      }
    },
    system: {
      label: 'System',
      requiredPermission: 'systemManagePermissions',
      permissions: {
        basic: [
          {
            key: 'accessDashboard',
            label: 'System Access'
          }
        ],
        moderate: [
        ],
        privileged: [
          {
            key: 'systemManagementAccess',
            label: 'System Management Access'
          },
          {
            key: 'gdprGetAll',
            label: 'GDPR - Download'
          },
          {
            key: 'gdprDeleteAll',
            label: 'GDPR - Delete'
          },
          {
            key: 'reportAccessModule',
            label: 'Report - Access Reports and All Data'
          }
        ]
      }
    }
  }

  // Build the permsissions into a format for rendering
  // Disable permissions we don't have access to
  const buildPermissions = () => {
    const buildPerms = []
    for (const role in allPermissions) {
      buildPerms.push(<Box><H5 weight='bold' margin={{ top: 'none' }}>{allPermissions[role].label}</H5></Box>)
      for (const level in allPermissions[role].permissions) {
        if (!allPermissions[role].permissions[level].length) {
          buildPerms.push(
            <Box>
              <FormField
                label={`${capitalize(level)}`}
                name='permissions'
              >
                <Text color='dark-3'>N/A</Text>
              </FormField>
            </Box>
          )
        } else {
          buildPerms.push(
            <Box background={`${level}Permissions`} pad={{ vertical: 'none', horizontal: 'small' }}>
              <FormField
                label={`${capitalize(level)}`}
                name='permissions'
              >
                <CheckBoxGroup
                  disabled={!hasPermission(allPermissions[role].requiredPermission)}
                  labelKey='label'
                  name='permissions'
                  valueKey='key'
                  options={allPermissions[role].permissions[level]}
                />
              </FormField>
            </Box>
          )
        }
      }
    }

    return buildPerms
  }

  // Submit form values
  const submitForm = async (values) => {
    // const apiToken = LocalStorageService.get('apiToken')

    if (roleId === 'new') {
      const roleAdded = await RoleService.addRole(apiToken, values)
      if (roleAdded.error) {
        NotificationService.error('Error adding the role, please try again - ' + roleAdded.error)
      } else {
        NotificationService.success('Role added')
        if (roleAdded?.data?.[0]?.id) {
          history.push(`/role/${roleAdded.data[0].id}/edit`)
        } else {
          history.push('/role')
        }
      }
    } else {
      const roleUpdated = await RoleService.updateRole(apiToken, values)
      if (roleUpdated?.error) {
        NotificationService.error('Error updating the role, please try again - ' + roleUpdated.error)
      } else {
        NotificationService.success('Role updated')
        console.log('Role updated ', roleUpdated)
      }
    }
  }

  const goBack = () => {
    history.push('/roles')
  }

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Role details
    const getRoleDetails = async (userId) => {
      const roleDetails = await RoleService.getRole(apiToken, roleId, {
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
      })
      if (roleDetails.error) {
        NotificationService.error(roleDetails.error)
      } else {
        if (!unmounted) {
          setRoleDetails({
            id: roleDetails.data[0].id,
            title: roleDetails.data[0].title,
            permissions: roleDetails.data[0].permissions,
            defaultRole: roleDetails.data[0].defaultRole,
            deleted: roleDetails.data[0].deleted,
            organisation: roleDetails.data[0].organisation,
            site: roleDetails.data[0].site,
          })
        }
      }
    }

    if (roleId !== 'new') {
      getRoleDetails(roleId)
    }

    // mapPermissions()

    return () => { unmounted = true }
  }, [])

  return (
    <Box>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Role: {roleDetails.id ? roleDetails.title : 'New Role'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              onChange={nextValue => {
                // console.log(nextValue)
                setRoleDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={roleDetails}
            >
              <Text>Role Details</Text>
              <FormField
                label='Name'
                name='title'
                required
              >
                <TextInput
                  name='title'
                  type='text'
                />
              </FormField>

              <Box>
                <H4>Permissions</H4>
              </Box>

              <Grid
                rows={['auto', 'auto', 'auto', 'auto']}
                columns={['small', 'auto', 'auto', 'auto']}
                gap='small'
                areas={[
                  { name: 'main', start: [0, 0], end: [1, 1] }
                ]}
              >
                {buildPermissions()}
              </Grid>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RoleEdit
