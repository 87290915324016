
import ApiService from './ApiService'

class UploadService {
  file = async (apiToken, params, data) => {
    const url = '/api/upload/file'

    const options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UploadService:file ', result)
    return result
  }

  image = async (apiToken, params, data) => {
    const url = '/api/upload/image'

    const options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UploadService:file ', result)
    return result
  }
}
export default new UploadService()
