
import ApiService from './ApiService'

class PasswordService {
    resetPasswordRequest = async (organisationId, email) => {
      var url = '/api/auth/password/request-reset'

      var params = {}

      var options = {
        data: {
          organisationId: organisationId,
          email: email
        }
      }

      try {
        var result = await ApiService.post(null, url, params, options)
      } catch (err) {
        console.log(err)
      }
      console.log('PasswordService:resetPasswordRequest ', result)
      return result
    }

    resetPassword = async (token, password) => {
      var url = '/api/auth/password/reset'

      var params = {}

      var options = {
        data: {
          token: token,
          password: password
        }
      }

      try {
        var result = await ApiService.post(null, url, params, options)
      } catch (err) {
        console.log(err)
      }
      console.log('PasswordService:resetPassword ', result)
      return result
    }
}
export default new PasswordService()
