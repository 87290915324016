import ApiService from './ApiService'

class UserGroupService {
  createGroup = async (apiToken, options) => {
    var url = '/api/usergroup'

    var params = {}

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:createGroup ', result)
    return result
  }

  get = async (apiToken, groupId, params) => {
    var url = `/api/usergroup/${groupId}`

    try {
      var userGroup = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:get ', userGroup)
    return userGroup
  }

  getGroups = async (apiToken, params, where) => {
    var url = '/api/usergroup'

    if (where) {
      params.where = where
    }

    try {
      var groups = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:getGroups ', groups)
    return groups
  }

  updateGroup = async (apiToken, groupId, data) => {
    var url = `/api/usergroup/${groupId}`

    var params = {}

    try {
      var result = await ApiService.put(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    return result
  }

  deleteUserGroup = async (apiToken, groupId, force) => {
    var url = `/api/usergroup/${groupId}`

    var params = {}

    if (force) {
      params.force = 1
    }

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:deleteUserGroup ', result)
    return result
  }
}
export default new UserGroupService()
