import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import TextArea from '../../../components/simple/input/TextArea'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'

import H2 from '../../../components/simple/heading/H2.js'
import RatingButtonGroup from '../../../components/compound/ratingButtonGroup/RatingButtonGroup'
import Text from '../../../components/simple/text/Text'
import DateInput from '../../../components/simple/input/DateInput'

function OutcomeMeeting(props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState()
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(localFormDetails)
  }

  useEffect(() => {
    setQuestions(props.questions)
  }, [props.questions])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Outcome Meeting</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

        {localFormDetails && questions?.length
          ? (
            <>
              <Text margin={{ bottom: 'small' }}>Please go through the following questions with the client and try to answer as fully as possible</Text>
              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Form
                onChange={nextValue => {
                  setLocalFormDetails(nextValue)
                }}
                onSubmit={({ value: nextValue }) => {
                  submitForm(nextValue)
                }}
                value={localFormDetails}
              >
                {questions.map((question, index) => {
                  if (index % 2) {
                    return (
                      <Box direction='row-responsive' gap='small'>
                        <Box key={index - 1} width="40%">
                          <FormField
                            label={questions[index - 1].questionText}
                            name={questions[index - 1].key}
                            width='100%'
                          >
                            <OptionButtons
                              id={questions[index - 1].key}
                              name={questions[index - 1].key}
                            />
                          </FormField>
                        </Box>

                        <Box key={index}>
                          <FormField
                            label={question.questionText}
                            name={question.key}
                            width='100%'
                          >
                            <DateInput
                              id={question.key}
                              name={question.key}
                            />
                          </FormField>
                        </Box>

                      </Box>
                      )
                    }

                })}

                <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

                <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                  <Button label='< Back' onClick={() => previousPage()} secondary />
                  <Button type='submit' label='Next Page' primary />
                </Box>
              </Form>
            </>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default OutcomeMeeting
