import React, { useContext, useEffect, useRef, useState } from 'react'

import { ThemeContext } from 'styled-components'

import { useHistory, useParams } from 'react-router-dom'

import {
  ResponsiveContext
} from 'grommet'

import {
  CancellationLayer,
  Error,
  StepFooter,
  StepContent,
  FormContext,
  FormHeader
} from './shared'

import { getWidth } from './utils/utils'

import { getObjectByValue } from '../../../utils/objects'

// Services
import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'

// Appt
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Text from '../../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

// Steps
import StepOneDepression from './steps/stepOne-Depression'

export const defaultFormValues = {
  // step One
  clientName: '',
  littleInterest: '',
  depressed: '',
  sleeping: '',
  tired: '',
  overeating: '',
  feelingBad: '',
  noConcentration: '',
  speakingSlowly: '',
  badThoughts: ''
}

// TODO: Add question texts & remaining questions
const questions = [
  // step One
  {
    questionText: 'Little interest or pleasure in doing things',
    key: 'littleInterest'
  },
  {
    questionText: 'Feeling down, depressed, or hopeless',
    key: 'depressed'
  },
  {
    questionText: 'Trouble falling or staying asleep, or sleeping too much',
    key: 'sleeping'
  },
  {
    questionText: 'Feeling tired or having little energy',
    key: 'tired'
  },
  {
    questionText: 'Poor appetite or overeating',
    key: 'overeating'
  },
  {
    questionText: 'Feeling bad about yourself — or that you are a failure or have let yourself or your family down',
    key: 'feelingBad'
  },
  {
    questionText: 'Trouble concentrating on things, such as reading the newspaper or watching television',
    key: 'noConcentration'
  },
  {
    questionText: 'Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving .around a lot more than usual',
    key: 'speakingSlowly'
  },
  {
    questionText: 'Thoughts that you would be better off dead or of hurting yourself in some way',
    key: 'badThoughts'
  },
  {
    questionText: 'Client Name',
    key: 'clientName'
  }
]

export const steps = [
  {
    description: <><Text weight='bold'>Over the last 2 weeks, how often have you been bothered by the following problems?</Text><Text size='small'>0=Not at all, 1=Several days, 2=More than half the days, 3=Nearly every day</Text></>,
    inputs: <StepOneDepression />,
    title: 'Depression'
    // header: true // identifies a large circle in the header
  }
]

function Phq9 (props) {
  const history = useHistory()

  const urlParams = useParams()
  const userId = urlParams.userid

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const activeUser = LocalStorageService.get('userDetails')

  const size = useContext(ResponsiveContext)
  const theme = useContext(ThemeContext)
  const [activeIndex, setActiveIndex] = useState(0)
  // for readability, this is used to display numeric value of step on screen,
  // such as step 1 of 3. it will always be one more than the active array index
  const [activeStep, setActiveStep] = useState(activeIndex + 1)

  // store form values in state so they persist
  // when user goes back a step
  const [formValues, setFormValues] = useState(defaultFormValues)

  // controls state of cancel layer
  const [open, setOpen] = useState(false)

  // tracks validation results for the current step
  const [valid, setValid] = useState(true)

  const [clientId, setClientId] = useState(props.location?.clientid)
  useEffect(() => { setClientId(props.location?.clientid) }, [props.location?.clientid])

  const submitForm = async () => {
    console.log(formValues)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    let question = ''

    for (var key in formValues) {
      // console.log(key)

      question = getObjectByValue(questions, 'key', key)

      // Validate value entered here ?

      // question will be undefined until we have entered all questions
      if (question !== undefined && formValues[key] !== '') {
        item = {
          questionKey: key,
          questionText: question.questionText,
          response: formValues[key].toString()
        }
        submitArray.push(item)
      }
    }

    // Build output data
    const data = {
      user: activeUser.id,
      subject: formValues && formValues.clientName ? formValues.clientName : parseInt(props.location?.clientid),
      organisation: activeOrganisation.id,
      // status: draft ? 'Draft' : 'Published',
      status: 'Published',
      type: 'phq9form',
      reference: '',
      notify: JSON.stringify(formValues.notifyStaff || []),
      responses: submitArray
    }

    var submissionAdded = await FormService.makeSubmissions(apiToken, data)
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error)
    } else {
      NotificationService.info('PHQ9 Form added')
      history.push('/clients')
    }
  }

  // ref allows us to access the wizard container and ensure scroll position
  // is at the top as user advances between steps. useEffect is triggered
  // when the active step changes.
  const formRef = useRef()

  useEffect(() => {
    setActiveStep(activeIndex + 1)
  }, [activeIndex])

  const id = 'simple-wizard'
  // scroll to top of step when step changes
  useEffect(() => {
    const container = formRef.current
    const header = document.querySelector(`#${id}`)
    container.scrollTop = -header.getBoundingClientRect().bottom
  }, [activeIndex, open])

  useEffect(() => {
    const getResponses = async () => {
      const params = {
        fields: 'id,questionKey,questionText,response'
      }
      const where = {
        submission: urlParams.formId
      }

      const responses = await FormService.getResponses(apiToken, params, where)

      const savedResponses = {}

      responses.data.forEach(element => {
        savedResponses[element.questionKey] = parseInt(element.response)
      })

      setFormValues(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (urlParams.formId !== 'new') {
      getResponses()
    }

    // Set client name in dropdown
    setFormValues(previousValues => ({
      ...previousValues,
      clientName: parseInt(props.location?.clientid || null)
    }))
  }, [])

  useEffect(() => {
    setClientId(formValues.clientName)
  }, [formValues.clientName])

  const numberColumns = 2
  const width = getWidth(numberColumns, theme, size)
  return (
    <FormContext.Provider
      value={{
        activeIndex,
        id,
        defaultFormValues,
        setActiveIndex,
        activeStep,
        setActiveStep,
        valid,
        ref: formRef,
        setValid,
        steps,
        formValues,
        setFormValues,
        formTitle: 'FQ Form',
        width
      }}
    >
      <Box fill>
        {/* Breadcrumb section */}
        <BreadcrumbBar
          path={<><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
        >
          FQ Forms:
          {/* FQ Forms: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'} */}
        </BreadcrumbBar>

        {/* <FormHeader setOpen={setOpen} /> */}

        <StepContent
          // onSubmit={({ value }) => console.log('onSubmit:', value)}
          onSubmit={({ value }) => submitForm()}
        />
        <StepFooter />
      </Box>
      {open && <CancellationLayer onSetOpen={setOpen} />}
    </FormContext.Provider>
  )
}

export default Phq9
