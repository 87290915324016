import React, { useEffect, useState } from 'react'

import LocalStorageService from '../../services/LocalStorageService'
import FormService from '../../services/FormService'
import RiskMatrixService from '../../services/RiskMatrixService'

// Appt Components
import Box from '../../components/simple/box/Box'

function UserStatus (props) {
  const [riskLevel, setRiskLevel] = useState({})
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')

  const riskColorLookup = {
    Safeguarding: '#ff0012',
    'High Complexity': '#f16f33',
    'Medium Complexity': '#bc831c',
    'Low Complexity': '#63a345',
    Independent: '#00a9ea'
  }

  useEffect(() => {
    getStatus()
  }, [props.userId])

  const getStatus = async () => {
    const riskMatrix = await RiskMatrixService.riskStatusForUser(apiToken, props.userId)
    if (riskMatrix?.summary) {
      setRiskLevel(riskMatrix.summary)
    }
  }

  return (
    <Box
      background={{ color: riskColorLookup[riskLevel?.Overall?.textLevel] || '#bbb' }}
      height='10px'
      width='10px'
      margin={{top: '7px', right: '5px'}}
    />
  )
}

export default UserStatus
