// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { List as GList } from 'grommet'

/**
 * `List` renders a Grommet List component
 */
class List extends React.Component {
  render () {
    return (
      <GList
        {...this.props}
        className={this.props.className}
      >
        {this.props.children}
      </GList>)
  }
}

List.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   *  "start"  
   *  "end"  
   *  "center"  
   *  "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * The DOM tag or react component to use for the element.  
   *   
   * "ul"
   */
  as: PropTypes.string,
  /**
   * Item background. An array value indicates that items should have different backgrounds, modulo the array index.  
   *   
   * "light-2"  
   * ["white", "light-2"]
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  /**
   * Item border  
   *   
   * true  
   * "horizontal"  
   * {  
   * &nbsp;"color": "border",  
   * &nbsp;"side": "horizontal",  
   * &nbsp;"size": "small"  
   * }
   */
  border: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Function that will be called when each data item is rendered.  
   * It will be passed three arguments, the individual data item, its index, and an object indicating the state of the item, if any.  
   * It should return a react element. For example: children={(item, index, { active }) =&gt; &lt;Box ...>{...}</Box>}  
   *   
   * (datum, index, { active }) => {...}
   */
  children: PropTypes.func,
  /**
   * Array of data objects.  
   *   
   * [  
   * &nbsp;"string"  
   * &nbsp;{  
   * &nbsp;&nbsp;""  
   * &nbsp;}  
   * ]
   */
  data: PropTypes.array,
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * Item specific background, border, and pad, keyed by data index.  
   * For example: { 27: { background: ..., border: ..., pad: ... }}, where the background, border, and pad accept the same values as the same named properties on List.  
   *   
   * {  
   * &nbsp;""  
   * }
   */
  itemProps: PropTypes.object,
  /**
   * The amount of margin around the component. An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * When supplied, this function will be called with an event object that include a 'item' property  
   * containing the data value associated with the clicked item and an 'index' property containing the  
   * index in 'data' of the clicked item. You should not include interactive elements, like Anchor or  
   * Button inside 'primaryKey' or 'secondaryKey' as that can cause confusion with overlapping interactive elements.  
   *   
   * ({ datum, index }) => {...}
   */
  onClickItem: PropTypes.func,
  /**
   * Use this to indicate that 'data' doesn't contain all that it could. It will be called when all of the data items have been rendered.  
   * This might be used when the total number of items that could be retrieved is more than you'd want to load into the browser.  
   * 'onMore' allows you to lazily fetch more from the server only when needed.  
   */
  onMore: PropTypes.func,
  /**
   * Item padding  
   *   
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"  
   * {  
   * nbsp;"vertical": "...",  
   * nbsp;"horizontal": "...",  
   * nbsp;"top": "...",  
   * nbsp;"bottom": "...",  
   * nbsp;"left": "...",  
   * nbsp;"right": "...",  
   * nbsp;"start": "...",  
   * nbsp;"end": "...",  
   * }
   */
  pad: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * When a string is supplied, it indicates the property in a data item object to use to get the primary content.  
   * If a function is supplied, it will be called with the current data item object and should return a React element that will be rendered as the primary content.  
   *   
   * "string"  
   * "function"
   */
  primaryKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * When a string is supplied, it indicates the property in a data item object to use to get the secondary content.  
   * If a function is supplied, it will be called with the current data item object and should return a React element that will be rendered as the secondary content.  
   *   
   * "string"  
   * "function"
   */
  secondaryKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * How many items to render at a time.
   *   
   * 50
   */
  step: PropTypes.number
}

export default List
