import React from 'react'

import { useHistory } from 'react-router-dom'

// Grommet
import { ResponsiveContext } from 'grommet'

import { hasAnyPermissionIn } from '../../utils/permissions'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'
import Grid from '../../components/simple/grid/Grid'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function Forms (props) {
  const history = useHistory()

  return (
    <>
      <BreadcrumbBar
        path={<><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        Forms
      </BreadcrumbBar>

      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'small' }} round='small'>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Text margin={{ top: 'xsmall' }} size='xlarge'>Available Forms</Text>
        </Box>
      </Box>

      <Grid columns={{ count: 3, size: 'auto' }}>
        {hasAnyPermissionIn(['formAccessAll', 'formAccessCaseworkReferral']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/caseworkform/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Casework Referral' plain />
          </Box>}
        {/* {hasAnyPermissionIn(['formAccessAll', 'formAccessClientCarePlan']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/clientcareplanform/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Client Care Plan' plain />
          </Box>}
        {hasAnyPermissionIn(['formAccessAll', 'formExit']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/exitForm/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Exit Form' plain />
          </Box>}
        {hasAnyPermissionIn(['formAccessAll', 'formAccessMonitoring']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/monitoringform/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Monitoring' plain />
          </Box>}
        {hasAnyPermissionIn(['formAccessAll', 'formAccessRiskMatrix']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/riskmatrix/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Risk Matrix' plain />
          </Box>}
        {hasAnyPermissionIn(['formAccessAll', 'formAccessGAD7']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/gad7form/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='GAD7' plain />
          </Box>}
        {hasAnyPermissionIn(['formAccessAll', 'formAccessPHQ9']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/phq9form/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='PHQ9' plain />
          </Box>} */}
        {hasAnyPermissionIn(['formAccessAll', 'formAccessCounselling']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/counsellingreferral/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Counselling Referral' plain />
          </Box>}
        {/* {hasAnyPermissionIn(['formAccessAll', 'formAccessExit']) &&
          <Box background='white' elevation='small' margin='xsmall' pad='small' round='small'>
            <Button onClick={() => { history.push('/form/exit/new/edit') }} icon={<FontAwesome icon={['fal', 'clipboard']} />} fill='horizontal' label='Exit' plain />
          </Box>} */}
      </Grid>
    </>
  )
}

export default Forms
