import React, { createContext, useState, useEffect, useLayoutEffect } from 'react'

// Notifications
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Grommet
import { Grommet, ResponsiveContext, ThemeContext } from 'grommet'

// Appt router
import PrivateRoute from './PrivateRoute'

// Appt Components
import Box from './components/simple/box/Box'
import Text from './components/simple/text/Text'
import ScrollToTop from './components/ScrollToTop'

// Appt Screens
import ConnectionCheck from './components/compound/connectionCheck/ConnectionCheck'
import Dashboard from './screens/Dashboard'
import GlobalAdminList from './screens/globalAdmins/GlobalAdminList'
import LoginScreen from './screens/Login'
import Logout from './screens/Logout'
import PasswordReset from './components/compound/login/PasswordReset'
import OrganisationEdit from './screens/organisations/OrganisationEdit'
import OrganisationList from './screens/organisations/OrganisationList'
import PartnerEdit from './screens/partners/PartnerEdit'
import PartnerList from './screens/partners/PartnerList'
import PartnerOrgEdit from './screens/partnerOrgs/PartnerOrgEdit'
import PartnerOrgList from './screens/partnerOrgs/PartnerOrgList'
import PartnerOrgListArchive from './screens/partnerOrgs/PartnerOrgListArchive'
import RiskMatrixWizard from './screens/riskMatrix/RiskMatrixWizard'
import Reports from './screens/Reports'
import RoleEdit from './screens/roles/RoleEdit'
import RoleList from './screens/roles/RoleList'
import ServiceUserListArchive from './screens/serviceUsers/ServiceUserListArchive'
import ServiceUserList from './screens/serviceUsers/ServiceUserList'
import ServiceUserListMy from './screens/serviceUsers/ServiceUserListMy'
import ServiceUserProfile from './screens/serviceUsers/ServiceUserProfile'
import ServiceUserEdit from './screens/serviceUsers/ServiceUserEdit'
import SiteEdit from './screens/sites/SiteEdit'
import SiteList from './screens/sites/SiteList'
import StaffEdit from './screens/staff/StaffEdit'
import StaffList from './screens/staff/StaffList'
import StaffListMy from './screens/staff/StaffListMy'
import StaffListArchive from './screens/staff/StaffListArchive'

import SystemManagement from './screens/SystemManagement'
import UserActivate from './screens/users/UserActivate'
import UserEdit from './screens/users/UserEdit'
import UserList from './screens/users/UserList'
import UserUpload from './screens/users/UserUpload'
import GDRP from './screens/GDRP'

// Appt Menu
import Sidebar from './components/compound/sidebar/Sidebar'

// Stores
import { useStore, withStore } from 'react-context-hook'

// Services
import LocalStorageService from './services/LocalStorageService'
import ApiService from './services/ApiService'
import NotificationService from './services/NotificationService'
import PasswordService from './services/PasswordService'

// Forms
import CaseworkReferralForm from './screens/forms/caseworkReferral/Form-CaseworkReferral'
import CounsellingReferralForm from './screens/forms/counsellingReferral/Form-CounsellingReferral'
import ClientCarePlan from './screens/forms/clientCarePlan/Form-ClientCarePlan'
import Forms from './screens/forms/Forms'
import MonitoringForm from './screens/forms/monitoringForm/Form-MonitoringForm'
import ExitCaseworkForm from './screens/forms/exitCasework/Form-ExitCaseworkForm'
import Gad7 from './screens/forms/gad7/Form-Gad7'
import Phq9 from './screens/forms/phq9/Form-Phq9'
import ExitCounselling from './screens/forms/exitCounselling/Form-ExitCounselling'

// Utilities
import { isGlobalAdmin, hasPermission, isCurrentOrgAdmin } from './utils/permissions'
// import Test from './utils/Test'

// Theme
import { snowdropTheme } from './utils/snowdropTheme'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome as falHome,
  faSave as falSave,
  faUser as falUser,
  faClipboard as falClipboard,
  faUserCircle as falUserCircle,
  faExclamationCircle as falExclamationCircle,
  faClipboardListCheck as falClipboardListCheck,
  faFolderOpen as falFolderOpen,
  faSearch as falSearch,
  faChartPie as falChartPie,
  faPlusCircle as falPlusCircle,
  faMinusCircle as falMinusCircle,
  faQuestionCircle as falQuestionCircle,
  faCircle as falCircle,
  faCloudUpload as falCloudUpload,
  faKey as falKey,
  faTrash as falTrash,
  faEdit as falEdit,
  faEye as falEye,
  faFileAlt as falFileAlt,
  faDownload as falDownload,
  faCog as falCog,
  faAngleRight as falAngleRight,
  faCalculator as falCalculator,
  faUndo as falUndo,
  faPencil as falPencil
} from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle, faGraduationCap, faHome, faSave, faUser, faUserFriends, faPlusSquare, faQuestion, faVideo, faFolderOpen, faMusic, faNewspaper, faFilePdf, faFile, faUpload, faPlus, faThLarge, faGavel, faCircle } from '@fortawesome/pro-solid-svg-icons'

// Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
  // Link
} from 'react-router-dom'

export const GlobalContext = createContext()

function App () {
  library.add(falPencil, falHome, falSave, falUser, falClipboard, falClipboardListCheck, falFolderOpen, falUserCircle, falExclamationCircle, falSearch, falChartPie, falPlusCircle, falMinusCircle, falQuestionCircle, falCloudUpload, falKey, falTrash, falEdit, falEye, falFileAlt, falDownload, falCog, falAngleRight, falCalculator, falUndo)
  library.add(faExclamationTriangle, faGraduationCap, faHome, faSave, faUser, faUserFriends, faPlusSquare, faQuestion, faVideo, faFolderOpen, faMusic, faNewspaper, faFilePdf, faFile, faUpload, faPlus, faThLarge, faGavel, faCircle)

  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore()
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [userOrgs, setUserOrgs, deleteUserOrgs] = useStore([])
  const [userSites, setUserSites, deleteUserSites] = useStore([])

  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore({})
  const [activeSite, setActiveSite, deleteActiveSite] = useStore({})

  const [themeOveride, setThemeOveride] = useState({})
  // const [background, setBackground] = useState({ color: 'primary', image: 'url(/background-pattern.jpg)' })

  const [terms, setTerms, deleteTerms] = useStore({})

  const [loginState, setLoginState] = useState({
    loggedInStatus: false,
    apiToken: '',
    userOrganisations: [],
    userDetails: {}
  })

  // clear the user's session if they log out
  const clearSession = () => {
    // remove all localstorage
    LocalStorageService.clear()

    // remove the session data
    deleteApiToken()
    deleteCurrentUser()
    deleteUserOrgs()
    deleteUserSites()
    deleteActiveOrg()
    deleteActiveSite()
    deleteTerms()
    // history.push('/')
  }

  const handleLogin = (data) => {
    const combinedSites = [].concat(data.sitesAsAdmin, data.sitesAsUser)

    // it's possible for a users to both an admin and user of a site
    // this code remove any duplicates. I'm sure there's a more elegant solution but... deadlines.
    var sites = []
    var siteIdsPresent = []
    for (const site of combinedSites) {
      if (!siteIdsPresent.includes(site.id)) {
        siteIdsPresent.push(site.id)
        sites.push(site)
      }
    }

    // Set state
    data.loggedInStatus = true
    setLoginState(data)

    // And set in local storage & global store
    LocalStorageService.set('apiToken', data.apiToken)
    setApiToken(data.apiToken)

    // set the users Orgs and Sites
    LocalStorageService.set('userOrganisations', data.userOrganisations)
    setUserOrgs(data.userOrganisations)
    LocalStorageService.set('userSites', sites)
    setUserSites(sites)

    // set the first one to the active one
    LocalStorageService.set('activeOrg', data.userOrganisations[0])
    setActiveOrg(data.userOrganisations[0])

    // active site is now set in the sidebar
    const newActiveSite = sites.find((site) => {
      return site.organisation === data.userOrganisations[0].id && site.permissions
    })

    LocalStorageService.set('activeSite', newActiveSite)
    setActiveSite(newActiveSite)

    LocalStorageService.set('userDetails', data.userDetails)
    setCurrentUser(data.userDetails)

    // this forces a rerender allow a user to see the sections that they have access to see
    // I'm sure there's a more elegant solution, but deadline
    window.location.href = '/dashboard'

    generateThemeColorsFromOrganisation()
  }

  const handleLogout = () => {
    setLoginState({
      loggedInStatus: false,
      apiToken: '',
      userOrganisations: [],
      userSites: [],
      userDetails: {}
    })
    clearSession()
  }

  const handlePasswordReset = async (token, data) => {
    console.log(data, token)
    try {
      var passwordResetStatus = await PasswordService.resetPassword(token, data.password)
    } catch (err) {
      NotificationService.error(err)
    }

    NotificationService.info('Your password has been reset')

    // history doesn't work in App.js, we'll use this as a test case
    window.location.href = '/login'
  }

  // Set local storage etc with the active organisation
  const selectActiveOrganisation = (org) => {
    LocalStorageService.set('activeOrg', org)
    setActiveOrg(org)

    generateThemeColorsFromOrganisation()
  }

  const selectActiveSite = (site) => {
    LocalStorageService.set('activeSite', site)
    setActiveSite(site)
  }

  useEffect(() => {
    // setup the api token when the page is refreshed
    // this might not be needed, but I don't want to mess with more
    // right now than I need to
    const localApiToken = LocalStorageService.get('apiToken')
    if (localApiToken) {
      setApiToken(localApiToken)
      generateThemeColorsFromOrganisation()
    } else {
      clearSession()
    }
  }, [])

  // TODO: refresh the list of orgs and sites when the user refreshes the screen
  // // Get Organisation for this User
  // const getMyOrganisations = async (apiToken) => {
  //   if (apiToken) {
  //     const myOrganisations = await OrganisationService.getOrganisations(apiToken, {
  //       fields: 'id,name,active'
  //     })
  //     if (myOrganisations?.data) {
  //       setUserOrgs(myOrganisations.data)
  //     }
  //   }
  // }

  // const getMySites = async (apiToken) => {
  //   if (apiToken) {
  //     const mySites = await SiteService.getSites(apiToken, {
  //       fields: 'id,name,active'
  //     })
  //     if (mySites?.data) {
  //       setUserSites(mySites.data)
  //     }
  //   }
  // }
  // Basically componentDidMount
  // useEffect(() => {
  //   (async () => {
  //     await getMyOrganisations(apiToken)
  //   })()
  //   return () => {

  //   }
  // }, [apiToken])

  // useEffect(() => {
  //   (async () => {
  //     await getMySites(apiToken)
  //   })()
  //   return () => {

  //   }
  // }, [apiToken])

  const generateThemeColorsFromOrganisation = () => {
    // setBackground({ color: 'primary' })

    // this doesn't load unless it's coming from the local storage service
    const activeOrg = LocalStorageService.get('activeOrg')
    if (activeOrg?.primaryColour) {
      const secondaryColour = activeOrg?.secondaryColour || activeOrg?.secondaryColor

      setThemeOveride({
        global: {
          colors: {
            primary: `rgba(${activeOrg?.primaryColour})`,
            brand: `rgba(${activeOrg?.primaryColour})`,
            secondary: `rgba(${secondaryColour})`,
            'text-color': '#00788A',
            darkGrey: '#333',
            focus: '#FFD200',
            'optionbuttons-background-checked': `rgba(${secondaryColour})`,
            background: '#fffefe',
            primaryOne: `rgba(${activeOrg?.primaryColour})`,
            primaryTwo: `rgba(${activeOrg?.primaryColour})`,
            primaryThree: `rgba(${activeOrg?.primaryColour})`,
            secondaryOne: `rgba(${secondaryColour})`,
            secondaryTwo: `rgba(${secondaryColour})`,
            secondaryThree: `rgba(${secondaryColour})`,
            tertiaryOne: `rgba(${activeOrg?.tertiaryColour})`,
            tertiaryTwo: `rgba(${activeOrg?.tertiaryColour})`,
            tertiaryThree: `rgba(${activeOrg?.tertiaryColour})`,
            tertiaryFour: `rgba(${activeOrg?.tertiaryColour})`
          }
        },
        tab: {
          hover: {
            color: 'white',
            background: 'secondary'
          }
        }
      })
    }

    // if (activeOrg?.logoUrl) {
    // setBackground(activeOrg.logoUrl)
    // }
  }

  useEffect(() => {
    var menuSetup = []
    menuSetup.push({ linkHref: '/dashboard', icon: 'home', linkTitle: 'Dashboard ' })

    menuSetup.push({ linkHref: '/clients/my', icon: 'folder-open', linkTitle: 'My Clients' })
    menuSetup.push({ linkHref: '/clients', icon: 'folder-open', linkTitle: 'Clients' })

    if (hasPermission('partnerOrgEditAll') || hasPermission('partnerOrgEditMy')) {
      menuSetup.push({ linkHref: '/partnerorgs', icon: 'folder-open', linkTitle: 'Partner Orgs' })
    }
    if (hasPermission('formAccessModule')) {
      menuSetup.push({ linkHref: '/form', icon: 'plus-circle', linkTitle: 'Referral Forms' })
    }
    if (isGlobalAdmin() || hasPermission('systemManagementAccess') || isCurrentOrgAdmin()) {
      menuSetup.push({ linkHref: '/system', icon: 'cog', linkTitle: 'System' })
    }
    if (hasPermission('staffViewAll') || hasPermission('staffViewMy') || isCurrentOrgAdmin()) {
      menuSetup.push({ linkHref: '/staff/my', icon: 'user', linkTitle: 'My Staff' })
    }
    if (hasPermission('staffViewAll') || isCurrentOrgAdmin()) {
      menuSetup.push({ linkHref: '/staff', icon: 'user', linkTitle: 'All Staff' })
    }

    setMenuState(menuSetup)
  }, [activeOrg])

  const thisActiveOrg = LocalStorageService.get('activeOrg')

  const [menuState, setMenuState] = useState([])

  var background = { color: 'background', image: 'url(/background-pattern.jpg)' }
  if (thisActiveOrg?.primaryColour) {
    background = { color: 'rgba(242,244,248,255)' }
  }

  var logoUrl = thisActiveOrg?.logoUrl ? thisActiveOrg?.logoUrl : '/logo.png'

  // hide the sidebar on certain screens
  const pathsToHideSidebarOn = [
    '/user/activate',
    '/login',
    '/reset-password'
  ]
  const sidebarVisible = pathsToHideSidebarOn.includes(window.location.pathname) ? false : true

  return (
    <GlobalContext.Provider value={{ menuState, setMenuState }}>
      <Router>
        <ScrollToTop />
        <Grommet id='scroll-wrapper' background={background} full theme={snowdropTheme}>
          <ThemeContext.Extend
            value={themeOveride}
          >
            <ConnectionCheck />
            <ToastContainer position={toast.POSITION.TOP_RIGHT} />
            <ResponsiveContext.Consumer>
              {size => {
                return (
                  <Box align='center' width='100%' height={size !== 'small' ? '100%' : null} pad='medium' margin={{ top: ApiService.showPreviewBanner ? '60px' : null }}>
                    {ApiService.showPreviewBanner &&
                      <Box style={{ position: 'absolute', top: 0, left: 0, right: 0 }} height='60px' background='#000'>
                        <Text color='white' margin={{ top: '20px;' }} alignSelf='center'>{ApiService.previewBannerMessage}</Text>
                      </Box>}
                    <Box align='center' alignSelf='center' direction='row-responsive' gap='medium' justify='center' margin={{ top: size }} pad={{ horizontal: size }} width='xxlarge'>
                      {apiToken && sidebarVisible && (
                        <Box flex={false} alignSelf='start' width={size !== 'small' ? '250px' : '100%'}>
                          <Sidebar
                            background='#fff'
                            round='small'
                            width='medium'
                            data={menuState}
                            dividerColor='brand'
                            history={history}
                            image={logoUrl}
                            organisations={userOrgs}
                            sites={userSites}
                            userSites={userSites}
                            userOrgs={userOrgs}
                            selectedOrganisation={activeOrg ? activeOrg.id : -1}
                            selectedSite={activeSite ? activeSite.id : -1}
                            selectActiveOrganisation={selectActiveOrganisation}
                            selectActiveSite={selectActiveSite}
                          />
                        </Box>
                      )}

                      <Switch>
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form'
                          component={Forms}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/caseworkform/:formId?/:viewtype?'
                          component={CaseworkReferralForm}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/counsellingreferral/:formId?/:viewtype?'
                          component={CounsellingReferralForm}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/clientcareplanform/:formId?/:viewtype?'
                          component={ClientCarePlan}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/monitoringform/:formId?/:viewtype?'
                          component={MonitoringForm}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/exitcasework/:formId?/:viewtype?'
                          component={ExitCaseworkForm}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/exitcounselling/:formId?/:viewtype?'
                          component={ExitCounselling}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/gad7form/:formId?/:viewtype?'
                          component={Gad7}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/phq9form/:formId?/:viewtype?'
                          component={Phq9}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/form/riskmatrix/:formId/:viewtype?/:userId?'
                          component={RiskMatrixWizard}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />

                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/partnerorgs'
                          component={PartnerOrgList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/partnerorgs/archive'
                          component={PartnerOrgListArchive}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/partnerorg/:orgid/edit'
                          component={PartnerOrgEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/partners'
                          component={PartnerList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/partner/:partnerid/edit'
                          component={PartnerEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          exact
                          path='/role/:roleid/edit'
                          component={RoleEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/roles'
                          component={RoleList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          exact
                          path='/staff/:staffid/edit'
                          component={StaffEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/staff'
                          component={StaffList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/staff/my'
                          component={StaffListMy}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/staff/archive'
                          component={StaffListArchive}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/service/:organisationid/edit'
                          component={OrganisationEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/services'
                          component={OrganisationList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/site/:siteid/edit'
                          component={SiteEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/sites'
                          component={SiteList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/globaladmins'
                          component={GlobalAdminList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/system'
                          component={SystemManagement}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <Route path='/user/activate' component={UserActivate} />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/user/:userid/edit'
                          component={UserEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/client/new/upload'
                          component={UserUpload}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                          type='client'
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/gdpr'
                          component={GDRP}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/clients/archive'
                          component={ServiceUserListArchive}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/clients'
                          component={ServiceUserList}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/clients/my'
                          component={ServiceUserListMy}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/client/:userid/edit'
                          component={ServiceUserEdit}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/client/:userid/profile'
                          component={ServiceUserProfile}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <Route
                          exact
                          path='/logout'
                          render={props => (
                            <Logout
                              {...props}
                              handleLogout={handleLogout}
                              loggedInStatus={loginState}
                            />
                          )}
                        />
                        {/* NOTE: Use this render method to pass props to the component */}
                        <Route
                          exact
                          path='/login'
                          render={props => (
                            <LoginScreen
                              {...props}
                              handleLogin={handleLogin}
                              handleLogout={handleLogout}
                              loggedInStatus={loginState}
                            />
                          )}
                        />
                        <Route
                          exact
                          path='/reset-password'
                          render={props => (
                            <PasswordReset
                              {...props}
                              handlePasswordReset={handlePasswordReset}
                            />)}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/reports'
                          component={Reports}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/dashboard'
                          component={Dashboard}
                          loggedInStatus={loginState}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <PrivateRoute
                          terms={terms}
                          exact
                          path='/'
                          component={Dashboard}
                          loggedInStatus={loginState}
                          activeOrg={activeOrg}
                          activeSite={activeSite}
                        />
                        <Route render={() => <h1>404: page not found</h1>} />
                      </Switch>
                    </Box>
                  </Box>
                )
              }}
            </ResponsiveContext.Consumer>
          </ThemeContext.Extend>
        </Grommet>
      </Router>
    </GlobalContext.Provider>
  )
}

export default withStore(App)
