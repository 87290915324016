import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import moment from 'moment'

import {
  Error,
  FormContext
} from '../shared'

import { constants } from '../../../../utils/constants'

// Services
import LocalStorageService from '../../../../services/LocalStorageService'
import NotificationService from '../../../../services/NotificationService'
import UserService from '../../../../services/UserService'

// Appt
import Box from '../../../../components/simple/box/Box'
import Button from '../../../../components/simple/button/Button'
import DateInput from '../../../../components/simple/input/DateInput'
import DataTable from '../../../../components/simple/dataTable/DataTable'
import Divider from '../../../../components/simple/divider/Divider'
import FontAwesome from '../../../../components/compound/fontAwesome/FontAwesome'
import FormField from '../../../../components/simple/formField/FormField'
import Select from '../../../../components/simple/input/Select'
import Text from '../../../../components/simple/text/Text'
import TextArea from '../../../../components/simple/input/TextArea'
import TextInput from '../../../../components/simple/input/TextInput'
import ClientNames from '../../shared/ClientNames'

function StepTwoClientDetails () {
  const { formValues, setFormValues, valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const apiToken = LocalStorageService.get('apiToken')

  // Children handling
  const [children, setChildren] = useState(formValues?.children ? JSON.parse(formValues?.children) : [])
  const [childrenAddButton, setChildrenAddButton] = useState(false)

  const [mappedChildren, setMappedChildren] = useState([])
  const [childrenColumns, setChildrenDataColumns] = useState()

  const addChildren = () => {
    // Don't save if all fields empty
    if (formValues.firstNameChild !== '' || formValues.lastNameChild !== '' || formValues.dobChild !== '') {
      const child = {
        app1ChildrenKey: children.length,
        firstNameChild: formValues.firstNameChild,
        lastNameChild: formValues.lastNameChild,
        dobChild: formValues.dobChild
      }
      setChildren(previousValues => [...previousValues, child])

      // Re-initialise input fields
      setFormValues(previousValues => ({
        ...previousValues,
        firstNameChild: '',
        lastNameChild: '',
        dobChild: ''
      }))

      setChildrenAddButton(false)
    }
  }

  const removeChildren = (data) => {
    const newNames = children.filter((item) => item.app1ChildrenKey !== data.app1ChildrenKey)

    // Renumber the names keys
    newNames.forEach((name, index) => {
      name.app1ChildrenKey = index
    })

    setChildren(newNames)
  }

  const editChildren = (data) => {
    setFormValues(previousValues => ({
      ...previousValues,
      firstNameChild: data.firstNameChild,
      lastNameChild: data.lastNameChild,
      dobChild: data.dobChild
    }))

    removeChildren(data)
  }

  useEffect(() => {
    setChildrenDataColumns([
      {
        property: 'firstNameChild',
        header: <Text pad='none' margin='none'>First Name</Text>
      },
      {
        property: 'lastNameChild',
        header: <Text>Last Name</Text>
      },
      {
        property: 'dobChild',
        header: <Text>DOB</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeChildren(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editChildren(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedChildren = children.map((children, index) => ({
      app1ChildrenKey: index,
      firstNameChild: children.firstNameChild,
      lastNameChild: children.lastNameChild,
      dobChild: moment(children.dobChild).format('DD/MM/YYYY')
    }))

    setMappedChildren(mappedChildren)

    // Update formValues with stringifed list of children
    if (children.length) {
      setFormValues(previousValues => ({
        ...previousValues,
        children: JSON.stringify(children)
      }))
    }
  }, [children])

  useEffect(() => {
    console.log(formValues)
    const getUserDetails = async (userId) => {
      const params = {
        fields: 'firstName,dob,lastName,email,ethnicity,gender',
        limit: 1,
        type: 'client'
      }

      const userDetails = await UserService.getUser(apiToken, userId, params)
      if (userDetails?.error) {
        NotificationService.error(userDetails.error)
      } else {
        if (userDetails?.data.length) {
          console.log(userDetails.data)
          setFormValues(previousValues => ({
            ...previousValues,
            clientFirstName: userDetails.data[0].firstName,
            clientLastName: userDetails.data[0].lastName,
            clientGender: userDetails.data[0].gender,
            clientDOB: userDetails.data[0].dob
          }))
        }
      }
    }

    if (formValues.clientName) {
      getUserDetails(formValues.clientName)
    }
  }, [formValues.clientName])

  const stepTwoInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <Text weight='bold'>Client Details</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientLastName */}
                  <FormField
                    label='Last Name'
                    name='clientLastName'
                  >
                    <TextInput
                      name='clientLastName'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientFirstName */}
                  <FormField
                    label='First Name'
                    name='clientFirstName'
                  >
                    <TextInput
                      name='clientFirstName'
                      type='text'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientKnownAs */}
                  <FormField
                    label='Known As'
                    name='clientKnownAs'
                  >
                    <TextInput
                      name='clientKnownAs'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientDOB */}
                  <FormField
                    label='Date of Birth'
                    name='clientDOB'
                  >
                    <DateInput
                      name='clientDOB'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientMaritalStatus */}
                  <FormField
                    label='Marital Status'
                    name='clientMaritalStatus'
                  >
                    <Select
                      name='clientMaritalStatus'
                      labelKey='label'
                      options={constants.maritalStatus}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientGender */}
                  <FormField
                    label='Gender'
                    name='clientGender'
                  >
                    <Select
                      name='clientGender'
                      labelKey='label'
                      options={constants.gender}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientFaith */}
                  <FormField
                    label='Faith/Religion'
                    name='clientFaith'
                  >
                    <TextInput
                      name='clientFaith'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientChildcareRequired */}
                  <FormField
                    label='Childcare Required?'
                    name='clientChildcareRequired'
                  >
                    <Select
                      name='clientChildcareRequired'
                      labelKey='label'
                      options={constants.yesNoNA}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientNationality */}
                  <FormField
                    label='Nationality'
                    name='clientNationality'
                  >
                    <Select
                      name='clientNationality'
                      labelKey='label'
                      options={constants.nationality}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientPrimaryLanguage */}
                  <FormField
                    label='Primary Language'
                    name='clientPrimaryLanguage'
                  >
                    <Select
                      name='clientPrimaryLanguage'
                      labelKey='label'
                      options={constants.language}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientAddressLineOne */}
                  <FormField
                    label='Address Line One'
                    name='clientAddressLineOne'
                  >
                    <TextInput
                      name='clientAddressLineOne'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientAddressLineTwo */}
                  <FormField
                    label='Address Line Two'
                    name='clientAddressLineTwo'
                  >
                    <TextInput
                      name='clientAddressLineTwo'
                      type='text'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientCity */}
                  <FormField
                    label='City'
                    name='clientCity'
                  >
                    <TextInput
                      name='clientCity'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientPostcode */}
                  <FormField
                    label='Postcode'
                    name='clientPostcode'
                  >
                    <TextInput
                      name='clientPostcode'
                      type='text'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Children</Text>
          {children?.length > 0 &&
            <Box direction='row-responsive' gap='medium'>
              <DataTable
                columns={childrenColumns}
                data={mappedChildren}
                fill='horizontal'
                size='large'
              />
            </Box>}

          <Box direction='row-responsive' gap='small'>
            <FormField
              label='First Name'
              htmlFor='firstNameChild'
              name='firstNameChild'
            >
              <TextInput
                id='firstNameChild'
                name='firstNameChild'
                onChange={() => setValid(true)}
              />
            </FormField>
            <FormField
              label='Last Name'
              htmlFor='lastNameChild'
              name='lastNameChild'
            >
              <TextInput
                id='lastNameChild'
                name='lastNameChild'
                onChange={() => setValid(true)}
              />
            </FormField>
            <FormField
              label='DOB'
              htmlFor='dobChild'
              name='dobChild'
            >
              <DateInput
                id='dobChild'
                name='dobChild'
                onChange={() => setValid(true)}
              />
            </FormField>

            {/* <Button disabled={!childrenAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addChildren()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} /> */}
            <Button label={<Text>Add</Text>} color='primary' onClick={() => addChildren()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
          </Box>

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Housing Situation</Text>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientHousingSituation */}
                  <FormField
                    label='Housing Situation'
                    name='clientHousingSituation'
                  >
                    <Select
                      name='clientHousingSituation'
                      labelKey='label'
                      options={constants.housingType}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                {formValues.clientHousingSituation === 'other' &&
                  <Box width={responsive === 'small' ? '100%' : '50%'}>
                    {/* clientHousingSituationOther */}
                    <FormField
                      label='Other'
                      name='clientHousingSituationOther'
                    >
                      <TextInput
                        name='clientHousingSituationOther'
                        type='text'
                      />
                    </FormField>
                  </Box>}
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientPhone */}
                  <FormField
                    label='Phone Number'
                    name='clientPhone'
                  >
                    <TextInput
                      name='clientPhone'
                      type='tel'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientEmail */}
                  <FormField
                    label='Email Address'
                    name='clientEmail'
                  >
                    <TextInput
                      name='clientEmail'
                      type='email'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* clientPreferredContactMethod */}
                  <FormField
                    label='Preferred Contact Method'
                    name='clientPreferredContactMethod'
                  >
                    <Select
                      name='clientPreferredContactMethod'
                      labelKey='label'
                      options={constants.contactMethods}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Safeguarding</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* clientSafeguarding */}
                  <FormField
                    label='Please detail any safeguarding concerns'
                    name='clientSafeguarding'
                  >
                    <TextArea
                      name='clientSafeguarding'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '100%'}>
                  {/* clientExploitationType */}
                  <FormField
                    label='Type of exploitation (if known)'
                    name='clientExploitationType'
                  >
                    <TextArea
                      name='clientExploitationType'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

        </Box>
      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepTwoInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepTwoClientDetails
