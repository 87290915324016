import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image, Text } from 'grommet'
// import { calculateFileSize, truncateText } from '../../../utils'

const SIZE = {
  B: 'bytes',
  KB: 'KB',
  MB: 'MB',
  GB: 'GB'
}

const calculateFileSize = size => {
  let adjustedSize = size
  let count = 0
  let suffix

  while (adjustedSize > 1000) {
    adjustedSize = adjustedSize / 1000
    count = count + 1
  }
  if (count === 0) {
    suffix = SIZE.B
  } else if (count === 1) {
    suffix = SIZE.KB
  } else if (count === 2) {
    suffix = SIZE.MB
  } else {
    suffix = SIZE.GB
  }

  const calculatedSize = {
    value: adjustedSize,
    suffix: suffix
  }
  return calculatedSize
}

const truncateText = (text) => {
  if (text.length > 15) {
    const truncatedText = text.substr(0, 15)
    return truncatedText + '...'
  }
  return text
}

export const FileInfo = ({ file, showPreview, showFileSize }) => (
  <Box direction='row' gap='xsmall' align='center'>
    {showPreview && (
      <Box width='xxsmall' height='xxsmall'>
        <Image src={file.preview} fit='cover' />
      </Box>
    )}

    <Text weight='bold'>{truncateText(file.name)}</Text>
    {showFileSize && (
      <Text>
        {calculateFileSize(file.size).value.toFixed(1)}{' '}
        {calculateFileSize(file.size).suffix}
      </Text>
    )}
  </Box>
)

FileInfo.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    preview: PropTypes.string
  }).isRequired,
  showFileSize: PropTypes.bool.isRequired,
  showPreview: PropTypes.bool.isRequired
}

FileInfo.defaultProps = {
  file: {
    preview: undefined
  }
}
