import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import moment from 'moment'
// import useInterval from 'react-useinterval'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { constants } from '../../utils/constants'
import Responsive from '../../utils/Responsive'
import { hasAnyPermissionIn, hasPermission } from '../../utils/permissions'

// Grommet
import { ResponsiveContext } from 'grommet'

import { getObjectByValue } from '../../utils/objects'

import LocalStorageService from '../../services/LocalStorageService'
import MediaService from '../../services/MediaService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DateTime from '../../components/compound/dateTime/DateTime'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import H3 from '../../components/simple/heading/H3.js'
import Layer from '../../components/simple/layer/Layer'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import Paragraph from '../../components/simple/paragraph/Paragraph'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function Notes(props) {
  // Note id we are editing
  const [noteToEdit, setNoteToEdit] = useState(null)

  // Set staff or client for permission checks
  const [userType, setUserType] = useState('')

  // Notes list on display ie. could be filtered
  const [notes, setNotes] = useState([])

  // data for the editor
  const [noteData, setNoteData] = useState()

  // Are we viewing archived notes ?
  const [viewArchive, setViewArchive] = useState(false)

  // All Notes
  const [notesAll, setNotesAll] = useState([])

  const [noteToArchiveRestore, setNoteToArchiveRestore] = useState(null)
  const [showArchiveRestoreOptions, showArchiveRestore] = useState(false)

  // New note detail being added
  const [newNote, setNewNote] = useState({
    assignTo: '',
    dateRecorded: new Date(),
    duration: '',
    noteTitle: '',
    noteDescription: '',
    noteType: '',
    timeRecorded: ''
  })


  // Display Note full screen?
  const [displayNoteOpen, setDisplayNoteOpen] = useState(false)
  const [noteFull, setNoteFull] = useState()

  // Has an interval been started to save a draft Note?
  // const [intervalState, setIntervalState] = useState(false)

  // Has a draft version of the Note been saved ?
  // This will contain the id of the draft version if so
  // const [draftSaved, setDraftSaved] = useState(null)

  const [users, setUsers] = useState([])

  // Search bar input
  const [filter, setFilter] = useState('')

  //draft notes
  const [draftNote, setDraftNote] = useState({})

  const saveDraft = async () => {

    const params = {
      orgId: activeOrganisation.id
    }

    const description = noteData 

    let data = {
      createdAt: newNote.createdAt,
      title: newNote.noteTitle,
      description: description || '',
      organisation: activeOrganisation.id,
      published: false,
      userGroup: orgId,
      subType: newNote.noteType,
      author: currentUser.id,
      type: 'note',
    }



    try {
      if (draftNote?.id) {
        await MediaService.update(apiToken, draftNote.id, data)
      } else {
        await MediaService.create(apiToken, params, data)
      }
    } catch (err) {
      console.error(err)
      NotificationService.error('There was an error saving this draft')
      return
    }

    // get the notes again
    await getUsersAndNotes()

    NotificationService.success('Draft successfully saved')

  }

  const setDateTime = (date, time) => {
    const createdAt = date + ' ' + time
    setNewNote(previousValues => ({
      ...previousValues,
      dateRecorded: date,
      timeRecorded: time,
      createdAt: createdAt
    }))
  }

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const currentUser = LocalStorageService.get('userDetails')

  const urlParams = useParams()
  const orgId = urlParams.orgid


  // // Start a 5s interval if not already started to save draft Note
  // const checkInterval = (values) => {
  //   setNewNote(values)

  //   if (!intervalState) {
  //     setIntervalState(true)

  //     // const intervalId = setInterval(() => submitNote(false), 5000)
  //   }
  // }

  // // If intervalState set, call submitNote every five seconds
  // // else do nothing
  // useInterval(() => {
  //   submitNote(false)
  // }, intervalState ? 5000 : null)

  const onOpenDisplayNote = (noteId) => {
    if (noteId) {
      const selectedNote = getObjectByValue(notes, 'id', noteId)

      setNoteFull(selectedNote)
      setDisplayNoteOpen(true)
    }
  }

  const onCloseDisplayNote = () => {
    setDisplayNoteOpen(undefined)
  }

  // Filter has been changed
  const filterDocuments = (input) => {
    const filtered = notesAll.filter(note => {
      return note.description.toLowerCase().includes(input.toLowerCase())
    })

    setFilter(input)
    setNotes(filtered)
  }

  // Prompt user to delete/restore note
  const showArchiveRestoreNoteOptions = (note) => {
    setNoteToArchiveRestore(note)
    showArchiveRestore(true)
  }

  // Archive/Restore note
  const archiveRestoreNote = async () => {
    if (viewArchive) {
      // Restore note
      const response = await MediaService.update(apiToken, noteToArchiveRestore.id, { deleted: false })
      if (response?.data[0]) {
        getUsersAndNotes()
        NotificationService.success(`${noteToArchiveRestore.title} restored`)
      } else {
        NotificationService.error(response.error)
      }
    } else {
      // Delete from server
      const response = await MediaService.delete(apiToken, noteToArchiveRestore)
      if (response.data.success === 'true') {
        // And from state to force a refresh
        const newNotes = notes.filter((item) => item.id !== noteToArchiveRestore.id)
        setNotes(newNotes)
        NotificationService.success(`${noteToArchiveRestore.title || 'Note '} archived`)
      } else {
        NotificationService.error(response.error)
      }
    }
    showArchiveRestore(false)
  }

  // View notes
  const viewNotes = async () => {
    setViewArchive(!viewArchive)
  }

  useEffect(() => {
    (async () => {
      await getUsersAndNotes()
    })()
  }, [viewArchive])

  const encodeUtf8 = (s) => {
    return unescape(encodeURIComponent(s))
  }

  const decodeUtf8 = (s) => {
    try {
      return decodeURIComponent(escape(s))
    } catch (err) {
      console.error(err)
      return s
    }
  }

  // Submit Note Details
  const submitNote = async (submitStatus) => {
    if (submitStatus !== undefined) {
      // if (submitStatus) {
      const params = {
        orgId: activeOrganisation.id
      }

      const usersToNotify = []
      usersToNotify.push(newNote.assignTo)

      const description = noteData

      const data = {
        createdAt: newNote.createdAt,
        title: newNote.noteTitle,
        description: description,
        organisation: activeOrganisation.id,
        published: true,
        subType: newNote.noteType,
        author: currentUser.id,
        userGroup: orgId,
        // team: currentUser.team,
        type: 'note'
      }

      // Need to do it this way or create takes ages to return a response
      if (newNote.assignTo) {
        data.usersToNotify = JSON.stringify(usersToNotify)
        data.assignedUser = newNote.assignTo
      }

      let response = null

      if (noteToEdit === null) {
        response = await MediaService.create(apiToken, params, data)
      } else {
        response = await MediaService.update(apiToken, noteToEdit, data)
        setNoteToEdit(null)
      }

      if (response?.error || !response?.data) {
        NotificationService.error(response?.error || 'Error saving data')
      } else {
        // If submitStatus=false we have saved a draft copy
        // so save the media id in draftSaved to update later on
        // if (!submitStatus && response?.data) {
        //   setDraftSaved(response.data[0].id)
        // }

        if (submitStatus) {
          // // Stop interval saving of Draft Note
          // setIntervalState(false)

          // Clear input
          setNewNote({ noteType: '', noteTitle: '', noteDescription: '', assignTo: '', duration: '' })

          // clear the draft note
          setDraftNote({})

          // clear the editor
          setNoteData('')

          // Refresh document list
          const notes = await getUsersAndNotes()

          NotificationService.success('Note successfully saved')
        }
      }
    }
  }

  const editNote = (note) => {
    setNewNote({
      assignTo: note.usersToNotify,
      dateRecorded: note.createdAt,
      duration: '',
      noteTitle: note.title,
      noteDescription: note.description,
      noteType: note.subType,
      timeRecorded: ''
    })

    setNoteData(note.description.replace(/(?:\r\n|\r|\n)/g, '<br>'))

    setNoteToEdit(note.id)

    try {
      document.getElementById('scroll-wrapper').scrollTo(0, 350)
    } catch (err) {
      console.error(err)
    }

  }

  const resumeDraft = () => {
    setNewNote({
      dateRecorded: draftNote.createdAt,
      duration: draftNote.duration,
      noteTitle: draftNote.title,
      noteDescription: draftNote.description || "",
      noteType: draftNote.subType,
      timeRecorded: ''
    })

    setNoteData(draftNote.description.replace(/(?:\r\n|\r|\n)/g, '<br>'))

    setNoteToEdit(draftNote.id)
  }

  const getDraftNote = async () => {

    // don't get drafts if we're viewing
    if (viewArchive) {
      return
    }

    // Now get the Notes
    let params = {
      fields: 'id,title,team,author,published,createdAt,subType,description,owner,userGroup',
      limit: 1,
      orgId: activeOrganisation.id,
      sort: 'id DESC',
      where: {
        organisation: activeOrganisation.id,
        published: false,
        userGroup: orgId,
        author: currentUser.id,
        type: 'note'
      }
    }

    const response = await MediaService.get(apiToken, params)
    if (response?.error) {
      // NotificationService.error(notes.error)
    } else if (response?.data?.[0]) {
      setDraftNote(response.data[0])
    }

  }

  const getUsersAndNotes = async () => {
    let params = {
      fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
      limit: 1000,
      orgId: activeOrganisation.id,
      siteId: activeSite?.id || null,
      sort: 'createdAt DESC',
      type: 'staff',
      where: {
        deleted: false
      }
    }

    const users = await UserService.getUsers(apiToken, params)
    if (users?.error) {
      NotificationService.error(users.error)
    } else {
      const mappedUsers = users.data.map((data, index) => ({
        id: data.id,
        name: data.firstName + ' ' + data.lastName
      }))

      setUsers(mappedUsers)

      // Now get the Notes
      params = {
        fields: 'id,title,createdAt,subType,team,description,author,assignedTo',
        limit: 2000,
        orgId: activeOrganisation.id,
        sort: 'createdAt DESC',
        where: {
          organisation: activeOrganisation.id,
          published: true,
          userGroup: orgId,
          type: 'note',
          deleted: viewArchive
        }
      }

      getDraftNote()

      const notes = await MediaService.get(apiToken, params)
      if (notes?.error) {
        // NotificationService.error(notes.error)
      } else {

        let owner = ''
        var notesToSave = notes.data
        for (const noteKey in notesToSave) {
          const note = notes.data[noteKey]
          owner = users?.data.find(user => parseInt(user?.id) === parseInt(note.author))
          notesToSave[noteKey].ownerName = ''
          if (owner?.firstName || owner?.lastName) {
            notesToSave[noteKey].ownerName = (owner?.firstName || '') + ' ' + (owner?.lastName || '')
          }
        }

        setNotes(notesToSave)
        setNotesAll(notesToSave)
      }
    }
  }

  useEffect(() => {
    let unmounted = false

    if (urlParams.staffid === undefined) {
      setUserType('client')
    } else {
      setUserType('staff')
    }

    getUsersAndNotes()

    return () => { unmounted = true }
  }, [])

  const stripHtml = html => {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>{viewArchive ? 'Archived' : null} Notes</H2>
          {showArchiveRestoreOptions &&
            <NotificationLayer button1Text='Yes' button1Click={archiveRestoreNote} button2Text='No' button2Click={() => { showArchiveRestore(false) }}>Are you sure you want to archive the note {noteToArchiveRestore.title ? noteToArchiveRestore.title : ''}?</NotificationLayer>}
          <Button label={<FontAwesome icon={['fal', 'question-circle']} />} plain tip='Notes' />
          {hasPermission(`${userType}AccessArchive`) &&
            <Anchor onClick={() => { viewNotes() }}>
              <Text size='xsmall' weight='light' color='darkGrey'>
                {viewArchive ? (hasPermission(`${userType}NotesView`) ? 'View Notes' : '') : (hasPermission(`${userType}NotesArchive`) ? 'View Archive' : '')}
              </Text>
            </Anchor>}
        </Box>

        <>
          <Form
            onChange={nextValue => {
              setNewNote(nextValue)
            }}
            onSubmit={event => {
              submitNote(true)
            }}
            value={newNote}
          >
            <Responsive
              rows={['auto']}
              columns={{
                small: ['auto'],
                medium: ['auto'],
                large: ['auto'],
                xlarge: ['auto']
              }}
              gap='medium'
            >
              <Box>

                {draftNote?.id &&
                  <Box margin={{ bottom: 'medium' }} border='primary' pad='small' background='primary' round='xsmall' elevation='small'>
                    <Button gap='small' onClick={() => resumeDraft()}>
                      <FontAwesome color='white' icon={['fal', 'pencil']} alignSelf='start' margin={{ right: 'small' }} />
                      <Text margin={{ left: 'medium' }} weight='bold' color='white'>Continue editing draft</Text>
                    </Button>
                  </Box>}

                <H3 color='primary'>New Note</H3>
                {/* Type */}
                <FormField
                  label='Type'
                  name='noteType'
                >
                  <Select
                    name='noteType'
                    emptySearchMessage='No types found'
                    labelKey='label'
                    options={constants.noteTypes}
                    valueKey={{ key: 'value', reduce: true }}
                  />
                </FormField>

                {/* Title */}
                <FormField
                  label='Title'
                  name='noteTitle'
                  required
                >
                  <TextInput
                    name='noteTitle'
                  />
                </FormField>

                {/* Description */}
                <FormField
                  label='Message'
                  name='noteDescription'
                >
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: ['heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList']
                    }}
                    data={noteData}
                    onBlur={(event, editor) => {
                      const data = editor.getData()
                      setNoteData(data)
                    }}
                  />
                </FormField>

                {/* Assign To */}
                <FormField
                  label='Notify'
                  name='assignTo'
                >
                  <Select
                    name='assignTo'
                    emptySearchMessage='No assignees found'
                    // onChange={
                    //   (event, option) => { setAcademicYear(event.value) }
                    // }
                    labelKey='name'
                    options={users}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Duration */}
                <FormField
                  label='Duration (minutes)'
                  name='duration'
                >
                  <TextInput
                    name='duration'
                    type='number'
                  />
                </FormField>

                {/* Date Recorded */}
                <FormField
                  label='Date Recorded'
                  name='dateRecorded'
                >
                  <DateTime
                    date={newNote.dateRecorded}
                    time={newNote.timeRecorded}
                    daysOfWeek={true}
                    setDateTime={setDateTime}
                  />
                </FormField>

                <Responsive
                  rows={['auto']}
                  columns={{
                    small: ['auto'],
                    medium: ['1/2', '1/2'],
                    large: ['1/2', '1/2'],
                    xlarge: ['1/2', '1/2']
                  }}
                  gap='medium'
                >
                  <Button label='Submit Note' primary type='submit' />
                  <Button label='Save Draft' onClick={() => saveDraft()} secondary />

                </Responsive>
              </Box>

              <Divider color='primary' />


              <Box pad={{ left: 'small' }} margin={{ top: 'medium' }}>
                <H3 color='primary'>History</H3>
                <Box direction='row-responsive' gap='small'>
                  <TextInput
                    icon={<FontAwesome icon={['fal', 'search']} />}
                    onChange={event => filterDocuments(event.target.value)}
                    reverse
                    value={filter}
                  />
                </Box>
                {notes?.length > 0
                  ? notes?.map((item) => (
                    <Box direction='column' key={item.id} margin={{ left: '0px', top: '20px' }} pad={{ bottom: 'medium' }} border={{ side: 'bottom', color: 'lightGrey' }}>
                      <Box direction='row' justify='between'>
                        <Text size='medium' weight='bold'>{moment(item.createdAt).format('MMM Do YYYY - HH:mm')}</Text>
                        <Button icon={<Text><FontAwesome icon={['fal', 'eye']} /></Text>} onClick={() => onOpenDisplayNote(item.id)} plain />
                      </Box>
                      <Text size='small' truncate margin={{ top: '-5px' }}>{item.subType} ({item.team}): {item.title === 'Note' ? '' : item.title}</Text>
                      {item.ownerName && <Text size='small'>By: {item.ownerName}</Text>}

                      <Text><div dangerouslySetInnerHTML={{ __html: decodeUtf8(item.description).replace(/(?:\r\n|\r|\n)/g, '<br>') }} /></Text>

                      <Box direction='row'>
                        {hasPermission(`${userType}NoteEdit`) &&
                          <Anchor onClick={() => { editNote(item) }} size='xsmall' weight={100} color='darkGrey'>
                            <Text color='darkGrey' margin={{ right: 'small' }} size='xsmall' weight='light' >
                              Edit
                            </Text>
                          </Anchor>}


                        {!viewArchive && hasAnyPermissionIn([`${userType}NoteArchive`]) &&
                          <Anchor onClick={() => { showArchiveRestoreNoteOptions(item) }} size='xsmall' weight={100} color='darkGrey'>
                            <Text size='xsmall' weight='light' color='darkGrey'>
                              Archive
                            </Text>
                          </Anchor>}

                        {viewArchive && hasAnyPermissionIn([`${userType}NoteRestore`]) &&
                          <Anchor onClick={() => { showArchiveRestoreNoteOptions(item) }} size='xsmall' weight={100} color='darkGrey'>
                            <Text size='xsmall' weight='light' color='darkGrey'>
                              Restore
                            </Text>
                          </Anchor>}
                      </Box>

                    </Box>)
                  )
                  : <Text>No Notes available</Text>}
              </Box>
            </Responsive>

            {displayNoteOpen &&
              <ResponsiveContext.Consumer>
                {responsive => (
                  <Layer
                    margin={responsive === 'small' ? 'medium' : 'large'}
                    onClickOutside={onCloseDisplayNote}
                    onEsc={onCloseDisplayNote}
                    position='center'
                    responsive={false}
                  >
                    <Box
                      flex
                      overflow='auto'
                      pad='medium'
                      width={responsive === 'small' ? 'medium' : 'large'}
                    >
                      <Text weight='bold'>{noteFull.title}</Text>
                      <div dangerouslySetInnerHTML={{ __html: noteFull.description.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                    </Box>
                  </Layer>)}
              </ResponsiveContext.Consumer>}
            <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Button label='< Back' onClick={() => props.previousPage()} secondary />
            </Box>
          </Form>
        </>
      </Box>
    </Box>
  )
}

export default Notes
