// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { ResponsiveContext } from 'grommet'

// Appt
import Box from '../../components/simple/box/Box'
import Text from '../../components/simple/text/Text'

/**
 * `BreadcrumbBar` renders a box with 'quick Actions' and action buttons
 */
function BreadcrumbBar (props) {
  return (
    <ResponsiveContext.Consumer>
      {responsive => (
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='column' pad={{ horizontal: 'medium' }}>
            <Text margin={{ top: 'small' }} size='xlarge'>{props.children}</Text>
            <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'>{props.path}</Text>
          </Box>
        </Box>)}
    </ResponsiveContext.Consumer>)
}

// BreadcrumbBar.defaultProps = {
//   label: 'Quick Actions'
// }

BreadcrumbBar.propTypes = {
  /**
   * Note that the title is passed as a child of the BreadcrumbBar
   *
   */
  /**
   * The route location
   */
  path: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default BreadcrumbBar
