
import ApiService from './ApiService'
import LocalStorageService from './LocalStorageService'

const apiToken = LocalStorageService.get('apiToken')

class NotificationMessagesService {
  get = async (params) => {
    var url = '/api/notification/get'

    try {
      var result = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('NotificationMessagesService:get ', result)
    return result
  }

  read = async (notificationId) => {
    var url = `/api/notification/read/${notificationId}`

    var params = {}

    var options = {
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('NotificationMessagesService:read ', result)
    return result
  }

//   create = async (apiToken, params, data) => {
//     const url = '/api/media'

//     const options = {
//       data: data
//     }

//     try {
//       var result = await ApiService.post(apiToken, url, params, options)
//     } catch (err) {
//       console.log(err)
//     }
//     console.log('NotificationMessagesService:create ', result)
//     return result
//   }
}
export default new NotificationMessagesService()
