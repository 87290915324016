// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { RadioButtonGroup as GRadioButtonGroup } from 'grommet'

/**
 * `RadioButtonGroup` renders a Grommet RadioButtonGroup control
 */
class RadioButtonGroup extends React.Component {
  render () {
    // const onChange = (input) => this.props.onChange(input)

    return (
      <GRadioButtonGroup
        {...this.props}
        // onChange={(input) => onChange(input)}
      />)
  }
}

RadioButtonGroup.propTypes = {
  /**
   * Function that will be called to render the visual representation.  
   * It will be passed an object indicating whether the button is checked.  
   * It should return a react element.  
   *   
   * For example: children={(option, { checked }) = <Box ...>{...}</Box>}  
   */
  children: PropTypes.func,
  /**
   * Disables all options  
   *   
   * true   
   * **false**
   */
  disabled: PropTypes.bool,
  /**
   * The DOM name attribute value to use for the underlying ```<input/>``` element.  
   *   
   * "a-dom-name"
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user clicks on one of the radio buttons.  
   * It will be passed a React event object.    
   *   
   * ({ target: { value } }) => {...}
   */
  onChange: PropTypes.func,
  /**
   * Options can be either a string, boolean, number or an object.  
   * Each option is rendered as a single RadioButton.  
   *   
   * ["string"]  
   * [true, false]  
   * ["number"]  
   * [  
   * &nbsp;{  
   * &nbsp;&nbsp;"disabled": false,  
   * &nbsp;&nbsp;"id": "ONE",  
   * &nbsp;&nbsp;"name": "one",  
   * &nbsp;&nbsp;"value": "1",  
   * &nbsp;&nbsp;"label": "one"  
   * &nbsp;}  
   * ]
   */
  options: PropTypes.array,
  /**
   * Currently selected option value.  
   *   
   * "string"  
   * number  
   * true  
   * false  
   * "object"
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object
  ])
}

export default RadioButtonGroup
