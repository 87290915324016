import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserGroupService from '../../services/UserGroupService'
import UserGroupTagService from '../../services/UserGroupTagService'
import UserTagService from '../../services/UserTagService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'

// PartnerOrgEdit screens
import Contacts from './PartnerOrgEdit-Contacts'
import Documents from './PartnerOrgEdit-Documents'
import Notes from './PartnerOrgEdit-Notes'
import PartnerDetails from './PartnerOrgEdit-PartnerDetails'

function PartnerOrgEdit (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const [showDeleteOptions, showDelete] = useState(false)
  const [orgToDelete, setOrgToDelete] = useState(null)

  // Prompt user to delete
  const showDeleteOrgOptions = (orgId) => {
    setOrgToDelete(orgId)
    showDelete(true)
  }

  // Delete an Organisation
  const deleteOrg = async () => {
    const response = await UserGroupService.deleteUserGroup(apiToken, orgToDelete)
    if (response.data.success === 'true') {
      NotificationService.info('Partner Organisation deleted')
      history.goBack()
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Indicates form has changed
  var formChanged = false

  const setFormChanged = () => {
    formChanged = true
  }

  const [organisationDetails, setOrganisationDetails] = useState({
    // Partner Details
    name: '',
    website: '',
    region: '',
    orgType: '',
    address: '',
    postcode: '',
    city: '',
    otherNotes: '',
    receptionPhone: '',
    receptionEmail: '',
    admin: '',
    existingOrgAdmin: false,
    hasSites: false,
    numAllowedUsers: undefined,
    numUsers: 0
  })

  const [meterValue, setMeterValue] = useState()

  // Determine if initial user details/tags have been loaded
  // otherwise fields don't get populated
  const [detailsLoaded, setDetailsLoaded] = useState(true)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // This array indicates the sensitivity setting that needs
  // to be set for the User Tag settings
  // notag indicates keys that should not be saved as Tags as
  // they are saved as standard User settings (or are Children or Renovations fields)

  var tabs = []
  if (hasAnyPermissionIn(['partnerOrgEditMy', 'partnerOrgEditAll'])) {
    tabs.push({
      tabId: 0,
      sensitivity: 'details',
      notag: [
        'name'
      ]
    })
  }

  // if (hasAnyPermissionIn(['clientViewAllPersonal', 'clientViewMyPersonal'])) {
  //   tabs.push({
  //     tabId: 1,
  //     sensitivity: 'personal',
  //     notag: [
  //       'email',
  //       'firstNameChild',
  //       'lastNameChild',
  //       'dobChild',
  //       'livingWithClientChild',
  //       'renovationDate',
  //       'renovationDescription'
  //     ]
  //   })
  // }

  // if (hasAnyPermissionIn(['clientViewAllSensitive', 'clientViewMyfSensitive'])) {
  //   tabs.push({
  //     tabId: 2,
  //     sensitivity: 'sensitive'
  //   })
  // }

  const [tabSensitivity] = useState(tabs)

  // A list of questions for each tab
  // notag indicates keys that should not be saved as Tags as
  // they are saved as standard User Group settings

  const [questions, setQuestions] = useState([
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Organisation Name',
          key: 'name',
          notag: true
        },
        {
          questionText: 'Address',
          key: 'address'
        },
        {
          questionText: 'Postcode',
          key: 'postcode'
        },
        {
          questionText: 'City',
          key: 'city'
        },
        {
          questionText: 'Other Notes',
          key: 'otherNotes'
        },
        {
          questionText: 'Reception Phone',
          key: 'receptionPhone'
        },
        {
          questionText: 'Reception Email',
          key: 'receptionEmail'
        },
        {
          questionText: 'Website',
          key: 'website'
        },
        {
          questionText: 'Region',
          key: 'region'
        },
        {
          questionText: 'Organisation Type',
          key: 'orgType'
        }
      ],
      notag: [
        'name'
      ]
    },
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Address',
          key: 'address'
        },
        {
          questionText: 'Postcode',
          key: 'postcode'
        },
        {
          questionText: 'City',
          key: 'city'
        },
        {
          questionText: 'Mobile',
          key: 'mobile'
        },
        {
          questionText: 'Email',
          key: 'email'
        },
        {
          questionText: 'Preferred Contact Method',
          key: 'preferredContactMethod'
        },
        {
          questionText: 'Client Date of Birth',
          key: 'clientDob'
        },
        {
          questionText: 'Disability or Mental/Physical Health Diagnosis',
          key: 'healthDiagnosis'
        },
        {
          questionText: 'Partner Name',
          key: 'partnerName'
        },
        {
          questionText: 'Partner Date of Birth',
          key: 'partnerDob'
        },
        {
          questionText: 'Partner Living with Client',
          key: 'partnerLivingWithClient'
        },
        {
          questionText: 'First Name',
          key: 'firstNameChild'
        },
        {
          questionText: 'Last Name',
          key: 'lastNameChild'
        },
        {
          questionText: 'Date of Birth',
          key: 'dobChild'
        },
        {
          questionText: 'Living with Client',
          key: 'livingWithClientChild'
        },
        {
          questionText: 'HO Reference Number',
          key: 'hoReferenceNumber'
        },
        {
          questionText: 'Immigration Status',
          key: 'immigrationStatus'
        },
        {
          questionText: 'NINO',
          key: 'nino'
        },
        {
          questionText: 'NRM Status',
          key: 'nrmStatus'
        },
        {
          questionText: 'Country of Origin',
          key: 'countryOfOrigin'
        },
        {
          questionText: 'Date',
          key: 'renovationDate'
        },
        {
          questionText: 'Description',
          key: 'renovationDescription'
        },
        {
          questionText: 'Assigned Assessor',
          key: 'assignedAssessor'
        },
        {
          questionText: 'Assessment Outcome (Which pathway)',
          key: 'assessmentOutcome'
        },
        {
          questionText: 'Referring Agency (include Reach-in)',
          key: 'referringAgency'
        },
        {
          questionText: 'Suitable for Placement Student',
          key: 'suitableForPlacement'
        },
        {
          questionText: 'Referred',
          key: 'referredDate'
        },
        {
          questionText: 'Support Ended',
          key: 'supportEndedDate'
        },
        {
          questionText: 'Assessment Completed',
          key: 'assessmentCompletedDate'
        },
        {
          questionText: 'Counselling Started',
          key: 'counsellingStartedDate'
        },
        {
          questionText: 'Counselling Completed',
          key: 'counsellingCompletedDate'
        },
        {
          questionText: 'Attendance Form Completed',
          key: 'attendanceFormCompletedDate'
        },
        {
          questionText: 'NRM Exit Date',
          key: 'NRMExitDate'
        },
        {
          questionText: 'CG Date',
          key: 'CGDate'
        },
        {
          questionText: 'Personal Notes',
          key: 'personalNotes'
        },
        // Hidden question to store the array of children
        {
          questionText: 'Children',
          key: 'children'
        },
        // Hidden question to store the array of renovations
        {
          questionText: 'Renovations',
          key: 'renovations'
        }
      ]
    },
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Type of Trafficking',
          key: 'traffickingType'
        },
        {
          questionText: 'Literacy Level',
          key: 'literacyLevel'
        },
        {
          questionText: 'Education',
          key: 'education1'
        },
        {
          questionText: 'Employment',
          key: 'employment1'
        },
        {
          questionText: 'Housing Type/Situation',
          key: 'housingType1'
        },
        {
          questionText: 'Income',
          key: 'income2'
        },
        {
          questionText: 'Employment',
          key: 'employment2'
        },
        {
          questionText: 'Housing Type/Situation',
          key: 'housingType2'
        },
        {
          questionText: 'Income',
          key: 'income'
        },
        {
          questionText: 'Legal Interventions',
          key: 'legalInterventions'
        },
        {
          questionText: 'Previous Pathways',
          key: 'previousPathways'
        },
        {
          questionText: 'Counselling Reports Completed (Pathway 4)',
          key: 'counsellingReportsCompleted'
        },
        {
          questionText: 'Last Completed',
          key: 'carePlanLastCompleted'
        },
        {
          questionText: 'Next Due',
          key: 'carePlanNextDue'
        },
        {
          questionText: 'Last Completed',
          key: 'monitoringFormLastCompleted'
        },
        {
          questionText: 'Next Due',
          key: 'monitoringFormNextDue'
        },
        {
          questionText: 'Sensitive Notes (Only visible on this tab)',
          key: 'sensitiveNotes'
        }
      ]
    },
    // Documents - dummy questions
    {
      tabId: 3,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    },
    // Notes - dummy questions
    {
      tabId: 4,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
      // questions: [
      //   {
      //     questionText: 'Type',
      //     key: 'noteType'
      //   },
      //   {
      //     questionText: 'Description',
      //     key: 'noteDescription'
      //   },
      //   {
      //     questionText: 'Assign To',
      //     key: 'noteAssignTo'
      //   }
      // ]
    },
    // Forms - dummy questions
    {
      tabId: 5,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    },
    // Access - dummy questions
    {
      tabId: 6,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    }
  ])

  // Build array ready for UserGroupTag submission
  const buildUserGroupTags = (formDetailsToSave) => {
    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through question on current tab
    // Skip any that are marked as notag
    // Building array for User Tag saving
    for (var value of questions[tabIndex].questions) {
      // if (checkInputHasContent(formDetailsToSave[value.key]) && !tabSensitivity?.[tabIndex]?.notag?.includes(value.key)) {
      if (!value.notag) {
        item = {
          usergroup: parseInt(orgId),
          organisation: activeOrg.id,
          key: value.key,
          value: formDetailsToSave[value.key],
          type: 'string'
        }
        submitArray.push(item)
      }
    }

    return submitArray
  }

  // Submit Org Details
  const submitOrgDetails = async (formDetailsToSave) => {
    // Update user details with 'local' data
    setOrganisationDetails(formDetailsToSave)

    switch (tabIndex) {
      // Documents
      case 3:
        break
        // Notes
      case 4:
        break
      default:
        var orgData = {
          data: {
            name: formDetailsToSave.name,
            type: 'partnerOrg'
          }
        }

        if (orgId === 'new') {
          // Save User Group
          const organisationAdded = await UserGroupService.createGroup(apiToken, orgData)
          if (organisationAdded.error) {
            NotificationService.error(organisationAdded.error)
          } else {
            console.log(organisationAdded.data)
            NotificationService.info('Organisation added. Please edit to include further details.')
            history.goBack()
          }
        } else {
          // Update User Group
          const organisationUpdated = await UserGroupService.updateGroup(apiToken, orgId, orgData)
          if (organisationUpdated.error) {
            NotificationService.error(organisationUpdated.error)
          } else {
            // And then User Group Tags
            const userGroupTags = buildUserGroupTags(formDetailsToSave)
            console.log(userGroupTags)
            const response = await UserGroupTagService.set(apiToken, userGroupTags)
            if (response?.error) {
              NotificationService.error(response.error)
            } else {
              NotificationService.info('Organisation updated')
            }
          }
        }

        // if (orgId === 'new') {
        //   const dataToSubmit = {
        //     data: data,
        //     organisation: activeOrg.id
        //   }

        //   // Add User
        //   const params = {
        //     orgId: activeOrg.id
        //   }

        //   var userAdded = await UserService.createUser(apiToken, dataToSubmit, params)
        //   // const userAdded = {
        //   // error: '*** TEMP: NOT WRITING TO DATABASE ***'
        //   // }
        //   if (userAdded.error) {
        //     NotificationService.error(userAdded.error)
        //   } else {
        //     // And then User Tags
        //     const userTags = buildUserTags(formDetailsToSave, userAdded.data.id[0])

        //     const response = UserTagService.set(apiToken, userTags, 'client')
        //     if (response?.error) {
        //       NotificationService.error(response.error)
        //     } else {
        //       NotificationService.success('Data successfully saved')
        //       history.push('/clients')
        //       // nextPage()
        //     }
        //   }
        // } else {
        //   // Save the standard User information
        //   data.id = orgId

        //   const userData = {
        //     data: data,
        //     organisation: activeOrg.id
        //   }
        //   var userUpdated = await UserService.updateUser(apiToken, orgId, userData)
        //   if (userUpdated.error) {
        //     NotificationService.error(userUpdated.error)
        //   } else {
        //     // Now save the User Tags
        //     const userTags = buildUserTags(formDetailsToSave, orgId)

        //     const response = UserTagService.set(apiToken, userTags, 'client')
        //     if (response?.error) {
        //       NotificationService.error(response.error)
        //     } else {
        //       NotificationService.success('Data successfully saved')

        //       // Clear the form changed flag
        //       formChanged = false
        //     }
        //   }
        // }
    }
  }

  const urlParams = useParams()

  const orgId = urlParams.orgid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    if (!formChanged) {
      setMeterValue((nextIndex + 1) * 100 / questions.length)
      setTabIndex(nextIndex)
    } else {
      NotificationService.warning('Form details have changed, please save before changing tab')
    }
  }

  const previousPage = () => {
    // go back to the client screen
    history.push('/clients')
  }

  const tabContent = []

  tabContent.push({
    name: 'Partner Details',
    hideTitle: false,
    content: <PartnerDetails setFormChanged={setFormChanged} previousPage={previousPage} submitForm={submitOrgDetails} orgDetails={organisationDetails} setOrgDetails={setOrganisationDetails} questions={questions[0].questions} />
  })

  // only show the extra tabs when editing a user
  if (orgId !== 'new') {
    if (hasPermission('partnerOrgDocumentView')) {
      tabContent.push({
        name: 'Documents',
        hideTitle: false,
        content: <Documents previousPage={previousPage} />
      })
    }

    if (hasPermission('partnerOrgNoteView')) {
      tabContent.push({
        name: 'Notes',
        hideTitle: false,
        content: <Notes previousPage={previousPage} />
      })
    }

    if (hasAnyPermissionIn(['partnerContactViewMy', 'partnerContactViewAll'])) {
      tabContent.push({
        name: 'Contacts',
        hideTitle: false,
        content: <Contacts previousPage={previousPage} />
      })
    }
  }

  useEffect(() => {
    let unmounted = false

    // Check if adding a new Org or editing an existing one
    const getOrgDetails = async (orgId) => {
      const params = {
        fields: 'id,name,owner',
        limit: 1,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null
      }

      setLoading(true)
      const org = await UserGroupService.get(apiToken, parseInt(orgId), params)
      if (org?.error) {
        NotificationService.error(org.error)
      } else {
        if (!unmounted) {
          if (org?.data) {
            // const mappedOrg = org.data.map((data, index) => ({
            //   id: data.id,
            //   name: data.firstName + ' ' + data.lastName
            // }))

            // setLocalOrgDetails(org.data[0])
            setOrganisationDetails(previousValues => ({
              ...previousValues,
              name: org.data[0].name
            }))
          }
        }
      }
    }

    const getUserGroupTags = async (orgId) => {
      const where = {
        usergroup: orgId,
        // type: 'general',
        organisation: activeOrg.id
      }

      const params = {
        fields: 'key,value'
      }

      // Get User Group Tags and format for saving as form values
      const userGroupTags = await UserGroupTagService.get(apiToken, params, where)
      const tagsToSave = {}
      if (userGroupTags?.length) {
        for (const tag of userGroupTags) {
          let tagOK = true

          // Don't save any fields that should not be tags
          // This is probably not needed once the system
          // is up and running - just items such as firstName
          // may be saved as a tag
          tabSensitivity.forEach((values) => {
            if (values.notag) {
              if (values.notag.includes(tag.key) && tagOK) {
                tagOK = false
              }
            }
          })

          if (tagOK) {
            tagsToSave[tag.key] = tag.value
          }
        }

        console.log(organisationDetails)
        setOrganisationDetails(previousValues => ({
          ...previousValues,
          website: tagsToSave.website,
          region: tagsToSave.region,
          orgType: tagsToSave.orgType,
          address: tagsToSave.address,
          postcode: tagsToSave.postcode,
          city: tagsToSave.city,
          otherNotes: tagsToSave.otherNotes,
          receptionPhone: tagsToSave.receptionPhone,
          receptionEmail: tagsToSave.receptionEmail
        }))

        // setOrganisationDetails(tagsToSave)

        setDetailsLoaded(true)
      }

      // // Convert to integer
      // tagsToSave.caseworkerAssigned = parseInt(tagsToSave.caseworkerAssigned)
      // tagsToSave.volunteersAssigned = parseInt(tagsToSave.volunteersAssigned)

      // Add saved tags to form data
      // setOrganisationDetails(prevState => ({
      //   ...prevState,
      //   ...tagsToSave
      // }))
    }

    if (orgId !== 'new') {
      if (!hasAnyPermissionIn(['partnerOrgEditMy', 'partnerOrgEditAll'])) {
        history.push('/dashboard')
      } else {
        getOrgDetails(orgId)
        getUserGroupTags(orgId)
      }
    } else {
      setDetailsLoaded(true)

      if (!hasAnyPermissionIn(['partnerOrgCreate'])) {
        history.push('/dashboard')
      }
    }

    setLoading(false)

    // Set initial Meter setting
    setMeterValue(100 / questions.length)

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Partner Organisation: {organisationDetails.firstName ? organisationDetails.firstName + ' ' + organisationDetails.lastName : 'New User'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteOrg} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete organisation ?</NotificationLayer>}
        </Box>
      </Box>

      {orgId !== 'new' && hasAnyPermissionIn(['partnerOrgArchiveMy', 'partnerOrgArchiveAll']) &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge' weight='400' margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              {hasAnyPermissionIn(['partnerOrgArchiveMy', 'partnerOrgArchiveAll']) &&
                <Button label={<Text><FontAwesomeIcon icon={['fal', 'trash']} /> Archive Partner</Text>} margin={{ horizontal: 'xsmall' }} target='_self' onClick={() => showDeleteOrgOptions(orgId)} />}
            </Box>
          </Box>
        </Box>}

      <Box background='white' elevation='small' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
        >
          {loading ? (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>
          ) : (
            <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />)}
        </Box>
      </Box>
    </Box>
  )
}

export default PartnerOrgEdit
