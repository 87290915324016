import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

// Error handler
import useErrorHandler from '../utils/useErrorHandler'
import FlashMessage from '../utils/FlashMessage'

// Services
import UserService from '../services/UserService'
import ApiService from '../services/ApiService'
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'
import FormField from '../components/simple/formField/FormField'
import Select from '../components/simple/input/Select'
import Button from '../components/simple/button/Button'

import { hasAnyPermissionIn } from '../utils/permissions'


function GDRP (props) {
  const history = useHistory()
  const { error, showError } = useErrorHandler(null)

  // const { clients, setClientsA } = useState([])
  const [users, setUsers] = useState([])
  const [allUsers, setAllUsers] = useState([])

  const [selectedDownloadClient, setSelectedDownloadClient] = useState()
  const [selectedDeleteClient, setSelectedDeleteClient] = useState()

  const activeSite = LocalStorageService.get('activeSite')
  const activeOrg = LocalStorageService.get('activeOrg')
  const apiToken = LocalStorageService.get('apiToken')

  const getClients = async () => {
    const params = {
      fields: 'id,firstName,lastName,reference',
      limit: 2000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'client',
      sort: 'firstName ASC lastName ASC',
      where: {
        deleted: false
      }
    }
    const users = await UserService.getUsers(apiToken, params)

    // map the data
    const dataToSave = users.data.map((u) => ({
      ...u,
      ...{
        name: `${u.firstName || ''} ${u.lastName || ''}`
      }
    }))

    if (users.error) {
      NotificationService.error(users.error)
      return
    }

    if (dataToSave) {
      setUsers(dataToSave)
      setAllUsers(dataToSave)
    }
  }

  useEffect(() => {
    if (!hasAnyPermissionIn(['gdprGetAll', 'gdprDeleteAll'])) {
      NotificationService.error('Access Denied')
      history.push('/system')
    }
  }, [props.activeSite])

  useEffect(() => {
    getClients()
  }, [props.activeSite])

  const downloadData = async () => {
    var params = {
      user: selectedDownloadClient
    }
    try {
      var results = await ApiService.get(apiToken, `/gdpr/data`, params)
    } catch (err) {
      console.log(err)
    }

    if (!results?.data) {
      NotificationService.warning('There is no data to download for this user.')
      return
    }

    // build up the data
    var csvData = []
    for (const model in results.data) {

      var modelMap = {}
      var modelData = []

      // add a new row for the model name
      csvData.push([
        `Type: ${model}`
      ])

      // if there's any data for this model, return it
      if (results.data[model].length) {
        // build a map for the model
        for (const row of results.data[model]) {
          // build a map of the keys
          for (const rowKey in row) {
            // update the model map with any new keys that are found
            if (!modelMap[rowKey]) {
              modelMap[rowKey] = Object.keys(modelMap).length
            }
          }
        }

        // add the model key as the first row for that model
        var modelRow = []
        for (const modelKey in modelMap) {
          const thisIndex = modelMap[modelKey]
          modelRow[thisIndex] = modelKey
        }
        csvData.push(modelRow)

        // add the data
        for (const row of results.data[model]) {
          var thisRow = []

          // build the data from the model map
          for (const modelKey in modelMap) {
            const thisIndex = modelMap[modelKey]
            modelRow[thisIndex] = modelKey

            const cellData = row[modelKey]
            thisRow.push(cellData)
          }
          csvData.push(thisRow)
        }
      }
    }

    const csvContent = csvData.map(e => e.join(',')).join('\n')
    saveFile(csvContent, 'gdpr-data-request.csv')
  }

  const saveFile = (data, filename) => {
    var pom = document.createElement('a')
    var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    var url = URL.createObjectURL(blob)
    pom.href = url
    pom.setAttribute('download', filename)
    pom.click()
  }

  const deleteData = async () => {
    if (window.confirm('Are you sure you want to do this? This will PERMANENTLY delete all data for this client')) {
      if (window.confirm('Are you ABSOLUTELY sure you want to do this? This cannot be undone')) {
        var params = {
          user: selectedDeleteClient
        }
        try {
          await ApiService.get(apiToken, '/gdpr/data/delete', params)
        } catch (err) {
          console.log(err)
          NotificationService.error('Error deleting data')
          return
        }

        NotificationService.success('Data permanently deleted')
      }
    }
  }

  return (
    <>
      <Box elevation='small' flex='grow' background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>GDRP </H1>
          {error &&
            <FlashMessage message={error} />}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box direction='row-responsive' flex='grow' gap='small' justify='between' margin={{ bottom: 'medium' }}>
        {hasAnyPermissionIn(['gdprGetAll']) &&
          <Box background='#fff' pad='medium' round='small' style={{ flex: 1 }} elevation='small'>
            <Text size='xlarge'>Download all data</Text>
            <FormField
              label='Select client'
              name='staff'
              required
            >
              <Select
                id='staff'
                name='staff'
                labelKey={(u) => `${u.firstName || ''} ${u.lastName || ''}`}
                onChange={({ option }) => setSelectedDownloadClient(option.id)}
                options={users}
                onSearch={text => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, 'i')

                  setUsers(allUsers.filter(o => exp.test(o.name)))
                }}
                valueKey={{ key: 'id', reduce: true }}
              />
            </FormField>
            <Button label='Download' onClick={() => downloadData()} />
          </Box>}

        {hasAnyPermissionIn(['gdprDeleteAll']) &&
          <Box background='#fff' pad='medium' round='small' style={{ flex: 1 }} elevation='small'>
            <Text size='xlarge' color='red'>Delete all data</Text>
            <FormField
              label='Select client'
              name='staff'
              required
            >
              <Select
                id='staff'
                name='staff'
                labelKey={(u) => `${u.firstName || ''} ${u.lastName || ''}`}
                onChange={({ option }) => setSelectedDeleteClient(option.id)}
                options={users}
                onSearch={text => {
                  // The line below escapes regular expression special characters:
                  // [ \ ^ $ . | ? * + ( )
                  const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                  // Create the regular expression with modified value which
                  // handles escaping special characters. Without escaping special
                  // characters, errors will appear in the console
                  const exp = new RegExp(escapedText, 'i')

                  setUsers(allUsers.filter(o => exp.test(o.name)))
                }}
                valueKey={{ key: 'id', reduce: true }}
              />
            </FormField>
            <Button label='Delete' onClick={() => deleteData()} />

          </Box>}

      </Box>
    </>
  )
}

export default GDRP
