
// const hideValues = false

import ApiService from './ApiService'

class AuditTrailService {
  get = async (apiToken, where, customParams = {}) => {
    var url = '/api/audittrail'

    var params = {
      ...{
        fields: 'id,description,type,key,actionedBy,createdAt,subject,organisation,additionalData',
        limit: 1000
      },
      ...customParams
    }

    if (where) {
      params.where = where
    }

    try {
      var auditTrails = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('AuditTrailService:get ', auditTrails)
    return auditTrails
  }
}
export default new AuditTrailService()
