// React
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Heading as GHeading } from 'grommet'

/**
 * `H4` renders text as a Grommet Heading level 4 component
 */
class H4 extends React.Component {
  render () {
    return (
      <GHeading
        {...this.props}
        level='4'
      >
        {this.props.children}
      </GHeading>)
  }
}

H4.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   *  "start"  
   *  "end"  
   *  "center"  
   *  "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * A color identifier to use for the text color.  
   *   
   * "neutral-1"  
   * {"dark": "light-1", "light": "dark-2"}
   */
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * The heading level. It corresponds to the number after the 'H' for the DOM tag.  
   * Set the level for semantic accuracy and accessibility. The sizing can be further adjusted using the size property.  
   *   
   * 1, 2, 3,4, 5, 6
   */
  level: PropTypes.number,
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Whether the font size should be scaled for mobile environments.  
   *   
   * **true**  
   * false
   */
  responsive: PropTypes.bool,
  /**
   * The font size and line height are primarily driven by the chosen tag.  
   * But, it can be adjusted via this size property. The tag should be set for semantic correctness and accessibility.  
   * This size property allows for stylistic adjustments.  
   *   
   * "xsmall"  
   * "small"  
   * **"medium"**  
   * "large"  
   * "xlarge"  
   * "xxlarge"
   */  
  size: PropTypes.string,
  /**
   * How to align the text inside the component.    
   *   
   * **"start"**  
   * "center"  
   * "end"
   */
  textAlign: PropTypes.string,
  /**
   * Restrict the text to a single line and truncate with ellipsis if it is too long to all fit.  
   *   
   * true  
   * **false**
   */
  truncate: PropTypes.bool
}

export default H4
