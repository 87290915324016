// react
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../simple/box/Box'
import Text from '../../simple/text/Text'

import ConnectionService from '../../../services/ConnectionService'

/**
 * `ConnectionCheck` makes a regular check
 * to see if the connection is still available
 * displaying a banner if not
 */
function ConnectionCheck (props) {
  const [connected, setConnected] = useState('pong')

  useEffect(() => {
    const interval = setInterval(async () => {
      const connection = await ConnectionService.getConnection()

      setConnected(connection)
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {connected !== 'pong' && (
        <Box
          align='center'
          background='red'
          elevation='medium'
          margin={{ vertical: 'small' }}
          pad={{ vertical: 'small', horizontal: 'medium' }}
          round='small'
        >
          <Text color='white'>There is a problem connecting to the server. Please wait, the system will automatically reconnect.</Text>
        </Box>
      )}
    </>
  )
}

ConnectionCheck.propTypes = {
  /**
   * Connection status
   *
   * "pong" - connected
   */
  connected: PropTypes.string
}

export default ConnectionCheck
