import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

import { constants } from '../../../../utils/constants'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import OptionButtons from '../../../../components/compound/optionButtons/OptionButtons'
import Select from '../../../../components/simple/input/Select'
import Text from '../../../../components/simple/text/Text'
import TextArea from '../../../../components/simple/input/TextArea'

function StepThreeClientDetails () {
  const { formValues, setFormValues, valid, setValid } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const stepThreeInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* traffickingStatus */}
                  <FormField
                    label='Trafficking Status'
                    name='traffickingStatus'
                  >
                    <Select
                      name='traffickingStatus'
                      labelKey='label'
                      options={constants.traffickingStatus}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* ukStatus */}
                  <FormField
                    label='UK Status'
                    name='ukStatus'
                  >
                    <Select
                      name='ukStatus'
                      labelKey='label'
                      options={constants.ukStatus}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* income */}
                  <FormField
                    label='Income'
                    name='income'
                  >
                    <Select
                      name='income'
                      labelKey='label'
                      options={constants.counsellingFormIncomeDetails}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

          <Text weight='bold'>Language</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* english */}
                  <FormField
                    label='English'
                    name='english'
                  >
                    <Select
                      name='english'
                      labelKey='label'
                      options={constants.literacyLevel}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* literacyFirstLanguage */}
                  <FormField
                    label='Literacy First Language'
                    name='literacyFirstLanguage'
                  >
                    <Select
                      name='literacyFirstLanguage'
                      labelKey='label'
                      options={constants.literacyLevel}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* literacyEnglish */}
                  <FormField
                    label='Literacy English'
                    name='literacyEnglish'
                  >
                    <Select
                      name='literacyEnglish'
                      labelKey='label'
                      options={constants.literacyLevel}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* interpreter */}
                  <FormField
                    label='Interpreter'
                    name='interpreter'
                  >
                    <Select
                      name='interpreter'
                      labelKey='label'
                      options={constants.interpreterNeeded}
                      valueKey={{ key: 'value', reduce: true }}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <Text weight='bold'>Legal Information</Text>
          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* solicitorName */}
                  <FormField
                    label='Solicitor Name and Organisation'
                    name='solicitorName'
                  >
                    <TextArea
                      name='solicitorName'
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* solicitorContactDetails */}
                  <FormField
                    label='Solicitor Contact Details'
                    name='solicitorContactDetails'
                  >
                    <TextArea
                      name='solicitorContactDetails'
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          <ResponsiveContext.Consumer>
            {responsive => (
              <Box direction='row-responsive' gap='small'>
                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* traffickerComplaint */}
                  <FormField
                    label='Has the client made a complaint or given any evidence against their traffickers, as part of a police investigation?
                    NB: Please note this may affect the kind of counselling we are able to offer.
                    '
                    name='traffickerComplaint'
                  >
                    <OptionButtons
                      id='traffickerComplaint'
                      name='traffickerComplaint'
                      options={['Yes', 'No']}
                    />
                  </FormField>
                </Box>

                <Box width={responsive === 'small' ? '100%' : '50%'}>
                  {/* policeInvestigation */}
                  <FormField
                    label='Is there an ongoing police investigation?
                    NB: Please note this may affect the kind of counselling we are able to offer.
                    '
                    name='policeInvestigation'
                  >
                    <OptionButtons
                      id='policeInvestigation'
                      name='policeInvestigation'
                      options={['Yes', 'No']}
                    />
                  </FormField>
                </Box>
              </Box>)}
          </ResponsiveContext.Consumer>

          {formValues.policeInvestigation === 'Yes' &&
            <FormField
              label='Please provide details of the stage of the investigation'
              name='policeInvestigationDetails'
            >
              <TextArea
                name='policeInvestigationDetails'
              />
            </FormField>}
        </Box>
      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepThreeInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepThreeClientDetails
