import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { hasPermission } from '../../utils/permissions'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserGroupService from '../../services/UserGroupService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

function PartnerOrgListArchive () {
  const [showDeleteOptions, showDelete] = useState(false)
  const [orgToDelete, setOrgToDelete] = useState(null)
  const [orgValues, setOrgValues] = useState([])
  const [orgToRestore, setOrgToRestore] = useState(null)
  const [showRestoreOptions, showRestore] = useState(false)

  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'name',
    direction: 'asc'
  })

  // Prompt user to delete Org
  const showDeleteOrgOptions = (org) => {
    setOrgToDelete(org)
    showDelete(true)
  }

  // Prompt user to restore Org
  const showRestoreOrgOptions = async (org) => {
    setOrgToRestore(org)

    showRestore(true)
  }

  // Restore an Org
  const restoreOrg = async () => {
    showRestore(false)

    const orgData = {
      data: { deleted: false }
      // organisation: activeOrg.id
    }

    var orgUpdated = await UserGroupService.updateGroup(apiToken, orgToRestore.id, orgData)
    if (orgUpdated.error) {
      NotificationService.error(orgUpdated.error)
    } else {
      // Delete from state to force a refresh
      const newOrgValues = orgValues.filter((item) => item.id !== orgToRestore.id)
      setOrgValues(newOrgValues)

      NotificationService.info(orgToRestore.name + ' has been restored')
    }
  }

  // Delete an Organisation
  const deleteOrg = async () => {
    showDelete(false)

    // MOCK start
    // Getting Access Denied from the following
    // Uncomment when fixed
    const response = await UserGroupService.deleteUserGroup(apiToken, orgToDelete.id, true)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newOrgValues = orgValues.filter((item) => item.id !== orgToDelete.id)
      setOrgValues(newOrgValues)
      NotificationService.info('Organisation deleted')
    } else {
      NotificationService.error(response.error)
    }

    // And then these lines can be removed
    // const newOrgValues = orgValues.filter((item) => item.id !== orgToDelete.id)
    // setOrgValues(newOrgValues)
    // MOCK end
  }

  useEffect(() => {
    let unmounted = false

    // Get organisations
    const getOrganisations = async () => {
      const params = {
        fields: 'id,name',
        limit: 1000
      }

      const where = {
        deleted: true,
        type: 'partnerOrg'
      }

      const organisations = await UserGroupService.getGroups(apiToken, params, where)
      if (organisations?.error) {
        NotificationService.error(organisations.error)
      } else {
        if (!unmounted) {
          // if there's no organisations, do nothing
          if (!organisations || !organisations.data) {
            return
          }

          const mappedOrganisations = organisations.data.map((data, index) => ({
            id: data.id,
            name: data.name,
            createdAt: data.createdAt
          }))

          setOrgValues(mappedOrganisations)
        }
      }
    }

    if (hasPermission('partnerOrgAccessArchive')) {
      getOrganisations()
    }

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'name',
      header: <Text>Name</Text>,
      primary: true
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          {hasPermission('partnerOrgRestoreAll') &&
            <Button label={<Text><FontAwesome color='background' icon={['fal', 'undo']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showRestoreOrgOptions(datum)} target='_self' />}
          {hasPermission('usergroupDestroyAll') &&
            <Button label={<Text><FontAwesome color='background' icon={['fal', 'trash']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteOrgOptions(datum)} target='_self' />}
        </Box>
      )
    }
  ]

  return (
    <>
      <Box background='background' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Partner Organisations Archive </H1>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteOrg} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to permanently delete partner organisation {orgToDelete.name}?</NotificationLayer>}
          {showRestoreOptions &&
            <NotificationLayer button1Text='Yes' button1Click={restoreOrg} button2Text='No' button2Click={() => { showRestore(false) }}>Are you sure you want to restore partner organisation {orgToRestore.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor color='secondary' href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='background' elevation='small' direction='column' gap='small' round='small'>
        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', vertical: 'medium' }}>
          {orgValues?.length
            ? (
              <DataTable
                columns={dataColumns}
                data={orgValues}
                onSort={setSort}
                paginate
                sort={sort}
                step={10}
              />)
            : (
              <Text size='small' margin={{ horizontal: 'small' }}>There are no partner organisations to display</Text>)}
        </Box>
      </Box>
    </>
  )
}

export default PartnerOrgListArchive
