import React from 'react'

// Appt Components
import Box from '../components/simple/box/Box'
// import Button from '../components/simple/button/Button'
import Text from '../components/simple/text/Text'

const FlashMessage = (props) => {
  return (
    <Box background={props.type === 'M' ? 'status-ok' : 'status-error'} direction='row-responsive' margin={{ horizontal: 'medium' }} pad={{ horizontal: 'medium', vertical: 'small' }} round='xsmall'>
      <Box>
        {/* <Button type='button' class='close' data-dismiss='alert'>×</Button> */}
        <Text>{props.message}</Text>
      </Box>
    </Box>
  )
}

export default FlashMessage
