import React, { useContext, useEffect, useRef, useState } from 'react'

import { ThemeContext } from 'styled-components'

import { useHistory, useParams } from 'react-router-dom'

import {
  ResponsiveContext
} from 'grommet'

import {
  CancellationLayer,
  Error,
  StepFooter,
  StepContent,
  FormContext,
  FormHeader
} from './shared'

import { getWidth } from './utils/utils'

import { getObjectByValue } from '../../../utils/objects'

// Services
import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'

// Appt
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Text from '../../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

// Steps
import StepOneExit from './steps/StepOneExit'

export const defaultFormValues = {
  // step One
  clientName: '',
  feelSafe: '',
  otherPeople: '',
  goodPerson: '',
  worldDangerous: '',
  trustAnyone: '',
  lovingFeelings: '',
  trustJudgment: '',
  avoidPeople: '',
  lostRespect: '',
  goodDecisions: '',
  peopleTrusted: '',
  lostFreedom: '',
  depend: '',
  caring: '',
  comfort: '',
  welcoming: '',
  easy: '',
  environment: '',
  buildingComments: '',
  counsellor: '',
  counsellorComments: '',
  counsellingTime: '',
  counsellingTimeComments: '',
  completing: '',
  completingComments: '',
  frequency: '',
  frequencyComments: '',
  medication: '',
  depression: '',
  anxiety: '',
  panic: '',
  sleeping: '',
  relationships: '',
  safe: '',
  lonely: '',
  confidence: '',
  coping: '',
  control: '',
  positive: '',
  positiveFuture: '',
  feedback: '',
  useComment: '',
  contactClient: ''
}

// TODO: Add question texts & remaining questions
const questions = [
  // step One
  {
    questionText: 'I do not feel safe anywhere anymore',
    key: 'feelSafe'
  },
  {
    questionText: 'Other people can be genuinely loving toward me',
    key: 'otherPeople'
  },
  {
    questionText: 'I am a good person',
    key: 'goodPerson'
  },
  {
    questionText: 'The world is very dangerous',
    key: 'worldDangerous'
  },
  {
    questionText: 'I do not trust anyone anymore',
    key: 'trustAnyone'
  },
  {
    questionText: 'It is possible for me to have close and loving feelings with other people',
    key: 'lovingFeelings'
  },
  {
    questionText: 'I trust my own judgement',
    key: 'trustJudgment'
  },
  {
    questionText: 'I avoid other people because they might hurt me',
    key: 'avoidPeople'
  },
  {
    questionText: 'I have lost respect for myself',
    key: 'lostRespect'
  },
  {
    questionText: 'I do not feel confident that i can make good decisions for myself',
    key: 'goodDecisions'
  },
  {
    questionText: 'Some people can be trusted',
    key: 'peopleTrusted'
  },
  {
    questionText: 'Because I do not feel able to protect myself, I have lost my sense of freedom',
    key: 'lostFreedom'
  },
  {
    questionText: 'I feel as though I can depend on other people',
    key: 'depend'
  },
  {
    questionText: 'Most people are basically caring',
    key: 'caring'
  },
  {
    questionText: 'I comfort myself very well when I am upset',
    key: 'comfort'
  },
  {
    questionText: 'Client Name',
    key: 'clientName'
  },
  {
    questionText: 'Welcoming',
    key: 'welcoming'
  },
  {
    questionText: 'Easy to get to',
    key: 'easy'
  },
  {
    questionText: 'A good environment for counselling',
    key: 'environment'
  },
  {
    questionText: 'Comments',
    key: 'buildingComments'
  },
  {
    questionText: 'How well do you think your counsellor understood your issues?',
    key: 'counsellor'
  },
  {
    questionText: 'Comments',
    key: 'counsellorComments'
  },
  {
    questionText: 'How do you feel about the length of time you received counseling?',
    key: 'counsellingTime'
  },
  {
    questionText: 'Comments',
    key: 'counsellingTimeComments'
  },
  {
    questionText: 'How did you find completing the forms? (PHQ9 & GAD7, assessment forms, etc)',
    key: 'completing'
  },
  {
    questionText: 'Comments',
    key: 'completingComments'
  },
  {
    questionText: 'How did you feel about the frequency of the forms? (PHQ9 & GAD7, assessment forms, etc)',
    key: 'frequency'
  },
  {
    questionText: 'Comments',
    key: 'frequencyComments'
  },
  {
    questionText: 'Any changes in medication',
    key: 'medication'
  },
  {
    questionText: 'Helping with feelings of depression',
    key: 'depression'
  },
  {
    questionText: 'Helping with feelings of anxiety',
    key: 'anxiety'
  },
  {
    questionText: 'Having fewer panic attacks',
    key: 'panic'
  },
  {
    questionText: 'Sleeping better',
    key: 'sleeping'
  },
  {
    questionText: 'Improving the quality of my relationships with friends & others',
    key: 'relationships'
  },
  {
    questionText: 'Feeling more safe and secure',
    key: 'safe'
  },
  {
    questionText: 'Feeling less lonely',
    key: 'lonely'
  },
  {
    questionText: 'Improving my confidence',
    key: 'confidence'
  },
  {
    questionText: 'Coping with life on a day to day level',
    key: 'coping'
  },
  {
    questionText: 'Feeling more in control of my life',
    key: 'control'
  },
  {
    questionText: 'Feeling more positive generally',
    key: 'positive'
  },
  {
    questionText: 'Feeling more positive about the future',
    key: 'positiveFuture'
  },
  {
    questionText: 'Could you please tell us anything else about your experiences of coming for counselling at Snowdrop Project. All feedback is welcome, both what went well, and not so well for you',
    key: 'feedback'
  },
  {
    questionText: 'It can be useful for the Snowdrop Project to use clients comments for research, funding and publicity purposes. Would you be willing for us to use comments from this form? All comments used for whatever purposes are made anonymous',
    key: 'useComment'
  },
  {
    questionText: 'It can sometimes be helpful to contact formerclients when our services are being reviewed so that the services remain relevant and appropriate for our clients. Would you be willing for us to use your comments in this way?',
    key: 'contactClient'
  }
]

export const steps = [
  {
    description: <Text weight='bold'>Could you please answer the following questions about your experience of the counselling at the snowdrop Project?</Text>,
    inputs: <StepOneExit />,
    title: 'Exit (Counselling)'
    // header: true // identifies a large circle in the header
  }
]

function ExitCounselling (props) {
  const history = useHistory()

  const urlParams = useParams()
  const userId = urlParams.userid
  const viewtype = urlParams.viewtype

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const activeUser = LocalStorageService.get('userDetails')

  const size = useContext(ResponsiveContext)
  const theme = useContext(ThemeContext)
  const [activeIndex, setActiveIndex] = useState(0)
  // for readability, this is used to display numeric value of step on screen,
  // such as step 1 of 3. it will always be one more than the active array index
  const [activeStep, setActiveStep] = useState(activeIndex + 1)

  // store form values in state so they persist
  // when user goes back a step
  const [formValues, setFormValues] = useState(defaultFormValues)

  // controls state of cancel layer
  const [open, setOpen] = useState(false)

  // tracks validation results for the current step
  const [valid, setValid] = useState(true)

  const submitForm = async () => {
    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    let question = ''

    for (var key in formValues) {
      question = getObjectByValue(questions, 'key', key)
      // question will be undefined until we have entered all questions
      if (question !== undefined && formValues[key] !== '') {
        item = {
          questionKey: key,
          questionText: question.questionText,
          response: formValues[key].toString()
        }
        submitArray.push(item)
      }
    }

    // Build output data
    const data = {
      user: activeUser.id,
      subject: formValues && formValues.clientName ? formValues.clientName : parseInt(props.location?.clientid),
      organisation: activeOrganisation.id,
      // status: draft ? 'Draft' : 'Published',
      status: 'Published',
      type: 'exitCounselling',
      reference: '',
      notify: JSON.stringify(formValues.notifyStaff || []),
      responses: submitArray
    }
    console.log(data)

    var submissionAdded = await FormService.makeSubmissions(apiToken, data)
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error)
    } else {
      NotificationService.info('Form Submitted')
      history.push('/clients')
    }
  }

  // ref allows us to access the wizard container and ensure scroll position
  // is at the top as user advances between steps. useEffect is triggered
  // when the active step changes.
  const formRef = useRef()

  useEffect(() => {
    setActiveStep(activeIndex + 1)
  }, [activeIndex])

  const id = 'simple-wizard'
  // scroll to top of step when step changes
  useEffect(() => {
    const container = formRef.current
    const header = document.querySelector(`#${id}`)
    container.scrollTop = -header.getBoundingClientRect().bottom
  }, [activeIndex, open])

  useEffect(() => {
    const getResponses = async () => {
      const params = {
        fields: 'id,questionKey,questionText,response',
        limit: 2000
      }
      const where = {
        submission: urlParams.formId
      }

      const responses = await FormService.getResponses(apiToken, params, where)

      const savedResponses = {}

      responses.data.forEach(element => {
        var data = element.response
        const intKeys = [
          'clientName',
          'feelSafe',
          'otherPeople',
          'goodPerson',
          'worldDangerous',
          'trustAnyone',
          'lovingFeelings',
          'trustJudgment',
          'avoidPeople',
          'lostRespect',
          'goodDecisions',
          'peopleTrusted',
          'lostFreedom',
          'depend',
          'caring',
          'comfort'
        ]
        if (intKeys.includes(element.questionKey)) {
          data = parseInt(data)
        }
        savedResponses[element.questionKey] = data
      })

      setFormValues(previousValues => ({
        ...previousValues,
        ...savedResponses
      }))
    }

    if (urlParams.formId !== 'new') {
      getResponses()
    }
  }, [])

  const numberColumns = 2
  const width = getWidth(numberColumns, theme, size)
  return (
    <FormContext.Provider
      value={{
        activeIndex,
        id,
        defaultFormValues,
        setActiveIndex,
        activeStep,
        setActiveStep,
        valid,
        ref: formRef,
        setValid,
        steps,
        formValues,
        setFormValues,
        formTitle: 'FQ Form',
        width
      }}
    >
      <Box fill>
        {/* Breadcrumb section */}
        <BreadcrumbBar
          path={<><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
        >
          FQ Forms:
          {/* FQ Forms: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'} */}
        </BreadcrumbBar>

        {/* <FormHeader setOpen={setOpen} /> */}

        <StepContent
          // onSubmit={({ value }) => console.log('onSubmit:', value)}
          onSubmit={({ value }) => submitForm()}
        />
        <StepFooter viewType={viewtype}/>
      </Box>
      {open && <CancellationLayer onSetOpen={setOpen} />}
    </FormContext.Provider>
  )
}

export default ExitCounselling
