import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { ResponsiveContext } from 'grommet'

import moment from 'moment'

import { hasAnyPermissionIn, hasPermission } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserGroupMemberService from '../../services/UserGroupMemberService'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import Text from '../../components/simple/text/Text'

function PartnerListArchive (props) {
  const [showDeleteOptions, showDelete] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [userValues, setUserValues] = useState([])
  const [loading, setLoading] = useState(false)
  const [partnerToRestore, setPartnerToRestore] = useState(null)
  const [showRestoreOptions, showRestore] = useState(false)

  const history = useHistory()

  const size = useContext(ResponsiveContext)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'name',
    direction: 'asc'
  })

  // Prompt user to restore Partner
  const showRestorePartnerOptions = async (partner) => {
    setPartnerToRestore(partner)

    showRestore(true)
  }

  // Restore a Partner
  const restorePartner = async () => {
    showRestore(false)

    const partnerData = {
      data: { deleted: false }
    }

    var partnerUpdated = await UserService.updateUser(apiToken, partnerToRestore.id, partnerData, {
      type: 'partnerContact'
    })
    if (partnerUpdated.error) {
      NotificationService.error(partnerUpdated.error)
    } else {
      // Delete from state to force a refresh
      const newPartnerValues = userValues.filter((item) => item.id !== partnerToRestore.id)
      setUserValues(newPartnerValues)

      NotificationService.info(partnerToRestore.name + ' has been restored')
    }
  }

  // Prompt user to delete
  const showDeletePartnerOptions = (org) => {
    setUserToDelete(org)
    showDelete(true)
  }

  // Delete a Partner
  const deletePartner = async () => {
    const response = await UserService.deleteUser(apiToken, userToDelete, true)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
      setUserValues(newUserValues)
      NotificationService.success('Partner deleted')
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  useEffect(() => {
    let unmounted = false

    // Get partners
    const getPartners = async () => {
      // First get the UserGroupMember's the Usergroup has
      const params = {
        fields: 'id,user',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null
      }

      params.where = {
        deleted: true,
        usergroup: props.partnerOrgId
      }
      setLoading(true)
      const members = await UserGroupMemberService.getMembers(apiToken, params)

      if (members?.data?.length > 0) {
        const membersIds = members?.data.map(a => a.user)
        console.log(membersIds)

        const params = {
          fields: 'id,firstName,lastName,email,createdAt',
          limit: 1000,
          orgId: activeOrg.id,
          siteId: activeSite?.id || null,
          type: 'partnerContact'
        }

        params.where = {
          id: membersIds,
          deleted: true
        }
        const users = await UserService.getUsers(apiToken, params)
        if (users?.error) {
          NotificationService.error(users.error)
        } else {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              createdAt: moment(data.createdAt).fromNow(),
              id: data.id,
              email: data.email,
              name: data.firstName + ' ' + data.lastName
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    getPartners()

    setLoading(false)

    return () => { unmounted = true }
  }, [])

  // Build dataTable columns
  // based on screen size
  const dataColumns = []

  dataColumns.push(
    {
      property: 'name',
      header: <Text>Name</Text>,
      primary: true,
      search: true
    }
  )

  if (size !== 'small') {
    dataColumns.push(
      {
        property: 'email',
        header: <Text>Email</Text>,
        search: true
      },
      {
        property: 'createdAt',
        header: <Text>Created</Text>
      }

    )
  }

  dataColumns.push(
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          {hasAnyPermissionIn(['partnerContactEditAll', 'partnerContactEditMy']) &&
            <Button label={<Text><FontAwesome color='background' icon={['fal', 'undo']} /></Text>} margin={{ horizontal: 'xsmall' }} target='_self' onClick={() => showRestorePartnerOptions(datum)} />}
          {hasPermission('partnerContactDestroyAll') &&
            <Button label={<Text><FontAwesome color='background' icon={['fal', 'trash']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showDeletePartnerOptions(datum)} target='_self' />}

        </Box>
      )
    }

  )

  return (
    <>
      <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
        {showDeleteOptions &&
          <NotificationLayer button1Text='Yes' button1Click={deletePartner} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete partner {userToDelete.name}?</NotificationLayer>}
        {showRestoreOptions &&
          <NotificationLayer button1Text='Yes' button1Click={restorePartner} button2Text='No' button2Click={() => { showRestore(false) }}>Are you sure you want to restore partner {partnerToRestore.name}?</NotificationLayer>}

        {/* <Box direction='row-responsive' gap='xxsmall'>
          {hasPermission('partnerContactCreate') &&
            <Button href='partner/new/edit' label={<Text><FontAwesome color='background' icon={['fal', 'plus-circle']} /> Add Partner</Text>} primary target='_self' />}
        </Box> */}
      </Box>

      <Box direction='column' gap='medium' pad={{ horizontal: 'medium', bottom: 'medium' }}>
        <Box align='center'>
          <PulseLoader
            size={15}
            color='orange'
            loading={loading}
          />
        </Box>
        <DataTable
          columns={dataColumns}
          data={userValues}
          onSort={setSort}
          paginate
          sort={sort}
          step={10}
        />
        {!userValues.length && <Text size='small' margin={{ horizontal: 'small' }}>There are no partners to display</Text>}
      </Box>
    </>
  )
}

export default PartnerListArchive
