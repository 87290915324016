import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

// Grommet
import { ResponsiveContext } from 'grommet'

import { capitalize } from '../../utils/text'
import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import UserStatus from '../shared/User-Status'

function ServiceUserListMy(props) {
  const [userValues, setUserValues] = useState([])

  const [userValuesDataTable, setUserValuesDataTable] = useState([])
  const history = useHistory()
  const [userToDelete, setUserToDelete] = useState(null)
  const [showDeleteOptions, showDelete] = useState(false)
  const [assignedCaseworkUsers, setAssignedCaseworkUsers] = useState({})
  const [assignedCounsellingUsers, setAssignedCounsellingUsers] = useState({})
  const [staff, setStaff] = useState({})

  const [loading, setLoading] = useState(true)

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'reference',
    direction: 'asc'
  })

  const terms = LocalStorageService.get('terms')
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const currentUser = LocalStorageService.get('userDetails')

  // Prompt user to delete
  const showDeleteUserOptions = (user) => {
    setUserToDelete(user)
    showDelete(true)
  }

  // Delete a User
  const deleteUser = async () => {
    // Delete from server
    const response = await UserService.deleteUser(apiToken, userToDelete, false, {
      type: 'client'
    })
    console.log('deleteUser', userToDelete, response)
    if (response?.data?.success === 'true') {
      // And from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
      setUserValues(newUserValues)
      NotificationService.success('User archived')
    } else if (response?.error) {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Basically componentDidMount

  const getAssignedCaseworkUsers = async () => {

    setLoading(true)

    // get the users
    const params = {
      fields: 'id,firstName,lastName',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'staff',
      where: {
        deleted: false
      }
    }
    const users = await UserService.getUsers(apiToken, params)
    if (users?.error || !users?.data) {
      return
    }

    var mappedUsers = {}
    for (const user of users.data) {
      mappedUsers[String(user.id)] = `${user.firstName || ''} ${user.lastName || ''}`
    }

    setStaff(mappedUsers)

    // get the user tags
    const tagWhere = {
      type: 'general',
      organisation: activeOrg.id,
      site: activeSite.id,
      key: 'caseworkerAssigned',
      sensitivity: 'basic'
    }
    const tagParams = {
      fields: 'id,user,key,value',
      type: 'client'
    }
    const userTags = await UserTagService.get(apiToken, tagParams, tagWhere)
    if (userTags) {
      var tagsToSave = {}
      for (const tag of userTags) {
        if (tag.value && mappedUsers[tag.value]) {
          tagsToSave[String(tag.user)] = mappedUsers[tag.value]
        }
      }
      setAssignedCaseworkUsers(tagsToSave)
    }
  }

  const getAssignedCounsellingUsers = async () => {

    setLoading(true)

    // get the users
    const params = {
      fields: 'id,firstName,lastName',
      limit: 1000,
      orgId: activeOrg.id,
      siteId: activeSite?.id || null,
      type: 'staff',
      where: {
        deleted: false
      }
    }
    const users = await UserService.getUsers(apiToken, params)
    if (users?.error || !users?.data) {
      return
    }

    var mappedUsers = {}
    for (const user of users.data) {
      mappedUsers[String(user.id)] = `${user.firstName || ''} ${user.lastName || ''}`
    }

    // get the user tags
    const tagWhere = {
      type: 'general',
      organisation: activeOrg.id,
      site: activeSite.id,
      key: 'counsellorAssigned',
      sensitivity: 'basic'
    }
    const tagParams = {
      fields: 'id,user,key,value',
      type: 'client'
    }
    const userTags = await UserTagService.get(apiToken, tagParams, tagWhere)
    if (userTags) {
      var tagsToSave = {}
      for (const tag of userTags) {
        if (tag.value && mappedUsers[tag.value]) {
          tagsToSave[String(tag.user)] = mappedUsers[tag.value]
        }
      }
      setAssignedCounsellingUsers(tagsToSave)
    }
  }

  useEffect(() => {
    getAssignedCaseworkUsers()
    getAssignedCounsellingUsers()
  }, [])

  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'client',
        where: {
          deleted: false
        }
      }
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              reference: data.reference,
              caseworker: '',
              counsellor: '',
              fullname: `${data.firstName || ''} ${data.lastName || ''}`,
              name: <Box direction='row'><UserStatus userId={data.id} /><Text>{data.firstName}&nbsp;</Text><Text>{data.lastName}&nbsp;</Text></Box>
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    if (hasAnyPermissionIn(['clientViewMy', 'clientViewAll'])) {
      getUsers()
    }
    return () => { unmounted = true }
  }, [assignedCaseworkUsers, assignedCounsellingUsers])

  useEffect(() => {

    console.log('TRACE - assignedCaseworkUsers', assignedCaseworkUsers)
    console.log('TRACE - map clients', userValues)
    var newUserValues = userValues
    for (const key in newUserValues) {
      const client = newUserValues[key]

      if (assignedCaseworkUsers[client.id]) {
        newUserValues[key].caseworker = assignedCaseworkUsers[client.id]
      }

      if (assignedCounsellingUsers[client.id]) {
        newUserValues[key].counsellor = assignedCounsellingUsers[client.id]
      }
    }

    var actualClients = []
    for (const client of newUserValues) {
      if (client.caseworker === staff[currentUser.id]) {
        actualClients.push(client)
      } else if (client.counsellor === staff[currentUser.id]) {
        actualClients.push(client)
      }
    }

    console.log('TRACE - finale userValues', userValues)
    console.log('TRACE - finale actualClients', actualClients)

    // setUserValues(newUserValues)
    setUserValuesDataTable(actualClients)
    setLoading(false)

  }, [assignedCaseworkUsers, assignedCounsellingUsers, userValues])

  // useEffect(() => {
  //   var newUserValues = userValues
  //   for (const key in newUserValues) {
  //     const client = newUserValues[key]

  //     if (assignedUsers[client.id]) {
  //       newUserValues[key].caseworker = assignedUsers[client.id]
  //     }
  //   }
  //   var actualClients = []
  //   for (const client of newUserValues) {
  //     if (client.caseworker === staff[currentUser.id]) {
  //       actualClients.push(client)
  //     } else if (client.counsellor === staff[currentUser.id]) {
  //       actualClients.push(client)
  //     }
  //   }
  //   setUserValuesDataTable(actualClients)
  //   setLoading(false)
  // }, [assignedUsers, userValues])



  // useEffect(() => {
  //   var newUserValues = []

  //   for (var client of userValues) {
  //     if (assignedUsers[client.id] === currentUser.id) {
  //       client.caseworker = assignedUsers[client.id]
  //       newUserValues.push(client)
  //     }
  //   }
  //   setUserValues(newUserValues)
  //   setUserValuesDataTable(newUserValues)
  //   setLoading(false)
  // }, [userValues])

  const dataColumns = [
    {
      property: 'id',
      header: <Text weight={600}>Reference</Text>,
      primary: true,
      search: true,
      render: datum => (
        <Text>C000{datum.id}</Text>
      )
    },
    {
      property: 'fullname',
      header: <Text weight={600}>Name</Text>,
      // primary: true,
      render: datum => datum.name,
      search: true
    },
    {
      property: 'caseworker',
      header: <Text weight={600}>Case Worker</Text>,
      render: datum => (
        <Text>{datum.caseworker}</Text>
      ),
      search: true
    },
    {
      property: 'counsellor',
      header: <Text weight={600}>Counsellor</Text>,
      render: datum => (
        <Text>{datum.counsellor}</Text>
      ),
      search: true
    },
    {
      property: 'actions',
      header: <Text weight={600}>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          {hasAnyPermissionIn(['clientEditMy', 'clientEditAll']) &&
            <Button
              href={`/client/${datum.id}/edit`}
              target='_self'
              plain
              children={
                <Box pad='5px'>
                  <FontAwesome icon={['fal', 'edit']} color='#fe8300' />
                </Box>
              }
            />}
          {hasAnyPermissionIn(['clientArchiveMy', 'clientArchiveAll']) &&
            <Button
              target='_self'
              plain
              onClick={() => showDeleteUserOptions(datum)}
              children={
                <Box pad='5px'>
                  <FontAwesome icon={['fal', 'trash']} color='#fe8300' />
                </Box>
              }
            />}
        </Box>
      ),
      sortable: false
    }
  ]

  return (
    <>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>My Clients </H1>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to archive user {userToDelete.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' pad='medium' round='small'>
        <Box direction='column'>
          <Box alignSelf='end' direction='row-responsive' pad={{ horizontal: 'medium' }}>
            <Box direction='row-responsive' gap='xsmall'>
              {hasPermission('clientAccessArchive') &&
                <Button href='clients/archive' label={<Text><FontAwesome icon={['fal', 'trash']} /> View Archive</Text>} secondary />}
              {hasPermission('clientCreate') &&
                <Box direction='row-responsive' gap='small'>
                  <Button href='client/new/edit' label={<Text><FontAwesome icon={['fal', 'plus-circle']} /> Add Client</Text>} target='_self' />
                </Box>}
            </Box>
          </Box>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>
          <Box direction='column' gap='medium' pad={{ horizontal: 'medium' }}>

            {userValuesDataTable?.length
              ? (
                <ResponsiveContext.Consumer>
                  {responsive => (
                    <DataTable
                      columns={responsive === 'small' ? dataColumns.filter(el => el.property !== 'reference') : dataColumns}
                      // columns={dataColumns}
                      data={userValuesDataTable}
                      onSort={setSort}
                      sort={sort}
                      step={1000}
                    />)}
                </ResponsiveContext.Consumer>)
              : <Text margin={{ vertical: 'small' }}>There are no records to display.</Text>}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ServiceUserListMy









// -------------------------



// import React, { useEffect, useState } from 'react'

// import { useHistory } from 'react-router-dom'

// // Grommet
// import { ResponsiveContext } from 'grommet'

// import { capitalize } from '../../utils/text'
// import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// // Spinner
// import PulseLoader from 'react-spinners/PulseLoader'

// // Services
// import LocalStorageService from '../../services/LocalStorageService'
// import NotificationService from '../../services/NotificationService'
// import UserService from '../../services/UserService'
// import UserTagService from '../../services/UserTagService'

// // Appt Components
// import Anchor from '../../components/simple/anchor/Anchor'
// import Box from '../../components/simple/box/Box'
// import Button from '../../components/simple/button/Button'
// import DataTable from '../../components/simple/dataTable/DataTable.js'
// import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
// import Text from '../../components/simple/text/Text'
// import H1 from '../../components/simple/heading/H1'
// import NotificationLayer from '../../components/compound/notification/NotificationLayer'
// import UserStatus from '../shared/User-Status'

// function ServiceUserListMy (props) {
//   const [userValues, setUserValues] = useState([])

//   const [userValuesDataTable, setUserValuesDataTable] = useState([])
//   const history = useHistory()
//   const [userToDelete, setUserToDelete] = useState(null)
//   const [showDeleteOptions, showDelete] = useState(false)
//   const [assignedUsers, setAssignedUsers] = useState({})
//   const [staff, setStaff] = useState({})

//   const [loading, setLoading] = useState(true)

//   // DataTable sort state
//   const [sort, setSort] = useState({
//     property: 'reference',
//     direction: 'asc'
//   })

//   const terms = LocalStorageService.get('terms')
//   const apiToken = LocalStorageService.get('apiToken')
//   const activeOrg = LocalStorageService.get('activeOrg')
//   const activeSite = LocalStorageService.get('activeSite')
//   const currentUser = LocalStorageService.get('userDetails')

//   // Prompt user to delete
//   const showDeleteUserOptions = (user) => {
//     setUserToDelete(user)
//     showDelete(true)
//   }

//   // Delete a User
//   const deleteUser = async () => {
//     // Delete from server
//     const response = await UserService.deleteUser(apiToken, userToDelete, false, {
//       type: 'client'
//     })
//     console.log('deleteUser', userToDelete, response)
//     if (response?.data?.success === 'true') {
//       // And from state to force a refresh
//       const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
//       setUserValues(newUserValues)
//       NotificationService.success('User archived')
//     } else if (response?.error) {
//       NotificationService.error(response.error)
//     }
//     showDelete(false)
//   }

//   // Basically componentDidMount

//   const getAssignedUsers = async () => {

//     setLoading(true)

//     // get the users
//     const params = {
//       fields: 'id,firstName,lastName',
//       limit: 1000,
//       orgId: activeOrg.id,
//       siteId: activeSite?.id || null,
//       type: 'staff',
//       where: {
//         deleted: false
//       }
//     }
//     const users = await UserService.getUsers(apiToken, params)
//     if (users?.error || !users?.data) {
//       return
//     }

//     var mappedUsers = {}
//     for (const user of users.data) {
//       mappedUsers[String(user.id)] = `${user.firstName || ''} ${user.lastName || ''}`
//     }

//     setStaff(mappedUsers)

//     // get the user tags
//     const tagWhere = {
//       type: 'general',
//       organisation: activeOrg.id,
//       site: activeSite.id,
//       key: 'caseworkerAssigned',
//       sensitivity: 'basic'
//     }
//     const tagParams = {
//       fields: 'id,user,key,value',
//       type: 'client'
//     }
//     const userTags = await UserTagService.get(apiToken, tagParams, tagWhere)
//     if (userTags) {
//       var tagsToSave = {}
//       for (const tag of userTags) {
//         if (tag.value && mappedUsers[tag.value]) {
//           tagsToSave[String(tag.user)] = mappedUsers[tag.value]
//         }
//       }
//       setAssignedUsers(tagsToSave)
//     }
//   }

//   useEffect(() => {
//     getAssignedUsers()
//   }, [])

//   useEffect(() => {
//     let unmounted = false

//     // Get Users for this organisation
//     const getUsers = async () => {
//       const params = {
//         fields: 'id,firstName,lastName',
//         limit: 1000,
//         orgId: activeOrg.id,
//         siteId: activeSite?.id || null,
//         type: 'client',
//         where: {
//           deleted: false
//         }
//       }
//       const users = await UserService.getUsers(apiToken, params)
//       if (users?.error) {
//         // NotificationService.error(users.error)
//       } else {
//         if (!unmounted) {
//           if (users?.error) {
//             setUserValues(null)
//           } else if (users?.data) {
//             const mappedUsers = users.data.map((data, index) => ({
//               id: data.id,
//               reference: data.reference,
//               caseworker: '',
//               fullname: `${data.firstName || ''} ${data.lastName || ''}`,
//               name: <Box direction='row'><UserStatus userId={data.id} /><Text>{data.firstName}&nbsp;</Text><Text>{data.lastName}&nbsp;</Text></Box>
//             }))

//             setUserValues(mappedUsers)
//           }
//         }
//       }
//     }

//     if (hasAnyPermissionIn(['clientViewMy', 'clientViewAll'])) {
//       getUsers()
//     }
//     return () => { unmounted = true }
//   }, [assignedUsers])

//   useEffect(() => {
//     var newUserValues = userValues
//     for (const key in newUserValues) {
//       const client = newUserValues[key]

//       if (assignedUsers[client.id]) {
//         newUserValues[key].caseworker = assignedUsers[client.id]
//       }
//     }
//     var actualClients = []
//     for (const client of newUserValues) {
//       if (client.caseworker === staff[currentUser.id]) {
//         actualClients.push(client)
//       }
//     }
//     setUserValuesDataTable(actualClients)
//     setLoading(false)
//   }, [assignedUsers, userValues])



//   // useEffect(() => {
//   //   var newUserValues = []

//   //   for (var client of userValues) {
//   //     if (assignedUsers[client.id] === currentUser.id) {
//   //       client.caseworker = assignedUsers[client.id]
//   //       newUserValues.push(client)
//   //     }
//   //   }
//   //   setUserValues(newUserValues)
//   //   setUserValuesDataTable(newUserValues)
//   //   setLoading(false)
//   // }, [userValues])

//   const dataColumns = [
//     {
//       property: 'id',
//       header: <Text weight={600}>Reference</Text>,
//       primary: true,
//       search: true,
//       render: datum => (
//         <Text>C000{datum.id}</Text>
//       )
//     },
//     {
//       property: 'fullname',
//       header: <Text weight={600}>Name</Text>,
//       // primary: true,
//       render: datum => datum.name,
//       search: true
//     },
//     {
//       property: 'caseworker',
//       header: <Text weight={600}>Case Worker</Text>,
//       render: datum => (
//         <Text>{datum.caseworker}</Text>
//       ),
//       search: true
//     },
//     {
//       property: 'actions',
//       header: <Text weight={600}>Actions</Text>,
//       render: datum => (
//         <Box direction='row'>
//           {hasAnyPermissionIn(['clientEditMy', 'clientEditAll']) &&
//             <Button
//               href={`/client/${datum.id}/edit`}
//               target='_self'
//               plain
//               children={
//                 <Box pad='5px'>
//                   <FontAwesome icon={['fal', 'edit']} color='#fe8300' />
//                 </Box>
//               }
//             />}
//           {hasAnyPermissionIn(['clientArchiveMy', 'clientArchiveAll']) &&
//             <Button
//               target='_self'
//               plain
//               onClick={() => showDeleteUserOptions(datum)}
//               children={
//                 <Box pad='5px'>
//                   <FontAwesome icon={['fal', 'trash']} color='#fe8300' />
//                 </Box>
//               }
//             />}
//         </Box>
//       ),
//       sortable: false
//     }
//   ]

//   return (
//     <>
//       <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
//         <Box direction='column' pad={{ horizontal: 'medium' }}>
//           <H1 margin={{ top: 'small', bottom: 'none' }}>My Clients </H1>
//           {showDeleteOptions &&
//             <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to archive user {userToDelete.name}?</NotificationLayer>}
//           <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
//         </Box>
//       </Box>

//       <Box background='white' elevation='small' direction='column' gap='small' pad='medium' round='small'>
//         <Box direction='column'>
//           <Box alignSelf='end' direction='row-responsive' pad={{ horizontal: 'medium' }}>
//             <Box direction='row-responsive' gap='xsmall'>
//               {hasPermission('clientAccessArchive') &&
//                 <Button href='clients/archive' label={<Text><FontAwesome icon={['fal', 'trash']} /> View Archive</Text>} secondary />}
//               {hasPermission('clientCreate') &&
//                 <Box direction='row-responsive' gap='small'>
//                   <Button href='client/new/edit' label={<Text><FontAwesome icon={['fal', 'plus-circle']} /> Add Client</Text>} target='_self' />
//                 </Box>}
//             </Box>
//           </Box>
//           <Box align='center'>
//             <PulseLoader
//               size={15}
//               color='orange'
//               loading={loading}
//             />
//           </Box>
//           <Box direction='column' gap='medium' pad={{ horizontal: 'medium' }}>

//             {userValues?.length
//               ? (
//                 <ResponsiveContext.Consumer>
//                   {responsive => (
//                     <DataTable
//                       columns={responsive === 'small' ? dataColumns.filter(el => el.property !== 'reference') : dataColumns}
//                       // columns={dataColumns}
//                       data={userValuesDataTable}
//                       onSort={setSort}
//                       sort={sort}
//                       step={1000}
//                     />)}
//                 </ResponsiveContext.Consumer>)
//               : <Text margin={{ vertical: 'small' }}>There are no records to display.</Text>}
//           </Box>
//         </Box>
//       </Box>
//     </>
//   )
// }

// export default ServiceUserListMy
