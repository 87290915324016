import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import Moment from 'react-moment'

import { hasAnyPermissionIn } from '../../utils/permissions'


import AuditTrailService from '../../services/AuditTrailService'
import LocalStorageService from '../../services/LocalStorageService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Grid from '../../components/simple/grid/Grid'
import Text from '../../components/simple/text/Text'

function ServiceUserProfile () {
  const history = useHistory()

  const [userDetails, setUserDetails] = useState({})
  const [auditTrailDetails, setAuditTrailDetails] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const params = useParams()

  const userId = params.userid

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Check if adding a new User or editing an existing one
    const getUserDetails = async (userId) => {
      const params = {
        fields: 'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
        type: 'client'
      }
      const userDetails = await UserService.getUser(apiToken, userId, params)
      if (userDetails.error) {
        // NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          setUserDetails(userDetails.data[0])
          // console.log(userDetails.data)
        }
      }
    }

    const getAuditTrail = async (userId) => {
      const where = {
        subject: userId,
        organisation: activeOrg.id
      }
      const auditTrail = await AuditTrailService.get(apiToken, where)
      if (!auditTrail.error) {
        if (!unmounted) {
          setAuditTrailDetails(auditTrail.data)
        }
      }
    }

    if (userId !== 'new') {
      getUserDetails(userId)
      getAuditTrail(userId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      {/* Breadcrumb section */}
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Client: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New Client'}</Text>
          {/* {error &&
            <FlashMessage message={error} />} */}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {/* Actions section */}
      {userId !== 'new' && hasAnyPermissionIn(['clientEditMy', 'clientEditAll', 'formAccessRiskMatrix', 'formAccessAll']) &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge' weight='400' margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              {hasAnyPermissionIn(['clientEditMy', 'clientEditAll']) &&
                <Button href={`/client/${userId}/edit`} label={<Text><FontAwesomeIcon icon={['fal', 'edit']} /> Edit Profile</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
              }
              {hasAnyPermissionIn(['formAccessRiskMatrix', 'formAccessAll']) &&
                <Button href={`/riskmatrix/${userId}/edit`} label={<Text><FontAwesomeIcon icon={['fal', 'calculator']} /> Risk Matrix</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
              }
            </Box>
          </Box>
        </Box>}

      {/* Main section */}
      <Box background='white' elevation='small' direction='row-responsive' gap='small' gridArea='main' margin={{ bottom: 'medium' }} pad='small' round='small'>
        <Grid
          rows={['auto', 'auto']}
          columns={['auto', 'auto']}
          gap='small'
          areas={[
            { name: 'main', start: [0, 0], end: [1, 1] }
          ]}
        >

          <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box margin={{ bottom: 'medium' }}>
              <Text size='small'>Name</Text>
              <Text size='large' weight='bold'>{userDetails.firstName} {userDetails.lastName}</Text>
            </Box>

            <Box direction='row' margin={{ bottom: 'medium' }}>
              <Box direction='column'>
                <Text size='small'>Reference</Text>
                <Text size='large' weight='bold'>{userDetails.reference || 'None'}</Text>
              </Box>
              <Box direction='column'>
                <Text size='small'>Gender</Text>
                <Text size='large' weight='bold'>{userDetails.gender || 'None'}</Text>
              </Box>
            </Box>
            <Text size='small'>Ethnicity</Text>
            <Text size='large' weight='bold'>{userDetails.ethnicity || 'None'}</Text>
          </Box>

          <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Text>Action Log</Text>
            {auditTrailDetails.length > 0
              ? auditTrailDetails?.slice(0).reverse().map((item) => (
                <Box direction='column' key={item.id}>
                  <Text size='small'><Moment format='MMM Do YYYY-HH:mm'>{item.createdAt}</Moment></Text>
                  <Text size='large' weight='bold'>{item.description}</Text>
                </Box>)
              )
              : <Text>No Action Log details available</Text>}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default ServiceUserProfile
