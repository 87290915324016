import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import RatingButtonGroup from '../../../components/compound/ratingButtonGroup/RatingButtonGroup'
import Text from '../../../components/simple/text/Text'

function Results(props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState()
  const [totalScore, setTotalScore] = useState([])
  const [combinedScore, setCombinedScore] = useState(0)
  const [combinedScoreMax, setCombinedScoreMax] = useState(0)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(props.formDetails)
  }

  useEffect(() => {
    setQuestions(props.questions)
  }, [props.questions, localFormDetails])


  useEffect(() => {
    var score = []
    var thisCombinedScore = 0
    var thisCombinedScoreMax = 0
    if (!questions || !questions.length) return

    // remove the first question
    var tempQuestions = Object.assign([], questions)
    tempQuestions.splice(0, 1)

    // remove the last question
    tempQuestions.splice(questions.length - 1)

    for (const section of tempQuestions) {
      var thisSection = {
        label: section.label,
        numQuestions: section.questions.length,
        score: 0,
        average: 0
      }
      for (const question of section.questions) {
        const thisScore = localFormDetails[question.key] ? parseInt(localFormDetails[question.key]) : 0
        thisSection.score += thisScore
        if (section.label) {
          thisCombinedScore += thisScore
        }
      }
      thisCombinedScoreMax += section.questions.length * 5
      thisSection.average = Math.round(thisSection.score / thisSection.numQuestions, 2)
      score.push(thisSection)
    }

    setCombinedScore(thisCombinedScore)
    setCombinedScoreMax(thisCombinedScoreMax - 20)
    setTotalScore(score)
  }, [questions, localFormDetails])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Summary</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

        {localFormDetails && questions?.length
          ? (
            <>
              <Text>1-None of the time, 2-Rarely, 3-Some of the time, 4-Often, 5-All of the time</Text>
              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Form
                onChange={nextValue => {
                  // setLocalFormDetails(nextValue)
                }}
                onSubmit={({ value: nextValue }) => {
                  submitForm(nextValue)
                }}
                value={localFormDetails}
              >
                {/* independentLiving */}
                {totalScore.map((section, index) => (
                  section.label &&
                    <Box key={index}>
                      <Text weight='bold'>{section.label}: {section.score} / {section.numQuestions * 5}</Text>
                    </Box>
                ))}

                <Box>
                  <Text weight='bold'>TOTAL: {combinedScore} / {combinedScoreMax}</Text>
                </Box>



                <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

                <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                  <Button label='< Back' onClick={() => previousPage()} secondary />
                  <Button onClick={() => props.nextPage(props.formDetails)} label='Next Page' primary />
                </Box>
              </Form>
            </>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Results
