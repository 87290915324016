import React from 'react'
import PropTypes from 'prop-types'

// Appt
import Box from '../../../../components/simple/box/Box'
import FontAwesome from '../../../../components/compound/fontAwesome/FontAwesome'
import Text from '../../../../components/simple/text/Text'

export const Error = ({ children, ...rest }) => (
  <Box
    animation='fadeIn'
    background='status-critical'
    margin={{ bottom: 'medium' }}
    pad='small'
    round='4px'
    width='medium'
  >
    <Box direction='row' gap='xsmall' {...rest}>
      <Box flex={false} margin={{ top: 'hair' }} pad={{ top: 'xxsmall' }}>
        <FontAwesome icon={['fal', 'exclamation-circle']} />
      </Box>
      <Text size='xsmall'>{children}</Text>
    </Box>
  </Box>
)

Error.propTypes = {
  children: PropTypes.string
}
