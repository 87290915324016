import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import RatingButtonGroup from '../../../components/compound/ratingButtonGroup/RatingButtonGroup'
import Text from '../../../components/simple/text/Text'

function MentalWellbeing (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState()
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(localFormDetails)
  }

  useEffect(() => {
    setQuestions(props.questions)
  }, [props.questions])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>{props.title}</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

        {localFormDetails && questions?.length
          ? (
            <>
              <Text margin={{ bottom: 'small' }}>Please go through the questions with the Client and select their answer in the relevant box. Please explain that these are standard questions we ask all our clients to help us provide a better service, but they are free not to answer if they don’t want to. Please  leave  the ‘Score’ column blank, to be filled in by your Supervisor.</Text>
              <Text>1-None of the time, 2-Rarely, 3-Some of the time, 4-Often, 5-All of the time</Text>
              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Form
                onChange={nextValue => {
                  setLocalFormDetails(nextValue)
                }}
                onSubmit={({ value: nextValue }) => {
                  submitForm(nextValue)
                }}
                value={localFormDetails}
              >
                {/* <Box direction='row-responsive' gap='small'> */}
                {/* independentLiving */}
                {questions.map((question, index) => (
                  <Box direction='row-responsive' gap='small' key={index}>
                    <FormField
                      label={question.questionText}
                      name={question.key}
                      width='100%'
                    >
                      <RatingButtonGroup
                        clear='n/a'
                        id={question.key}
                        name={question.key}
                        minRange={1}
                        maxRange={5}
                        step={1}
                      />
                    </FormField>
                  </Box>
                ))}
                {/* <FormField
                    label={questions[0].questionText}
                    name={questions[0].key}
                    width='100%'
                  >
                    <RatingButtonGroup
                      clear='n/a'
                      id={questions[0].key}
                      name={questions[0].key}
                      minRange={1}
                      maxRange={4}
                      step={1}
                    />
                  </FormField>
                </Box> */}

                <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

                <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                  <Button label='< Back' onClick={() => previousPage()} secondary />
                  <Button type='submit' label='Next Page' primary />
                </Box>
              </Form>
            </>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default MentalWellbeing
