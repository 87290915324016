import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import SiteService from '../../services/SiteService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

function SiteList () {
  const [showDeleteOptions, showDelete] = useState(false)
  const [siteToDelete, setSiteToDelete] = useState(null)
  const [siteValues, setSiteValues] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'name',
    direction: 'asc'
  })

  // Prompt user to delete
  const showDeleteSiteOptions = (site) => {
    setSiteToDelete(site)
    showDelete(true)
  }

  // Delete a Site
  const deleteSite = async () => {
    // Delete from server
    const response = await SiteService.delete(apiToken, siteToDelete)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newSiteValues = siteValues.filter((item) => item.id !== siteToDelete.id)
      setSiteValues(newSiteValues)
      NotificationService.info('Site deleted')
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  useEffect(() => {
    let unmounted = false

    // Get sites
    const getSites = async () => {
      const params = {
        fields: 'id,name,address,postcode,city,createdAt,organisation,deleted',
        limit: 1000,
        where: {
          deleted: false
        }
      }

      setLoading(true)
      const sites = await SiteService.getSites(apiToken, params)
      if (sites?.error) {
        // NotificationService.error(sites.error)
      } else {
        if (!unmounted) {
          // if there's no sites, do nothing
          if (!sites || !sites.data) {
            return
          }

          const mappedSites = sites.data.map((data, index) => ({
            id: data.id,
            name: data.name,
            address: data.address,
            city: data.city,
            postcode: data.postcode,
            createdAt: data.createdAt
          }))

          setSiteValues(mappedSites)
        }
      }
    }

    getSites()

    setLoading(false)

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'name',
      header: <Text>Name</Text>,
      primary: true,
      search: true
    },
    {
      property: 'address',
      header: <Text>Address</Text>
    },
    {
      property: 'city',
      header: <Text>City</Text>,
    },
    {
      property: 'postcode',
      header: <Text>Postcode</Text>
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button href={`site/${datum.id}/edit`} label={<Text><FontAwesome color='background' icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
          <Button label={<Text><FontAwesome color='background' icon={['fal', 'trash']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteSiteOptions(datum)} target='_self' />
        </Box>
      )
    }
  ]

  return (
    <>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Manage Sites</H1>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteSite} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete site {siteToDelete.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' round='small'>
        <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
          <Box>
            <Text color='#000' size='large' weight='bold'>Sites</Text>
          </Box>
          <Box direction='row-responsive' gap='xxsmall'>
            <Button href='site/new/edit' label={<Text><FontAwesome color='background' icon={['fal', 'plus-circle']} /> Add Site</Text>} primary target='_self' />
          </Box>
        </Box>

        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', bottom: 'medium' }}>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>
          <DataTable
            columns={dataColumns}
            data={siteValues}
            onSort={setSort}
            paginate
            sort={sort}
            step={10}
          />
          {!siteValues.length && <Text size='small' color='darkGrey'>No sites to display</Text>}
        </Box>
      </Box>
    </>
  )
}

export default SiteList
