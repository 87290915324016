import React, { useEffect, useState } from 'react'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import UserService from '../../../services/UserService'

// Utils
import { constants } from '../../../utils/constants'

// Appt Components
import DateInput from '../../../components/simple/input/DateInput'
import MaskedInput from '../../../components/simple/input/MaskedInput'
import Select from '../../../components/simple/input/Select'
import TextInput from '../../../components/simple/input/TextInput'

const ValueBox = (props) => {
  const [valueField, setValueField] = useState()
  const [autoCompleteValues, setAutoCompleteValues] = useState([])
  const [timeValue, setTimeValue] = useState()

  const apiToken = LocalStorageService.get('apiToken')

  const autoCompleteField = (
    <Select
      {...props}
      labelKey='label'
      options={autoCompleteValues}
      valueKey={{ key: 'value', reduce: true }}
    />)

  const booleanField = (
    <Select
      {...props}
      labelKey='label'
      options={constants.booleanFieldOptions}
      valueKey={{ key: 'value', reduce: true }}
    />)

  const dateField = (
    <DateInput
      format='dd/mm/yyyy'
      name={props.name}
      value={(new Date()).toISOString()}
    />)

  const integerField = (
    <TextInput
      name={props.name}
      type='number'
    />)

  const listField = (
    <Select
      {...props}
      labelKey='label'
      //   onChange={({ value: nextValue }) => {
      //     props.handleFieldChanged(props.name, nextValue)
      //   }}
      options={constants[props.list]}
      valueKey={{ key: 'value' }}
    />)

  const textField = (
    <TextInput
      name={props.name}
      type='text'
    />)

  const timeField = (
    <MaskedInput
      dropProps={{ background: { color: 'white' }, plain: true }}
      mask={[
        {
          length: [1, 2],
          options: Array.from({ length: 12 }, (v, k) => k + 1),
          regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
          placeholder: 'hh'
        },
        { fixed: ':' },
        {
          length: 2,
          options: ['00', '15', '30', '45'],
          regexp: /^[0-5][0-9]$|^[0-9]$/,
          placeholder: 'mm'
        },
        { fixed: ' ' },
        {
          length: 2,
          options: ['am', 'pm'],
          regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
          placeholder: 'ap'
        }
      ]}
      name={props.name}
      // value={value}
      onChange={event => setTimeValue(event.target.value)}
    />)

  // Set Value field based on field datatype
  const setValue = async () => {
    switch (props.type) {
      case 'autocomplete': setValueField(autoCompleteField); break
      case 'boolean': setValueField(booleanField); break
      case 'date': setValueField(dateField); break
      case 'integer': setValueField(integerField); break
      case 'list': setValueField(listField); break
      case 'time': setValueField(timeField); break
      default:
        setValueField(textField)
    }
  }

  useEffect(() => {
    let unmounted = false

    const getUsers = async (userType) => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        type: userType,
        where: {
          deleted: false
        }
      }

      const users = await UserService.getUsers(apiToken, params)

      const mappedUsers = users.data.map(item => (
        {
          label: item.firstName + ' ' + item.lastName,
          value: String(item.id)
        }
      ))
      setAutoCompleteValues(mappedUsers)
    }

    switch (props.fieldType) {
      case 'client': getUsers('client'); break
      case 'subject': getUsers('client'); break
      case 'type': setAutoCompleteValues(constants.formTypes); break
      case 'gender': setAutoCompleteValues(constants.gender); break
      case 'ethnicity': setAutoCompleteValues(constants.ethnicity); break
      case 'pronoun': setAutoCompleteValues(constants.pronoun); break
      case 'primaryLanguage': setAutoCompleteValues(constants.language); break
      case 'language': setAutoCompleteValues(constants.language); break
      case 'traffickingTypes': setAutoCompleteValues(constants.traffickingTypes); break
      case 'clientExploitationType': setAutoCompleteValues(constants.traffickingTypes); break
      case 'literacyLevel': setAutoCompleteValues(constants.literacyLevel); break
      case 'education': setAutoCompleteValues(constants.education); break
      case 'employment': setAutoCompleteValues(constants.employment); break
      case 'housingType': setAutoCompleteValues(constants.housingType); break
      case 'income': setAutoCompleteValues(constants.income); break
      case 'legalInterventions': setAutoCompleteValues(constants.legalInterventions); break
      case 'immigrationStatus': setAutoCompleteValues(constants.immigrationStatus); break
      case 'nrmStatus': setAutoCompleteValues(constants.nrmStatus); break
      case 'countries': setAutoCompleteValues(constants.countries); break
      case 'maritalStatus': setAutoCompleteValues(constants.maritalStatus); break
      case 'supportOrganisation': setAutoCompleteValues(constants.supportOrganisation); break
      case 'housingStatus': setAutoCompleteValues(constants.housingStatus); break
      case 'legalStatus': setAutoCompleteValues(constants.legalStatus); break
      case 'urgentNeeds': setAutoCompleteValues(constants.urgentNeeds); break
      case 'englishStandard': setAutoCompleteValues(constants.englishStandard); break
      case 'incomeDetails': setAutoCompleteValues(constants.incomeDetails); break
      case 'traffickingStatus': setAutoCompleteValues(constants.traffickingStatus); break
      case 'ukStatus': setAutoCompleteValues(constants.ukStatus); break
      case 'interpreterNeeded': setAutoCompleteValues(constants.interpreterNeeded); break
      case 'substanceAbuse': setAutoCompleteValues(constants.substanceAbuse); break
      case 'assignedTo': getUsers('staff'); break
    }

    setValue()

    return () => { unmounted = true }
  }, [props.type, props.list, props.fieldType])

  useEffect(() => {
    setValue()
  }, [autoCompleteValues])
  return (
    <>
      {valueField}
    </>
  )
}

export default ValueBox
