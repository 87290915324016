import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import UserService from '../../../services/UserService'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import CheckBox from '../../../components/simple/input/CheckBox'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import Divider from '../../../components/simple/divider/Divider'
import H2 from '../../../components/simple/heading/H2.js'
import Select from '../../../components/simple/input/Select'
import Text from '../../../components/simple/text/Text.js'
import FeedbackSubmissionButton from '../shared/FeedbackSubmissionButton'

function SubmitPage(props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  useEffect(() => {
    var formDetails = props.formDetails
    if (formDetails?.feedbackRequiredBy) { formDetails.feedbackRequiredBy = parseInt(formDetails.feedbackRequiredBy) }
    setLocalFormDetails(formDetails)
  }, [props.formDetails])

  const [consentChecked, setConsentChecked] = useState(false)

  // Additional staff who can be notified
  const [staffToNotify, setStaffToNotify] = useState([])
  // List of staff with direct access
  const [grantedStaff, setGrantedStaff] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const params = useParams()

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = (status = 'Published', feedbackRequired = false, feedbackRequiredBy, changesRequested = false, feedback, formId) => {
    props.submitForm(localFormDetails, status, feedbackRequired, feedbackRequiredBy, changesRequested, feedback, formId)
  }

  useEffect(() => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      staffNotifications: JSON.stringify(grantedStaff)
    }))
  }, [grantedStaff])

  useEffect(() => {
    let unmounted = false

    // Get all Staff with direct access to this user
    const getDirectAccess = async () => {
      const params = {
        fields: 'id,firstName,lastName,email',
        limit: 1000
      }

      const directAccessGrants = await UserService.getDirectAccessGrants(apiToken, localFormDetails.clientName, params)
      if (directAccessGrants?.data?.length) {
        const grantedStaff = directAccessGrants.data.map((data, index) => ({
          id: data.id,
          name: data.firstName + ' ' + data.lastName
        }))
        setGrantedStaff(grantedStaff)
      }
    }

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'staff',
        where: {
          deleted: false
        }
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setStaffToNotify(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName
            }))

            setStaffToNotify(mappedUsers)

            getDirectAccess()
          }
        }
      }
    }

    getUsers()
    setLoading(false)

    return () => { unmounted = true }
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Submit Form</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <>
              <Box direction='column'>
                <Text weight='bold'>Staff Notifications</Text>
                <Text>The following staff members are assigned to this client and will be automatically notified:</Text>
                {grantedStaff.map((staff, index) => {
                  return (<Box direction='row' key={index} margin='xsmall' width='100%'>
                    <Text size='large'>{staff.name}</Text>
                  </Box>)
                })}
              </Box>

              <Form
                onChange={nextValue => {
                  setLocalFormDetails(nextValue)
                }}
                onSubmit={({ value: nextValue }) => {
                  submitForm(nextValue)
                }}
                value={localFormDetails}
              >
                {/* Additional notification */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                >
                  <Select
                    name={questions[0].key}
                    labelKey='name'
                    options={staffToNotify}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>


                {/* Additional notification */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                >
                  <Select
                    name={questions[3].key}
                    labelKey='name'
                    options={staffToNotify}
                    valueKey={{ key: 'id', reduce: true }}
                    required
                  />
                </FormField>

                <Divider color='primary' margin={{ top: 'medium', bottom: 'medium' }} />

                {/* Consent button */}
                <FormField
                  name={questions[1].key}
                >
                  <CheckBox
                    label={questions[1].questionText}
                    name={questions[1].key}
                    onChange={event => setConsentChecked(event.target.checked)}
                    toggle
                  />
                </FormField>

                <FeedbackSubmissionButton previousPage={() => previousPage()} consentChecked={consentChecked} submitForm={submitForm} type='clientcareplanform' feedbackRequiredBy={localFormDetails.feedbackRequiredBy} clientId={localFormDetails.clientName} />

              </Form>

            </>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default SubmitPage
