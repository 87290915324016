export const userTagKeys = {
  knownAs: {
    sensitivity: 'basic'
  },
  preferredPronoun: {
    sensitivity: 'basic'
  },
  startedSupportDate: {
    sensitivity: 'basic'
  },
  primaryLanguage: {
    sensitivity: 'basic'
  },
  interpreterNeeded: {
    sensitivity: 'basic'
  },
  childcareNeeded: {
    sensitivity: 'basic'
  },
  otherLanguage: {
    sensitivity: 'basic'
  },
  emergencyContactName: {
    sensitivity: 'basic'
  },
  emergencyContactPhone: {
    sensitivity: 'basic'
  },
  otherInterpreterNeeded: {
    sensitivity: 'basic'
  },
  otherChildcareNeeded: {
    sensitivity: 'basic'
  },
  supportOrganisation: {
    sensitivity: 'basic'
  },
  caseworkerAssigned: {
    sensitivity: 'basic'
  },
  counsellorAssigned: {
    sensitivity: 'basic'
  },
  volunteersAssigned: {
    sensitivity: 'basic'
  },
  meetingFrequency: {
    sensitivity: 'basic'
  },
  weeklyActivitiesRegistered: {
    sensitivity: 'basic'
  },
  counsellingReferralDate: {
    sensitivity: 'basic'
  },
  lastUpdated: {
    sensitivity: 'basic'
  },
  stickyNote: {
    sensitivity: 'basic'
  },
  address: {
    sensitivity: 'personal'
  },
  postcode: {
    sensitivity: 'personal'
  },
  city: {
    sensitivity: 'personal'
  },
  mobile: {
    sensitivity: 'personal'
  },
  preferredContactMethod: {
    sensitivity: 'personal'
  },
  clientDob: {
    sensitivity: 'personal'
  },
  healthDiagnosis: {
    sensitivity: 'personal'
  },
  partnerName: {
    sensitivity: 'personal'
  },
  partnerDob: {
    sensitivity: 'personal'
  },
  partnerLivingWithClient: {
    sensitivity: 'personal'
  },
  firstNameChild: {
    sensitivity: 'personal'
  },
  lastNameChild: {
    sensitivity: 'personal'
  },
  dobChild: {
    sensitivity: 'personal'
  },
  livingWithClientChild: {
    sensitivity: 'personal'
  },
  hoReferenceNumber: {
    sensitivity: 'personal'
  },
  immigrationStatus: {
    sensitivity: 'personal'
  },
  nino: {
    sensitivity: 'personal'
  },
  nrmStatus: {
    sensitivity: 'personal'
  },
  countryOfOrigin: {
    sensitivity: 'personal'
  },
  renovationDate: {
    sensitivity: 'personal'
  },
  renovationDescription: {
    sensitivity: 'personal'
  },
  assignedAssessor: {
    sensitivity: 'personal'
  },
  assessmentOutcome: {
    sensitivity: 'personal'
  },
  referringAgency: {
    sensitivity: 'personal'
  },
  suitableForPlacement: {
    sensitivity: 'personal'
  },
  referredDate: {
    sensitivity: 'personal'
  },
  supportEndedDate: {
    sensitivity: 'personal'
  },
  assessmentCompletedDate: {
    sensitivity: 'personal'
  },
  counsellingStartedDate: {
    sensitivity: 'personal'
  },
  counsellingCompletedDate: {
    sensitivity: 'personal'
  },
  attendanceFormCompletedDate: {
    sensitivity: 'personal'
  },
  NRMExitDate: {
    sensitivity: 'personal'
  },
  CGDate: {
    sensitivity: 'personal'
  },
  personalNotes: {
    sensitivity: 'personal'
  },
  // Hidden question to store the array of children
  children: {
    sensitivity: 'personal'
  },
  renovations: {
    sensitivity: 'personal'
  },
  traffickingType: {
    sensitivity: 'sensitive'
  },
  literacyLevel: {
    sensitivity: 'sensitive'
  },
  education1: {
    sensitivity: 'sensitive'
  },
  employment1: {
    sensitivity: 'sensitive'
  },
  housingType1: {
    sensitivity: 'sensitive'
  },
  income2: {
    sensitivity: 'sensitive'
  },
  employment2: {
    sensitivity: 'sensitive'
  },
  housingType2: {
    sensitivity: 'sensitive'
  },
  income: {
    sensitivity: 'sensitive'
  },
  legalInterventions: {
    sensitivity: 'sensitive'
  },
  previousPathways: {
    sensitivity: 'sensitive'
  },
  counsellingReportsCompleted: {
    sensitivity: 'sensitive'
  },
  carePlanLastCompleted: {
    sensitivity: 'sensitive'
  },
  carePlanNextDue: {
    sensitivity: 'sensitive'
  },
  monitoringFormLastCompleted: {
    sensitivity: 'sensitive'
  },
  monitoringFormNextDue: {
    sensitivity: 'sensitive'
  },
  sensitiveNotes: {
    sensitivity: 'sensitive'
  }
}
