import React, { useState, useEffect } from 'react'

// Grommet
import { ResponsiveContext } from 'grommet'

import { constants } from '../../../utils/constants'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import H2 from '../../../components/simple/heading/H2.js'
import RadioButtonGroup from '../../../components/simple/input/RadioButtonGroup'
import Select from '../../../components/simple/input/Select'
import Text from '../../../components/simple/text/Text'
import TextInput from '../../../components/simple/input/TextInput'

function PersonalDetails (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)
  useEffect(() => { setLocalFormDetails(props.formDetails) }, [props.formDetails])

  const [children, setChildren] = useState([])
  const [childAddButton, setChildAddButton] = useState(false)

  const [mappedChildren, setMappedChildren] = useState([])
  const [childrenColumns, setChildrenDataColumns] = useState()

  // Child management functions
  const addChild = () => {
    // Don't save if all fields empty
    if (localFormDetails.firstNameChild !== '' || localFormDetails.lastNameChild !== '' || localFormDetails.dobChild !== '') {
      const child = {
        childKey: children.length,
        firstNameChild: localFormDetails.firstNameChild,
        lastNameChild: localFormDetails.lastNameChild,
        dobChild: localFormDetails.dobChild
      }

      setChildren(children => [...children, child])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        firstNameChild: '',
        lastNameChild: '',
        dobChild: ''
      }))

      setChildAddButton(false)
    }
  }

  const removeChild = (data) => {
    const newChildren = children.filter((item) => item.firstNameChild !== data.firstNameChild && item.lastNameChild !== data.lastNameChild && item.dobChild !== data.dobChild)

    // Renumber the children keys
    newChildren.forEach((children, index) => {
      children.childKey = index
    })

    setChildren(newChildren)
  }

  const editChild = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      firstNameChild: children[data.childKey].firstNameChild,
      lastNameChild: children[data.childKey].lastNameChild,
      dobChild: children[data.childKey].dobChild
      // dobChild: moment(data.dobChild).format('YYYY-DD-MM')
    }))

    setChildAddButton(true)
    removeChild(data)
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  // Children have been altered
  // so rebuild the display list
  useEffect(() => {
    setChildrenDataColumns([
      {
        property: 'firstNameChild',
        header: <Text pad='none' margin='none'>First Name</Text>
      },
      {
        property: 'lastNameChild',
        header: <Text>Last Name</Text>
      },
      {
        property: 'dobChild',
        header: <Text>Date of birth</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeChild(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editChild(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedChildren = children.map((child, index) => ({
      childKey: index,
      firstNameChild: child.firstNameChild,
      lastNameChild: child.lastNameChild,
      dobChild: moment(child.dobChild).format('DD-MM-YYYY')
    }))

    setMappedChildren(mappedChildren)

    // Stringify children and store them
    const childrenToSave = JSON.stringify(children)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      children: childrenToSave
    }))
  }, [children])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Personal Details</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setChildAddButton(nextValue?.firstNameChild !== '' && nextValue?.lastNameChild !== '' && nextValue?.dobChild !== '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              {/* Urgent Needs */}
              <FormField
                label={questions[0].questionText}
                name={questions[0].key}
              >
                <RadioButtonGroup
                  direction='row-responsive'
                  labelKey='label'
                  name={questions[0].key}
                  // onChange={event => {
                  //   //   setToSend(event.value)
                  //   props.setNotifications(event.value)
                  //   console.log('Group1: ', event.value)
                  // }}
                  options={constants.urgentNeeds}
                  valueKey='value'
                />
              </FormField>

              <Divider color='primary' margin={{ top: 'small', bottom: 'medium' }} />

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Surname */}
                      <FormField
                        label={questions[1].questionText}
                        name={questions[1].key}
                        required
                      >
                        <TextInput
                          name={questions[1].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* First name */}
                      <FormField
                        label={questions[2].questionText}
                        name={questions[2].key}
                        required
                      >
                        <TextInput
                          name={questions[2].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Known as */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <TextInput
                          name={questions[3].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* dob */}
                      <FormField
                        label={questions[4].questionText}
                        name={questions[4].key}
                        required
                      >
                        <DateInput
                          name={questions[4].key}
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* address 1 */}
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                        required
                      >
                        <TextInput
                          name={questions[5].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* address 2 */}
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <TextInput
                          name={questions[6].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* phone */}
                      <FormField
                        label={questions[7].questionText}
                        name={questions[7].key}
                      >
                        <TextInput
                          name={questions[7].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* postcode */}
                      <FormField
                        label={questions[8].questionText}
                        name={questions[8].key}
                        required
                      >
                        <TextInput
                          name={questions[8].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* marital status */}
                      <FormField
                        label={questions[9].questionText}
                        name={questions[9].key}
                      >
                        <Select
                          name={questions[9].key}
                          labelKey='label'
                          options={constants.maritalStatus}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* gender */}
                      <FormField
                        label={questions[10].questionText}
                        name={questions[10].key}
                        required
                      >
                        <Select
                          name={questions[10].key}
                          labelKey='label'
                          options={constants.gender}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* emergency conatct name */}
                      <FormField
                        label={questions[11].questionText}
                        name={questions[11].key}
                      >
                        <TextInput
                          name={questions[11].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* emergency contact phone */}
                      <FormField
                        label={questions[12].questionText}
                        name={questions[12].key}
                      >
                        <TextInput
                          name={questions[12].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Children</Text>
              {mappedChildren.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={childrenColumns}
                    data={mappedChildren}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}
              <Box direction='row-responsive' gap='medium'>
                <FormField
                  label={questions[13].questionText}
                  name={questions[13].key}
                >
                  <TextInput
                    name={questions[13].key}
                    type='text'
                  />
                </FormField>

                <FormField
                  label={questions[14].questionText}
                  name={questions[14].key}
                >
                  <TextInput
                    name={questions[14].key}
                    type='text'
                  />
                </FormField>

                <FormField
                  label={questions[15].questionText}
                  name={questions[15].key}
                >
                  <DateInput
                    name={questions[15].key}
                  />
                </FormField>

                <Button disabled={!childAddButton} label={<Text><FontAwesome icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addChild()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Box direction='row-responsive' gap='small'>
                {/* nino */}
                <FormField
                  label={questions[16].questionText}
                  name={questions[16].key}
                >
                  <TextInput
                    name={questions[16].key}
                    type='text'
                  />
                </FormField>

                {/* att */}
                <FormField
                  label={questions[17].questionText}
                  name={questions[17].key}
                >
                  <TextInput
                    name={questions[17].key}
                    type='text'
                  />
                </FormField>

                {/* ho ref */}
                <FormField
                  label={questions[18].questionText}
                  name={questions[18].key}
                >
                  <TextInput
                    name={questions[18].key}
                    type='text'
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* support area */}
                <FormField
                  label={questions[19].questionText}
                  name={questions[19].key}
                  required
                >
                  <Select
                    name={questions[19].key}
                    labelKey='label'
                    options={constants.supportArea}
                    valueKey={{ key: 'value', reduce: true }}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* trafficking decision */}
                <FormField
                  label={questions[20].questionText}
                  name={questions[20].key}
                  required
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name={questions[20].key}
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.nrmStatus}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => props.previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default PersonalDetails
