import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import UserTagService from '../../../services/UserTagService'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Meter from '../../../components/simple/meter/Meter'
import Tabs from '../../../components/simple/tabs/Tabs'
import Text from '../../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

// Child Forms
import ClientFeedback from './ClientFeedback'
import OutcomeMeeting from './OutcomeMeeting'
import SubmitPage from './SubmitPage'
import SummaryReport from './SummaryReport'

function ExitCaseworkForm(props) {
  const history = useHistory();
  const urlParams = useParams();

  const [meterValue, setMeterValue] = useState();

  const [formDetails, setFormDetails] = useState({
    // Case Details
    clientName: '',
    dateReferralReceived: '',
    dateCaseClosed: '',
    caseworker: '',
    supervisor: '',
    // Questions
    understandCaseClosure: '',
    differenceMade: '',
    achievedAtSnowdrop: '',
    goalsToAchieve: '',
    caseworkerDidWell: '',
    caseworkerDoneBetter: '',
    befriendersAssist: '',
    communityActivities: '',
    counsellingService: '',
    renovationTeam: '',
    supportedBetter: '',
    supportSituation: '',
    // publicity yes-no
    anonymousPublicity: '',
    anonymousCaseStudy: '',
    mediaStory: '',
    publicityMaterials: '',
    // newsletter
    alumniNewsletter: '',
    recieveNewsletter: '',
    detailsNewsletter: '',

    // Submit Page
    clientNameSignature: '',
    clientNameSignatureSign: '',
    clientNameSignatureSignDate: '',
    supervisorSignature: '',
    supervisorSignatureSign: '',
    supervisorSignatureSignDate: '',
  });

  const apiToken = LocalStorageService.get('apiToken');
  const activeOrganisation = LocalStorageService.get('activeOrg');
  const activeSite = LocalStorageService.get('activeSite');
  const activeUser = LocalStorageService.get('userDetails');

  const clientId = props.location?.clientid || null;

  const submitForm = async (
    formDetailsToSave,
    status,
    feedbackRequired = false,
    feedbackRequiredBy,
    changesRequested,
    feedback,
    formId,
  ) => {
    setFormDetails(formDetailsToSave);

    var submitArray = [];
    var item = {
      key: '',
      questionText: '',
      answer: '',
    };

    // Loop through each form value
    for (var key in formDetailsToSave) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(
          question => question.key === key,
        );

        if (found && checkInputHasContent(formDetailsToSave[key])) {
          let response = null;

          response = formDetailsToSave[key];

          item = {
            questionKey: key,
            questionText: found.questionText,
            response: response,
            responseType: found?.responseType || 'string',
          };
          submitArray.push(item);
        }
      });
    }

    // Build output data
    var data = {
      user: activeUser.id,
      organisation: activeOrganisation.id,
      status: status,
      type: 'exitcasework',
      reference: '',
      responses: submitArray,
    };

    if (clientId) {
      data.subject = clientId;
    }

    data.feedbackRequired = feedbackRequired;
    data.feedbackRequiredBy = feedbackRequiredBy;
    data.revisionRequired = changesRequested;
    data.feedbackNotes = feedback;

    // Only submit if there is data
    if (submitArray.length !== 0) {
      // Stringify the answers and add that also
      const stringifiedAnswers = JSON.stringify(submitArray);

      submitArray.push({
        questionKey: 'exitFormAnswers',
        questionText: 'Exit Form Answers',
        response: stringifiedAnswers,
        responseType: 'string',
      });
      if (clientId) {
        submitArray.push({
          questionKey: 'clientName',
          questionText: 'Who is the client',
          response: clientId,
          responseType: 'integer',
        });
      }

      var submissionResponse;
      if (urlParams.formId !== 'new') {
        delete data.user;
        // delete data.subject
        delete data.organisation;
        submissionResponse = await FormService.updateSubmission(
          apiToken,
          urlParams.formId,
          data,
        );
      } else {
        submissionResponse = await FormService.makeSubmissions(apiToken, data);
      }

      let theSubjectID = clientId ? clientId : data.subject
      
      // Set the exited user tags
      if (data.status === 'Published' && theSubjectID) {
        await UserTagService.set(apiToken, [
          {
            user: theSubjectID,
            organisation: activeOrganisation.id,
            key: 'supportEndedDate',
            value: new Date(),
            type: 'general',
            sensitivity: 'personal',
          },
        ]);
      }

      if (submissionResponse.error) {
        NotificationService.error(submissionResponse.error);
      } else {
        if (status === 'Published' || feedbackRequired) {
          NotificationService.success('Form Submitted');
          if (clientId) {
            history.push(`/client/${clientId}/edit`);
          } else {
            history.push('/dashboard');
          }
        } else {
          nextPage();
        }
      }
    } else {
      // If we're on the last page stay there
      if (tabIndex !== tabContent.length - 1) {
        nextPage();
      }
    }
  };

  // Check if the passed in input field has any value
  const checkInputHasContent = input => {
    let inputHasContent = false;

    switch (typeof input) {
      case 'string':
        inputHasContent = input.length > 0;
        break;
      case 'number':
        inputHasContent = input > 0;
        break;
      case 'boolean':
        break;
      case 'object':
        inputHasContent = true;
        // inputHasContent = input.length > 0
        break;
    }

    return inputHasContent;
  };

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0);
  const onActiveTab = nextIndex => {
    setMeterValue(((nextIndex + 1) * 100) / tabContent.length);
    setTabIndex(nextIndex);
  };

  // Go Back
  const goBack = () => {
    history.goBack();
  };

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack();
    } else {
      const prevPageIndex = tabIndex - 1;
      onActiveTab(prevPageIndex);
    }
  };

  const nextPage = formDetailsToSave => {
    if (formDetailsToSave) {
      setFormDetails(formDetailsToSave);
    }

    if (tabIndex === tabContent.length - 1) {
      // Don't think we ever get here
      submitForm();
    } else {
      const nextPageIndex = tabIndex + 1;
      onActiveTab(nextPageIndex);
    }
  };

  const [questions] = useState([
    // Summary Report
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Name of Client',
          key: 'clientName',
        },
        {
          questionText: 'Support Start',
          key: 'dateSupportStart',
        },
        {
          questionText: 'Support End',
          key: 'dateSupportEnd',
        },
        {
          questionText: 'Final Caseworker Name',
          key: 'caseworker',
        },
        {
          questionText: 'Supervisor Name',
          key: 'supervisor',
        },
        {
          questionText: 'Other CW/Vols Involved',
          key: 'otherPeopleInvolved',
        },
        {
          questionText: 'Summary of Work with Client',
          key: 'summaryOfWork',
        },
        {
          questionText: 'Outstanding Actions',
          key: 'outstandingActions',
        },
        {
          questionText: 'Reason for Closure',
          key: 'reasonForClosure',
        },
        {
          questionText: 'What have you learned from this case',
          key: 'whatHaveYouLearned',
        },
        {
          questionText: 'Any Signposting Details',
          key: 'signpostingDetails',
        },
        {
          questionText: 'Other Notes',
          key: 'otherNotes',
        },
      ],
    },
    // Outcome Meeting
    {
      tabId: 1,
      questions: [
        {
          questionText:
            'Did they receive Counselling while supported by Snowdrop?',
          key: 'counsellingReceived',
        },
        {
          questionText: 'Date (if known)',
          key: 'counsellingReceivedDate',
        },
        {
          questionText: 'Have they gone into employment or work experience?',
          key: 'goneIntoEmployment',
        },
        {
          questionText: 'Date (if known)',
          key: 'goneIntoEmploymentDate',
        },
        {
          questionText: 'Have they gone onto college or other education?',
          key: 'goneIntoCollege',
        },
        {
          questionText: 'Date (if known)',
          key: 'goneIntoCollegeDate',
        },
        {
          questionText:
            'Have they taken part in ESOL or other English classes?',
          key: 'takenPartInEsol',
        },
        {
          questionText: 'Date (if known)',
          key: 'takenPartInEsolDate',
        },
        {
          questionText: 'Are they in permanent housing?',
          key: 'inPermanentHousing',
        },
        {
          questionText: 'Date (if known)',
          key: 'inPermanentHousingDate',
        },
        {
          questionText:
            'If they are in permanent housing have they been able to manage finances?',
          key: 'managingFinance',
        },
        {
          questionText: 'Date (if known)',
          key: 'managingFinanceDate',
        },
        {
          questionText: 'Did they have Neg CG',
          key: 'haveNegCg',
        },
        {
          questionText: 'Date (if known)',
          key: 'haveNegCgDate',
        },
        {
          questionText: 'If they have a negative CG has it been overturned?',
          key: 'negCgOverturned',
        },
        {
          questionText: 'Date (if known)',
          key: 'negCgOverturnedDate',
        },
        {
          questionText: 'Have they received DL?',
          key: 'receivedDl',
        },
        {
          questionText: 'Date (if known)',
          key: 'receivedDlDate',
        },
        {
          questionText: 'Have they received Leave to Remain?',
          key: 'receivedLeaveToRemain',
        },
        {
          questionText: 'Date (if known)',
          key: 'receivedLeaveToRemainDate',
        },
        {
          questionText: 'Have they received compensation?',
          key: 'receivedCompensation',
        },
        {
          questionText: 'Date (if known)',
          key: 'receivedCompensationDate',
        },
        {
          questionText: 'If they have received compensation how much?',
          key: 'compensationAmount',
        },
        {
          questionText: 'Date (if known)',
          key: 'compensationAmountDate',
        },
        {
          questionText:
            'Have they engaged with casework? (met with caseworker at least once per month over at least 6 months, responded to phone calls etc)',
          key: 'engagedWithCasework',
        },
        {
          questionText: 'Date (if known)',
          key: 'engagedWithCaseworkDate',
        },
        {
          questionText: 'Have they engaged with Counselling?',
          key: 'engagedWithCounselling',
        },
        {
          questionText: 'Date (if known)',
          key: 'engagedWithCounsellingDate',
        },
        {
          questionText: 'Are they a single mum?',
          key: 'singleMum',
        },
        {
          questionText: 'Date (if known)',
          key: 'singleMumDate',
        },
        {
          questionText: 'Have they attended Toddler group?',
          key: 'attendedToddleGroup',
        },
        {
          questionText: 'Date (if known)',
          key: 'attendedToddleGroupDate',
        },
        {
          questionText: 'Have they attended sewing classes?',
          key: 'attendedSewingClase',
        },
        {
          questionText: 'Date (if known)',
          key: 'attendedSewingClaseDate',
        },
        {
          questionText: 'Have they attended Dance classes?',
          key: 'attendedDanceClass',
        },
        {
          questionText: 'Date (if known)',
          key: 'attendedDanceClassDate',
        },
        {
          questionText: 'Have they attended Snowdrop English?',
          key: 'attendedSnowdropEnglish',
        },
        {
          questionText: 'Date (if known)',
          key: 'attendedSnowdropEnglishDate',
        },
        {
          questionText:
            'Have they been involved in a police investigation whilst under our support?',
          key: 'involvedInPoliceInvestigate',
        },
        {
          questionText: 'Date (if known)',
          key: 'involvedInPoliceInvestigateDate',
        },
        {
          questionText: 'Received support to: Access solicitor',
          key: 'supportToAccessSolicitor',
        },
        {
          questionText: 'Date (if known)',
          key: 'supportToAccessSolicitorDate',
        },
        {
          questionText: 'Received support to: Access DWP Benefits',
          key: 'supportToAccessDwpBenefits',
        },
        {
          questionText: 'Date (if known)',
          key: 'supportToAccessDwpBenefitsDate',
        },
        {
          questionText: 'Received support to: access health appointments',
          key: 'supportToAccessHealthAppointments',
        },
        {
          questionText: 'Date (if known)',
          key: 'supportToAccessHealthAppointmentsDate',
        },
        {
          questionText: 'Received support to: access mental health care',
          key: 'supportToAccessMentalHealthCare',
        },
        {
          questionText: 'Date (if known)',
          key: 'supportToAccessMentalHealthCareDate',
        },
        {
          questionText:
            'Signposted to other support agencies outside Sheffield',
          key: 'signpostedToOtherAgencies',
        },
        {
          questionText: 'Date (if known)',
          key: 'signpostedToOtherAgenciesDate',
        },
      ],
    },
    // Client Feedback and Consent
    {
      tabId: 2,
      questions: [
        {
          questionText:
            'What do you feel like you have achieved during your time at Snowdrop?',
          key: 'whatHaveYouAchieved',
        },
        {
          questionText:
            'Is there anything Snowdrop could have supported you better with?',
          key: 'snowdropNegativeFeedback',
        },
        {
          questionText: 'Is there anything that your caseworker did well?',
          key: 'caseworkerPositiveFeedback',
        },
        {
          questionText:
            'Is there anything that your caseworker could have done better?',
          key: 'caseworkerNegativeFeedback',
        },
        {
          questionText:
            'Did you get support from a befriender? Do you have any feedback about this?',
          key: 'befrienderFeedback',
        },
        {
          questionText:
            'Did you attend any community activities? If so, which ones and do you have any feedback about them?',
          key: 'activitiesFeedback',
        },
        {
          questionText:
            'Did you have a house renovation? If so please comment on how this went.',
          key: 'houseRenovationFeedback',
        },
        {
          questionText:
            'Do you have any other feedback about your time with Snowdrop support?',
          key: 'snowdropSupportFeedback',
        },
        {
          questionText: 'What are your plans for the future?',
          key: 'futurePlans',
        },
        {
          questionText:
            'Are you happy for the feedback to be (anonymously) used by Snowdrop to publicise our work in print or on the website or on social media?',
          key: 'consentFeedbackPrint',
        },
        {
          questionText:
            'Would you be happy for us to use your story as an anonymous case study on our website or in publicity materials?  (We would make sure you could not be identified)',
          key: 'consentStory',
        },
        {
          questionText:
            'Would you be happy to be contacted in future if we have any opportunities for you to speak to the Media about your story?',
          key: 'consentFutureMedia',
        },
        {
          questionText: `"Would you be happy for us to use your story as an anonymous case study on our website or in publicity materials?" -  saying 'If yes, would you like to choose a pseudonym for yourself ?'`,
          key: 'consentCaseStudy',
        },
      ],
    },
    // Submit Form
    {
      tabId: 4,
      questions: [
        {
          questionText:
            'If you would like to notify an additional staff member, please select them below.',
          key: 'additionalNotification',
        },
        {
          questionText:
            'I confirm that I have the consent of the client to submit this application',
          key: 'clientConsent',
        },
        // Hidden field to hold the automatic notifications
        {
          questionText: 'Staff Notifications',
          key: 'staffNotifications',
        },
        {
          questionText: 'Who needs to approve this form',
          key: 'feedbackRequiredBy',
        },
      ],
    },
  ]);

  const tabContent = [
    {
      // Summary Report
      name: 'Page 1',
      hideTitle: true,
      content: (
        <SummaryReport
          clientId={clientId}
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          questions={questions[0].questions}
        />
      ),
    },
    {
      // Outcome Meeting
      name: 'Page 2',
      hideTitle: true,
      content: (
        <OutcomeMeeting
          title="Questions"
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          questions={questions[1].questions}
        />
      ),
    },
    {
      // Client Feedback and Consent
      name: 'Page 3',
      hideTitle: true,
      content: (
        <ClientFeedback
          title="Alumni"
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          questions={questions[2].questions}
        />
      ),
    },
    {
      // name: 'Submit Form',
      name: 'Page 4',
      hideTitle: true,
      content: (
        <SubmitPage
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          formDetails={formDetails}
          setFormDetails={setFormDetails}
          questions={questions[3].questions}
        />
      ),
    },
  ];

  const getResponses = async formId => {
    const params = {
      fields: 'id,questionKey,questionText,response',
      limit: 2000,
    };
    const where = {
      submission: formId,
    };

    const responses = await FormService.getResponses(apiToken, params, where);

    const savedResponses = {};

    responses.data.forEach(element => {
      let thisValue = element.response;

      const integerFields = ['clientName'];
      if (integerFields.includes(element.questionKey)) {
        thisValue = parseInt(element.response);
      }
      savedResponses[element.questionKey] = thisValue;
    });
    setFormDetails(previousValues => ({
      ...previousValues,
      ...savedResponses,
    }));
  };
  const [feedbackNotes, setFeedbackNotes] = useState();
  const getNotesFromThisForm = async () => {
    const params = {
      fields:
        'id,user,organisation,reference,type,status,createdAt,feedbackNotes,revisionRequired',
      limit: 1,
      sort: 'createdAt DESC',
      orgId: activeOrganisation.id,
      siteId: activeSite.id,
    };
    const where = {
      organisationId: activeOrganisation.id,
      // subject: clientId,
      // user: activeUser.id,
      type: 'exitcasework',
      id: urlParams.formId,
    };
    console.log('my search', params, where);
    const submissions = await FormService.getSubmissions(
      apiToken,
      params,
      where,
    );
    if (submissions?.data?.length && submissions.data[0]?.feedbackNotes) {
      setFeedbackNotes(submissions.data[0]?.feedbackNotes);
    }
  };

  useEffect(() => {
    (async () => {
      if (['view', 'feedback'].includes(urlParams.viewtype)) {
        getResponses(urlParams.formId);
        getNotesFromThisForm();
      }
    })();
  }, []);

  return (
    <Box width="xlarge">
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">Snowdrop CRM</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        Exit Form (Casework)
      </BreadcrumbBar>

      {feedbackNotes && (
        <Box
          background="stickyNote"
          direction="column"
          gap="small"
          margin={{ bottom: 'medium' }}
          round="small"
          elevation="small"
        >
          <Box
            direction="row-responsive"
            justify="between"
            pad={{ horizontal: 'medium', vertical: 'small' }}
          >
            <Box>
              <Text weight="bold">Feedback</Text>
              <Text>{feedbackNotes}</Text>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        flex="grow"
        gridArea="main"
        background="white"
        direction="column"
        gap="small"
        round="small"
      >
        <Box
          gap="small"
          margin={{ horizontal: 'small' }}
          pad="small"
          round="small"
        >
          <Meter
            background="light-2"
            size="full"
            thickness="xsmall"
            type="bar"
            values={[{ color: 'brand', value: meterValue }]}
          />

          <Tabs
            alignControls="start"
            activeIndex={tabIndex}
            content={tabContent}
            onActive={onActiveTab}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ExitCaseworkForm
