import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Responsive from '../../utils/Responsive'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Text from '../../components/simple/text/Text.js'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function PartnerDetails (props) {
  const [loading, setLoading] = useState(false)
  const [localOrgDetails, setLocalOrgDetails] = useState(props.orgDetails)
  const [questions, setQuestions] = useState(props.questions)

  const urlParams = useParams()

  const orgId = urlParams.orgid

  // Submit Partner Org Details
  const submitOrgDetails = () => {
    // console.log(localOrgDetails)
    props.submitForm(localOrgDetails)
  }

  // Set flag that form has changed
  const setFormChanged = () => {
    props.setFormChanged(true)
  }

  // Need to include this as it takes time for
  // props.userDetails to get populated
  useEffect(() => {
    setLocalOrgDetails(props.orgDetails)
  }, [props.orgDetails])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        {/* <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Basic</H2>
          <Button label={<FontAwesomeIcon icon={['fal', 'question-circle']} />} plain tip='Basic Client Details' />
        </Box> */}
        {orgId && orgId === 'new' &&
          <Box fill direction='row' gap='small'>
            <Text color='secondary'>Enter a name and save to unlock all fields.</Text>
          </Box>}

        {localOrgDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setFormChanged(true)
                setLocalOrgDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitOrgDetails(nextValue)
              }}
              // validate='blur'
              value={localOrgDetails}
            >

              <Responsive
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['2/3', '1/3'],
                  large: ['2/3', '1/3'],
                  xlarge: ['2/3', '1/3']
                }}
                gap='small'
              >
                <Box direction='column'>
                  <Box>
                    {/* Organisation Name */}
                    <Text>Name</Text>
                    <FormField
                      label={questions[0].questionText}
                      name={questions[0].key}
                    >
                      <TextInput
                        name={questions[0].key}
                      />
                    </FormField>
                  </Box>

                  <Box direction='row-responsive' gap='small'>
                    <Box>
                      {/* Website */}
                      <FormField
                        label={questions[7].questionText}
                        name={questions[7].key}
                      >
                        <TextInput
                          name={questions[7].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Region */}
                      <FormField
                        label={questions[8].questionText}
                        name={questions[8].key}
                      >
                        <TextInput
                          name={questions[8].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* OrgType */}
                      <FormField
                        label={questions[9].questionText}
                        name={questions[9].key}
                      >
                        <TextInput
                          name={questions[9].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Text>Address</Text>
                  <Box direction='row-responsive' gap='small'>
                    <Box>
                      {/* Address */}
                      <FormField
                        label={questions[1].questionText}
                        name={questions[1].key}
                      >
                        <TextInput
                          name={questions[1].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Postcode */}
                      <FormField
                        label={questions[2].questionText}
                        name={questions[2].key}
                      >
                        <TextInput
                          name={questions[2].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* City */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <TextInput
                          name={questions[3].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Box>
                    {/* Other Notes */}
                    <FormField
                      label={questions[4].questionText}
                      name={questions[4].key}
                    >
                      <TextArea
                        name={questions[4].key}
                        disabled={orgId === 'new'}
                      />
                    </FormField>
                  </Box>
                </Box>

                <Box direction='column'>
                  <Text>General Contact Information</Text>
                  <Box direction='row-responsive' gap='small'>
                    <Box>
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                      >
                        <TextInput
                          name={questions[5].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <TextInput
                          name={questions[6].key}
                          type='text'
                          disabled={orgId === 'new'}
                        />
                      </FormField>
                    </Box>
                  </Box>
                </Box>
              </Responsive>

              {/* <Responsive
                rows={['auto']}
                columns={{
                    small: ['auto'],
                    medium: ['auto', 'auto', 'auto', 'auto'],
                    large: ['auto', 'auto', 'auto', 'auto'],
                    xlarge: ['auto', 'auto', 'auto', 'auto']
                }}
                gap='small'
                >
                <Box>
                    <FormField
                    label={questions[4].questionText}
                    name={questions[4].key}
                    >
                    <DateInput
                        name={questions[4].key}
                        disabled={orgId === 'new'}
                    />
                    </FormField>
                </Box>
                <Box>
                    <FormField
                    label={questions[5].questionText}
                    name={questions[5].key}
                    >
                    <Select
                        name={questions[5].key}
                        emptySearchMessage='No genders found'
                        disabled={orgId === 'new'}
                        // onChange={
                        //   (event, option) => { setAcademicYear(event.value) }
                        // }
                        labelKey='label'
                        options={constants.gender}
                        valueKey={{ key: 'value', reduce: true }}
                    />
                    </FormField>
                </Box>
                <Box>
                    <FormField
                    label={questions[6].questionText}
                    name={questions[6].key}
                    >
                    <TextInput
                        name={questions[6].key}
                        disabled={orgId === 'new'}
                        type='text'
                        placeholder='e.g. they/them'
                    />
                    </FormField>
                </Box>
                </Box>
              </Responsive> */}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => props.previousPage()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}

export default PartnerDetails
