
import ApiService from './ApiService'

class OrganisationService {
  addOrganisation = async (apiToken, data) => {
    var url = '/api/organisation'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:addOrganisation ', result)
    return result
  }

  // myOrganisations = async (apiToken) => {
  //   var url = '/api/my/organisations'

  //   var params = {
  //     fields: 'id,name,primaryColour,secondaryColour,logoUrl,createdAt',
  //     limit: 1000
  //   }

  //   try {
  //     var organisations = await ApiService.get(apiToken, url, params)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   console.log('OrganisationService:myOrganisations ', organisations)
  //   return organisations
  // }

  getOrganisations = async (apiToken, params) => {
    var url = '/api/organisation'

    try {
      var organisations = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getOrganisations ', organisations)
    return organisations
  }

  getOrganisation = async (apiToken, customParams = {}, organisationId) => {
    var url = `/api/organisation/${organisationId}`

    var params = {
      fields: 'id,name,primaryColour,secondaryColour,logoUrl,createdAt',
      limit: 1,
      ...customParams
    }

    try {
      var organisation = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getOrganisation ', organisation)
    return organisation
  }

  updateOrganisation = async (apiToken, organisationId, data) => {

    if (!organisationId) {
      throw Error('No organisationId supplied')
    }

    var url = `/api/organisation/${organisationId}`

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:updateOrganisation ', result)
    return result
  }

  setOrganisationAdmin = async (apiToken, organisationId, data) => {

    if (!organisationId) {
      throw Error('No organisationId supplied')
    }

    var url = `/api/organisation/${organisationId}/admin`

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:updateOrganisation ', result)
    return result
  }

  getUserCount = async (apiToken, type = 'staff') => {
    var url = `/api/user`

    var params = {
      fields: 'id,email,firstName,lastName,type',
      type,
      where: {
        deleted: false
      }
    }

    try {
      var users = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    return users?.page?.total || 0
  }

  getRolesForOrg = async (apiToken, organisationId) => {
    var url = `/api/organisation/${organisationId}/roles`

    var params = {
      fields: 'id,title,permissions'
    }

    try {
      var roles = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getRolesForOrg ', roles)
    return roles
  }

  deleteOrg = async (apiToken, orgId) => {
    var url = `/api/organisation/${orgId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:deleteOrg ', result)
    return result
  }
}
export default new OrganisationService()
