// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { CheckBoxGroup as GCheckBoxGroup } from 'grommet'

/**
 * `CheckBoxGroup` renders a Grommet CheckBoxGroup control
 */
class CheckBoxGroup extends React.Component {
  render () {
    return (
      <GCheckBoxGroup
        {...this.props}
      />)
  }
}

CheckBoxGroup.propTypes = {
  /**
   * Disables all options  
   *   
   * true   
   * **false**
   */
  disabled: PropTypes.bool,
  /**
   * When the options array contains objects, this property indicates how to determine the label of each option.  
   * If a string is provided, it is used as the key to retrieve each option's label.  
   *   
   * "string"
   */
  labelKey: PropTypes.string,
  /**
   * Required when used in the Context of Form and FormField.  
   *   
   * "string"
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user clicks on a CheckBox option.  
   * It will pass a React event object with the additional CheckBoxGroup properties of 'option' and 'value'.  
   *  
   * ({ value, option }) => {...}
   */
  onChange: PropTypes.func,
  /**
   * Options can be either a string, boolean, number or an object of CheckBox props excluding the 'checked' property, use CheckBoxGroup 'value' prop instead of 'checked'.  
   *   
   * ["string"]  
   * {  
   * &nbsp;""  
   * }
   */
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  /**
   * An array of the values for the checked options.  
   * If options is provided as an object, the value array will be the values that the valueKey maps to.    
   *   
   * [  
   * &nbsp;"string"  
   * &nbsp;number  
   * ]
   */
  value: PropTypes.array,
  /**
   * When the options array contains objects, this property indicates how to determine the value of each option.  
   * If a string is provided, it is used as the key to retrieve each option's value.  
   *   
   * "string"
   */
  valueKey: PropTypes.string
}

export default CheckBoxGroup
