export const constants = {
  requestType: {
    json: 'JSON',
    formData: 'FORMDATA'
  },
  responseType: {
    json: 'JSON',
    blob: 'BLOB',
    blobText: 'BLOBTEXT',
    formData: 'FORMDATA'
  },
  dateRanges: [
    {
      label: 'Custom',
      value: 'custom'
    },
    {
      label: 'Today',
      value: 'today'
    },
    {
      label: 'Yesterday',
      value: 'yesterday'
    },
    {
      label: 'This Week',
      value: 'thisweek'
    },
    {
      label: 'Last Week',
      value: 'lastweek'
    },
    {
      label: 'This Month',
      value: 'thismonth'
    },
    {
      label: 'Last Month',
      value: 'lastmonth'
    },
    {
      label: 'This Year',
      value: 'thisyear'
    },
    {
      label: 'Last Year',
      value: 'lastyear'
    },
    {
      label: 'All Time',
      value: 'alltime'
    }
  ],
  autoCompleteQueries: [
    {
      label: 'is',
      value: '='
    },
    {
      label: 'is not',
      value: '!='
    }
  ],

  booleanQueries: [
    {
      label: 'is',
      value: '='
    },
    {
      label: 'is not',
      value: '!='
    }
  ],
  dateQueries: [
    {
      label: 'on',
      value: '='
    },
    {
      label: 'after',
      value: '>'
    },
    {
      label: 'before',
      value: '<'
    }
  ],
  integerQueries: [
    {
      label: 'equal to',
      value: 'equal to'
    },
    {
      label: 'not equal to',
      value: 'not equal to'
    },
    {
      label: 'greater than',
      value: 'greater than'
    },
    {
      label: 'less than',
      value: 'less than'
    },
    {
      label: 'greater than or equal',
      value: 'greater than or equal'
    },
    {
      label: 'less than or equal',
      value: 'less than or equal'
    }
  ],

  listQueries: [
    {
      label: 'is',
      value: '='
    },
    {
      label: 'is not',
      value: '!='
    }
  ],
  timeQueries: [
    {
      label: 'on',
      value: 'on'
    },
    {
      label: 'after',
      value: 'after'
    },
    {
      label: 'before',
      value: 'before'
    }
  ],
  modelTypes: [
    {
      label: 'Client',
      value: 'client'
    },
    {
      label: 'Staff',
      value: 'staff'
    },
    {
      label: 'Partner Contact',
      value: 'partnerContact'
    },
    {
      label: 'Note',
      value: 'note'
    },
    {
      label: 'Document',
      value: 'document'
    },
    {
      label: 'Form',
      value: 'formSubmission'
    }
  ],
  formSubmission: [
    {
      label: 'Client',
      value: 'subject',
      type: 'autocomplete'
    },
    {
      label: 'Type',
      value: 'type',
      type: 'autocomplete'
    },
    {
      label: 'Date Submitted',
      value: 'createdAt',
      type: 'date'
    }
  ],
  formTypes: [
    {
      label: 'Client Care Plan',
      value: 'clientcareplanform'
    },
    {
      label: 'Monitoring Form',
      value: 'monitoringform'
    },
    {
      label: 'Exit (Casework)',
      value: 'exitcasework'
    },
    {
      label: 'Exit (Counselling)',
      value: 'exitcounselling'
    },
    {
      label: 'Risk Matrix',
      value: 'riskmatrix'
    },
    {
      label: 'Casework Referral',
      value: 'caseworkform'
    },
    {
      label: 'Counselling Referral',
      value: 'counsellingreferral'
    }
  ],
  client: [
    {
      label: 'First Name',
      value: 'firstName',
      type: 'text'
    },
    {
      label: 'Last Name',
      value: 'lastName',
      type: 'text'
    },
    {
      label: 'Email',
      value: 'email',
      type: 'text'
    },
    {
      label: 'Gender',
      value: 'gender',
      type: 'autocomplete'
    },
    {
      label: 'Support Organisation',
      value: 'supportOrganisation',
      type: 'autocomplete'
    },
    {
      label: 'CG Date',
      value: 'CGDate',
      type: 'date'
    },
    {
      label: 'NRM Exit Date',
      value: 'NRMExitDate',
      type: 'date'
    },
    {
      label: 'Childcare Needed',
      value: 'childcareNeeded',
      type: 'boolean'
    },
    {
      label: 'City',
      value: 'city',
      type: 'text'
    },
    {
      label: 'Exploitation Type',
      value: 'clientExploitationType',
      type: 'autocomplete'
    },
    {
      label: 'Immigration Status',
      value: 'immigrationStatus',
      type: 'autocomplete'
    },
    {
      label: 'Interpreter Needed',
      value: 'interpreterNeeded',
      type: 'boolean'
    },
    {
      label: 'Known As',
      value: 'knownAs',
      type: 'text'
    },
    {
      label: 'Literacy Level',
      value: 'literacyLevel',
      type: 'autocomplete'
    },
    {
      label: 'Mobile',
      value: 'mobile',
      type: 'text'
    },
    {
      label: 'NINO',
      value: 'nino',
      type: 'text'
    },
    {
      label: 'NRM Status',
      value: 'nrmStatus',
      type: 'autocomplete'
    },
    {
      label: 'Postcode',
      value: 'postcode',
      type: 'text'
    },
    {
      label: 'Primary Language',
      value: 'primaryLanguage',
      type: 'autocomplete'
    }
  ],
  staff: [
    {
      label: 'FirstName',
      value: 'firstName',
      type: 'text'
    },
    {
      label: 'LastName',
      value: 'lastName',
      type: 'text'
    },
    {
      label: 'Email',
      value: 'email',
      type: 'text'
    }
  ],

  partnerContact: [
    {
      label: 'FirstName',
      value: 'firstName',
      type: 'text'
    },
    {
      label: 'LastName',
      value: 'lastName',
      type: 'text'
    },
    {
      label: 'Email',
      value: 'email',
      type: 'text'
    }
  ],

  note: [
    {
      label: 'Type',
      value: 'subType',
      type: 'text',
      // list: 'noteTypes'
    },
    {
      label: 'Title',
      value: 'title',
      type: 'contains'
    },
    {
      label: 'Description',
      value: 'description',
      type: 'contains'
    },
    // {
    //   label: 'AssignedTo',
    //   value: 'assignedTo',
    //   type: 'autocomplete'
    // }
  ],
  document: [
    {
      label: 'Type',
      value: 'subType',
      type: 'text',
      // list: 'noteTypes'
    },
    {
      label: 'Title',
      value: 'title',
      type: 'contains'
    },
    {
      label: 'Description',
      value: 'description',
      type: 'contains'
    }
    // {
    //   label: 'Description',
    //   value: 'description',
    //   type: 'text'
    // },
    // {
    //   label: 'AssignedTo',
    //   value: 'assignedTo',
    //   type: 'autocomplete'
    // }
  ],
  regions: [
    {
      label: 'North East',
      value: 'NorthEast'
    },
    {
      label: 'North West',
      value: 'NorthWest'
    },
    {
      label: 'Yorkshire and the Humber',
      value: 'Yorkshire'
    },
    {
      label: 'East Midlands',
      value: 'EastMidlands'
    },
    {
      label: 'West Midlands',
      value: 'WestMidlands'
    },
    {
      label: 'East of England',
      value: 'EastEngland'
    },
    {
      label: 'London',
      value: 'London'
    },
    {
      label: 'South East',
      value: 'SouthEast'
    },
    {
      label: 'South West',
      value: 'SouthWest'
    }
  ],
  mortgageTerms: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },

    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },

    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },

    { label: '30', value: '30' },
    { label: '31', value: '31' },
    { label: '32', value: '32' },
    { label: '33', value: '33' },
    { label: '34', value: '34' },
    { label: '35', value: '35' },
    { label: '36', value: '36' },
    { label: '37', value: '37' },
    { label: '38', value: '38' },
    { label: '39', value: '39' },

    { label: '40', value: '40' }
  ],
  ethnicity: [
    {
      label: 'White',
      value: 'white'
    },
    {
      label: 'Mixed or Multiple ethnic groups',
      value: 'mixed'
    },
    {
      label: 'Asian or Asian British',
      value: 'asian'
    },
    {
      label: 'Black, African Caribbean or Black British',
      value: 'black'
    },
    {
      label: 'Other ethnic groups',
      value: 'other'
    }
  ],
  frequency: [
    // {
    //   label: 'Daily',
    //   value: 'daily'
    // },
    {
      label: 'Weekly',
      value: 'weekly'
    },
    {
      label: 'Fortnightly',
      value: 'fortnightly'
    },
    {
      label: '4 Weekly',
      value: 'fourweekly'
    },
    {
      label: 'Monthly',
      value: 'monthly'
    },
    {
      label: 'Annually',
      value: 'annually'
    }
  ],
  contactMethods: [
    {
      label: 'Mobile',
      value: 'mobile'
    },
    {
      label: 'Phone',
      value: 'phone'
    },
    {
      label: 'Email',
      value: 'email'
    },
    {
      label: 'Text',
      value: 'text'
    },
    {
      label: 'Post',
      value: 'post'
    }
  ],
  yesNo: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ],
  yesNoNA: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'N/A',
      value: 'na'
    }
  ],
  yesNoUnsure: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'Unsure',
      value: 'unsure'
    }
  ],
  gender: [
    {
      label: 'Male',
      value: 'male'
    },
    {
      label: 'Female',
      value: 'female'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  pronoun: [
    {
      label: 'He/Him',
      value: 'he-him'
    },
    {
      label: 'She/Her',
      value: 'she-her'
    },
    {
      label: 'They/Them',
      value: 'they-them'
    }
  ],
  language: [
    {
      value: 'Afar',
      label: 'Afar'
    },
    {
      value: 'Abkhaz',
      label: 'Abkhaz'
    },
    {
      value: 'Avestan',
      label: 'Avestan'
    },
    {
      value: 'Afrikaans',
      label: 'Afrikaans'
    },
    {
      value: 'Akan',
      label: 'Akan'
    },
    {
      value: 'Albanian',
      label: 'Albanian'
    },
    {
      value: 'Amharic',
      label: 'Amharic'
    },
    {
      value: 'Aragonese',
      label: 'Aragonese'
    },
    {
      value: 'Arabic',
      label: 'Arabic'
    },
    {
      value: 'Assamese',
      label: 'Assamese'
    },
    {
      value: 'Avaric',
      label: 'Avaric'
    },
    {
      value: 'Aymara',
      label: 'Aymara'
    },
    {
      value: 'Azerbaijani',
      label: 'Azerbaijani'
    },
    {
      value: 'South Azerbaijani',
      label: 'South Azerbaijani'
    },
    {
      value: 'Bashkir',
      label: 'Bashkir'
    },
    {
      value: 'Belarusian',
      label: 'Belarusian'
    },
    {
      value: 'Bulgarian',
      label: 'Bulgarian'
    },
    {
      value: 'Bihari',
      label: 'Bihari'
    },
    {
      value: 'Bislama',
      label: 'Bislama'
    },
    {
      value: 'Bambara',
      label: 'Bambara'
    },
    {
      value: 'Bengali - Bangla',
      label: 'Bengali - Bangla'
    },
    {
      value: 'Bengali - Sylheti',
      label: 'Bengali - Sylheti'
    },
    {
      value: 'Tibetan Standard, Tibetan, Central',
      label: 'Tibetan Standard, Tibetan, Central'
    },
    {
      value: 'Breton',
      label: 'Breton'
    },
    {
      value: 'Bosnian',
      label: 'Bosnian'
    },
    {
      value: 'Catalan; Valencian',
      label: 'Catalan; Valencian'
    },
    {
      value: 'Chechen',
      label: 'Chechen'
    },
    {
      value: 'Chamorro',
      label: 'Chamorro'
    },
    {
      value: 'Corsican',
      label: 'Corsican'
    },
    {
      value: 'Cree',
      label: 'Cree'
    },
    {
      value: 'Czech',
      label: 'Czech'
    },
    {
      value: 'Old Church Slavonic, Church Slavonic, Old Bulgarian',
      label: 'Old Church Slavonic, Church Slavonic, Old Bulgarian'
    },
    {
      value: 'Chuvash',
      label: 'Chuvash'
    },
    {
      value: 'Welsh',
      label: 'Welsh'
    },
    {
      value: 'Danish',
      label: 'Danish'
    },
    {
      value: 'German',
      label: 'German'
    },
    {
      value: 'Divehi; Dhivehi; Maldivian;',
      label: 'Divehi; Dhivehi; Maldivian;'
    },
    {
      value: 'Dzongkha',
      label: 'Dzongkha'
    },
    {
      value: 'Ewe',
      label: 'Ewe'
    },
    {
      value: 'Greek, Modern',
      label: 'Greek, Modern'
    },
    {
      value: 'English',
      label: 'English'
    },
    {
      value: 'Esperanto',
      label: 'Esperanto'
    },
    {
      value: 'Spanish; Castilian',
      label: 'Spanish; Castilian'
    },
    {
      value: 'Estonian',
      label: 'Estonian'
    },
    {
      value: 'Basque',
      label: 'Basque'
    },
    {
      value: 'Persian(Farsi)',
      label: 'Persian(Farsi)'
    },
    {
      value: 'Fula; Fulah; Pulaar; Pular',
      label: 'Fula; Fulah; Pulaar; Pular'
    },
    {
      value: 'Finnish',
      label: 'Finnish'
    },
    {
      value: 'Fijian',
      label: 'Fijian'
    },
    {
      value: 'Faroese',
      label: 'Faroese'
    },
    {
      value: 'French',
      label: 'French'
    },
    {
      value: 'Western Frisian',
      label: 'Western Frisian'
    },
    {
      value: 'Irish',
      label: 'Irish'
    },
    {
      value: 'Gaelic',
      label: 'Gaelic'
    },
    {
      value: 'Galician',
      label: 'Galician'
    },
    {
      value: 'Guaran',
      label: 'Guaran'
    },
    {
      value: 'Gujarati',
      label: 'Gujarati'
    },
    {
      value: 'Manx',
      label: 'Manx'
    },
    {
      value: 'Hausa',
      label: 'Hausa'
    },
    {
      value: 'Hebrew(modern)',
      label: 'Hebrew(modern)'
    },
    {
      value: 'Hindi',
      label: 'Hindi'
    },
    {
      value: 'Hiri Motu',
      label: 'Hiri Motu'
    },
    {
      value: 'Croatian',
      label: 'Croatian'
    },
    {
      value: 'Haitian; Haitian Creole',
      label: 'Haitian; Haitian Creole'
    },
    {
      value: 'Hungarian',
      label: 'Hungarian'
    },
    {
      value: 'Armenian',
      label: 'Armenian'
    },
    {
      value: 'Herero',
      label: 'Herero'
    },
    {
      value: 'Interlingua',
      label: 'Interlingua'
    },
    {
      value: 'Indonesian',
      label: 'Indonesian'
    },
    {
      value: 'Interlingue',
      label: 'Interlingue'
    },
    {
      value: 'Igbo',
      label: 'Igbo'
    },
    {
      value: 'Nuosu',
      label: 'Nuosu'
    },
    {
      value: 'Inupiaq',
      label: 'Inupiaq'
    },
    {
      value: 'Ido',
      label: 'Ido'
    },
    {
      value: 'Icelandic',
      label: 'Icelandic'
    },
    {
      value: 'Italian',
      label: 'Italian'
    },
    {
      value: 'Inuktitut',
      label: 'Inuktitut'
    },
    {
      value: 'Japanese',
      label: 'Japanese'
    },
    {
      value: 'Javanese',
      label: 'Javanese'
    },
    {
      value: 'Georgian',
      label: 'Georgian'
    },
    {
      value: 'Kongo',
      label: 'Kongo'
    },
    {
      value: 'Kikuyu, Gikuyu',
      label: 'Kikuyu, Gikuyu'
    },
    {
      value: 'Kwanyama, Kuanyama',
      label: 'Kwanyama, Kuanyama'
    },
    {
      value: 'Kazakh',
      label: 'Kazakh'
    },
    {
      value: 'Kalaallisut, Greenlandic',
      label: 'Kalaallisut, Greenlandic'
    },
    {
      value: 'Khmer',
      label: 'Khmer'
    },
    {
      value: 'Kannada',
      label: 'Kannada'
    },
    {
      value: 'Korean',
      label: 'Korean'
    },
    {
      value: 'Kanuri',
      label: 'Kanuri'
    },
    {
      value: 'Kashmiri',
      label: 'Kashmiri'
    },
    {
      value: 'Kurdish',
      label: 'Kurdish'
    },
    {
      value: 'Komi',
      label: 'Komi'
    },
    {
      value: 'Cornish',
      label: 'Cornish'
    },
    {
      value: 'Kyrgyz',
      label: 'Kyrgyz'
    },
    {
      value: 'Latin',
      label: 'Latin'
    },
    {
      value: 'Luxembourgish, Letzeburgesch',
      label: 'Luxembourgish, Letzeburgesch'
    },
    {
      value: 'Ganda',
      label: 'Ganda'
    },
    {
      value: 'Limburgish',
      label: 'Limburgish'
    },
    {
      value: 'Lingala',
      label: 'Lingala'
    },
    {
      value: 'Lao',
      label: 'Lao'
    },
    {
      value: 'Lithuanian',
      label: 'Lithuanian'
    },
    {
      value: 'Luba- Katanga',
      label: 'Luba- Katanga'
    },
    {
      value: 'Latvian',
      label: 'Latvian'
    },
    {
      value: 'Malagasy',
      label: 'Malagasy'
    },
    {
      value: 'Marshallese',
      label: 'Marshallese'
    },
    {
      value: 'MƒÅori',
      label: 'MƒÅori'
    },
    {
      value: 'Macedonian',
      label: 'Macedonian'
    },
    {
      value: 'Malayalam',
      label: 'Malayalam'
    },
    {
      value: 'Mongolian',
      label: 'Mongolian'
    },
    {
      value: 'Marathi (MarƒÅ·π≠hƒ´)',
      label: 'Marathi (MarƒÅ·π≠hƒ´)'
    },
    {
      value: 'Malay',
      label: 'Malay'
    },
    {
      value: 'Maltese',
      label: 'Maltese'
    },
    {
      value: 'Burmese',
      label: 'Burmese'
    },
    {
      value: 'Nauru',
      label: 'Nauru'
    },
    {
      value: 'North Ndebele',
      label: 'North Ndebele'
    },
    {
      value: 'Nepali',
      label: 'Nepali'
    },
    {
      value: 'Ndonga',
      label: 'Ndonga'
    },
    {
      value: 'Dutch',
      label: 'Dutch'
    },
    {
      value: 'Norwegian Nynorsk',
      label: 'Norwegian Nynorsk'
    },
    {
      value: 'Norwegian',
      label: 'Norwegian'
    },
    {
      value: 'South Ndebele',
      label: 'South Ndebele'
    },
    {
      value: 'Navajo, Navaho',
      label: 'Navajo, Navaho'
    },
    {
      value: 'Chichewa;Chewa;Nyanja',
      label: 'Chichewa;Chewa;Nyanja'
    },
    {
      value: 'Occitan',
      label: 'Occitan'
    },
    {
      value: 'Ojibwe, Ojibwa',
      label: 'Ojibwe, Ojibwa'
    },
    {
      value: 'Oromo',
      label: 'Oromo'
    },
    {
      value: 'Oriya',
      label: 'Oriya'
    },
    {
      value: 'Ossetian, Ossetic',
      label: 'Ossetian, Ossetic'
    },
    {
      value: 'Panjabi, Punjabi',
      label: 'Panjabi, Punjabi'
    },
    {
      value: 'PƒÅli',
      label: 'PƒÅli'
    },
    {
      value: 'Polish',
      label: 'Polish'
    },
    {
      value: 'Pashto, Pushto',
      label: 'Pashto, Pushto'
    },
    {
      value: 'Portuguese',
      label: 'Portuguese'
    },
    {
      value: 'Quechua',
      label: 'Quechua'
    },
    {
      value: 'Romansh',
      label: 'Romansh'
    },
    {
      value: 'Kirundi',
      label: 'Kirundi'
    },
    {
      value: 'Romanian',
      label: 'Romanian'
    },
    {
      value: 'Russian',
      label: 'Russian'
    },
    {
      value: 'Kinyarwanda',
      label: 'Kinyarwanda'
    },
    {
      value: 'Sanskrit',
      label: 'Sanskrit'
    },
    {
      value: 'Sardinian',
      label: 'Sardinian'
    },
    {
      value: 'Sindhi',
      label: 'Sindhi'
    },
    {
      value: 'Northern Sami',
      label: 'Northern Sami'
    },
    {
      value: 'Sango',
      label: 'Sango'
    },
    {
      value: 'Sinhala, Sinhalese',
      label: 'Sinhala, Sinhalese'
    },
    {
      value: 'Slovak',
      label: 'Slovak'
    },
    {
      value: 'Slovene',
      label: 'Slovene'
    },
    {
      value: 'Samoan',
      label: 'Samoan'
    },
    {
      value: 'Shona',
      label: 'Shona'
    },
    {
      value: 'Somali',
      label: 'Somali'
    },
    {
      value: 'Serbian',
      label: 'Serbian'
    },
    {
      value: 'Swati',
      label: 'Swati'
    },
    {
      value: 'Southern Sotho',
      label: 'Southern Sotho'
    },
    {
      value: 'Sundanese',
      label: 'Sundanese'
    },
    {
      value: 'Swedish',
      label: 'Swedish'
    },
    {
      value: 'Swahili',
      label: 'Swahili'
    },
    {
      value: 'Tamil',
      label: 'Tamil'
    },
    {
      value: 'Telugu',
      label: 'Telugu'
    },
    {
      value: 'Tajik',
      label: 'Tajik'
    },
    {
      value: 'Thai',
      label: 'Thai'
    },
    {
      value: 'Tigrinya',
      label: 'Tigrinya'
    },
    {
      value: 'Turkmen',
      label: 'Turkmen'
    },
    {
      value: 'Tagalog',
      label: 'Tagalog'
    },
    {
      value: 'Tswana',
      label: 'Tswana'
    },
    {
      value: 'Tonga (Tonga Islands)',
      label: 'Tonga (Tonga Islands)'
    },
    {
      value: 'Turkish',
      label: 'Turkish'
    },
    {
      value: 'Tsonga',
      label: 'Tsonga'
    },
    {
      value: 'Tatar',
      label: 'Tatar'
    },
    {
      value: 'Twi',
      label: 'Twi'
    },
    {
      value: 'Tahitian',
      label: 'Tahitian'
    },
    {
      value: 'Uyghur, Uighur',
      label: 'Uyghur, Uighur'
    },
    {
      value: 'Ukrainian',
      label: 'Ukrainian'
    },
    {
      value: 'Urdu',
      label: 'Urdu'
    },
    {
      value: 'Uzbek',
      label: 'Uzbek'
    },
    {
      value: 'Venda',
      label: 'Venda'
    },
    {
      value: 'Vietnamese',
      label: 'Vietnamese'
    },
    {
      value: 'Volap√ºk',
      label: 'Volap√ºk'
    },
    {
      value: 'Walloon',
      label: 'Walloon'
    },
    {
      value: 'Wolof',
      label: 'Wolof'
    },
    {
      value: 'Xhosa',
      label: 'Xhosa'
    },
    {
      value: 'Yiddish',
      label: 'Yiddish'
    },
    {
      value: 'Yoruba',
      label: 'Yoruba'
    },
    {
      value: 'Zhuang, Chuang',
      label: 'Zhuang, Chuang'
    },
    {
      value: 'Chinese',
      label: 'Chinese'
    },
    {
      value: 'Zulu',
      label: 'Zulu'
    },
  ],

  frequency: [
    {
      label: 'Daily',
      value: 'daily'
    },
    {
      label: 'Weekly',
      value: 'weekly'
    },
    {
      label: 'Fortnightly',
      value: 'fortnightly'
    },
    {
      label: 'Monthly',
      value: 'monthly'
    },
    {
      label: 'Annually',
      value: 'annually'
    }
  ],
  contactMethods: [
    {
      label: 'Mobile',
      value: 'mobile'
    },
    {
      label: 'Phone',
      value: 'phone'
    },
    {
      label: 'Email',
      value: 'email'
    },
    {
      label: 'Text',
      value: 'text'
    },
    {
      label: 'Post',
      value: 'post'
    }
  ],
  yesNoNA: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'N/A',
      value: 'na'
    }
  ],
  traffickingTypes: [
    {
      label: 'Sexual Exploitation',
      value: 'sexualexploitation'
    },
    {
      label: 'Child Sexual Exploitation',
      value: 'childsexualexploitation'
    },
    {
      label: 'Labour Exploitation',
      value: 'labourexploitation'
    },
    {
      label: 'Domestic Servitude',
      value: 'domesticservitude'
    },
    {
      label: 'Criminal Exploitation',
      value: 'criminalexploitation'
    },
    {
      label: 'Child Criminal Exploitation',
      value: 'childcriminalexploitation'
    },
    {
      label: 'Organ Harvesting',
      value: 'organharvesting'
    },
    {
      label: 'Unknown',
      value: 'unknown'
    }
  ],
  literacyLevel: [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Poor',
      value: 'poor'
    },
    {
      label: 'Basic',
      value: 'basic'
    },
    {
      label: 'Good',
      value: 'good'
    },
    {
      label: 'Fluent',
      value: 'fluent'
    }
  ],
  education: [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'ESOL',
      value: 'esol'
    },
    {
      label: 'Non - Accredited courses',
      value: 'nonaccreditedcourses'
    },
    {
      label: 'GCSES',
      value: 'gcses'
    },
    {
      label: 'Access Course',
      value: 'accesscourse'
    },
    {
      label: 'University',
      value: 'university'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  employment: [
    {
      label: 'Unemployed',
      value: 'unemployed'
    },
    {
      label: 'Volunteering',
      value: 'volunteering'
    },
    {
      label: 'Zero Hours',
      value: 'zerohours'
    },
    {
      label: 'Precarious / Risky / Cash in Hand',
      value: 'precarious'
    },
    {
      label: 'Self - Employed',
      value: 'selfemployed'
    },
    {
      label: 'Employed - Part-time',
      value: 'parttime'
    },
    {
      label: 'Employed - Full-time',
      value: 'fulltime'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  housingType: [
    {
      label: 'Safe House',
      value: 'safehouse'
    },
    {
      label: 'NASS',
      value: 'nass'
    },
    {
      label: 'Temporary Accommodation',
      value: 'temporaryaccommodation'
    },
    {
      label: 'Council House',
      value: 'councilhouse'
    },
    {
      label: 'Private Rented',
      value: 'privaterented'
    },
    {
      label: 'Housing Assn',
      value: 'housingassn'
    },
    {
      label: 'Homeless',
      value: 'homeless'
    },
    {
      label: 'Host Accommodation',
      value: 'hostaccommodation'
    },
    {
      label: 'Living With Friends',
      value: 'livingwithfriends'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  income: [
    {
      label: 'Employment Income',
      value: 'employment'
    },
    {
      label: 'DWP Benefits (list)',
      value: 'dwpbenefits'
    },
    {
      label: 'NASS S95 or S4',
      value: 'nasss95ors4'
    },
    {
      label: 'Destitute',
      value: 'destitute'
    },
    {
      label: 'Chaotic',
      value: 'chaotic'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  legalInterventions: [
    {
      label: 'Immigration',
      value: 'immigration'
    },
    {
      label: 'Compensation',
      value: 'compensation'
    },
    {
      label: 'Police Investigation',
      value: 'policeinvestigation'
    },
    {
      label: 'Family Law',
      value: 'familylaw'
    },
    {
      label: 'Public Law',
      value: 'publiclaw'
    },
    {
      label: 'Criminal Law',
      value: 'criminallaw'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  immigrationStatus: [
    {
      label: 'UK Citizen',
      value: 'ukcitizen'
    },
    {
      label: 'EU - (Legacy/Unknown Status)',
      value: 'eucitizen'
    },
    {
      label: 'EU - Pre Settled Status',
      value: 'eu-pre-settled'
    },
    {
      label: 'EU - Settled Status',
      value: 'eu-settled'
    },
    {
      label: 'EU - No Status',
      value: 'eu-no-status'
    },
    {
      label: 'Refugee / HP',
      value: 'refugeeorhp'
    },
    {
      label: 'Asylum Seeker',
      value: 'asylumseeker'
    },
    {
      label: 'Failed Asylum Seeker',
      value: 'failedasylumseeker'
    },
    {
      label: 'Discretionary Leave',
      value: 'discretionaryleave'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  nrmStatus: [
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Positive CG',
      value: 'positivecg'
    },
    {
      label: 'Negative CG',
      value: 'negativecg'
    },
    {
      label: 'Challenging CG',
      value: 'challengingcg'
    },
    {
      label: 'Non NRM Referral',
      value: 'nonnrmreferral'
    }
  ],
  countries: [
    {
      value: 'Afghanistan',
      label: 'Afghanistan'
    },
    {
      value: 'Albania',
      label: 'Albania'
    },
    {
      value: 'Algeria',
      label: 'Algeria'
    },
    {
      value: 'American Samoa',
      label: 'American Samoa'
    },
    {
      value: 'Andorra',
      label: 'Andorra'
    },
    {
      value: 'Angola',
      label: 'Angola'
    },
    {
      value: 'Anguilla',
      label: 'Anguilla'
    },
    {
      value: 'Antarctica',
      label: 'Antarctica'
    },
    {
      value: 'Antigua And Barbuda',
      label: 'Antigua And Barbuda'
    },
    {
      value: 'Argentina',
      label: 'Argentina'
    },
    {
      value: 'Armenia',
      label: 'Armenia'
    },
    {
      value: 'Aruba',
      label: 'Aruba'
    },
    {
      value: 'Australia',
      label: 'Australia'
    },
    {
      value: 'Austria',
      label: 'Austria'
    },
    {
      value: 'Azerbaijan',
      label: 'Azerbaijan'
    },
    {
      value: 'Bahamas',
      label: 'Bahamas'
    },
    {
      value: 'Bahrain',
      label: 'Bahrain'
    },
    {
      value: 'Bangladesh',
      label: 'Bangladesh'
    },
    {
      value: 'Barbados',
      label: 'Barbados'
    },
    {
      value: 'Belarus',
      label: 'Belarus'
    },
    {
      value: 'Belgium',
      label: 'Belgium'
    },
    {
      value: 'Belize',
      label: 'Belize'
    },
    {
      value: 'Benin',
      label: 'Benin'
    },
    {
      value: 'Bermuda',
      label: 'Bermuda'
    },
    {
      value: 'Bhutan',
      label: 'Bhutan'
    },
    {
      value: 'Bolivia',
      label: 'Bolivia'
    },
    {
      value: 'Bosnia And Herzegovina',
      label: 'Bosnia And Herzegovina'
    },
    {
      value: 'Botswana',
      label: 'Botswana'
    },
    {
      value: 'Bouvet Island',
      label: 'Bouvet Island'
    },
    {
      value: 'Brazil',
      label: 'Brazil'
    },
    {
      value: 'British Indian Ocean Territory',
      label: 'British Indian Ocean Territory'
    },
    {
      value: 'Brunei Darussalam',
      label: 'Brunei Darussalam'
    },
    {
      value: 'Bulgaria',
      label: 'Bulgaria'
    },
    {
      value: 'Burkina Faso',
      label: 'Burkina Faso'
    },
    {
      value: 'Burundi',
      label: 'Burundi'
    },
    {
      value: 'Cambodia',
      label: 'Cambodia'
    },
    {
      value: 'Cameroon',
      label: 'Cameroon'
    },
    {
      value: 'Canada',
      label: 'Canada'
    },
    {
      value: 'Cape Verde',
      label: 'Cape Verde'
    },
    {
      value: 'Cayman Islands',
      label: 'Cayman Islands'
    },
    {
      value: 'Central African Republic',
      label: 'Central African Republic'
    },
    {
      value: 'Chad',
      label: 'Chad'
    },
    {
      value: 'Chile',
      label: 'Chile'
    },
    {
      value: 'China',
      label: 'China'
    },
    {
      value: 'Christmas Island',
      label: 'Christmas Island'
    },
    {
      value: 'Cocos(keeling) Islands',
      label: 'Cocos(keeling) Islands'
    },
    {
      value: 'Colombia',
      label: 'Colombia'
    },
    {
      value: 'Comoros',
      label: 'Comoros'
    },
    {
      value: 'Congo',
      label: 'Congo'
    },
    {
      value: 'Congo, The Democratic Republic Of The',
      label: 'Congo, The Democratic Republic Of The'
    },
    {
      value: 'Cook Islands',
      label: 'Cook Islands'
    },
    {
      value: 'Costa Rica',
      label: 'Costa Rica'
    },
    {
      value: "Cote D'ivoire",
      label: "Cote D'ivoire"
    },
    {
      value: 'Croatia',
      label: 'Croatia'
    },
    {
      value: 'Cuba',
      label: 'Cuba'
    },
    {
      value: 'Cyprus',
      label: 'Cyprus'
    },
    {
      value: 'Czech Republic',
      label: 'Czech Republic'
    },
    {
      value: 'Denmark',
      label: 'Denmark'
    },
    {
      value: 'Djibouti',
      label: 'Djibouti'
    },
    {
      value: 'Dominica',
      label: 'Dominica'
    },
    {
      value: 'Dominican Republic',
      label: 'Dominican Republic'
    },
    {
      value: 'East Timor',
      label: 'East Timor'
    },
    {
      value: 'Ecuador',
      label: 'Ecuador'
    },
    {
      value: 'Egypt',
      label: 'Egypt'
    },
    {
      value: 'El Salvador',
      label: 'El Salvador'
    },
    {
      value: 'Equatorial Guinea',
      label: 'Equatorial Guinea'
    },
    {
      value: 'Eritrea',
      label: 'Eritrea'
    },
    {
      value: 'Estonia',
      label: 'Estonia'
    },
    {
      value: 'Ethiopia',
      label: 'Ethiopia'
    },
    {
      value: 'Falkland Islands (malvinas)',
      label: 'Falkland Islands (malvinas)'
    },
    {
      value: 'Faroe Islands',
      label: 'Faroe Islands'
    },
    {
      value: 'Fiji',
      label: 'Fiji'
    },
    {
      value: 'Finland',
      label: 'Finland'
    },
    {
      value: 'France',
      label: 'France'
    },
    {
      value: 'French Guiana',
      label: 'French Guiana'
    },
    {
      value: 'French Polynesia',
      label: 'French Polynesia'
    },
    {
      value: 'French Southern Territories',
      label: 'French Southern Territories'
    },
    {
      value: 'Gabon',
      label: 'Gabon'
    },
    {
      value: 'Gambia',
      label: 'Gambia'
    },
    {
      value: 'Georgia',
      label: 'Georgia'
    },
    {
      value: 'Germany',
      label: 'Germany'
    },
    {
      value: 'Ghana',
      label: 'Ghana'
    },
    {
      value: 'Gibraltar',
      label: 'Gibraltar'
    },
    {
      value: 'Greece',
      label: 'Greece'
    },
    {
      value: 'Greenland',
      label: 'Greenland'
    },
    {
      value: 'Grenada',
      label: 'Grenada'
    },
    {
      value: 'Guadeloupe',
      label: 'Guadeloupe'
    },
    {
      value: 'Guam',
      label: 'Guam'
    },
    {
      value: 'Guatemala',
      label: 'Guatemala'
    },
    {
      value: 'Guinea',
      label: 'Guinea'
    },
    {
      value: 'Guinea - bissau',
      label: 'Guinea - bissau'
    },
    {
      value: 'Guyana',
      label: 'Guyana'
    },
    {
      value: 'Haiti',
      label: 'Haiti'
    },
    {
      value: 'Heard Island And Mcdonald Islands',
      label: 'Heard Island And Mcdonald Islands'
    },
    {
      value: 'Holy See (Vatican City State)',
      label: 'Holy See (Vatican City State)'
    },
    {
      value: 'Honduras',
      label: 'Honduras'
    },
    {
      value: 'Hong Kong',
      label: 'Hong Kong'
    },
    {
      value: 'Hungary',
      label: 'Hungary'
    },
    {
      value: 'Iceland',
      label: 'Iceland'
    },
    {
      value: 'India',
      label: 'India'
    },
    {
      value: 'Indonesia',
      label: 'Indonesia'
    },
    {
      value: 'Iran, Islamic Republic Of',
      label: 'Iran, Islamic Republic Of'
    },
    {
      value: 'Iraq',
      label: 'Iraq'
    },
    {
      value: 'Ireland',
      label: 'Ireland'
    },
    {
      value: 'Israel',
      label: 'Israel'
    },
    {
      value: 'Italy',
      label: 'Italy'
    },
    {
      value: 'Jamaica',
      label: 'Jamaica'
    },
    {
      value: 'Japan',
      label: 'Japan'
    },
    {
      value: 'Jordan',
      label: 'Jordan'
    },
    {
      value: 'Kazakstan',
      label: 'Kazakstan'
    },
    {
      value: 'Kenya',
      label: 'Kenya'
    },
    {
      value: 'Kiribati',
      label: 'Kiribati'
    },
    {
      value: "Korea, Democratic People's Republic Of",
      label: "Korea, Democratic People's Republic Of"
    },
    {
      value: 'Korea, Republic Of',
      label: 'Korea, Republic Of'
    },
    {
      value: 'Kosovo',
      label: 'Kosovo'
    },
    {
      value: 'Kuwait',
      label: 'Kuwait'
    },
    {
      value: 'Kyrgyzstan',
      label: 'Kyrgyzstan'
    },
    {
      value: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic"
    },
    {
      value: 'Latvia',
      label: 'Latvia'
    },
    {
      value: 'Lebanon',
      label: 'Lebanon'
    },
    {
      value: 'Lesotho',
      label: 'Lesotho'
    },
    {
      value: 'Liberia',
      label: 'Liberia'
    },
    {
      value: 'Libyan Arab Jamahiriya',
      label: 'Libyan Arab Jamahiriya'
    },
    {
      value: 'Liechtenstein',
      label: 'Liechtenstein'
    },
    {
      value: 'Lithuania',
      label: 'Lithuania'
    },
    {
      value: 'Luxembourg',
      label: 'Luxembourg'
    },
    {
      value: 'Macau',
      label: 'Macau'
    },
    {
      value: 'Macedonia, The Former Yugoslav Republic Of',
      label: 'Macedonia, The Former Yugoslav Republic Of'
    },
    {
      value: 'Madagascar',
      label: 'Madagascar'
    },
    {
      value: 'Malawi',
      label: 'Malawi'
    },
    {
      value: 'Malaysia',
      label: 'Malaysia'
    },
    {
      value: 'Maldives',
      label: 'Maldives'
    },
    {
      value: 'Mali',
      label: 'Mali'
    },
    {
      value: 'Malta',
      label: 'Malta'
    },
    {
      value: 'Marshall Islands',
      label: 'Marshall Islands'
    },
    {
      value: 'Martinique',
      label: 'Martinique'
    },
    {
      value: 'Mauritania',
      label: 'Mauritania'
    },
    {
      value: 'Mauritius',
      label: 'Mauritius'
    },
    {
      value: 'Mayotte',
      label: 'Mayotte'
    },
    {
      value: 'Mexico',
      label: 'Mexico'
    },
    {
      value: 'Micronesia, Federated States Of',
      label: 'Micronesia, Federated States Of'
    },
    {
      value: 'Moldova, Republic Of',
      label: 'Moldova, Republic Of'
    },
    {
      value: 'Monaco',
      label: 'Monaco'
    },
    {
      value: 'Mongolia',
      label: 'Mongolia'
    },
    {
      value: 'Montserrat',
      label: 'Montserrat'
    },
    {
      value: 'Montenegro',
      label: 'Montenegro'
    },
    {
      value: 'Morocco',
      label: 'Morocco'
    },
    {
      value: 'Mozambique',
      label: 'Mozambique'
    },
    {
      value: 'Myanmar',
      label: 'Myanmar'
    },
    {
      value: 'Namibia',
      label: 'Namibia'
    },
    {
      value: 'Nauru',
      label: 'Nauru'
    },
    {
      value: 'Nepal',
      label: 'Nepal'
    },
    {
      value: 'Netherlands',
      label: 'Netherlands'
    },
    {
      value: 'Netherlands Antilles',
      label: 'Netherlands Antilles'
    },
    {
      value: 'New Caledonia',
      label: 'New Caledonia'
    },
    {
      value: 'New Zealand',
      label: 'New Zealand'
    },
    {
      value: 'Nicaragua',
      label: 'Nicaragua'
    },
    {
      value: 'Niger',
      label: 'Niger'
    },
    {
      value: 'Nigeria',
      label: 'Nigeria'
    },
    {
      value: 'Niue',
      label: 'Niue'
    },
    {
      value: 'Norfolk Island',
      label: 'Norfolk Island'
    },
    {
      value: 'Northern Mariana Islands',
      label: 'Northern Mariana Islands'
    },
    {
      value: 'Norway',
      label: 'Norway'
    },
    {
      value: 'Oman',
      label: 'Oman'
    },
    {
      value: 'Pakistan',
      label: 'Pakistan'
    },
    {
      value: 'Palau',
      label: 'Palau'
    },
    {
      value: 'Palestinian Territory, Occupied',
      label: 'Palestinian Territory, Occupied'
    },
    {
      value: 'Panama',
      label: 'Panama'
    },
    {
      value: 'Papua New Guinea',
      label: 'Papua New Guinea'
    },
    {
      value: 'Paraguay',
      label: 'Paraguay'
    },
    {
      value: 'Peru',
      label: 'Peru'
    },
    {
      value: 'Philippines',
      label: 'Philippines'
    },
    {
      value: 'Pitcairn',
      label: 'Pitcairn'
    },
    {
      value: 'Poland',
      label: 'Poland'
    },
    {
      value: 'Portugal',
      label: 'Portugal'
    },
    {
      value: 'Puerto Rico',
      label: 'Puerto Rico'
    },
    {
      value: 'Qatar',
      label: 'Qatar'
    },
    {
      value: 'Reunion',
      label: 'Reunion'
    },
    {
      value: 'Romania',
      label: 'Romania'
    },
    {
      value: 'Russian Federation',
      label: 'Russian Federation'
    },
    {
      value: 'Rwanda',
      label: 'Rwanda'
    },
    {
      value: 'Saint Helena',
      label: 'Saint Helena'
    },
    {
      value: 'Saint Kitts And Nevis',
      label: 'Saint Kitts And Nevis'
    },
    {
      value: 'Saint Lucia',
      label: 'Saint Lucia'
    },
    {
      value: 'Saint Pierre And Miquelon',
      label: 'Saint Pierre And Miquelon'
    },
    {
      value: 'Saint Vincent And The Grenadines',
      label: 'Saint Vincent And The Grenadines'
    },
    {
      value: 'Samoa',
      label: 'Samoa'
    },
    {
      value: 'San Marino',
      label: 'San Marino'
    },
    {
      value: 'Sao Tome And Principe',
      label: 'Sao Tome And Principe'
    },
    {
      value: 'Saudi Arabia',
      label: 'Saudi Arabia'
    },
    {
      value: 'Senegal',
      label: 'Senegal'
    },
    {
      value: 'Serbia',
      label: 'Serbia'
    },
    {
      value: 'Seychelles',
      label: 'Seychelles'
    },
    {
      value: 'Sierra Leone',
      label: 'Sierra Leone'
    },
    {
      value: 'Singapore',
      label: 'Singapore'
    },
    {
      value: 'Slovakia',
      label: 'Slovakia'
    },
    {
      value: 'Slovenia',
      label: 'Slovenia'
    },
    {
      value: 'Solomon Islands',
      label: 'Solomon Islands'
    },
    {
      value: 'Somalia',
      label: 'Somalia'
    },
    {
      value: 'South Africa',
      label: 'South Africa'
    },
    {
      value: 'South Georgia And The South Sandwich Islands',
      label: 'South Georgia And The South Sandwich Islands'
    },
    {
      value: 'Spain',
      label: 'Spain'
    },
    {
      value: 'Sri Lanka',
      label: 'Sri Lanka'
    },
    {
      value: 'Sudan',
      label: 'Sudan'
    },
    {
      value: 'Suriname',
      label: 'Suriname'
    },
    {
      value: 'Svalbard And Jan Mayen',
      label: 'Svalbard And Jan Mayen'
    },
    {
      value: 'Swaziland',
      label: 'Swaziland'
    },
    {
      value: 'Sweden',
      label: 'Sweden'
    },
    {
      value: 'Switzerland',
      label: 'Switzerland'
    },
    {
      value: 'Syrian Arab Republic',
      label: 'Syrian Arab Republic'
    },
    {
      value: 'Taiwan, Province Of China',
      label: 'Taiwan, Province Of China'
    },
    {
      value: 'Tajikistan',
      label: 'Tajikistan'
    },
    {
      value: 'Tanzania, United Republic Of',
      label: 'Tanzania, United Republic Of'
    },
    {
      value: 'Thailand',
      label: 'Thailand'
    },
    {
      value: 'Togo',
      label: 'Togo'
    },
    {
      value: 'Tokelau',
      label: 'Tokelau'
    },
    {
      value: 'Tonga',
      label: 'Tonga'
    },
    {
      value: 'Trinidad And Tobago',
      label: 'Trinidad And Tobago'
    },
    {
      value: 'Tunisia',
      label: 'Tunisia'
    },
    {
      value: 'Turkey',
      label: 'Turkey'
    },
    {
      value: 'Turkmenistan',
      label: 'Turkmenistan'
    },
    {
      value: 'Turks And Caicos Islands',
      label: 'Turks And Caicos Islands'
    },
    {
      value: 'Tuvalu',
      label: 'Tuvalu'
    },
    {
      value: 'Uganda',
      label: 'Uganda'
    },
    {
      value: 'Ukraine',
      label: 'Ukraine'
    },
    {
      value: 'United Arab Emirates',
      label: 'United Arab Emirates'
    },
    {
      value: 'United Kingdom',
      label: 'United Kingdom'
    },
    {
      value: 'United States',
      label: 'United States'
    },
    {
      value: 'United States Minor Outlying Islands',
      label: 'United States Minor Outlying Islands'
    },
    {
      value: 'Uruguay',
      label: 'Uruguay'
    },
    {
      value: 'Uzbekistan',
      label: 'Uzbekistan'
    },
    {
      value: 'Vanuatu',
      label: 'Vanuatu'
    },
    {
      value: 'Venezuela',
      label: 'Venezuela'
    },
    {
      value: 'Viet Nam',
      label: 'Viet Nam'
    },
    {
      value: 'Virgin Islands, British',
      label: 'Virgin Islands, British'
    },
    {
      value: 'Virgin Islands, U.s.',
      label: 'Virgin Islands, U.s.'
    },
    {
      value: 'Wallis And Futuna',
      label: 'Wallis And Futuna'
    },
    {
      value: 'Western Sahara',
      label: 'Western Sahara'
    },
    {
      value: 'Yemen',
      label: 'Yemen'
    },
    {
      value: 'Zambia',
      label: 'Zambia'
    },
    {
      value: 'Zimbabwe',
      label: 'Zimbabwe'
    },
  ],
  noteTypes: [
    {
      label: 'Phone Call',
      value: 'phone'
    },
    {
      label: 'Letter',
      value: 'letter'
    },
    {
      label: 'Email',
      value: 'email'
    },
    {
      label: 'Text',
      value: 'text'
    },
    {
      label: 'Face to Face',
      value: 'facetoface'
    },
    {
      label: 'Drop In',
      value: 'dropin'
    },
    {
      label: 'Appointment',
      value: 'appointment'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  maritalStatus: [
    {
      label: 'Married',
      value: 'married'
    },
    {
      label: 'Widowed',
      value: 'widowed'
    },
    {
      label: 'Separated',
      value: 'separated'
    },
    {
      label: 'Divorced',
      value: 'divorced'
    },
    {
      label: 'Single',
      value: 'single'
    }
  ],
  supportArea: [
    {
      label: 'Sheffield/Rotherham',
      value: 'sheffield'
    },
    {
      label: 'Barnsley',
      value: 'barnsley'
    }
  ],
  supportOrganisation: [
    {
      label: 'Snowdrop',
      value: 'snowdrop'
    },
    {
      label: 'Ashiana',
      value: 'ashiana'
    },
    {
      label: 'CH',
      value: 'ch'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  nationality: [
    {
      label: 'British',
      value: 'british'
    },
    {
      label: 'French',
      value: 'french'
    }
  ],
  carePlanPoint: [
    {
      label: 'Initial Assessment',
      value: 'initialAssessment'
    },
    {
      label: '6 month review',
      value: 'sixMonthReview'
    },
    {
      label: 'Case Closure',
      value: 'caseClosure'
    }
  ],
  housingStatus: [
    {
      label: 'NASS accommodation',
      value: 'nassAccommodation'
    },
    {
      label: 'Living with friends',
      value: 'LivingWithFriends'
    },
    {
      label: 'Permanent accommodation',
      value: 'PermanentAccommodation'
    },
    {
      label: 'Temporary accommodation',
      value: 'TemporaryAccommodation'
    },
    {
      label: 'Private rented',
      value: 'PrivateRented'
    },
    {
      label: 'Homeless',
      value: 'homeless'
    },
    {
      label: 'Refuge/safe house',
      value: 'refugeSafeHouse'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  legalStatus: [
    {
      label: 'UK Citizen',
      value: 'ukCitizen'
    },
    {
      label: 'EU Citizen',
      value: 'euCitizen'
    },
    {
      label: 'Refugee/HP',
      value: 'refugeeHP'
    },
    {
      label: 'Asylum Seeker',
      value: 'asylumSeeker'
    },
    {
      label: 'Failed Asylum Seeker',
      value: 'failedAsylumSeeker'
    }
  ],

  discretionaryLeave: [
    {
      label: 'N/A',
      value: 'na'
    },
    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'Applied for',
      value: 'appliedFor'
    },
    {
      label: 'Yes',
      value: 'yes'
    }
  ],
  urgentNeeds: [
    {
      label: 'Destitution',
      value: 'destitution'
    },
    {
      label: 'Leave expires within two weeks',
      value: 'leaveExpiresWithinTwoWeeks'
    },
    {
      label: 'Dangerous Situation',
      value: 'dangerousSituation'
    }
  ],
  englishStandard: [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Poor',
      value: 'poor'
    },
    {
      label: 'Basic',
      value: 'basic'
    },
    {
      label: 'Good',
      value: 'good'
    },
    {
      label: 'Fluent',
      value: 'fluent'
    }
  ],
  incomeDetails: [
    {
      label: 'Employed',
      value: 'employed'
    },
    {
      label: 'DWP Benefit',
      value: 'dwpBenefit'
    },
    {
      label: 'S95/S4 Support',
      value: 's95s4Support'
    },
    {
      label: 'Friends/Family',
      value: 'friendsFamily'
    },
    {
      label: 'None',
      value: 'none'
    }
  ],
  referrerOrganisation: [
    {
      label: 'Snowdrop Project',
      value: 'snowdropProject'
    },
    {
      label: 'Ashiana',
      value: 'ashiana'
    },
    {
      label: 'City Hearts',
      value: 'cityHearts'
    },
    {
      label: 'Other (Please specify)',
      value: 'other'
    }
  ],
  traffickingStatus: [
    {
      label: 'Not entered NRM',
      value: 'notEnteredNRM'
    },
    {
      label: 'Reasonable Grounds',
      value: 'reasonableGrounds'
    },
    {
      label: 'Positive CG',
      value: 'positiveCG'
    },
    {
      label: 'Negative CG',
      value: 'negativeCG'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  ukStatus: [
    {
      label: 'EU National Settled/Pre-Settled',
      value: 'euNational'
    },
    {
      label: 'Asylum Seeker',
      value: 'asylumSeeker'
    },
    {
      label: 'Refugee/HP',
      value: 'refugee'
    },
    {
      label: 'British National',
      value: 'britishNational'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  counsellingFormIncomeDetails: [
    {
      label: 'Employed',
      value: 'employed'
    },
    {
      label: 'DWP Benefit',
      value: 'dwpBenefit'
    },
    {
      label: 'NASS',
      value: 'nass'
    },
    {
      label: 'PIP (Disability Living Allowance)',
      value: 'pip'
    },
    {
      label: 'ASSIST Support',
      value: 'assistSupport'
    },
    {
      label: 'Friends/Family',
      value: 'friendsFamily'
    },
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ],
  interpreterNeeded: [
    {
      label: 'No need',
      value: 'noNeed'
    },
    {
      label: 'Interview Only',
      value: 'interviewOnly'
    },
    {
      label: 'Occasionally',
      value: 'occasionally'
    },
    {
      label: 'Regularly',
      value: 'regularly'
    },
    {
      label: 'Language required',
      value: 'languageRequired'
    }
  ],
  substanceAbuse: [
    {
      label: 'N/A',
      value: 'na'
    },
    {
      label: 'Historic',
      value: 'historic'
    },
    {
      label: 'Recent detox',
      value: 'recentDetox'
    },
    {
      label: 'Current - Low',
      value: 'currentLow'
    },
    {
      label: 'Current - High',
      value: 'currentHigh'
    }
  ],
  kpiColors: [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(73, 56, 41, 1)',
    'rgba(129, 108, 91, 1)',
    'rgba(169, 161, 140, 1)',
    'rgba(97, 51, 24, 1)',
    'rgba(133, 87, 35, 1)',
    'rgba(185, 156, 107, 1)',
    'rgba(143, 59, 27, 1)',
    'rgba(213, 117, 0, 1)',
    'rgba(219, 202, 105, 1)',
    'rgba(64, 79, 36, 1)',
    'rgba(102, 141, 60, 1)',
    'rgba(180, 208, 156, 1)',
    'rgba(78, 97, 114, 1)',
    'rgba(131, 146, 159, 1)',
    'rgba(163, 173, 184, 1)',
    'rgba(73, 56, 41, 1)',
    'rgba(129, 108, 91, 1)',
    'rgba(169, 161, 140, 1)',
    'rgba(97, 51, 24, 1)',
    'rgba(133, 87, 35, 1)',
    'rgba(185, 156, 107, 1)',
    'rgba(143, 59, 27, 1)',
    'rgba(213, 117, 0, 1)',
    'rgba(219, 202, 105, 1)',
    'rgba(64, 79, 36, 1)',
    'rgba(102, 141, 60, 1)',
    'rgba(180, 208, 156, 1)',
    'rgba(78, 97, 114, 1)',
    'rgba(131, 146, 159, 1)',
    'rgba(163, 173, 184, 1)',
  ]
}
