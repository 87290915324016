import ApiService from './ApiService'

class UserGroupMemberService {
  createMember = async (apiToken, options) => {
    var url = '/api/usergroupmember'

    var params = {}

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:createMember ', result)
    return result
  }

  deleteMember = async (apiToken, memberId) => {
    var url = `/api/usergroupmember/${memberId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:deleteMember ', result)
    return result
  }

  getMembers = async (apiToken, params) => {
    var url = '/api/usergroupmember'

    try {
      var userGroupMembers = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserGroupService:getMembers ', userGroupMembers)
    return userGroupMembers
  }

  // Finds a UserGroupMember and if found will delete it
  findAndDeleteMember = async (apiToken, partnerOrgId, partnerId) => {
    const params = {
      fields: 'id'
    }

    params.where = {
      usergroup: parseInt(partnerOrgId),
      user: parseInt(partnerId)
    }

    const members = await this.getMembers(apiToken, params)

    if (!members.error) {
      const deletedMember = await this.deleteMember(apiToken, members.data[0].id)
      return deletedMember
    }
  }
}
export default new UserGroupMemberService()
