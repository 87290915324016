import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import Text from '../../../components/simple/text/Text'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function Wellbeing (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)
  useEffect(() => { setLocalFormDetails(props.formDetails) }, [props.formDetails])

  // Determine if wellbeing goal Add button is enabled or not
  const [wellbeingGoalAddButton, setWellbeingGoalAddButton] = useState(true)
  // Array of wellbeing goal
  const [wellbeingGoals, setWellbeingGoals] = useState([])
  // Array of wellbeing goals mapped for DataTable
  const [mappedWellbeingGoals, setMappedWellbeingGoals] = useState([])
  // DataTable columns for wellbeing goals
  const [wellbeingGoalsColumns, setWellbeingGoalsDataColumns] = useState()

  useEffect(() => {
    try {
      setWellbeingGoals(JSON.parse(props.formDetails.wellbeingGoals))
    } catch (err) {
      console.error(err)
    }
  }, [props.formDetails.wellbeingGoals])

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.nextPage(localFormDetails)
  }

  // Wellbeing Goals management functions
  const addWellbeingGoal = () => {
    // Don't save if all fields empty
    if (localFormDetails.wellbeingGoal !== '' || localFormDetails.wellbeingGoalAction !== '' || localFormDetails.wellbeingGoalActionee !== '' || localFormDetails.wellbeingGoalDate !== '') {
      const event = {
        wellbeingGoal: localFormDetails.wellbeingGoal,
        wellbeingGoalAction: localFormDetails.wellbeingGoalAction,
        wellbeingGoalActionee: localFormDetails.wellbeingGoalActionee,
        wellbeingGoalDate: localFormDetails.wellbeingGoalDate
      }

      setWellbeingGoals(wellbeingGoals => [...wellbeingGoals, event])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        wellbeingGoal: '',
        wellbeingGoalAction: '',
        wellbeingGoalActionee: '',
        wellbeingGoalDate: ''
      }))

      setWellbeingGoalAddButton(true)
    }
  }

  const removeWellbeingGoal = (data) => {
    const newEvent = wellbeingGoals.filter((item) => item.wellbeingGoal !== data.wellbeingGoal)

    setWellbeingGoals(newEvent)
  }

  const editWellbeingGoal = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      wellbeingGoal: data.wellbeingGoal,
      wellbeingGoalAction: data.wellbeingGoalAction,
      wellbeingGoalActionee: data.wellbeingGoalActionee,
      wellbeingGoalDate: data.wellbeingGoalDate
    }))

    removeWellbeingGoal(data)
    setWellbeingGoalAddButton(false)
  }

  // Wellbeing Goals have been altered
  // so rebuild the display list
  useEffect(() => {
    setWellbeingGoalsDataColumns([
      {
        property: 'wellbeingGoal',
        header: <Text pad='none' margin='none'>Goal</Text>
      },
      {
        property: 'wellbeingGoalAction',
        header: <Text>What action is needed?</Text>
      },
      {
        property: 'wellbeingGoalActionee',
        header: <Text pad='none' margin='none'>Who is going to do this?</Text>
      },
      {
        property: 'wellbeingGoalDate',
        header: <Text pad='none' margin='none'>By when?</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeWellbeingGoal(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editWellbeingGoal(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedWellbeingGoals = wellbeingGoals.map((event, index) => ({
      wellbeingGoal: event.wellbeingGoal,
      wellbeingGoalAction: event.wellbeingGoalAction,
      wellbeingGoalActionee: event.wellbeingGoalActionee,
      wellbeingGoalDate: moment(event.wellbeingGoalDate).format('YYYY-MM-DD')
    }))

    setMappedWellbeingGoals(mappedWellbeingGoals)

    // Stringify wellbeing goals and store them
    const wellbeingGoalsToSave = JSON.stringify(wellbeingGoals)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      wellbeingGoals: wellbeingGoalsToSave
    }))
  }, [wellbeingGoals])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Mental/Physical Health and Wellbeing</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setWellbeingGoalAddButton(nextValue?.wellbeingGoal === '' || nextValue?.wellbeingGoalAction === '' || nextValue?.wellbeingGoalActionee === '' || nextValue?.wellbeingGoalDate === '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* wellbeing */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  width='100%'
                >
                  <TextArea
                    name={questions[0].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* wellbeingRisk */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <OptionButtons
                    id={questions[1].key}
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              {localFormDetails.wellbeingRisk === 'Yes' &&
                <Box direction='row-responsive' gap='small'>
                  {/* wellbeingRiskReason */}
                  <FormField
                    label={questions[2].questionText}
                    name={questions[2].key}
                    width='100%'
                  >
                    <TextArea
                      name={questions[2].key}
                    />
                  </FormField>
                </Box>}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Goals</Text>

              {mappedWellbeingGoals.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={wellbeingGoalsColumns}
                    data={mappedWellbeingGoals}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}

              <Box direction='row-responsive' gap='medium'>
                {/* wellbeingGoal */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                >
                  <TextInput
                    name={questions[3].key}
                    type='text'
                  />
                </FormField>

                {/* wellbeingGoalAction */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                >
                  <TextInput
                    name={questions[4].key}
                    type='text'
                  />
                </FormField>

                {/* wellbeingGoalActionee */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                >
                  <TextInput
                    name={questions[5].key}
                    type='text'
                  />
                </FormField>

                {/* wellbeingGoalDate */}
                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                >
                  <DateInput
                    name={questions[6].key}
                  />
                </FormField>

                <Button disabled={wellbeingGoalAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addWellbeingGoal()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Wellbeing
