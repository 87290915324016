import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { capitalize } from '../../utils/text'
import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'

// ServiceUserEdit screens
import Access from './ServiceUserEdit-Access'
import Basic from './ServiceUserEdit-Basic'
import Personal from './ServiceUserEdit-Personal'
import Sensitive from './ServiceUserEdit-Sensitive'
import Forms from './ServiceUserEdit-Forms'
import Contacts from './ServiceUserEdit-Contacts'
import Documents from '../shared/User-Documents'
import Notes from '../shared/User-Notes'
import Audit from './ServiceUserEdit-Audit'

function ServiceUserEdit(props) {
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  // Indicates form has changed
  var formChanged = false

  const setFormChanged = () => {
    formChanged = true
  }

  const [userDetails, setUserDetails] = useState({
    // Basic
    firstName: '',
    lastName: '',
    // email: '',
    reference: '',
    dob: '',
    ethnicity: '',
    gender: '',
    directAccessOnly: false,
    knownAs: '',
    preferredPronoun: '',
    startedSupportDate: '',
    primaryLanguage: '',
    interpreterNeeded: '',
    childcareNeeded: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    otherLanguage: '',
    otherInterpreterNeeded: '',
    otherChildcareNeeded: '',
    supportOrganisation: '',
    caseworkerAssigned: '',
    counsellorAssigned: '',
    volunteersAssigned: '',
    meetingFrequency: '',
    weeklyActivitiesRegistered: '',
    counsellingReferralDate: '',
    lastUpdated: '',
    stickyNote: '',
    // Personal
    address: '',
    postcode: '',
    city: '',
    mobile: '',
    email: '',
    preferredContactMethod: '',
    clientDob: '',
    healthDiagnosis: '',
    previousAddress: [],
    previousAddressStreet: '',
    previousPostcode: '',
    previousCity: '',
    dateMovedInAddress: '',
    dateMovedOutAddress: '',
    partnerName: '',
    partnerDob: '',
    partnerLivingWithClient: '',
    firstNameChild: '',
    lastNameChild: '',
    dobChild: '',
    livingWithClientChild: '',
    children: '',
    hoReferenceNumber: '',
    immigrationStatus: '',
    nino: '',
    nrmStatus: '',
    countryOfOrigin: '',
    renovationDate: '',
    renovationDescription: '',
    renovations: '',
    assignedAssessor: '',
    assessmentOutcome: '',
    referringAgency: '',
    suitableForPlacement: '',
    referredDate: '',
    referredDateCounselling: '',
    referredDateFirst: '',
    supportEndedDate: '',
    assessmentCompletedDate: '',
    counsellingStartedDate: '',
    counsellingCompletedDate: '',
    attendanceFormCompletedDate: '',
    NRMExitDate: '',
    CGDate: '',
    personalNotes: '',
    brpCardNumber: ''
  })

  useEffect(() => {
  }, [userDetails])

  const [meterValue, setMeterValue] = useState()

  // Determine if initial user details/tags have been loaded
  // otherwise fields don't get populated
  const [detailsLoaded, setDetailsLoaded] = useState(true)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // add previous addresses
  const [previousAddresses, setPreviousAddresses] = useState([])
  const [children, setChildren] = useState([])
  const [renovations, setRenovations] = useState([])

  // This array indicates the sensitivity setting that needs
  // to be set for the User Tag settings
  // notag indicates keys that should not be saved as Tags as
  // they are saved as standard User settings (or are Children or Renovations fields)

  var tabs = []
  if (hasAnyPermissionIn(['clientViewAllBasic', 'clientViewMyBasic'])) {
    tabs.push({
      tabId: 0,
      sensitivity: 'basic',
      notag: [
        'firstName',
        'lastName',
        'reference',
        'dob',
        'gender'
      ]
    })
  }

  if (hasAnyPermissionIn(['clientViewAllPersonal', 'clientViewMyPersonal'])) {
    tabs.push({
      tabId: 1,
      sensitivity: 'personal',
      notag: [
        'email',
        'firstNameChild',
        'lastNameChild',
        'dobChild',
        'livingWithClientChild',
        'hasComeOnReunion',
        'renovationDate',
        'renovationDescription'
      ]
    })
  }

  if (hasAnyPermissionIn(['clientViewAllSensitive', 'clientViewMySensitive'])) {
    tabs.push({
      tabId: 2,
      sensitivity: 'sensitive',
      noTag: []
    })
  }

  const [tabSensitivity] = useState(tabs)

  // A list of questions for each tab
  const [questions, setQuestions] = useState([
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Reference',
          key: 'reference'
        },
        {
          questionText: 'First Name',
          key: 'firstName'
        },
        {
          questionText: 'Last Name',
          key: 'lastName'
        },
        {
          questionText: 'Known As',
          key: 'knownAs'
        },
        {
          questionText: 'Date of Birth',
          key: 'dob'
        },
        {
          questionText: 'Gender',
          key: 'gender'
        },
        {
          questionText: 'Preferred Pronoun',
          key: 'preferredPronoun'
        },
        {
          questionText: 'Support Started',
          key: 'startedSupportDate'
        },
        {
          questionText: 'Primary Language',
          key: 'primaryLanguage'
        },
        {
          questionText: 'Interpreter Needed',
          key: 'interpreterNeeded'
        },
        {
          questionText: 'Childcare Needed',
          key: 'childcareNeeded'
        },
        {
          questionText: 'Other Language',
          key: 'otherLanguage'
        },
        {
          questionText: 'Name of emergency contact/next of kin',
          key: 'emergencyContactName'
        },
        {
          questionText: 'Phone/contact of emergency contact/next of kin',
          key: 'emergencyContactPhone'
        },
        {
          questionText: 'Interpreter Needed',
          key: 'otherInterpreterNeeded'
        },
        {
          questionText: 'Childcare Needed',
          key: 'otherChildcareNeeded'
        },
        {
          questionText: 'Support Organisation',
          key: 'supportOrganisation'
        },
        {
          questionText: 'Assign Caseworker',
          key: 'caseworkerAssigned'
        },
        {
          questionText: 'Assign Volunteers',
          key: 'volunteersAssigned'
        },
        {
          questionText: 'Freq. Volunteer Meetings',
          key: 'meetingFrequency'
        },
        {
          questionText: 'Registered Weekly Activities',
          key: 'weeklyActivitiesRegistered'
        },
        {
          questionText: 'Date Counselling Referral',
          key: 'counsellingReferralDate'
        },
        {
          questionText: 'Last Updated',
          key: 'lastUpdated'
        },
        {
          questionText: 'Sticky Note',
          key: 'stickyNote'
        },
        {
          questionText: 'Childcare Needed Description',
          key: 'childcareNeededDescription'
        },
        {
          questionText: 'Assign Counsellor',
          key: 'counsellorAssigned'
        }
      ]
    },
    {
      tabId: 1,
      // eslint-disable-next-line no-sparse-arrays
      questions: [
        {
          questionText: 'Address',
          key: 'address'
        },
        {
          questionText: 'Postcode',
          key: 'postcode'
        },
        {
          questionText: 'City',
          key: 'city'
        },
        {
          questionText: 'Mobile',
          key: 'mobile'
        },
        {
          questionText: 'Email',
          key: 'email'
        },
        {
          questionText: 'Preferred Contact Method',
          key: 'preferredContactMethod'
        },

        {
          questionText: 'Client Date of Birth',
          key: 'clientDob'
        },
        {
          questionText: 'Disability or Mental/Physical Health Diagnosis',
          key: 'healthDiagnosis'
        },
        {
          questionText: 'Partner Name',
          key: 'partnerName'
        },
        {
          questionText: 'Partner Date of Birth',
          key: 'partnerDob'
        },
        {
          questionText: 'Partner Living with Client',
          key: 'partnerLivingWithClient'
        },
        {
          questionText: 'First Name',
          key: 'firstNameChild'
        },
        {
          questionText: 'Last Name',
          key: 'lastNameChild'
        },
        {
          questionText: 'Date of Birth',
          key: 'dobChild'
        },
        {
          questionText: 'Living with Client',
          key: 'livingWithClientChild'
        },
        {
          questionText: 'HO Reference Number',
          key: 'hoReferenceNumber'
        },
        {
          questionText: 'Immigration Status',
          key: 'immigrationStatus'
        },
        {
          questionText: 'NINO',
          key: 'nino'
        },
        {
          questionText: 'NRM Status',
          key: 'nrmStatus'
        },
        {
          questionText: 'Country of Origin',
          key: 'countryOfOrigin'
        },
        {
          questionText: 'Date',
          key: 'renovationDate'
        },
        {
          questionText: 'Description',
          key: 'renovationDescription'
        },
        {
          questionText: 'Assigned Assessor',
          key: 'assignedAssessor'
        },
        {
          questionText: 'Assessment Outcome (Which pathway)',
          key: 'assessmentOutcome'
        },
        {
          questionText: 'Referring Agency (Casework)',
          key: 'referringAgency'
        },
        {
          questionText: 'Suitable for Placement Student',
          key: 'suitableForPlacement'
        },
        {
          questionText: 'Referred for Casework',
          key: 'referredDate'
        },
        {
          questionText: 'Support Ended',
          key: 'supportEndedDate'
        },
        {
          questionText: 'Assessment Completed',
          key: 'assessmentCompletedDate'
        },
        {
          questionText: 'Counselling - Start',
          key: 'counsellingStartedDate'
        },
        {
          questionText: 'Counselling - End',
          key: 'counsellingCompletedDate'
        },
        {
          questionText: 'Attendance Form Completed',
          key: 'attendanceFormCompletedDate'
        },
        {
          questionText: 'NRM Exit Date',
          key: 'NRMExitDate'
        },
        {
          questionText: 'CG Date',
          key: 'CGDate'
        },
        {
          questionText: 'Personal Notes',
          key: 'personalNotes'
        },
        // Hidden question to store the array of children
        {
          questionText: 'Children',
          key: 'children'
        },
        // Hidden question to store the array of renovations
        {
          questionText: 'Renovations',
          key: 'renovations'
        },
        {
          questionText: 'Mobile (second)',
          key: 'mobileSecond'
        },
        {
          questionText: 'Has Come on Family Reunion',
          key: 'hasComeOnReunion'
        },
        {
          questionText: 'Father Name',
          key: 'fatherName'
        },
        {
          questionText: 'Father Date of Birth',
          key: 'fatherDob'
        },
        {
          questionText: 'Father Living with Client',
          key: 'fatherLivingWithClient'
        },
        {
          questionText: 'Referring Agency (Counselling)',
          key: 'referringAgencyCounselling'
        },
        {
          // previous address question
          questionText: 'Address',
          key: 'previousAddressStreet'
        },
        {
          questionText: 'Postcode',
          key: 'previousPostcode'
        },
        {
          questionText: 'City',
          key: 'previousCity'
        },
        {
          questionText: 'Date Moved In',
          key: 'dateMovedInAddress'
        },
        {
          questionText: 'Date Moved Out',
          key: 'dateMovedOutAddress'
        },
        { // BRP card number question
          questionText: 'BRP Card Number',
          key: 'brpCardNumber”'
        },
        { // client mother question
          questionText: 'Mother Name',
          key: 'motherName'
        },
        {
          questionText: 'Mother Date of Birth',
          key: 'motherDob'
        },
        {
          questionText: 'Mother Living with Client',
          key: 'motherLivingWithClient'
        },
        { // Hidden question to store the array of addresses
          questionText: 'Previous Address',
          key: 'previousAddresses'
        },
        {
          questionText: 'Referred for Counselling',
          key: 'referredDateCounselling'
        },
        {
          questionText: 'First Referred',
          key: 'referredDateFirst'
        }
      ]
    },
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Type of Trafficking - Primary',
          key: 'traffickingType'
        },
        {
          questionText: 'Literacy Level',
          key: 'literacyLevel'
        },
        {
          questionText: 'Education',
          key: 'education1'
        },
        {
          questionText: 'Employment',
          key: 'employment1'
        },
        {
          questionText: 'Housing Type/Situation',
          key: 'housingType1'
        },
        {
          questionText: 'Income',
          key: 'income2'
        },
        {
          questionText: 'Employment',
          key: 'employment2'
        },
        {
          questionText: 'Housing Type/Situation',
          key: 'housingType2'
        },
        {
          questionText: 'Income',
          key: 'income'
        },
        {
          questionText: 'Legal Interventions',
          key: 'legalInterventions'
        },
        {
          questionText: 'Previous Pathways',
          key: 'previousPathways'
        },
        {
          questionText: 'Counselling Reports Completed (Pathway 4)',
          key: 'counsellingReportsCompleted'
        },
        {
          questionText: 'Last Completed',
          key: 'carePlanLastCompleted'
        },
        {
          questionText: 'Next Due',
          key: 'carePlanNextDue'
        },
        {
          questionText: 'Last Completed',
          key: 'monitoringFormLastCompleted'
        },
        {
          questionText: 'Next Due',
          key: 'monitoringFormNextDue'
        },
        {
          questionText: 'Sensitive Notes (Only visible on this tab)',
          key: 'sensitiveNotes'
        },
        {
          questionText: 'Type of Trafficking - Secondary',
          key: 'traffickingTypeSecondary'
        },
        {
          questionText: 'DWP Benefits (separate by commas)',
          key: 'dwpBenefitsOther'
        },
        {
          questionText: 'First Name',
          key: 'professionalContactDetailsFirstName'
        },
        {
          questionText: 'Last Name',
          key: 'professionalContactDetailsLastName'
        },
        {
          questionText: 'Address',
          key: 'professionalContactDetailsAddress'
        },
        {
          questionText: 'Postcode',
          key: 'professionalContactDetailspostcode'
        },
        {
          questionText: 'City',
          key: 'professionalContactDetailscity'
        },
        {
          questionText: 'Mobile',
          key: 'professionalContactDetailsmobile'
        },
        {
          questionText: 'Email',
          key: 'professionalContactDetailsemail'
        }
      ]
    },
    // Documents - dummy questions
    {
      tabId: 3,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    },
    // Notes - dummy questions
    {
      tabId: 4,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
      // questions: [
      //   {
      //     questionText: 'Type',
      //     key: 'noteType'
      //   },
      //   {
      //     questionText: 'Description',
      //     key: 'noteDescription'
      //   },
      //   {
      //     questionText: 'Assign To',
      //     key: 'noteAssignTo'
      //   }
      // ]
    },
    // Forms - dummy questions
    {
      tabId: 5,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    },
    // Access - dummy questions
    {
      tabId: 6,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    },
    {
      tabId: 7,
      questions: []
    }
  ])

  // Submit User Details
  const submitUserDetails = async (formDetailsToSave) => {
    // Update user details with 'local' data
    setUserDetails(formDetailsToSave)
    let parsedPreviousAddresses = formDetailsToSave.previousAddresses
    let parsedChildren = formDetailsToSave.children
    let parsedRenovations = formDetailsToSave.renovations

    // Re-parse previous address if stringified
    if (!Array.isArray(formDetailsToSave.previousAddresses) && (formDetailsToSave.previousAddresses !== undefined && formDetailsToSave.previousAddresses !== '')) {
      parsedPreviousAddresses = JSON.parse(formDetailsToSave.previousAddresses)
    }
    // Re-parse children if stringified
    if (!Array.isArray(formDetailsToSave.children) && (formDetailsToSave.children !== undefined && formDetailsToSave.children !== '')) {
      parsedChildren = JSON.parse(formDetailsToSave.children)
    }
    // Re-parse renovations if stringified
    if (!Array.isArray(formDetailsToSave.renovations) && formDetailsToSave.renovations !== undefined && formDetailsToSave.renovations !== '') {
      parsedRenovations = JSON.parse(formDetailsToSave.renovations)
    }

    setUserDetails(prevState => ({
      ...prevState,
      previousAddresses: parsedPreviousAddresses,
      children: parsedChildren,
      renovations: parsedRenovations
    }))

    switch (tabIndex) {
      // Documents
      case 3:
        // if (formDetailsToSave.length === 0) {
        //   // nextPage()
        // } else {
        //   formDetailsToSave.forEach(async (document) => {
        //     const params = {
        //       orgId: activeOrg.id
        //     }

        //     let data = {
        //       fileName: document.path,
        //       contentType: document.type,
        //       file: document.preview
        //     }

        //     let response = await UploadService.file(apiToken, params, data)
        //     if (response?.error) {
        //       NotificationService.error(response.error)
        //     } else {
        //       data = {
        //         organisation: activeOrg.id,
        //         title: document.name,
        //         type: 'document',
        //         documentUrl: response.data.baseUrl
        //       }

        //       response = await MediaService.create(apiToken, params, data)

        //       if (response?.error) {
        //         NotificationService.error(response.error)
        //       } else {
        //         NotificationService.success('Data successfully saved')
        //         // nextPage()
        //       }
        //     }
        //   })
        // }
        break
      // // Notes
      // case 4:
      //   const data = {
      //     organisation: activeOrg.id,
      //     title: formDetailsToSave.name,
      //     type: 'note',
      //     documentUrl: response.data.baseUrl
      //   }

      //   response = await MediaService.create(apiToken, params, data)

      //   if (response?.error) {
      //     NotificationService.error(response.error)
      //   }
      //   break
      default:
        var data = {
          reference: formDetailsToSave?.reference,
          firstName: formDetailsToSave?.firstName,
          lastName: formDetailsToSave?.lastName,
          email: formDetailsToSave?.email,
          gender: formDetailsToSave?.gender,
          ethnicity: formDetailsToSave?.ethnicity,
          type: 'client'
        }

        if (formDetailsToSave.dob) {
          data.dob = moment(formDetailsToSave.dob).format('YYYY-MM-DD')
        }

        if (userId === 'new') {
          const dataToSubmit = {
            data: data,
            organisation: activeOrg.id
          }

          // Add User
          const params = {
            orgId: activeOrg.id
          }

          var userAdded = await UserService.createUser(apiToken, dataToSubmit, params)
          if (userAdded.error) {
            NotificationService.error(userAdded.error)
          } else {
            // And then User Tags
            const userTags = buildUserTags(formDetailsToSave, userAdded.data.id[0])

            const response = UserTagService.set(apiToken, userTags, 'client')
            if (response?.error) {
              NotificationService.error(response.error)
            } else {
              NotificationService.success('Data successfully saved')
              history.push('/clients')
              // nextPage()
            }
          }
        } else {
          // Save the standard User information
          data.id = userId

          const userData = {
            data: data,
            organisation: activeOrg.id
          }
          var userUpdated = await UserService.updateUser(apiToken, userId, userData, {
            type: 'client'
          })
          if (userUpdated.error) {
            NotificationService.error(userUpdated.error)
          } else {
            // Now save the User Tags
            const userTags = buildUserTags(formDetailsToSave, userId)
            const response = UserTagService.set(apiToken, userTags, 'client')
            if (response?.error) {
              NotificationService.error(response.error)
            } else {
              NotificationService.success('Data successfully saved')

              // Clear the form changed flag
              formChanged = false
            }
          }
        }
    }
  }

  const params = useParams()

  const userId = params.userid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    if (!formChanged) {
      setMeterValue((nextIndex + 1) * 100 / questions.length)
      setTabIndex(nextIndex)
    } else {
      NotificationService.warning('Form details have changed, please save before changing tab')
    }
  }

  const previousPage = () => {
    // go back to the client screen
    history.push('/clients')
  }

  const tabContent = []

  tabContent.push({
    name: 'Basic',
    hideTitle: false,
    content: <Basic setFormChanged={setFormChanged} previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[0].questions} />
  })

  // only show the extra tabs when editing a user
  if (userId !== 'new') {
    if (hasAnyPermissionIn(['clientViewAllPersonal', 'clientViewMyPersonal'])) {
      tabContent.push({
        name: 'Personal',
        hideTitle: false,
        content: <Personal setFormChanged={setFormChanged} previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[1].questions} children={children} setChildren={setChildren} renovations={renovations} setRenovations={setRenovations} />
      })
    }

    if (hasAnyPermissionIn(['clientViewAllSensitive', 'clientViewMySensitive'])) {
      tabContent.push({
        name: 'Sensitive',
        hideTitle: false,
        content: <Sensitive setFormChanged={setFormChanged} previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[2].questions} />
      })
    }

    if (hasAnyPermissionIn(['clientDocumentView', 'clientDocumentEdit'])) {
      tabContent.push({
        name: 'Documents',
        hideTitle: false,
        content: <Documents previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[3].questions} />
      })
    }

    if (hasAnyPermissionIn(['clientNoteView', 'clientNoteEdit'])) {
      tabContent.push({
        name: 'Notes',
        hideTitle: false,
        content: <Notes previousPage={previousPage} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[4].questions} />
      })
    }

    if (hasPermission('clientContactView')) {
      tabContent.push({
        name: 'Contacts',
        hideTitle: false,
        content: <Contacts userDetails={userDetails} previousPage={previousPage} />
      })
    }

    if (hasAnyPermissionIn(['clientFormView', 'clientFormEdit'])) {
      tabContent.push({
        name: 'Forms',
        hideTitle: false,
        content: <Forms previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[3].questions} />
      })
    }

    if (hasPermission('clientGrantDirectAccess')) {
      tabContent.push({
        name: 'Access',
        hideTitle: false,
        content: <Access previousPage={previousPage} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[5].questions} />
      })
    }

    if (hasPermission('clientAuditTrail')) {
      tabContent.push({
        name: 'Audit Trail',
        hideTitle: false,
        content: <Audit previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[3].questions} />
      })
    }


  }

  // Build array ready for UserTag submission
  const buildUserTags = (formDetailsToSave, userId) => {
    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through question on current tab
    // Skip any that are marked as notag
    // Building array for User Tag saving
    for (var value of questions[tabIndex].questions) {
      // if (checkInputHasContent(formDetailsToSave[value.key]) && !tabSensitivity?.[tabIndex]?.notag?.includes(value.key)) {
      if (!tabSensitivity?.[tabIndex]?.notag?.includes(value.key)) {
        var sensitivity = tabSensitivity[tabIndex].sensitivity ? tabSensitivity[tabIndex].sensitivity : 'default'

        item = {
          user: userId,
          organisation: activeOrg.id,
          key: value.key,
          value: formDetailsToSave[value.key] || '',
          type: 'general',
          sensitivity: sensitivity
        }
        submitArray.push(item)
      }
    }

    return submitArray
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    if (!input) {
      return inputHasContent
    }

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }


  useEffect(() => {
    let unmounted = false

    // Check if adding a new User or editing an existing one
    const getUserDetails = async (userId) => {
      const userDetails = await UserService.getUser(apiToken, userId, {
        type: 'client',
        fields: 'id,reference,firstName,lastName,email,dob,ethnicity,gender,directAccessOnly'
      })

      if (!userDetails?.data?.length) {
        NotificationService.error('Access denied. Unable to load record.')
        history.push('/clients')
        return
      }

      if (!unmounted) {
        setUserDetails(prevState => ({
          ...prevState,
          reference: userDetails?.data?.[0]?.reference,
          firstName: userDetails?.data?.[0]?.firstName,
          lastName: userDetails?.data?.[0]?.lastName,
          email: userDetails?.data?.[0]?.email,
          dob: userDetails?.data?.[0]?.dob ? moment(userDetails?.data?.[0]?.dob).format('YYYY-MM-DD') : null,
          ethnicity: userDetails?.data?.[0]?.ethnicity || '',
          gender: userDetails?.data?.[0]?.gender,
          directAccessOnly: userDetails?.data?.[0]?.directAccessOnly ? true : false
        }))
      }
    }

    const getUserTags = async (userId) => {
      const where = {
        user: userId,
        type: 'general',
        organisation: activeOrg.id,
        site: activeSite.id,
        sensitivity: ['basic', 'personal', 'sensitive']
      }

      const params = {
        fields: 'id,key,value',
        type: 'client',
        limit: 1000
      }

      // Get User Tags and format for saving as form values
      const userTags = await UserTagService.get(apiToken, params, where)
      const tagsToSave = {}
      if (userTags?.length) {
        for (const tag of userTags) {
          let tagOK = true

          // Don't save any fields that should not be tags
          // This is probably not needed once the system
          // is up and running - just items such as firstName
          // may be saved as a tag
          tabSensitivity.forEach((values) => {
            if (values.notag) {
              if (values.notag.includes(tag.key) && tagOK) {
                tagOK = false
              }
            }
          })

          if (tagOK) {
            // If key is children save to children state
            if (tag.key === 'children') {
              const childrenToSave = []
              var parsedChildren = []
              try {
                parsedChildren = JSON.parse(tag.value)
              } catch (err) {}
              let childKey = 0

              parsedChildren.forEach((child) => {
                childrenToSave.push({
                  childKey: childKey++,
                  firstNameChild: child.firstNameChild,
                  lastNameChild: child.lastNameChild,
                  dobChild: child.dobChild,
                  livingWithClientChild: child.livingWithClientChild,
                  hasComeOnReunion: child.hasComeOnReunion
                })
              })

              setUserDetails(prevState => ({
                ...prevState,
                children: childrenToSave
              }))
              // If key is previousAddress save to previousAddress state
            } else if (tag.key === 'previousAddresses' && tag.value) {

              const previousAddressToSave = []
              const parsedPreviousAddress = JSON.parse(tag.value)
              let previousAddressStreetKey = 0

              parsedPreviousAddress.forEach((previousAddress) => {
                previousAddressToSave.push({
                  previousAddressStreetKey: previousAddressStreetKey++,
                  previousAddressStreet: previousAddress.previousAddressStreet,
                  previousPostcode: previousAddress.previousPostcode,
                  previousCity: previousAddress.previousCity,
                  dateMovedInAddress: previousAddress.dateMovedInAddress,
                  dateMovedOutAddress: previousAddress.dateMovedOutAddress
                })
              })
              setUserDetails(prevState => ({
                ...prevState,
                previousAddresses: previousAddressToSave
              }))

            } else if (tag.key === 'renovations') {
              // If key is renovations save to renovations state
              const renovationsToSave = []
              if (tag.value) {
                const parsedRenovations = JSON.parse(tag.value)
                let renovationKey = 0

                parsedRenovations.forEach((renovation) => {
                  renovationsToSave.push({
                    renovationKey: renovationKey++,
                    renovationDate: renovation.renovationDate,
                    renovationDescription: renovation.renovationDescription
                  })
                })
              }

              setUserDetails(prevState => ({
                ...prevState,
                renovations: renovationsToSave
              }))
            } else {
              tagsToSave[tag.key] = tag.value
            }
          }
        }
        setDetailsLoaded(true)
      }

      // Convert to integer
      tagsToSave.caseworkerAssigned = parseInt(tagsToSave.caseworkerAssigned)
      tagsToSave.counsellorAssigned = parseInt(tagsToSave.counsellorAssigned)
      tagsToSave.volunteersAssigned = parseInt(tagsToSave.volunteersAssigned)

      // Add saved tags to form data
      setUserDetails(prevState => ({
        ...prevState,
        ...tagsToSave
      }))
    }

    if (userId !== 'new') {
      if (!hasAnyPermissionIn(['clientEditMy', 'clientEditAll'])) {
        history.push('/dashboard')
      } else {
        getUserDetails(userId)
        getUserTags(userId)
      }
    } else {
      setDetailsLoaded(true)

      if (!hasPermission('clientCreate')) {
        history.push('/dashboard')
      }
    }

    setLoading(false)

    // Set initial Meter setting
    setMeterValue(100 / questions.length)

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>
            {capitalize(props?.terms?.serviceUser || 'Client')}:
            {userDetails.firstName ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}
          </Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {userId !== 'new' && hasPermission('clientFormEdit') &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge' weight='400' margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              {hasAnyPermissionIn(['formAccessRiskMatrix', 'formAccessAll']) &&
                <Button href={`/form/riskmatrix/new/edit/${userId}`} label={<Text><FontAwesomeIcon icon={['fal', 'calculator']} /> Risk Matrix</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />}
            </Box>
          </Box>
        </Box>}
      {userDetails?.stickyNote &&
        <Box background='stickyNote' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' elevation='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text weight='bold'>Sticky Note:</Text>
              <Text>{userDetails?.stickyNote}</Text>
            </Box>
          </Box>
        </Box>}

      <Box background='white' elevation='small' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
        >
          {loading ? (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>
          ) : (
            <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />)}
        </Box>
      </Box>
    </Box>

  )
}

export default ServiceUserEdit
