import React, { useState, useEffect } from 'react'

import getKeyByValue from '../../utils/objects'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import RoleService from '../../services/RoleService'
import SiteService from '../../services/SiteService'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import CheckBox from '../../components/simple/input/CheckBox'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'
import { isGlobalAdmin, hasPermission } from '../../utils/permissions'

function StaffEditRoles (props) {
  const [sites, setSites] = useState([])
  const [siteRoles, setSiteRoles] = useState()
  const [userRolesBySite, setUserRolesBySite] = useState({})

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const checkGlobalAdmin = () => {
    // CheckBox component requires a function
    return
  }

  const submitForm = async (values) => {
    for (const site of sites) {

      const roleKey = `role-site-${site.id}`

      // is there a value submitted? Use that
      if (values[roleKey]) {

        if (values[roleKey] === 'noaccess') {

          // do they have a role for this site?
          if (userRolesBySite?.[site.id]?.id ) {
            // remove the role
            const result = await RoleService.removeRoleUser(apiToken, userRolesBySite?.[site.id].id, props.userId)
            if (result.error) {
              NotificationService.error(result.error)
            }
          }

        } else {
          const result = await RoleService.addRoleUser(apiToken, site.id, values[roleKey], props.userId)
          if (result.error) {
            NotificationService.error(result.error)
          }
          // otherwise, add the role

        }
      }
    }

    NotificationService.success('The roles have been successfully updated.')
    NotificationService.warning('The staff member will need to logout and then back in to see the changes to their role.')
  }

  const getRolesForUser = async (userId) => {
    // const params = {
    //   fields: 'id,title,permissions,createdAt',
    //   limit: 1000,
    //   siteId: site.id
    // }

    const roles = await RoleService.getRolesForUser(userId)
    if (!roles || roles.error) {
    } else if (roles.data) {

      var rolesBySite = {}
      for (const thisRole of roles.data) {
        const site = thisRole.site || null
        rolesBySite[site] = thisRole
      }
      setUserRolesBySite(rolesBySite)
    }
  }

  useEffect(() => {
    let unmounted = false

    // Get all Sites for this Org
    // and the associated Roles for each Site
    const getSites = async () => {
      const params = {
        fields: 'id,name',
        limit: 1000
      }

      const sites = await SiteService.getSites(apiToken, params)
      if (!sites.error) {
        if (!unmounted) {
          sites.data.forEach((site) => {
            getRolesForSite(site)
          })
        }
      }
    }

    const getRolesForSite = async (site) => {
      const params = {
        fields: 'id,title,permissions,createdAt',
        limit: 1000,
        siteId: site.id
      }

      const roles = await RoleService.getRoles(apiToken, params)
      if (roles.error) {
      } else {
        // Add a null role
        roles.data.unshift(
          {
            id: 'noaccess',
            title: '- No Access -'
          }
        )
        const thisSite = {
          id: site.id,
          name: site.name,
          roles: roles.data
        }
        setSites(sites => [...sites, thisSite])

        console.log(roles)
      }
    }



    getRolesForUser(props.userId)
    getSites()

    return () => { unmounted = true }
  }, [])

  // useEffect(() => {
  //   console.log(sites)
  // }, [sites])

  return (
    <Form
      //   validate='blur'
      onChange={nextValue => {
        // console.log(nextValue)
        setSiteRoles(nextValue)
      }}
      onSubmit={({ value: nextValue }) => {
      // console.log(nextValue)
        submitForm(nextValue)
      }}
      value={siteRoles}
    >
      <Divider color='primary' />
      <Box direction='row-responsive' gap='medium'>
        <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>
          <Text margin={{ top: 'xsmall' }} size='large'>Roles</Text>
          {sites.map((site, index) => {
            return (
              <Box direction='row-responsive' gap='medium' key={index}>
                <FormField
                  label='Site'
                  name={`role-site-${site.id}`}
                  width='small'
                >
                  <Text>{site.name}</Text>
                </FormField>

                <FormField
                  label='Role'
                  name={`role-site-${site.id}`}
                >
                  <Select
                    id='siteRole'
                    name={`role-site-${site.id}`}
                    labelKey='title'
                    defaultValue={(userRolesBySite[site.id] && userRolesBySite[site.id].id)}
                    placeholder='- No Access -'
                  //   onChange={({ option }) => changeOrganisation(option)}
                    options={site.roles}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>
              </Box>)
          })}
        </Box>

        {/* This needs to be hidden if User is not a global admin */}
        {isGlobalAdmin() && (
          <Box direction='column' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Text>Global Admin</Text>
            <FormField
              margin={{ top: 'small' }}
              name='isGlobalAdmin'
            >
              <CheckBox
                label='Is Global Admin'
                name='isGlobalAdmin'
                onChange={checkGlobalAdmin}
                disabled
                toggle
              />
            </FormField>
          </Box>
        )}

      </Box>
      <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

      <Box direction='row' justify='between' margin={{ top: 'medium' }}>
        <Button label='< Back' onClick={() => props.goBack()} secondary />
        <Button type='submit' label='Save' primary />
      </Box>

    </Form>
  )
}

export default StaffEditRoles
