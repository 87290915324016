import React, { useEffect, useState } from 'react'

import { useParams, useHistory } from 'react-router-dom'



// // Spinner
// import PulseLoader from 'react-spinners/PulseLoader'

// // Services
import LocalStorageService from '../../../services/LocalStorageService'
import FormService from '../../../services/FormService'



// // Appt Components
// import FormField from '../../../components/simple/formField/FormField.js'
// import Select from '../../../components/simple/input/Select'
import Button from '../../../components/simple/button/Button'
import Box from '../../../components/simple/box/Box'
import TextArea from '../../../components/simple/input/TextArea'
import FormField from '../../../components/simple/formField/FormField'

function FeedbackSubmissionButton (props) {
  // const [loading, setLoading] = useState(false)

  const [showSubmit, setShowSubmit] = useState(false)

  const [waitingForFeedback, setWaitingForFeedback] = useState(false)
  const [currentUserIsFeedback, setCurrentUserIsFeedback] = useState(false)
  const [waitingForRevision, setWaitingForRevision] = useState(false)
  const [currentUserIsRevision, setCurrentUserIsRevision] = useState(false)
  const [currentFormId, setCurrentFormId] = useState()

  const [feedback, setFeedback] = useState()

  // const [localFormDetails, setLocalFormDetails] = useState(props.localFormDetails)
  // useEffect(() => setLocalFormDetails(props.localFormDetails), [props.localFormDetails])
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')
  const activeUser = LocalStorageService.get('userDetails')
  const params = useParams()
  const history = useHistory()


  const getFormSubmission = async () => {
    const reqParams = {
      fields: 'id,user,organisation,reference,type,status,createdAt,feedbackRequired,feedbackRequiredBy,revisionRequired',
      // limit: 1,
      sort: 'createdAt DESC',
      orgId: activeOrg.id,
      siteId: activeSite.id,
      where: {
        orgId: activeOrg.id,
        id: params.formId
        // subject: props.clientId,
        // user: activeUser.id,
        // type: 'monitoringform'
      }
    }
    // const where = {
    //   organisationId: activeOrg.id,
    //   id: params.formId
    //   // subject: props.clientId,
    //   // user: activeUser.id,
    //   // type: 'monitoringform'
    // }
    const submissions = await FormService.getSubmissions(apiToken, reqParams)
    if (submissions?.data?.length) {

      const thisSubmission = submissions.data[0]

      setCurrentFormId(thisSubmission.id)
      if (thisSubmission.feedbackRequired) {
        setWaitingForFeedback(true)
        if (parseInt(thisSubmission.feedbackRequiredBy) === parseInt(activeUser.id)) {
          setCurrentUserIsFeedback(true)
        }
      }
      if (thisSubmission.revisionRequired) {
        setWaitingForRevision(true)
        if (parseInt(thisSubmission.user) === parseInt(activeUser.id)) {
          setCurrentUserIsRevision(true)
        }
      }

      // return submissions.data[0]
    }
  }
  useEffect(() => {
    getFormSubmission()
  }, [])

  const submitForApproval = () => {
    // if this is a new form, mark it as revision require and submit as a draft
    props.submitForm('Draft', true, props.feedbackRequiredBy, false)
  }

  const requestChanges = () => {
    // if this is a new form, mark it as revision require and submit as a draft
    props.submitForm('Draft', false, null, true, feedback, params.formId)
  }

  const approveForm = () => {
    // if this is a new form, mark it as revision require and submit as a draft
    props.submitForm('Published', false, null, false, null, params.formId)
  }
  useEffect(() => {
    var thisShowSubmit = false
    if (params.viewtype !== 'view') {
      thisShowSubmit = true
    }

    // don't show the submit button if there's no one assigned to give feedback
    if (params.viewtype !== 'view' && !props.feedbackRequiredBy) {
      thisShowSubmit = false
    }



    setShowSubmit(thisShowSubmit)
  }, [props.consentChecked, props.feedbackRequiredBy])

  return (<>
    <Box direction='row' justify='between' margin={{ top: 'medium' }}>
      <Button label='< Back' onClick={() => props.previousPage()} secondary />
      <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
        {showSubmit && <Button disabled={!props.consentChecked} onClick={() => submitForApproval()} label='Submit' primary />}
        {waitingForFeedback && currentUserIsFeedback && <Button onClick={() => requestChanges()} label='Request Changes' primary />}
        {waitingForFeedback && currentUserIsFeedback && <Button onClick={() => approveForm()} label='Approve' />}
      </Box>
    </Box>
    {waitingForFeedback && currentUserIsFeedback && <Box pad={{ horizontal: 'small' }} margin={{ top: 'small' }}>
      <FormField
        label='Feedback on form'
      >
        <TextArea
          onChange={(event) => { setFeedback(event.target.value) }}
          value={feedback}
        />
      </FormField>
    </Box>}
  </>
  )
}
export default FeedbackSubmissionButton
