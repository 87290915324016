import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import SiteService from '../../services/SiteService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function SiteEdit () {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [siteDetails, setSiteDetails] = useState({
    id: '',
    name: '',
    address: '',
    postcode: '',
    city: '',
    notes: ''
  })

  const params = useParams()

  const siteId = params.siteid

  // Submit form values
  const submitForm = async (values) => {
    const siteData = {
      // id: values.id,
      address: values.address,
      city: values.city,
      name: values.name,
      organisation: activeOrg.id,
      postcode: values.postcode,
      notes: values.notes
    }

    if (siteId === 'new') {
      const siteAdded = await SiteService.create(apiToken, siteData)
      if (siteAdded.error) {
        NotificationService.error('Error adding the site, please try again - ' + siteAdded.error)
      } else {
        NotificationService.success('This site has been created. You must logout and then login again in order to access it.')
        console.log('Site created ', siteAdded)
        history.push('/sites')
      }
    } else {
      const siteUpdated = await SiteService.update(apiToken, siteId, siteData)
      if (siteUpdated.error) {
        NotificationService.error('Error updating the site, please try again - ' + siteUpdated.error)
      } else {
        NotificationService.success('Site updated')
        console.log('Site updated ', siteUpdated)
      }
    }
  }

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    let unmounted = false

    // Get site details
    const getSiteDetails = async (siteId) => {
      const params = {
        fields: 'id,name,notes,address,postcode,city'
      }
      const siteDetails = await SiteService.getSite(apiToken, siteId, params)
      if (siteDetails.error) {
        NotificationService.error(siteDetails.error)
      } else {
        if (!unmounted) {
          setSiteDetails(siteDetails.data[0])
          console.log(siteDetails.data[0])
        }
      }
    }

    if (siteId !== 'new') {
      getSiteDetails(siteId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>System Site: {siteDetails.id ? siteDetails.name : 'New Site'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {/*siteDetails.id &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              <Button href={`/site/${siteDetails.id}/delete`} label={<Text><FontAwesome color='background' icon={['fal', 'trash']} /> Deactivate Site</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
            </Box>
          </Box>
      </Box>*/}

      <Box background='white' elevation='small' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)
                setSiteDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={siteDetails}
            >
              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['auto', 'auto'],
                  large: ['auto', 'auto'],
                  xlarge: ['auto', 'auto']
                }}
                gap='small'
              >
                <Box direction='column'>
                  <Text>Name</Text>

                  <FormField
                    direction='column'
                    label='Site Name'
                    name='name'
                    required
                  >
                    <TextInput
                      name='name'
                      type='text'
                    />
                  </FormField>

                  <Box direction='column'>
                    <Text>Address</Text>

                    <Box direction='row-responsive' gap='medium'>
                      <FormField
                        direction='column'
                        label='Address'
                        name='address'
                      >
                        <TextInput
                          name='address'
                          type='text'
                        />
                      </FormField>

                      <FormField
                        direction='column'
                        label='Postcode'
                        name='postcode'
                      >
                        <TextInput
                          name='postcode'
                          type='text'
                        />
                      </FormField>

                      <FormField
                        direction='column'
                        label='City'
                        name='city'
                      >
                        <TextInput
                          name='city'
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>

                </Box>

                <Box direction='column'>
                  <Text>Notes</Text>

                  <FormField
                    direction='column'
                    label='Other Notes'
                    name='notes'
                  >
                    <TextArea
                      name='notes'
                      type='text'
                    />
                  </FormField>

                </Box>
              </ResponsiveGrid>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SiteEdit
