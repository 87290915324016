import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { constants } from '../../utils/constants'
import Responsive from '../../utils/Responsive'

import LocalStorageService from '../../services/LocalStorageService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DateInput from '../../components/simple/input/DateInput'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import OptionButtons from '../../components/compound/optionButtons/OptionButtons'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function Sensitive(props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [localUserDetails, setLocalUserDetails] = useState(props.userDetails)

  const [questions, setQuestions] = useState(props.questions)

  // Submit User Details
  const submitUserDetails = () => {
    props.submitForm(localUserDetails)
  }

  // Set flag that form has changed
  const setFormChanged = () => {
    props.setFormChanged(true)
  }

  // Need to include this as it takes time for
  // props.userDetails to get populated
  useEffect(() => {
    var userDetails = props.userDetails

    // existing clients are breaking with some of the new dropdowns
    // this is a quick hack to fix that
    const breakingDropdowns = [
      'traffickingType',
      'traffickingTypeSecondary',
      'literacyLevel',
      'education1',
      'employment1',
      'housingType1',
      'income2',
      'employment2',
      'housingType2',
      'income',
      'legalInterventions'
    ]

    for (const field of breakingDropdowns) {
      if (!userDetails[field]) {
        userDetails[field] = ''
      }
    }

    setLocalUserDetails(userDetails)
  }, [props.userDetails])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Sensitive</H2>
          <Button label={<FontAwesomeIcon icon={['fal', 'question-circle']} />} plain tip='Sensitive Client Details' />
        </Box>
        {localUserDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setFormChanged(true)
                setLocalUserDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitUserDetails(nextValue)
              }}
              value={localUserDetails}
            >

              <Responsive
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['2/3', '1/3'],
                  large: ['2/3', '1/3'],
                  xlarge: ['2/3', '1/3']
                }}
                gap='small'
              >
                <Box>
                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto'],
                      large: ['auto', 'auto'],
                      xlarge: ['auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Type of Trafficking */}
                      <FormField
                        label={questions[0].questionText}
                        name={questions[0].key}
                      >

                        <Select
                          name={questions[0].key}
                          // emptySearchMessage='No types found'
                          labelKey='label'
                          options={constants.traffickingTypes}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                    <Box>
                      {/* Secondary Type of Trafficking */}
                      <FormField
                        label={questions[17].questionText}
                        name={questions[17].key}
                      >
                        <Select
                          name={questions[17].key}
                          // emptySearchMessage='No types found'
                          labelKey='label'
                          options={constants.traffickingTypes}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Literacy Level */}
                      <FormField
                        label={questions[1].questionText}
                        name={questions[1].key}
                      >
                        <Select
                          name={questions[1].key}
                          // emptySearchMessage='No levels found'
                          labelKey='label'
                          options={constants.literacyLevel}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Education */}
                      <FormField
                        label={questions[2].questionText}
                        name={questions[2].key}
                      >
                        <Select
                          name={questions[2].key}
                          // emptySearchMessage='No education found'

                          labelKey='label'
                          options={constants.education}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='xsmall'
                  >
                    <Box>
                      {/* Employment 2 */}
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <Select
                          name={questions[6].key}
                          // emptySearchMessage='No employment found'

                          labelKey='label'
                          options={constants.employment}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                    <Box>
                      {/* Housing Type 2 */}
                      <FormField
                        label={questions[7].questionText}
                        name={questions[7].key}
                      >
                        <Select
                          name={questions[7].key}
                          // emptySearchMessage='No housing found'

                          labelKey='label'
                          options={constants.housingType}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                    <Box>                      {/* Income 2 */}
                      <FormField
                        label={questions[8].questionText}
                        name={questions[8].key}
                      >
                        <Select
                          name={questions[8].key}
                          // emptySearchMessage='No income found'

                          labelKey='label'
                          options={constants.income}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                  </Responsive>
                  {localUserDetails?.income === 'dwpbenefits' &&
                    <Responsive
                      columns={{
                        small: ['auto'],
                        medium: ['auto'],
                        large: ['auto'],
                        xlarge: ['auto']
                      }}
                      gap='small'
                    >
                      <Box>
                        {/* other income */}
                        <FormField
                          label={questions[18].questionText}
                          name={questions[18].key}
                        >
                          <TextInput
                            name={questions[18].key}
                            type='text'
                          />
                        </FormField>

                      </Box>

                    </Responsive>}

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />
                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Legal Interventions */}
                      <FormField
                        label={questions[9].questionText}
                        name={questions[9].key}
                      >
                        <Select
                          multiple
                          name={questions[9].key}
                          // emptySearchMessage='No interventions found'
                          labelKey='label'
                          options={constants.legalInterventions}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Previous Pathways */}
                      <FormField
                        label={questions[10].questionText} name={questions[10].key}
                      >
                        <TextInput
                          name={questions[10].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                    <Box>
                      {/* Counselling Reports Completed */}
                      <FormField
                        label={questions[11].questionText}
                        name={questions[11].key}
                      >
                        <OptionButtons
                          id={questions[11].key}
                          name={questions[11].key}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />

                  <Box>
                    <Box>
                      <Text weight='bold'>Professional's contact details</Text>
                    </Box>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto'],
                      large: ['auto', 'auto'],
                      xlarge: ['auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* First Name */}
                      <FormField
                        label={questions[19].questionText}
                        name={questions[19].key}
                      >
                        <TextInput
                          name={questions[19].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Last Name */}
                      <FormField
                        label={questions[20].questionText}
                        name={questions[20].key}
                      >
                        <TextInput
                          name={questions[20].key}
                          type='text'
                        />
                      </FormField>
                    </Box>


                    <Box>
                      {/* Address */}
                      <FormField
                        label={questions[21].questionText}
                        name={questions[21].key}
                      >
                        <TextInput
                          name={questions[21].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Postcode */}
                      <FormField
                        label={questions[22].questionText}
                        name={questions[22].key}
                      >
                        <TextInput
                          name={questions[22].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* City */}
                      <FormField
                        label={questions[23].questionText}
                        name={questions[23].key}
                      >
                        <TextInput
                          name={questions[23].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto'],
                      large: ['auto', 'auto'],
                      xlarge: ['auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Mobile */}
                      <FormField
                        label={questions[24].questionText}
                        name={questions[24].key}
                      >
                        <TextInput
                          name={questions[24].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Email */}
                      <FormField
                        label={questions[25].questionText}
                        name={questions[25].key}
                      >
                        <TextInput
                          name={questions[25].key}
                          type='email'
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                </Box>

                <Box
                  margin={{ horizontal: 'medium' }}
                  direction='column'
                >
                  <Text>Care Plan</Text>

                  <Box direction='row'>
                    {/* Care Plan Last Completed */}
                    <FormField
                      label={questions[12].questionText}
                      name={questions[12].key}
                    >
                      <DateInput
                        id={questions[12].key}
                        name={questions[12].key}
                      />
                    </FormField>

                    {/* Care Plan Next Due */}
                    <FormField
                      label={questions[13].questionText}
                      name={questions[13].key}
                    >
                      <DateInput
                        id={questions[13].questionText}
                        name={questions[13].key}
                      />
                    </FormField>
                  </Box>

                  <Box direction='column'>
                    <Text>Monitoring Form</Text>

                    <Box direction='row'>
                      {/* Monitoring Form Last Completed */}
                      <FormField
                        label={questions[14].questionText}
                        name={questions[14].key}
                      >
                        <DateInput
                          id={questions[14].questionText}
                          name={questions[14].key}
                        />
                      </FormField>

                      {/* Monitoring Form Next Due */}
                      <FormField
                        label={questions[15].questionText}
                        name={questions[15].key}
                      >
                        <DateInput
                          id={questions[15].key}
                          name={questions[15].key}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Box height='medium'>
                    {/* Sensitive Notes */}
                    <FormField
                      contentProps={{ height: 'medium' }}
                      label={questions[16].questionText}
                      name={questions[16].key}
                    >
                      <TextArea
                        fill
                        name={questions[16].key}
                      />
                    </FormField>
                  </Box>
                </Box>
              </Responsive>

              <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => props.previousPage()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}

export default Sensitive
