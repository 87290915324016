import React, { useContext, useEffect, useState } from 'react'

import {
  ResponsiveContext
} from 'grommet'

import {
  Error,
  FormContext
} from '../shared'

// Appt
import Box from '../../../../components/simple/box/Box'
import Divider from '../../../../components/simple/divider/Divider'
import FormField from '../../../../components/simple/formField/FormField'
import RatingButtonGroup from '../../../../components/compound/ratingButtonGroup/RatingButtonGroup'
import ClientNames from '../../shared/ClientNames'

import NotifyStaff from '../../shared/NotifyStaff'

// Services
import LocalStorageService from '../../../../services/LocalStorageService'

function StepOneDepression () {
  const { valid, setValid, clientId } = useContext(FormContext)
  const size = useContext(ResponsiveContext)

  const stepOneInputs = [
    (setValid) => (
      <>
        <Box direction='column' gap='small'>
          <ClientNames />

          <FormField
            label='Little interest or pleasure in doing things'
            htmlFor='littleInterest'
            name='littleInterest'
          >
            <RatingButtonGroup
              clear='n/a'
              id='littleInterest'
              name='littleInterest'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Feeling down, depressed, or hopeless'
            htmlFor='depressed'
            name='depressed'
          >
            <RatingButtonGroup
              clear='n/a'
              id='depressed'
              name='depressed'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Trouble falling or staying asleep, or sleeping too much'
            htmlFor='sleeping'
            name='sleeping'
          >
            <RatingButtonGroup
              clear='n/a'
              id='sleeping'
              name='sleeping'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Feeling tired or having little energy'
            htmlFor='tired'
            name='tired'
          >
            <RatingButtonGroup
              clear='n/a'
              id='tired'
              name='tired'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Poor appetite or overeating'
            htmlFor='overeating'
            name='overeating'
          >
            <RatingButtonGroup
              clear='n/a'
              id='overeating'
              name='overeating'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Feeling bad about yourself — or that you are a failure or have let yourself or your family down'
            htmlFor='feelingBad'
            name='feelingBad'
          >
            <RatingButtonGroup
              clear='n/a'
              id='feelingBad'
              name='feelingBad'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Trouble concentrating on things, such as reading the newspaper or watching television'
            htmlFor='noConcentration'
            name='noConcentration'
          >
            <RatingButtonGroup
              clear='n/a'
              id='noConcentration'
              name='noConcentration'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual'
            htmlFor='speakingSlowly'
            name='speakingSlowly'
          >
            <RatingButtonGroup
              clear='n/a'
              id='speakingSlowly'
              name='speakingSlowly'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <FormField
            label='Thoughts that you would be better off dead or of hurting yourself in some way'
            htmlFor='badThoughts'
            name='badThoughts'
          >
            <RatingButtonGroup
              clear='n/a'
              id='badThoughts'
              name='badThoughts'
              minRange={0}
              maxRange={3}
              step={1}
            />
          </FormField>

          <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

          <NotifyStaff />
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'medium' }} />

      </>
    ),
    // () => (
    //   <FormField
    //     htmlFor='radio-button-group-validation'
    //     label='RadioButtonGroup'
    //     name='radio-button-group-validation'
    //   >
    //     <RadioButtonGroup
    //       id='radio-button-group-validation'
    //       name='radio-button-group-validation'
    //       options={['Radio button 1', 'Radio button 2']}
    //     />
    //   </FormField>
    // )
  ]

  //   useEffect(() => {
  //     let unmounted = false

  //     // Get Staff for this organisation
  //     const getStaff = async () => {
  //       var params = {
  //         fields: 'id,firstName,lastName',
  //         limit: 1000,
  //         type: 'user',
  //         orgId: activeOrg.id,
  //         siteId: activeSite?.id || null
  //       }

  //       const users = await UserService.getUsers(apiToken, params)
  //       if (users?.error) {
  //         // NotificationService.error(users.error)
  //       } else {
  //         if (!unmounted) {
  //           if (users?.data) {
  //             const mappedUsers = users.data.map((data, index) => ({
  //               id: data.id,
  //               name: (data?.firstName || '') + ' ' + (data?.lastName || '')
  //             }))
  //             setStaff(mappedUsers)
  //           }
  //         }
  //       }
  //     }

  //     getStaff()

  //     return () => { unmounted = true }
  //   }, [])

  return (
    <>
      <Box width='100%'>
        {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
        {stepOneInputs.map((input, index) => (
          <Box key={index}>
            {input(setValid)}
          </Box>
        ))}
        {/* </Grid> */}
      </Box>
      {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )}
    </>
  )
}

export default StepOneDepression
