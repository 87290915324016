import React, { useState, useEffect } from 'react'

// Utils
import { constants } from '../../../utils/constants'

// Appt Components
import Select from '../../../components/simple/input/Select'

const FieldBox = (props) => {
  const [filters, setFilters] = useState([])

  useEffect(() => {
    // Set filter options based on modelType
    if (props.modelType !== undefined) {
      setFilters(constants[props.modelType])
    }
  }, [props.modelType])

  return (
    <>
      <Select
        {...props}
        // name='reportSearch'
        labelKey='label'
        onChange={({ value: nextValue }) => {
          props.handleFieldChanged(props.name, nextValue)
        }}
        options={filters}
        valueKey={{ key: 'value' }}
      />
    </>
  )
}

export default FieldBox
