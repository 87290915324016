// react
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Meter as GMeter } from 'grommet'

/**
 * `Meter` renders a Grommet Meter component
 */
class Meter extends React.Component {
  render () {
    return (
      <GMeter
        {...this.props}
      />)
  }
}

Meter.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Background color.  
   *   
   * "string"  
   * {  
   * &nbsp;"color": "string",  
   * &nbsp;"opacity": "weak",  
   * &nbsp;&nbsp;"medium",  
   * &nbsp;&nbsp;"strong",  
   * &nbsp;&nbsp;"number",  
   * &nbsp;&nbsp;"boolean",  
   * }  
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The maximum value for the Meter. 
   *   
   * number  
   * "string"
   */
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * Whether to round the line ends.  
   *   
   * true  
   * **false**  
   */
  round: PropTypes.bool,
  /**
   * The size of the Meter.  
   *   
   * "xsmall"  
   * "small"  
   * **"medium"**  
   * "large"  
   * "xlarge"  
   * "full"  
   * "string"
   */
  size: PropTypes.string,
  /**
   * The size of the Meter.  
   *   
   * "xsmall"  
   * "small"  
   * **"medium"**  
   * "large"  
   * "xlarge"  
   * "full"  
   * "string"
   */
  thickness: PropTypes.string,
  /**
   * The visual type of meter.  
   *   
   * **"bar"**  
   * "circle"
   */
  type: PropTypes.string,
  /**
   * Array of value objects describing the data.  
   * 'value' is the actual numeric value.  
   * 'label' is a text string describing it.  
   * 'color' indicates the color name to use.  
   * If not specified a default one will be chosen.  
   * 'onClick' will be called when the user clicks on it.  
   * Set 'highlight' to call attention to it.  
   * 'onHover' will be called with a boolean argument indicating when the user hovers onto or away from it.  
   *   
   * [{"value": 75}]  
   * [  
   * &nbsp;{  
   * &nbsp;&nbsp;"value": 45,  
   * &nbsp;&nbsp;"color": "graph=0",  
   * &nbsp;&nbsp;"highlight": false,  
   * &nbsp;&nbsp;"label": "utilisation",  
   * &nbsp;&nbsp;"onClick": "() => {}",  
   * &nbsp;&nbsp;"onHover": "(true) => {}",  
   * &nbsp;}
   * ]  
   */
  values: PropTypes.array
}

export default Meter
