import { fileTypes } from './fileTypes'

// Validate a CSV file for invalid fields
function validateInvalidFields (csvRows, type) {
  // Get the required fileType
  const thisFileType = fileTypes[type]

  // Check required fields are present
  var missingRequiredFields = []
  for (const requiredField of thisFileType.requiredFields) {
    if (!csvRows[0].data.includes(requiredField)) {
      missingRequiredFields.push(requiredField)
    }
  }

  if (missingRequiredFields.length) {
    return {
      valid: false,
      error: `Fields ("${missingRequiredFields.join('", "')}") are missing required fields. The file will not be imported.`
    }
  }

  // loop through all of the required & optional fields
  // in the filetype declaration to check if csvRows has
  // any extra fields
  const allFileTypeFields = thisFileType.requiredFields.concat(thisFileType.optionalFields)
  const outOfBoundFields = []
  for (const thisKey of csvRows[0].data) {
    if (!allFileTypeFields.includes(thisKey)) {
      outOfBoundFields.push(thisKey)
    }
  }

  let warning = null
  if (outOfBoundFields.length) {
    warning = `This CSV has fields ("${outOfBoundFields.join('", "')}") that aren't recognised by the system, those fields will not be imported.`
  }

  return {
    valid: true,
    warning: warning
  }
}

// Validate a CSV file for empty required fields
function validateRequiredFields (csvRows, type) {
  // Get the required fileType
  const thisFileType = fileTypes[type]

  // Get position of required fields
  const requiredFieldIndexes = []
  for (const requiredField of thisFileType.requiredFields) {
    requiredFieldIndexes.push(csvRows[0].data.indexOf(requiredField))
  }

  // Check each row has a required field populated
  const missingRequiredData = []
  csvRows.forEach((dataRow, index) => {
    // Ignore header row zero
    if (index !== 0) {
      requiredFieldIndexes.forEach((fieldIndex) => {
        if (dataRow.data[fieldIndex] === '') {
          missingRequiredData.push(`(${index}, ${fieldIndex})`)
        }
      })
    }
  })

  if (missingRequiredData.length) {
    return {
      valid: false,
      error: `Fields ${missingRequiredData.join(', ')} are missing required data. This row will not be imported.`
    }
  }

  return {
    valid: true
  }
}

// Add all fields ready for insert to the database
function buildInsertFields (csvRows, type) {
  // Get the required fileType
  const thisFileType = fileTypes[type]

  // Get position of all fields
  const fieldsToAddIndexes = []
  const allFileTypeFields = thisFileType.requiredFields.concat(thisFileType.optionalFields)
  for (const fieldsToInsert of allFileTypeFields) {
    fieldsToAddIndexes.push(csvRows[0].data.indexOf(fieldsToInsert))
  }

  console.log(fieldsToAddIndexes)
  // Get each field and populate an insert statement
  const dataToInsert = []
  let rowToInsert = []
  // for (var i = 1; i < csvRows.length; i++) {
  //   fieldsToAddIndexes.forEach((fieldIndex) => {
  //     missingRequiredData.push(`(${i}, ${fieldIndex})`)
  //   })
  // }
  csvRows.forEach((dataRow, index) => {
    rowToInsert = {}
    // Ignore header row zero
    if (index !== 0) {
      fieldsToAddIndexes.forEach((fieldIndex) => {
        rowToInsert[allFileTypeFields[fieldIndex]] = dataRow.data[fieldsToAddIndexes[fieldIndex]]
        // rowToInsert.push(`${allFileTypeFields[fieldIndex]}: ${dataRow.data[fieldsToAddIndexes[fieldIndex]]}`)
      })
      dataToInsert.push(rowToInsert)
    }
  })

  return dataToInsert
}

export { buildInsertFields, validateInvalidFields, validateRequiredFields }
