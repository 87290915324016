// React
import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

// zxcvbn
import zxcvbn from 'zxcvbn'

import * as queryString from 'query-string'

// Appt
import Anchor from '../../simple/anchor/Anchor.js'
import Box from '../../simple/box/Box.js'
import Button from '../../simple/button/Button.js'
import Form from '../../simple/form/Form.js'
import FormField from '../../simple/formField/FormField.js'
import Image from '../../simple/image/Image.js'
import Meter from '../../simple/meter/Meter'
import Text from '../../simple/text/Text.js'
import TextInput from '../../simple/input/TextInput.js'

/**
 * `PasswordReset` renders a PasswordReset screen
 */
function PasswordReset (props) {
  const history = useHistory()
  const location = useLocation()

  const [password, setPassword] = useState({
    password: ''
  })
  const [strength, setStrength] = useState(null)

  const strengthLimit = {
    0: 'Insecure',
    1: 'Weak',
    2: 'Average',
    3: 'Good',
    4: 'Strong'
  }

  const strengthColor = {
    0: 'red',
    1: 'orangered',
    2: 'orange',
    3: 'yellowgreen',
    4: 'green'
  }
  const handleBackClick = () => history.push('/login')

  const handlePasswordChanged = (pwd) => {
    setPassword(pwd)

    if (pwd.password.length) {
      setStrength(zxcvbn(pwd.password).score)
    } else {
      setStrength(null)
    }
  }

  const onSubmit = () => {
    // try {
    //   var passwordResetStatus = await PasswordService.resetPassword(organisationId, values.email)
    // } catch (err) {
    //   NotificationService.error(err)
    // }

    // NotificationService.info('Your password has been reset')

    const params = queryString.parse(location.search)
    props.handlePasswordReset(params.token, password)
  }

  return (
    <>
      <Box align='center' animation='fadeIn' pad='large' alignSelf='center' width='medium' background='background'>
        <Box width='small'>
          {/* <Image fit='contain' src={props.image} /> */}
          <Text margin={{ top: '30px', bottom: '30px' }} textAlign='center' weight='bold'>Reset Password</Text>
        </Box>

        <Box width='medium'>
          <Form
            onChange={nextValue => {
              handlePasswordChanged(nextValue)
            }}
            onSubmit={() => onSubmit()}
            value={password}
          >
            <Text size='xsmall'>To conform with our Strong Password policy, you are required to use a sufficiently strong password. Please use a combination of upper and lowercase letter along with numbers and symbols.</Text>
            {strength !== null &&
              <Meter background='light-2' max={5} size='full' thickness='xsmall' type='bar' values={[{ color: strengthColor[strength], value: strength + 1 }]} />}

            <FormField margin={{ vertical: 'small' }} name='password' required>
              <TextInput name='password' placeholder='Enter password' type='password' />
              {strength !== null ? <Text color={strengthColor[strength]}>{strengthLimit[strength]}</Text> : ''}
            </FormField>

            <Button disabled={strength < 2} fill='horizontal' label='Change Password' primary type='submit' />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Anchor onClick={() => handleBackClick()} size='xsmall' label='< Back to login' />
            </Box>
          </Form>
        </Box>
      </Box>
    </>)
}

export default PasswordReset
