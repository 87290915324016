// Grommet
import { deepMerge } from 'grommet/utils'
import { css } from 'styled-components'

// Default theme
import { theme } from './theme'

export const snowdropTheme = deepMerge(theme, {
  // Global Settings
  global: {
    font: {
      family: 'Roboto Slab, Open Sans, sans-serif'
      // Roboto Slab
    },
    colors: {
      brand: '#00788A',
      primary: '#00788A',
      background: '#fffefe',
      secondary: '#F58466',
      'text-color': '#00788A',
      darkGrey: '#333',
      focus: '#FFD200',
      primaryOne: '#00788A',
      primaryTwo: '#22BCB9',
      primaryThree: '#E9E3DC',
      secondaryOne: '#F58466',
      secondaryTwo: '#FFD200',
      secondaryThree: '#695E4A',
      tertiaryOne: '#8DC63F',
      tertiaryTwo: '#F89728',
      tertiaryThree: '#52C6D8',
      tertiaryFour: '#A2988A',
      stickyNote: '#fde492',

      'optionbuttons-background-checked': 'brand',
      'optionbuttons-background-hover': 'light-4',
      'optionbuttons-background-default': 'light-2',

      random: '#0f0',
      basicPermissions: 'rgba(52, 153, 2, 0.15)',
      moderatePermissions: 'rgba(245, 204, 5, 0.15)',
      privilegedPermissions: 'rgba(204, 51, 0, 0.15)'
    },
    control: {
      border: {
        radius: 'none'
      }
    },
    focus: {
      shadow: {
        size: '0px'
      },
      outline: {
        size: '1px'
      }
    },
    input: {
      padding: {
        vertical: '5px'
      },
      weight: '400',
      font: {
        size: 'small'
      }
    }
  },
  calendar: {
    extend: 'background-color: white'
  },
  text: {
    color: 'primaryTwo',
    xsmall: {
      size: '12px'
    },
    small: {
      size: '14px'
    },
    medium: {
      size: '18px'
    },
    large: {
      size: '22px'
    },
    xlarge: {
      size: '26px'
    },
    xxlarge: {
      size: '34px'
    }
  },
  // heading: {
  // level: {
  // 1: {
  // font: {
  // size: 'small'
  // }
  // }
  // }
  // },
  // Button
  button: {
    default: {
      background: { color: 'brand' },
      color: 'white',
      round: 'small'
    },
    primary: {
      background: { color: 'brand' },
      color: 'white',
      round: 'small',
      font: {
        size: 'medium'
      },
      padding: {
        horizontal: 'large'
      }
    },
    secondary: {
      background: { color: 'grey' },
      color: 'white',
      border: { color: 'grey' },
      padding: {
        horizontal: 'medium'
      }
    },
    hover: {
      default: {
        color: '#fff',
        background: { color: 'secondary' }
      },
      primary: {
        color: '#fff',
        background: { color: 'secondary' }
      },
      secondary: {
        color: '#fff',
        background: { color: 'primary' }
      }
    },
    border: {
      radius: 'none'
    },
    size: {
      medium: {
        border: {
          radius: 'small'
        },
        pad: {
          vertical: 'none'
        }
      }
    }

  },
  // formField
  formField: {
    label: {
      margin: {
        left: '0px',
        bottom: 'none'
      },
      size: 'small'
    },
    content: {
      margin: {
        bottom: 'none'
      },
      color: '#f00'
    },
    extend: {
      color: 'text-color'
    },
    info: {
      size: 'xsmall'
    }
  },
  select: {
    container: {
      // extend needed otherwise an empty select dropdown
      // will have the dashboard background as it's background
      extend: `
        background-color: white;
      `
    },
    options: {
      container: {
        background: 'white'
      }
    }
  },
  dataTable: {
    header: {
      font: {
        weight: '500'
      }
    },
    groupHeader: {
      font: {
        weight: '500'
      }
    }
  },
  // tab
  tab: {
    active: {
      background: 'brand',
      color: 'white'
    },
    background: 'lightgrey',
    border: undefined,
    color: 'background',
    hover: {
      background: 'secondary',
      color: 'white'
    },
    margin: undefined,
    pad: {
      bottom: undefined,
      horizontal: 'small',
      vertical: 'small'
    }
    // extend: ({ theme }) => css`
    //   // border-radius: 4px;
    //   /* or 'border-radius: ${theme.global.control.border.radius}' */
    //   box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
    //   /* or 'box-shadow: ${theme.global.elevation.light.small}' */
    // `
  },
  tabs: {
    // background: 'dark-3',
    gap: 'medium'
    // header: {
    //   // background: 'dark-2',
    //   extend: ({ theme }) => css`
    //   padding: 10px;
    //   /* or 'padding: ${theme.global.edgeSize.small}' */
    //   box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.50);
    //   /* or 'box-shadow: ${theme.global.elevation.light.medium}' */
    // `
    // },
    // panel: {
    //   extend: ({ theme }) => css`
    //   padding: 48px;
    //   /* or 'padding: ${theme.global.edgeSize.large}' */
    //   box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.50);
    //    /* or 'box-shadow: ${theme.global.elevation.light.medium}' */
    // `
    // }
  },
  // textInput
  textInput: {
    extend: {
      background: 'white',
      color: 'primary'
    },
    focus: {

    },
    padding: {
      vertical: '5px'
    }
  },
  // Layer
  layer: {
    background: {
      light: 'white'
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)'
    }
  }
})
