import React, { useState, useEffect } from 'react'

// Grommet
import { ResponsiveContext } from 'grommet'

import { constants } from '../../../utils/constants'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import DateInput from '../../../components/simple/input/DateInput'
import Divider from '../../../components/simple/divider/Divider'
import H2 from '../../../components/simple/heading/H2.js'
import H4 from '../../../components/simple/heading/H4.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import RadioButtonGroup from '../../../components/simple/input/RadioButtonGroup'
import TextInput from '../../../components/simple/input/TextInput'

function HousingDetails (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)
  useEffect(() => { setLocalFormDetails(props.formDetails) }, [props.formDetails])

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Housing Details</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                <FormField
                  name={questions[0].key}
                  required
                >
                  <RadioButtonGroup
                    // direction='row-responsive'
                    labelKey='label'
                    name={questions[0].key}
                    options={constants.housingStatus}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Key Dates */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <DateInput
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <H4>Income Details</H4>

              <Box direction='row-responsive' gap='small'>
                <FormField
                  name={questions[2].key}
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name={questions[2].key}
                    options={constants.incomeDetails}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <H4>Trafficking and NRM support details</H4>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* address 1 */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <TextInput
                          name={questions[3].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Address 2 */}
                      <FormField
                        label={questions[4].questionText}
                        name={questions[4].key}
                      >
                        <TextInput
                          name={questions[4].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Phone */}
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                      >
                        <TextInput
                          name={questions[5].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Postcode */}
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <TextInput
                          name={questions[6].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              {/* Aftercare exit date */}
              <FormField
                label={questions[7].questionText}
                name={questions[7].key}
              >
                <DateInput
                  name={questions[7].key}
                />
              </FormField>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Referrer name */}
                      <FormField
                        label={questions[8].questionText}
                        name={questions[8].key}
                        required
                      >
                        <TextInput
                          name={questions[8].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Contact number */}
                      <FormField
                        label={questions[9].questionText}
                        name={questions[9].key}
                      >
                        <TextInput
                          name={questions[9].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Referrer relationship */}
                      <FormField
                        label={questions[10].questionText}
                        name={questions[10].key}
                        required
                      >
                        <TextInput
                          name={questions[10].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* email */}
                      <FormField
                        label={questions[11].questionText}
                        name={questions[11].key}
                      >
                        <TextInput
                          name={questions[11].key}
                          type='email'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <Box direction='row'>
                {/* Had support from reach in */}
                <FormField
                  label={questions[12].questionText}
                  name={questions[12].key}
                >
                  <OptionButtons
                    id={questions[12].key}
                    name={questions[12].key}
                  />
                </FormField>
              </Box>

              {localFormDetails.reachInSupport === 'Yes' &&
                <>
                  <ResponsiveContext.Consumer>
                    {responsive => (
                      <Box direction='row-responsive' gap='small'>
                        <Box width={responsive === 'small' ? '100%' : '50%'}>
                          {/* date reach in started */}
                          <FormField
                            label={questions[13].questionText}
                            name={questions[13].key}
                          >
                            <DateInput
                              name={questions[13].key}
                            />
                          </FormField>
                        </Box>

                        <Box width={responsive === 'small' ? '100%' : '50%'}>
                          <FormField
                            label={questions[14].questionText}
                            name={questions[14].key}
                          >
                            <DateInput
                              name={questions[14].key}
                            />
                          </FormField>
                        </Box>
                      </Box>)}
                  </ResponsiveContext.Consumer>

                  <ResponsiveContext.Consumer>
                    {responsive => (
                      <Box direction='row-responsive' gap='small'>
                        <Box width={responsive === 'small' ? '100%' : '50%'}>
                          <FormField
                            label={questions[15].questionText}
                            name={questions[15].key}
                          >
                            <TextInput
                              name={questions[15].key}
                              type='text'
                            />
                          </FormField>
                        </Box>

                        <Box width={responsive === 'small' ? '100%' : '50%'}>
                          <FormField
                            label={questions[16].questionText}
                            name={questions[16].key}
                          >
                            <TextInput
                              name={questions[16].key}
                              type='text'
                            />
                          </FormField>
                        </Box>
                      </Box>)}
                  </ResponsiveContext.Consumer>
                </>}
              <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default HousingDetails
